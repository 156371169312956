import { createStore, applyMiddleware } from "redux";
import logger from "redux-logger";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import ReduxThunk from "redux-thunk";
import createSagaMiddleware from "redux-saga";
import { composeWithDevTools } from "redux-devtools-extension";
import { createBrowserHistory } from "history";

import createRootReducer from "./rootReducer";
import rootSaga from "./rootSaga";

const customHistory = createBrowserHistory();
const sagaMiddleware = createSagaMiddleware(); // 사가 미들웨어를 만듭니다.

const persistConfig = {
	key: "root",
	storage,
	whitelist: ["auth", "food", "channel"],
};

export default function configureStore(s) {
	let mode = process.env.REACT_APP_MODE;
	let store = null;

	if (mode !== "dev" && mode !== "local") {
		store = createStore(
			persistReducer(persistConfig, createRootReducer(customHistory)),
			// logger 를 사용하는 경우, logger가 가장 마지막에 와야합니다.
			applyMiddleware(
				ReduxThunk.withExtraArgument({ history: customHistory }),
				sagaMiddleware // 사가 미들웨어를 적용하고
			)
		);
	} else {
		store = createStore(
			persistReducer(persistConfig, createRootReducer(customHistory)),
			// logger 를 사용하는 경우, logger가 가장 마지막에 와야합니다.
			composeWithDevTools(
				applyMiddleware(
					ReduxThunk.withExtraArgument({ history: customHistory }),
					sagaMiddleware, // 사가 미들웨어를 적용하고
					logger
				)
			)
		);
	}

	const persistor = persistStore(store);
	store.sagaTask = sagaMiddleware.run(rootSaga);

	return { store: store, persistor: persistor };
}
