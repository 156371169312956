import { Button, Modal } from "antd";

const ProductLinkInformationModal = ({
	item,
	linkInfoShow,
	setLinkInfoShow,
	mediaResult,
}) => {
	return (
		<>
			<Modal
				title=""
				wrapClassName="product-link-modal-wrap product-link-information-wrap new-link-info-modal"
				open={linkInfoShow}
				onCancel={() => setLinkInfoShow(false)}
				footer={<></>}
				centered={true}
				closable={!mediaResult}
				width={382}
			>
				<div className={`product-link-information-modal-div`}>
					<p>
						상품 판매를 원하신다면,{"\n"}링크복사를 통해 복사해서 사용해주세요.
					</p>
					<p>
						상품 정보 클릭 시 넘어가는 페이지는{"\n"}리워드가 적용되지 않으니
						{"\n"}반드시 링크생성을 통해 사용해 주세요
					</p>
					<Button
						className={`product-link-information-btn`}
						onClick={() => {
							window.open(
								`https://www.dangyoung.com/products/${item.target_id}`
							);
							setLinkInfoShow(false);
						}}
					>
						상품정보 보러가기
					</Button>
				</div>
			</Modal>
			{/* <Modal
				title=""
				wrapClassName="product-link-modal-wrap product-link-information-wrap"
				open={linkInfoShow}
				onCancel={() => setLinkInfoShow(false)}
				footer={<></>}
				centered={true}
				closable={!mediaResult}
			>
				<div className={`product-link-information-modal-div`}>
					{!mediaResult && (
						<h2
							style={{
								fontSize: "1.4em",
								color: "#212529",
								fontWeight: "bold",
								letterSpacing: "-0.8px",
							}}
						>
							꼭 확인해주세요!
						</h2>
					)}
					<h4>
						상품 판매를 원하신다면,{"\n"}
						{mediaResult ? (
							"링크생성을 통해 복사해서 사용"
						) : (
							<span>링크는 반드시 링크생성을 통해 복사해서 사용</span>
						)}
						해주세요
					</h4>
					{!mediaResult && (
						<img
							src={`/assets/images/seller/link-information.png`}
							alt={`링크생성 이미지`}
							style={{
								width: "50%",
								minWidth: "250px",
							}}
						/>
					)}
					{mediaResult ? (
						<p
							style={{
								width: "70%",
							}}
						>
							상품 정보 클릭 시 넘어가는 페이지는 리워드가 적용되지 않으니
							반드시 링크생성을 통해 사용해 주세요
						</p>
					) : (
						<p>
							상품 정보 클릭 시 넘어가는 페이지의 링크는 리워드가 적용되지
							않으니
							{"\n"}
							반드시 링크생성을 통해 사용해주셔야 합니다{"\n\n"}
							상품의 상세 정보를 확인해보시겠어요?
						</p>
					)}
					{mediaResult ? (
						<Button
							className={`product-link-information-btn`}
							onClick={() => {
								window.open(
									`https://www.dangyoung.com/products/${item.target_id}`
								);
								setLinkInfoShow(false);
							}}
						>
							상품정보 보러가기
						</Button>
					) : (
						<div className={`product-link-information-modal-footer`}>
							<button
								onClick={() => {
									setLinkInfoShow(false);
								}}
							>
								취소
							</button>
							<button
								className={`active`}
								onClick={() => {
									window.open(
										`https://www.dangyoung.com/products/${item.target_id}`
									);
								}}
							>
								보러가기
							</button>
						</div>
					)}
				</div>
			</Modal> */}
		</>
	);
};

export default ProductLinkInformationModal;
