import { useEffect } from "react";

import SignUpHeader from "../../components/user/SignUpHeader";

const Terms = ({ pageType }) => {
	useEffect(() => {
		if (!(pageType && pageType === "component")) {
			window.scrollTo({ top: 0 });
		}
	}, []);

	return (
		<>
			{pageType && pageType === "component" ? (
				<div className={`product-body`}>
					<p
						style={{
							color: "#979797",
							fontWeight: 600,
							fontSize: "1.2em",
							lineHeight: "1.2",
						}}
					>
						[필수] 이용약관
					</p>
					<p
						style={{
							whiteSpace: `pre-line`,
							fontSize: `1em`,
							color: `#979797`,
							fontWeight: 500,
							lineHeight: "1.2",
						}}
					>
						{/*하우투약 서비스 약관{`\n`}*/}
						{/*{`\n`}*/}
						임시 점검과 같은 서비스 이용에 지장이 있을 때는 사전에 미리
						공지하도록 하고 있습니다. 혹시라도 서비스의 운영을 방해하거나 혼란을
						유발시키는 등 다른 회원에게 피해를 입히는 회원은 서비스 이용에
						제한을 두어 다른 회원들이 쾌적한 서비스를 이용할 수 있도록 하고
						있습니다. 또한 회원님의 최소한의 개인정보만 요청하고 사용 전 모든
						것에 동의를 구하며 책임지고 관리하도록 하겠습니다.{`\n`}
						{`\n`}제 1 조 (목적){`\n`}
						주식회사 하우투약(이하 “회사”)이 제공하는 서비스를 이용해 주셔서
						감사합니다. 서비스를 이용함으로써 귀하는 본 약관에 동의하게 되므로
						본 약관을 주의 깊게 읽어 보시기 바랍니다. 본 이용약관은 이용자가
						회사가 제공하는 서비스를 이용하는데 적용됩니다. 본 이용 약관에
						언급되는 ‘하우투약 서비스’ 또는 ‘서비스’는 모든 특성과 기능, 후기,
						사용자 인터페이스뿐만 아니라 서비스와 관련된 모든 콘텐츠와
						소프트웨어를 포함하는 회사가 제공하는 개인 맞춤형 서비스를
						의미합니다. 본 이용 약관은 회사가 제공하는 모바일 애플리케이션의
						이용에 대하여 회사와 서비스 이용자의 권리, 의무 및 책임사항,
						이용조건 및 절차 등 기본적인 사항을 규정합니다.{`\n`}
						{`\n`}제 2 조 (용어의 정의){`\n`}① 이 약관에서 사용하는 용어의
						정의는 다음과 같습니다.{`\n`}
						1. 서비스: 회사가 제공하는 모든 서비스를 말합니다. (당신의영양제 등)
						{`\n`}
						2. “회원” 이란 이 약관에 따라 회사와 이용계약을 체결하고, 회사가
						제공하는 서비스를 이용하는 자를 의미합니다.{`\n`}
						3. “모바일 기기”란 콘텐츠를 다운로드 받거나 설치하여 사용할 수 있는
						기기로서, 휴대폰, 스마트폰, 태블릿 등을 의미합니다.{`\n`}
						4. “계정정보”란 회원의 회원번호와 외부계정정보, 기기정보, 별명,
						프로필 사진 등 회원이 회사에 제공한 정보와 서비스이용정보 (몸무게,
						키, 음식물 섭취 정보 등), 이용요금 결제 정보 등을 통칭합니다.
						{`\n`}
						5. “애플리케이션”이란 회사가 제공하는 서비스를 이용하기 위하여
						모바일 기기를 통해 다운로드 받거나 설치하여 사용하는 프로그램 일체를
						의미합니다.{`\n`}
						6. “게시물”이란 회원이 타인 또는 자신이 보게 할 목적으로 회사가
						제공하는 서비스 상에 게재한 부호, 문자, 음성, 음향, 그림, 사진,
						동영상, 링크 등으로 구성된 각종 콘텐츠 자체 또는 파일을 말합니다.
						{`\n`}② 이 약관에서 사용하는 용어의 정의는 본 조 제1항에서 정하는
						것을 제외하고는 관계법령 및 서비스별 정책에서 정하는 바에 의하며,
						이에 정하지 아니한 것은 일반적인 상관례에 따릅니다.{`\n`}
						{`\n`}제 3 조 (약관의 효력 및 변경){`\n`}① 회사는 본 약관의 내용을
						회원이 쉽게 알 수 있도록 모바일 애플리케이션 화면에 게시합니다.
						{`\n`}② 회사는 필요한 경우 관련 법령을 위배하지 않는 범위 내에서 본
						약관을 변경할 수 있습니다.{`\n`}③ 회사는 본 약관이 변경되는 경우
						적용일자 및 개정내용, 개정사유를 명시하여 변경사항을 모바일
						애플리케이션에서 시행일자 7일 전부터 공지합니다. 다만 변경된 내용이
						피치 못하게 회원에게 불리한 내용으로 개정되는 경우에는 그 적용일
						30일 전부터 계정에 등록된 이메일 주소로 (이메일 주소가 없는 경우
						서비스 내 알림 메시지를 띄우는 등의 별도의 전자적 수단) 이메일을
						발송하는 방법 등으로 개별적으로 알려 드리겠습니다.{`\n`}④ 회사가 전
						항에 따라 변경약관을 공지 또는 통지하면서 회원에게 공지 또는
						통지일로부터 개정약관 시행일 7일 후까지 명시적으로 거부의사를
						표시하지 아니하면 회원이 변경된 약관에 동의한 것으로 봅니다.{`\n`}⑤
						회원은 변경된 약관에 대하여 거부의사를 표시함으로써 이용계약의
						해지를 선택할 수 있습니다. 서비스 내에서의 개별 유료이용권의 해지
						효력과 관련하여서는 유료서비스 약관 관련 조항의 내용을 따릅니다.
						{`\n`}⑥ 본 약관은 여러분이 본 약관에 동의한 날로부터 본 약관 제9조에
						따른 이용계약의 해지 시까지 적용하는 것을 원칙으로 합니다. 단, 본
						약관의 일부 조항은 이용계약의 해지 후에도 유효하게 적용될 수
						있습니다{`\n`}
						{`\n`}제 4 조 (약관 외 준칙){`\n`}① 회사는 서비스 제공과 관련하여
						별도의 서비스 운영정책(이하 “운영정책”이라 합니다)을 둘 수 있습니다.
						{`\n`}② 이 약관에서 정하지 아니한 사항이나 해석에 대해서는 운영정책
						및 관계법령 또는 일반적인 상관례에 따릅니다
						{`\n`}
						{`\n`}제 2 장 서비스 이용 계약{`\n`}제 5 조 (회원 가입의 성립)
						{`\n`}① 회원 가입은 회원이 되고자 하는 자(이하 ‘가입신청자’)가
						약관의 내용에 동의한 후 회사가 정한 절차에 따라 회원 가입 신청을
						하고, 회사가 신청을 승인함으로써 채결됩니다.{`\n`}② 5조 1항에 따른
						가입 신청자에게 회사는 원칙적으로 서비스 가입을 승낙합니다. 다만
						회사는 아래 각 호의 경우에는 그 사유가 해소될 때까지 승낙을
						유보하거나 승낙하지 않을 수 있습니다.{`\n`}
						1. 다른 사람의 명의나 이메일 주소 등 개인정보를 이용하며 서비스에
						가입하려고 한 경우{`\n`}
						2. 허위의 정보를 기재한 경우{`\n`}
						3. 서비스 제공 설비 용량에 현실적인 여유가 없는 경우{`\n`}
						4. 서비스 제공을 위한 기술적인 부분에 문제가 있다고 판단되는 경우
						{`\n`}
						5. 기타 회사가 재정적, 기술적으로 필요하다고 인정하는 경우{`\n`}
						6. 회사로부터 서비스 이용정지 조치 등을 받은 자가 그 조치기간에
						서비스 이용계약을 임의로 해지하고 재가입을 신청하는 경우.{`\n`}
						7. 가입신청자가 이 약관에 의하여 이전에 회원자격을 상실한 적이 있는
						경우. 다만 회사의 재가입 승낙을 얻은 경우에는 예외로 함{`\n`}
						8. 기타 관련 법령에 위배되거나 세부지침 등 회사가 정한 기준에 반하는
						경우{`\n`}③ 회사는 전연령을 대상으로 서비스를 제공합니다. 그러나
						회원 가입 희망자가 만 14세 미만인 경우에는 부모 등 법정 대리인의
						동의 절차를 거쳐야 가입할 수 있습니다.{`\n`}④ 만약 여러분이 위
						조건을 위반하여 서비스에 가입한 것으로 판명될 때에는 회사는 즉시
						여러분의 서비스 이용을 정지시키거나 계정을 정지시키거나 삭제하는 등
						적절한 제한을 할 수 있습니다.{`\n`}
						{`\n`}제 3 장 서비스 이용{`\n`}
						{`\n`}제 6 조 (서비스의 제공){`\n`}① 서비스 이용 계약이 성립되면,
						회원은 원하는 때에 회사가 제공하는 서비스를 자유롭게 이용할 수
						있습니다.{`\n`}② 다만, 일부 세부 하위 서비스의 경우 별도의
						이용약관에 동의해야 이용이 가능하며 필요한 추가정보를 기재하거나,
						이메일 주소 승인 또는 문자메시지 인증 등 회사가 정한 인증 절차를
						완료하여야 서비스 이용이 가능합니다.
						{`\n`}③ 회사가 제공하는 서비스는 회원의 자격에 따라 차등 제공할 수
						있습니다.{`\n`}④ 회사는 더 나은 서비스를 위하여 소프트웨어의
						업데이트 버전을 제공할 수 있습니다. 소프트웨어의 업데이트에는 중요한
						기능의 추가 또는 불필요한 기능의 제거 등이 포함되어 있습니다.{`\n`}⑤
						회사는 더 나은 서비스를 제공하기 위하여 회원에게 이용과 관련된 각종
						고지, 관리 메시지 및 기타 광고를 비롯한 다양한 정보를 서비스 내에
						표시하거나 회원 정보에 등록되어 있는 연락처로 직접 발송할 수
						있습니다. 단, 광고성 정보 송신의 경우에는 사전에 수신에 동의한
						경우에만 전송합니다.{`\n`}⑥ 회원이 서비스를 이용하는 과정에서 Wi-Fi
						무선인터넷을 사용하지 않고, 가입하신 이동통신사의 무선인터넷에
						연결하여 이용하는 경우 이동통신사로부터 여러분에게 별도의 데이터
						통신요금이 부과될 수 있는 점을 유의하여 주시기 바랍니다. 서비스 이용
						과정에서 발생하는 데이터 통신요금은 여러분이 여러분의 비용과 책임
						하에 이동통신사에 납부하여야 합니다. 데이터 통신요금에 대한 자세한
						안내는 여러분이 가입하신 이동통신사에 문의하시기 바랍니다.
						{`\n`}
						{`\n`}제 7 조 (서비스의 변경 및 종료){`\n`}① 회사는 서비스를
						지속적으로 제공하기 위해 최선의 노력을 다합니다. 다만, 아래 각 호의
						경우 서비스의 일부를 제한하거나 중지할 수 있습니다.{`\n`}
						1. 서비스용 설비의 유지, 보수 등을 위한 정기 또는 임시 점검의 경우
						{`\n`}
						2. 정전, 제반 설비의 장애 또는 이용량의 폭주 등으로 정상적인 서비스
						이용에 지장이 있는 경우{`\n`}
						3. 제휴사와의 계약 종료, 정부의 명령/규제 등 회사의 제반 사정으로
						서비스의 전부 또는 일부를 유지할 수 없는 경우{`\n`}
						4. 기타 천재지변, 국가비상사태 등 불가항력적 사유가 있는 경우
						{`\n`}② 전항에 의한 서비스 중단의 경우에는 미리 제15조에서 정한
						방법으로 여러분에게 통지 내지 공지하겠습니다. 다만, 회사도 예측할 수
						없거나 통제할 수 없는 사유(회사의 과실이 없는 디스크 내지 서버 장애,
						시스템 다운 등)로 인해 사전 통지 내지 공지가 불가능한 경우에는
						그러하지 아니합니다. 이러한 경우에도 회사가 상황을 파악하는 즉시
						최대한 빠른 시일 내에 서비스를 복구하도록 노력하겠습니다.{`\n`}③
						회사는 서비스의 제공에 필요한 경우 정기점검을 실시할 수 있으며,
						정기점검시간은 모바일 애플리케이션을 통해 공지한 바에 따릅니다.
						{`\n`}④ 회사는 안정적인 서비스 제공을 위하여 서비스의 내용, 운영상
						또는 기술상 사항 등을 변경할 수 있습니다.{`\n`}⑤ 회사는 서비스를
						변경할 경우 변경 내용과 적용일자를 명시하여 모바일 애플리케이션에서
						사전 공지합니다.{`\n`}⑥ 회사는 무료로 제공되는 서비스의 일부 또는
						전부를 회사의 정책 및 운영의 필요상 수정, 중단, 변경할 수 있으며,
						이에 대하여 관련법에 특별한 규정이 없는 한 회원에게 별도의 보상을
						하지 않습니다.{`\n`}⑦ 회사는 유료로 제공되는 서비스의 일부 또는
						전부를 회사의 정책 및 운영의 필요상 수정, 중단, 변경할 수 있으며, 각
						유료서비스의 약관 및 세부지침에 따라 서비스를 이용하는 회원에게 이를
						고지하고 환불처리를 합니다.{`\n`}⑧ 모든 페이지가 완성되지 않고
						게시된 콘텐츠에 한해서 회사는 회원의 동의 없이 게시물을 삭제할 수
						있습니다.{`\n`}⑨ 음란 및 저작권 침해의 콘텐츠일 경우 회사는 회원의
						동의 없이 게시물을 삭제할 수 있다.{`\n`}
						{`\n`}제 8 조 (권리의 귀속 및 저작물의 이용){`\n`}① 서비스에 대한
						저작권 및 지적재산권은 회사에 귀속됩니다.{`\n`}② 회원은 사진, 글,
						회사에 대한 의견이나 제안 등 콘텐츠를 서비스 내에 게시할 수 있으며,
						이러한 게시물에 대한 저작권을 비롯한 지적재산권은 당연히 권리자가
						계속하여 보유합니다.
						{`\n`}③ 회원이 서비스 내에 콘텐츠를 게시하는 경우, 해당 콘텐츠가
						서비스에 노출될 수 있고 이에 필요한 범위 내에서 사용, 저장, 수정,
						복제, 공중 송신, 전시, 배포 등의 방식으로 이용할 수 있도록 사용을
						허락하는 전 세계적인 라이선스를 회사에게 제공하게 됩니다. 본
						라이선스에서 회원이 회사에게 부여하는 권리는 저작권법 등 관련
						법령에서 정하는 바에 따라 하우투약 서비스 내 노출, 서비스 홍보를
						위한 활용, 서비스 운영, 서비스 개선 및 새로운 서비스 개발을 위한
						연구, 웹 접근성 등 법률상 의무 준수, 외부 사이트에서의 검색, 수집 및
						링크 허용을 위해서만 제한적으로 행사할 것입니다. 만약 그 밖의 목적을
						위해 부득이 회원의 콘텐츠를 이용하고자 할 경우엔 사전에 회원에게
						설명을 드리고 동의를 받습니다.{`\n`}④ 하우투약은 회원이 부여해주신
						콘텐츠 이용 권한을 저작권법 등 관련 법령에서 정하는 바에 따라
						하우투약 서비스 내 노출, 서비스 홍보를 위한 활용, 서비스 운영, 개선
						및 새로운 서비스 개발을 위한 연구, 웹 전근성 등 법률상 의무 준수,
						외부 사이트에서의 검색, 수집 및 링크 허용을 위해서만 제한적으로
						행사할 것입니다. 만약 그 밖의 목적을 위해 부득이 여러분의 콘텐츠를
						이용하고자 할 경우엔 사전에 여러분께 설명을 드리고 동의를 받도록
						하겠습니다.
						{`\n`}⑤ 회원이 서비스 내에 게시하는 콘텐츠는 서비스 내 자체
						검색결과나 포털사이트의 검색결과에 노출될 수 있고, 회사와 서비스에
						관한 언론보도, PR, 출판, 콘테스트 홍보, 타 애플리케이션과의 자료
						공유 등의 목적으로 무상으로 사용될 수 있으며, 이를 위해 회원이
						게시판 게시물은 필요한 범위 내에서 일부 수정, 복제, 편집될 수
						있습니다.{`\n`}⑥ 회원이 서비스 내에 게시하는 콘텐츠는 서비스의
						인공지능 성능 향상을 위해 사용될 수 있습니다.
						{`\n`}⑦ 회원이 서비스 내에 게시하는 콘텐츠 내의 음식 종류, 시간,
						위치 정보, 성별, 나이 정보가 서비스의 성능 향상을 위해 사용될 수
						있고 타 애플리케이션에 제공될 수 있습니다.{`\n`}⑧ 회원은 회사가
						제공하는 서비스 내에 콘텐츠 삭제, 비공개 등의 관리기능이 제공되는
						경우 이를 통해 직접 타인의 이용 또는 접근을 통제할 수 있고, 고객
						센터를 통해서도 콘텐츠의 삭제, 비공개, 검색결과 제외 등의 조치를
						요청할 수 있습니다. 다만, 일부 서비스의 경우 삭제, 비공개 등의
						처리가 어려울 수 있으며, 이러한 내용은 각 서비스 상의 안내,
						공지사항, 고객센터 도움말 등에서 확인해주시길 부탁드립니다.{`\n`}⑨
						회원은 회사에 제공한 콘텐츠에 대해 회사에 라이선스를 부여하기 위해
						필요한 권리를 보유해야 합니다. 이러한 권리를 보유하지 않아 발생하는
						모든 문제에 대해서는 게시자가 책임을 부담하게 됩니다. 또한, 회원은
						음란하거나 폭력적이거나 기타 공서양속 및 법령에 위반하는 콘텐츠를
						공개 또는 게시할 수 없습니다.{`\n`}⑩ 회사는 회원의 콘텐츠가 관련
						법령에 위반되거나 음란 또는 청소년에게 유해한 게시물, 차별 갈등을
						조장하는 게시물, 도배 · 광고 · 홍보 · 스팸성 게시물, 계정을 양도
						또는 거래하는 내용의 게시물, 타인을 사칭하는 게시물 등이라고
						판단되는 경우 이를 삭제하거나 게시를 거부할 수 있습니다. 다만 회사가
						모든 콘텐츠를 검토할 의무가 있는 것은 아닙니다. 누군가 여러분의
						권리를 침해하였다면, 고객센터를 통해 게시중단 요청에 대한 도움을
						받으실 수 있습니다.{`\n`}
						{`\n`}제 9 조 (유료 서비스의 이용){`\n`}① 서비스의 대부분은 무료로
						제공하고 있으나, 일부 하위 서비스는 유료로 제공될 수 있습니다. 예를
						들면 식사 기록 서비스는 무료로 사용할 수 있으나 프리미엄 서비스는
						유료로 구매해야 합니다.{`\n`}② 회원이 회사가 제공하는 유료서비스를
						이용하는 경우 이용대금을 납부한 후 이용하는 것을 원칙으로 합니다.
						회사가 제공하는 유료서비스에 대한 이용요금의 결제 방법은 핸드폰결제,
						신용카드결제, 선불전자지급수단 결제 등이 있으며 각 유료서비스마다
						결제 방법의 차이가 있을 수 있습니다. 매월 정기적인 결제가 이루어지는
						서비스의 경우 회원 개인이 해당 서비스의 이용을 중단하고 정기 결제의
						취소를 요청하지 않는 한 매월 결제가 이루어집니다.{`\n`}③ 회사는
						결제의 이행을 위하여 반드시 필요한 여러분의 개인정보를 추가적으로
						요구할 수 있으며, 회원은 회사가 요구하는 개인정보를 정확하게
						제공하여야 합니다.{`\n`}④ 본 조에서 정하지 않은 내용은 개별 서비스
						내의 각 세부 하위 서비스의 유료서비스 약관(예: 당신의영양제
						유료서비스 이용약관 등)에서 정하며, 본 조의 본 조의 내용과 각 세부
						하위 서비스의 유료서비스 약관의 내용이 충돌하는 세부 하위 서비스의
						유료서비스 약관의 규정에 따릅니다.{`\n`}
						{`\n`}제 10 조 (서비스 이용 방법 및 주의점){`\n`}① 회원은 서비스를
						자유롭게 이용할 수 있으나, 아래 각 호의 행위는 하여서는 안 됩니다.
						{`\n`}
						1. 이용 신청 또는 변경 시 허위 사실을 기재하거나, 다른 사람의 계정
						및 비밀번호를 도용, 부정하게 사용하거나, 다른 사람의 명의를 사용하는
						행위
						{`\n`}
						2. 회사의 서비스 정보를 이용하여 얻은 정보를 회사의 사전 승낙 없이
						복제 또는 유통시키거나 상업적으로 이용하는 행위{`\n`}
						3. 타인의 명예를 손상시키거나 불이익을 주는 행위{`\n`}
						4. 회사 또는 제3자의 저작권 등 기타 권리를 침해하는 행위{`\n`}
						5. 회사가 안내하는 방법 이외의 다른 방법을 사용하여 서비스에
						접근하는 행위{`\n`}
						6. 서비스 내에 회사나 제3자 등에 대한 허위의 사실을 게시하는 행위
						{`\n`}
						7. 공공질서 및 미풍양속에 위반되는 내용의 콘텐츠를 타인에게 유포하는
						행위{`\n`}
						8. 서비스와 관련된 설비의 오동작이나 정보 등의 파괴 및 혼란을
						유발시키는 컴퓨터 바이러스 감염 자료를 등록 또는 유포하는 행위
						{`\n`}
						9. 서비스의 운영을 고의로 방해하거나 안정적 운영을 방해하는 행위
						{`\n`}
						10. 회사의 동의 없이 서비스 또는 이에 포함된 소프트웨어의 일부를
						복사, 수정, 배포, 판매, 양도, 대여, 담보 제공하거나 타인에게 그
						이용을 허락하는 행위와 소프트웨어를 역 설계하거나 소스 코드의 추출을
						시도하는 등 서비스를 복제, 분해 또는 모방하거나 기타 변형하는 행위
						{`\n`}
						11. 회사의 명시적인 동의 없이 상표 및 로고 등의 영업표지를 무단으로
						사용하는 행위{`\n`}
						12. 다른 이용자의 개인정보를 수집, 저장, 공개하는 행위{`\n`}
						13. 타인으로 가장하는 행위 및 타인과의 관계를 허위로 명시하는 행위
						{`\n`}
						14. 자기 또는 타인에게 재산상의 이익을 주거나 타인에게 손해를 가할
						목적으로 허위의 정보를 유통시키는 행위{`\n`}
						15. 범죄행위를 목적으로 하거나 기타 범죄행위과 관련된 행위{`\n`}
						16. 재물을 걸고 도박하거나 사행행위를 하는 행위{`\n`}
						17. 윤락행위를 알선하거나 음행을 매개하는 내용의 정보를 유통시키는
						행위
						{`\n`}
						18. 수치심이나 혐오감 또는 공포심을 일으키는 말이나 이미지를
						계속하여 상대방에게 도달하게 하여 상대방의 일상적 생활을 방해하는
						행위{`\n`}
						19. 스토킹(stalking), 허위 또는 악의적 신고 남용 등 다른 이용자를
						괴롭히는 행위{`\n`}
						20. 관련 법령에 의하여 그 전송 또는 게시가 금지되는 정보(컴퓨터
						프로그램 포함)의 전송 또는 게시 행위{`\n`}
						21. 회사 또는 제휴사의 직원이나 운영자를 가장하거나 사칭하여 또는
						타임의 명의를 도용하여 글이나 이미지를 게시하거나 메시지 등을
						발송하는 행위
						{`\n`}
						22. 기타 현행 법령, 본 약관 및 회사가 제공하는 서비스 관련
						세부지침을 위반하는 행위{`\n`}② 회원은 서비스의 이용 권한, 기타
						이용계약상 지위를 타인에게 양도 · 증여할 수 없으며, 담보로 제공할 수
						없습니다.{`\n`}③ 혹시라도 회원이 관련 법령, 회사의 모든 약관 또는
						정책을 준수하지 않는다면, 회사는 여러분의 위반행위 등을 조사할 수
						있고, 해당 게시물 등을 삭제 또는 임시 삭제하거나 여러분의 서비스
						전체 또는 서비스를 구성하는 일부 개별 서비스의 이용을 잠시 또는
						계속하여 중단하거나, 서비스 재가입 또는 일부 개별 서비스의 재이용에
						제한을 둘 수도 있습니다.{`\n`}④ 이용 제한은 위반 활동의 누적 정도에
						따라 한시적 제한에서 영구적 제한으로 단계적 제한하는 것을 원칙으로
						하지만, 음란한 내용의 게시와 유포 및 사행성 도박 홍보 등 관련
						법령에서 금지하는 명백한 불법행위나 타인의 권리침해로서 긴급한 위험
						또는 피해 차단이 요구되는 사안에 대해서는 위반 활동 횟수의 누적
						정도와 관계없이 즉시 영구적으로 이용이 제한될 수 있습니다.{`\n`}⑤
						회원은 서비스 제공 또는 이 약관 위반을 확인하기 위해 회사가 자료
						또는 접근권한의 제공 및 관련사실에 대한 소명을 요청하는 경우에는
						이에 성실히 임하여야 합니다.{`\n`}⑥ 회원은 회원이 운영하는 모바일
						애플리케이션에서의 개인정보 보호와 관련하여 다음 각 호의 사항을 모두
						준수하여야 합니다. 만약 본 항의 의무를 위반한 것을 회사가 인지하게
						되는 경우 회사는 해당 회원의 서비스 이용을 제한하거나 이용계약을
						해지할 수 있습니다.{`\n`}
						1. 모바일 애플리케이션 설정 페이지 내 회원의 모바일 애플리케이션
						동의항목 관리 화면에서 회원의 모바일 애플리케이션 실제 서비스 내용과
						일치하도록 항목을 입력하여야 합니다.{`\n`}
						2. 기타 정보통신망 이용촉진 및 정보보호 등에 관한 법률 등 개인정보의
						보호와 관련된 법령상 회원의 의무사항을 성실히 준수하여야 합니다.
						{`\n`}
						{`\n`}제 11 조 (이용계약 해지){`\n`}① 회원이 하우투약 계정 탈퇴를
						하는 경우 서비스 이용계약도 자동으로 해지됩니다.{`\n`}② 서비스
						이용계약 해지를 원하는 경우 회원은 언제든지 서비스 내 제공되는
						메뉴를 이용하여 해지 신청을 할 수 있으며, 회사는 법령이 정하는 바에
						따라 신속히 처리하겠습니다.{`\n`}③ 서비스 이용계약이 해지되면 관련
						법령 및 하우투약 개인정보 처리방침에 따라 여러분의 일정 정보를
						보유하는 경우를 제외하고는 해당 회원 계정에 부속된 게시물 일체를
						포함한 회원의 모든 데이터는 소멸됨과 동시에 복구할 수 없게 됩니다.
						다만, 이 경우에도 다른 회원이 별도로 담아갔거나 스크랩한 게시물과
						공용 게시판에 등록한 게시물 등은 삭제되지 않으므로 반드시 해지 신청
						이전에 삭제하신 후 탈퇴하시기 바랍니다.{`\n`}④ 전항에 따라 여러분이
						삭제하지 않은 게시물은 다른 이용자의 정상적 서비스 이용을 위하여
						필요한 범위 내에서 서비스 내에 삭제되지 않고 남아 있게 됩니다.{`\n`}
						⑤ 유료서비스 이용계약의 해지는 회원의 여러분의 유료서비스 이용계약
						해지 신청 및 회사의 승낙에 의해 성립하게 되고, 환불할 금액이 있는
						경우 환불도 이루어 지게 됩니다. 이 때, 유료서비스 이용계약 해지
						신청은 서비스 이용계약 해지(회원 탈퇴)와는 별도로 이루어지며, 회원을
						탈퇴하신 후에는 유료서비스 이용 내역을 확인할 수 없기 때문에 환불이
						되지 않습니다. 유료서비스 이용계약의 해지 및 환불을 원하실 경우
						반드시 환불 처리 완료 후 탈퇴하시기 바랍니다. 또한 각 세부
						하위서비스의 유료서비스에서 본 약관과 다른 계약해지 방법 및 효과를
						규정하고 있는 경우 각 유료서비스 약관 및 관련 세부지침에서 정한 바에
						따릅니다{`\n`}⑥ 서비스를 구성하는 일부 개별 서비스의 경우 일정기간
						동안 해당 개별 서비스를 이용하지 않을 경우 여러분의 정보를
						파기하거나 분리 보관할 수 있으며, 또는 해당 개별 서비스 기능의 일부
						또는 전부를 이용할 수 없도록 제한할 수 있습니다.{`\n`}⑦ 서비스
						이용계약이 해지된 경우라도 여러분은 다시 회사에 대하여 이용계약의
						체결을 신청할 수 있습니다. 다만, 여러분이 관련 법령, 본 약관 및
						세부지침을 준수하지 않아 서비스의 이용이 중단된 상태에서 이용계약을
						해지한 후 다시 이용계약 체결을 신청하는 경우에는 서비스 가입에
						일정기간 시간적 제한이 있을 수 있습니다. 또한 서비스를 구성하는 일부
						개별 서비스의 경우 다시 서비스 이용계약을 체결한 후에도 해당 개별
						서비스를 바로 이용하는 것에는 제10조 제3항에서 정한 바와 같이 일정한
						시간적 제한 등이 따를 수 있습니다.{`\n`}
						{`\n`}제 12 조 (개인 정보의 보호){`\n`}
						회사는 정보통신망이용촉진 및 정보보호 등에 관한 법률 등 관련
						법령에서 정하는 바에 따라 회원의 개인정보를 보호하기 위해
						노력합니다. 회원의 개인정보는 서비스의 원활한 제공을 위하여 회원이
						동의한 목적과 범위 내에서만 이용됩니다. 관련 법령에 의하거나 회원이
						별도로 동의하지 아니하는 한 회사가 회원의 개인정보를 제3자에게
						제공하는 일은 결코 없으므로, 안심하여도 좋습니다. 회사가 회원의
						개인정보를 안전하게 처리하기 위하여 기울이는 노력이나 기타 자세한
						사항은 하우투약 개인정보처리방침 등을 참고해 주시기 바랍니다.{`\n`}
						{`\n`}제 4 장 기타{`\n`}
						{`\n`}제 13 조 (손해배상 등){`\n`}① 회사는 관련 법령상 허용되는 한도
						내에서 통합서비스와 관련하여 본 약관에 명시되지 않은 어떠한 구체적인
						사항에 대한 약정이나 보증을 하지 않습니다. 또한, 회사의 과실 없이
						발생된 회원의 손해에 대하여는 책임을 부담하지 아니합니다.{`\n`}②
						회사는 회사의 과실로 인하여 회원이 손해를 입게 될 경우 본 약관 및
						관련 법령에 따라 회원의 손해를 배상하겠습니다. 다만 회사는 회사의
						과실 없이 발생된 아래와 같은 손해에 대해서는 책임을 부담하지
						않습니다. 또한 회사는 법률상 허용되는 한도 내에서 간접 손해, 특별
						손해, 결과적 손해, 징계적 손해, 및 징벌적 손해에 대한 책임을
						부담하지 않습니다.{`\n`}
						1. 천재지변 또는 이에 준하는 불가항력의 상태에서 발생한 손해{`\n`}
						2. 회원의 귀책사유로 서비스 이용에 장애가 발생한 경우{`\n`}
						3. 서비스에 접속 또는 이용과정에서 발생하는 개인적인 손해{`\n`}
						4. 제3자가 불법적으로 회사의 서버에 접속하거나 서버를 이용함으로써
						발생하는 손해{`\n`}
						5. 제3자가 회사 서버에 대한 전송 또는 회사 서버로부터의 전송을
						방해함으로써 발생하는 손해{`\n`}
						6. 제3자가 악성 프로그램을 전송 또는 유포함으로써 발생하는 손해
						{`\n`}
						7. 전송된 데이터의 생략, 누락, 파괴 등으로 발생한 손해, 명예훼손 등
						제3자가 서비스를 이용하는 과정에서 발생된 손해{`\n`}
						8. 기타 회사의 고의 또는 과실이 없는 사유로 인해 발생한 손해{`\n`}③
						회사는 회원이 서비스를 이용하여 기대하는 수익을 상실한 것에 대하여
						책임을 지지 않으며 그 밖에 서비스를 통하여 얻은 자료로 인한 손해
						등에 대하여도 책임을 지지 않습니다.{`\n`}④ 회사는 회원의 귀책사유로
						인한 서비스 이용의 장애에 대하여는 책임을 부담하지 않습니다.{`\n`}⑤
						회사는 서비스를 통해 회원의 모바일 애플리케이션 개발에 부가적인
						기능을 제공할 뿐이며, 서비스를 사용에 따른 어떠한 효과 향상도
						보증하지 않습니다.{`\n`}⑥ 회사는 무료로 제공되는 서비스 이용과
						관련하여 관련법령에 특별한 규정이 없는 한 책임을 부담하지 않습니다.
						{`\n`}⑦ 회사는 회사의 과실이 없는 한 회원 상호간 또는 회원과 제3자
						상호간에 서비스를 매개로 발생한 분쟁에 대해서는 개입할 의무가 없으며
						이로 인한 손해를 배상할 책임도 없습니다.
						{`\n`}
						{`\n`}제 14 조 (청소년 보호){`\n`}
						서비스는 기본적으로 모든 연령대가 자유롭게 이용할 수 있는 공간으로서
						유해 정보로부터 청소년을 보호하고 청소년의 안전한 인터넷 사용을 돕기
						위해 정보통신망법에서 정한 청소년보호정책을 별도로 시행하고 있으며,
						구체적인 내용은 서비스를 구성하는 개별 서비스 초기 화면 등에서
						확인할 수 있습니다.{`\n`}
						{`\n`}제 15 조 (통지 및 공지){`\n`}
						회사는 회원과의 의견 교환을 소중하게 생각합니다. 회원은 언제든지
						하우투약 고객센터에 의견을 개진할 수 있습니다. 서비스 이용자 전체에
						대한 공지는 7일 이상 서비스 공지사항 란에 게시함으로써 효력이
						발생합니다. 회원에게 중대한 영향을 미치는 사항의 경우에는 하우투약
						계정에 등록된 이메일 주소로 이메일 (이메일 주소가 없는 경우 서비스
						내 알림 메시지를 띄우는 등의 별도의 전자적 수단) 발송 또는 회원이
						등록한 휴대폰번호로 문자메시지를 발송하는 방법 등으로 개별적으로
						알려 드리겠습니다.{`\n`}
						{`\n`}제 16 조 (분쟁의 해결){`\n`}본 약관 또는 서비스는
						대한민국법령에 의하여 규정되고 이행됩니다. 서비스 이용과 관련하여
						회사와 회원 간에 분쟁이 발생하면 이의 해결을 위해 성실히 협의할
						것입니다. 그럼에도 불구하고 해결되지 않으면 민사소송법의 관할법원에
						소를 제기할 수 있습니다.{`\n`}
						1. 회사와 회원 간 제기된 소송은 대한민국법을 준거법으로 합니다.
						{`\n`}
						2. 회사와 회원간 발생한 분쟁에 관한 소송은 민사소송법 상의
						관할법원에 제소합니다.{`\n`}
						{`\n`}
						부칙{`\n`}이 약관은 2021년 10월 6일부터 적용됩니다.{`\n`}
						{`\n`}
						공고일자 : 2021년 10월 6일{`\n`}
						시행일자 : 2021년 10월 6일
					</p>
				</div>
			) : (
				<div className="main-wrap">
					<SignUpHeader title={"이용약관"} back={true} close={false} />
					<div className="max-width-apply">
						<div className={`product-body`}>
							{/*<h1*/}
							{/*	style={{*/}
							{/*		fontSize: `2em`,*/}
							{/*		fontWeight: `bold`,*/}
							{/*		textAlign: `center`,*/}
							{/*		margin: `0.7em 0`,*/}
							{/*	}}*/}
							{/*>*/}
							{/*	이용약관*/}
							{/*</h1>*/}
							<p
								style={{
									whiteSpace: `pre-line`,
									fontSize: `1.05em`,
									color: `#000000`,
									fontWeight: 500,
									lineHeight: "1.3",
									letterSpacing: "-0.478px",
								}}
							>
								<span
									style={{
										display: "block",
										fontSize: "1.5em",
										fontWeight: 700,
									}}
								>
									하우투약 서비스 약관{`\n`}
								</span>
								{`\n`}
								임시 점검과 같은 서비스 이용에 지장이 있을 때는 사전에 미리
								공지하도록 하고 있습니다. 혹시라도 서비스의 운영을 방해하거나
								혼란을 유발시키는 등 다른 회원에게 피해를 입히는 회원은 서비스
								이용에 제한을 두어 다른 회원들이 쾌적한 서비스를 이용할 수
								있도록 하고 있습니다. 또한 회원님의 최소한의 개인정보만 요청하고
								사용 전 모든 것에 동의를 구하며 책임지고 관리하도록 하겠습니다.
								{`\n`}
								{`\n`}제 1 조 (목적){`\n`}
								주식회사 하우투약(이하 “회사”)이 제공하는 서비스를 이용해 주셔서
								감사합니다. 서비스를 이용함으로써 귀하는 본 약관에 동의하게
								되므로 본 약관을 주의 깊게 읽어 보시기 바랍니다. 본 이용약관은
								이용자가 회사가 제공하는 서비스를 이용하는데 적용됩니다. 본 이용
								약관에 언급되는 ‘하우투약 서비스’ 또는 ‘서비스’는 모든 특성과
								기능, 후기, 사용자 인터페이스뿐만 아니라 서비스와 관련된 모든
								콘텐츠와 소프트웨어를 포함하는 회사가 제공하는 개인 맞춤형
								서비스를 의미합니다. 본 이용 약관은 회사가 제공하는 모바일
								애플리케이션의 이용에 대하여 회사와 서비스 이용자의 권리, 의무
								및 책임사항, 이용조건 및 절차 등 기본적인 사항을 규정합니다.
								{`\n`}
								{`\n`}제 2 조 (용어의 정의){`\n`}① 이 약관에서 사용하는 용어의
								정의는 다음과 같습니다.{`\n`}
								1. 서비스: 회사가 제공하는 모든 서비스를 말합니다. (당신의영양제
								등)
								{`\n`}
								2. “회원” 이란 이 약관에 따라 회사와 이용계약을 체결하고, 회사가
								제공하는 서비스를 이용하는 자를 의미합니다.{`\n`}
								3. “모바일 기기”란 콘텐츠를 다운로드 받거나 설치하여 사용할 수
								있는 기기로서, 휴대폰, 스마트폰, 태블릿 등을 의미합니다.{`\n`}
								4. “계정정보”란 회원의 회원번호와 외부계정정보, 기기정보, 별명,
								프로필 사진 등 회원이 회사에 제공한 정보와 서비스이용정보
								(몸무게, 키, 음식물 섭취 정보 등), 이용요금 결제 정보 등을
								통칭합니다.
								{`\n`}
								5. “애플리케이션”이란 회사가 제공하는 서비스를 이용하기 위하여
								모바일 기기를 통해 다운로드 받거나 설치하여 사용하는 프로그램
								일체를 의미합니다.{`\n`}
								6. “게시물”이란 회원이 타인 또는 자신이 보게 할 목적으로 회사가
								제공하는 서비스 상에 게재한 부호, 문자, 음성, 음향, 그림, 사진,
								동영상, 링크 등으로 구성된 각종 콘텐츠 자체 또는 파일을
								말합니다.
								{`\n`}② 이 약관에서 사용하는 용어의 정의는 본 조 제1항에서
								정하는 것을 제외하고는 관계법령 및 서비스별 정책에서 정하는 바에
								의하며, 이에 정하지 아니한 것은 일반적인 상관례에 따릅니다.
								{`\n`}
								{`\n`}제 3 조 (약관의 효력 및 변경){`\n`}① 회사는 본 약관의
								내용을 회원이 쉽게 알 수 있도록 모바일 애플리케이션 화면에
								게시합니다.
								{`\n`}② 회사는 필요한 경우 관련 법령을 위배하지 않는 범위 내에서
								본 약관을 변경할 수 있습니다.{`\n`}③ 회사는 본 약관이 변경되는
								경우 적용일자 및 개정내용, 개정사유를 명시하여 변경사항을 모바일
								애플리케이션에서 시행일자 7일 전부터 공지합니다. 다만 변경된
								내용이 피치 못하게 회원에게 불리한 내용으로 개정되는 경우에는 그
								적용일 30일 전부터 계정에 등록된 이메일 주소로 (이메일 주소가
								없는 경우 서비스 내 알림 메시지를 띄우는 등의 별도의 전자적
								수단) 이메일을 발송하는 방법 등으로 개별적으로 알려
								드리겠습니다.{`\n`}④ 회사가 전 항에 따라 변경약관을 공지 또는
								통지하면서 회원에게 공지 또는 통지일로부터 개정약관 시행일 7일
								후까지 명시적으로 거부의사를 표시하지 아니하면 회원이 변경된
								약관에 동의한 것으로 봅니다.{`\n`}⑤ 회원은 변경된 약관에 대하여
								거부의사를 표시함으로써 이용계약의 해지를 선택할 수 있습니다.
								서비스 내에서의 개별 유료이용권의 해지 효력과 관련하여서는
								유료서비스 약관 관련 조항의 내용을 따릅니다.
								{`\n`}⑥ 본 약관은 여러분이 본 약관에 동의한 날로부터 본 약관
								제9조에 따른 이용계약의 해지 시까지 적용하는 것을 원칙으로
								합니다. 단, 본 약관의 일부 조항은 이용계약의 해지 후에도
								유효하게 적용될 수 있습니다{`\n`}
								{`\n`}제 4 조 (약관 외 준칙){`\n`}① 회사는 서비스 제공과
								관련하여 별도의 서비스 운영정책(이하 “운영정책”이라 합니다)을 둘
								수 있습니다.{`\n`}② 이 약관에서 정하지 아니한 사항이나 해석에
								대해서는 운영정책 및 관계법령 또는 일반적인 상관례에 따릅니다
								{`\n`}
								{`\n`}제 2 장 서비스 이용 계약{`\n`}제 5 조 (회원 가입의 성립)
								{`\n`}① 회원 가입은 회원이 되고자 하는 자(이하 ‘가입신청자’)가
								약관의 내용에 동의한 후 회사가 정한 절차에 따라 회원 가입 신청을
								하고, 회사가 신청을 승인함으로써 채결됩니다.{`\n`}② 5조 1항에
								따른 가입 신청자에게 회사는 원칙적으로 서비스 가입을 승낙합니다.
								다만 회사는 아래 각 호의 경우에는 그 사유가 해소될 때까지 승낙을
								유보하거나 승낙하지 않을 수 있습니다.{`\n`}
								1. 다른 사람의 명의나 이메일 주소 등 개인정보를 이용하며
								서비스에 가입하려고 한 경우{`\n`}
								2. 허위의 정보를 기재한 경우{`\n`}
								3. 서비스 제공 설비 용량에 현실적인 여유가 없는 경우{`\n`}
								4. 서비스 제공을 위한 기술적인 부분에 문제가 있다고 판단되는
								경우
								{`\n`}
								5. 기타 회사가 재정적, 기술적으로 필요하다고 인정하는 경우{`\n`}
								6. 회사로부터 서비스 이용정지 조치 등을 받은 자가 그 조치기간에
								서비스 이용계약을 임의로 해지하고 재가입을 신청하는 경우.{`\n`}
								7. 가입신청자가 이 약관에 의하여 이전에 회원자격을 상실한 적이
								있는 경우. 다만 회사의 재가입 승낙을 얻은 경우에는 예외로 함
								{`\n`}
								8. 기타 관련 법령에 위배되거나 세부지침 등 회사가 정한 기준에
								반하는 경우{`\n`}③ 회사는 전연령을 대상으로 서비스를 제공합니다.
								그러나 회원 가입 희망자가 만 14세 미만인 경우에는 부모 등 법정
								대리인의 동의 절차를 거쳐야 가입할 수 있습니다.{`\n`}④ 만약
								여러분이 위 조건을 위반하여 서비스에 가입한 것으로 판명될 때에는
								회사는 즉시 여러분의 서비스 이용을 정지시키거나 계정을
								정지시키거나 삭제하는 등 적절한 제한을 할 수 있습니다.{`\n`}
								{`\n`}제 3 장 서비스 이용{`\n`}
								{`\n`}제 6 조 (서비스의 제공){`\n`}① 서비스 이용 계약이
								성립되면, 회원은 원하는 때에 회사가 제공하는 서비스를 자유롭게
								이용할 수 있습니다.{`\n`}② 다만, 일부 세부 하위 서비스의 경우
								별도의 이용약관에 동의해야 이용이 가능하며 필요한 추가정보를
								기재하거나, 이메일 주소 승인 또는 문자메시지 인증 등 회사가 정한
								인증 절차를 완료하여야 서비스 이용이 가능합니다.
								{`\n`}③ 회사가 제공하는 서비스는 회원의 자격에 따라 차등 제공할
								수 있습니다.{`\n`}④ 회사는 더 나은 서비스를 위하여 소프트웨어의
								업데이트 버전을 제공할 수 있습니다. 소프트웨어의 업데이트에는
								중요한 기능의 추가 또는 불필요한 기능의 제거 등이 포함되어
								있습니다.{`\n`}⑤ 회사는 더 나은 서비스를 제공하기 위하여
								회원에게 이용과 관련된 각종 고지, 관리 메시지 및 기타 광고를
								비롯한 다양한 정보를 서비스 내에 표시하거나 회원 정보에 등록되어
								있는 연락처로 직접 발송할 수 있습니다. 단, 광고성 정보 송신의
								경우에는 사전에 수신에 동의한 경우에만 전송합니다.{`\n`}⑥ 회원이
								서비스를 이용하는 과정에서 Wi-Fi 무선인터넷을 사용하지 않고,
								가입하신 이동통신사의 무선인터넷에 연결하여 이용하는 경우
								이동통신사로부터 여러분에게 별도의 데이터 통신요금이 부과될 수
								있는 점을 유의하여 주시기 바랍니다. 서비스 이용 과정에서
								발생하는 데이터 통신요금은 여러분이 여러분의 비용과 책임 하에
								이동통신사에 납부하여야 합니다. 데이터 통신요금에 대한 자세한
								안내는 여러분이 가입하신 이동통신사에 문의하시기 바랍니다.
								{`\n`}
								{`\n`}제 7 조 (서비스의 변경 및 종료){`\n`}① 회사는 서비스를
								지속적으로 제공하기 위해 최선의 노력을 다합니다. 다만, 아래 각
								호의 경우 서비스의 일부를 제한하거나 중지할 수 있습니다.{`\n`}
								1. 서비스용 설비의 유지, 보수 등을 위한 정기 또는 임시 점검의
								경우
								{`\n`}
								2. 정전, 제반 설비의 장애 또는 이용량의 폭주 등으로 정상적인
								서비스 이용에 지장이 있는 경우{`\n`}
								3. 제휴사와의 계약 종료, 정부의 명령/규제 등 회사의 제반
								사정으로 서비스의 전부 또는 일부를 유지할 수 없는 경우{`\n`}
								4. 기타 천재지변, 국가비상사태 등 불가항력적 사유가 있는 경우
								{`\n`}② 전항에 의한 서비스 중단의 경우에는 미리 제15조에서 정한
								방법으로 여러분에게 통지 내지 공지하겠습니다. 다만, 회사도
								예측할 수 없거나 통제할 수 없는 사유(회사의 과실이 없는 디스크
								내지 서버 장애, 시스템 다운 등)로 인해 사전 통지 내지 공지가
								불가능한 경우에는 그러하지 아니합니다. 이러한 경우에도 회사가
								상황을 파악하는 즉시 최대한 빠른 시일 내에 서비스를 복구하도록
								노력하겠습니다.{`\n`}③ 회사는 서비스의 제공에 필요한 경우
								정기점검을 실시할 수 있으며, 정기점검시간은 모바일
								애플리케이션을 통해 공지한 바에 따릅니다.{`\n`}④ 회사는 안정적인
								서비스 제공을 위하여 서비스의 내용, 운영상 또는 기술상 사항 등을
								변경할 수 있습니다.{`\n`}⑤ 회사는 서비스를 변경할 경우 변경
								내용과 적용일자를 명시하여 모바일 애플리케이션에서 사전
								공지합니다.{`\n`}⑥ 회사는 무료로 제공되는 서비스의 일부 또는
								전부를 회사의 정책 및 운영의 필요상 수정, 중단, 변경할 수
								있으며, 이에 대하여 관련법에 특별한 규정이 없는 한 회원에게
								별도의 보상을 하지 않습니다.{`\n`}⑦ 회사는 유료로 제공되는
								서비스의 일부 또는 전부를 회사의 정책 및 운영의 필요상 수정,
								중단, 변경할 수 있으며, 각 유료서비스의 약관 및 세부지침에 따라
								서비스를 이용하는 회원에게 이를 고지하고 환불처리를 합니다.
								{`\n`}⑧ 모든 페이지가 완성되지 않고 게시된 콘텐츠에 한해서
								회사는 회원의 동의 없이 게시물을 삭제할 수 있습니다.{`\n`}⑨ 음란
								및 저작권 침해의 콘텐츠일 경우 회사는 회원의 동의 없이 게시물을
								삭제할 수 있다.{`\n`}
								{`\n`}제 8 조 (권리의 귀속 및 저작물의 이용){`\n`}① 서비스에
								대한 저작권 및 지적재산권은 회사에 귀속됩니다.{`\n`}② 회원은
								사진, 글, 회사에 대한 의견이나 제안 등 콘텐츠를 서비스 내에
								게시할 수 있으며, 이러한 게시물에 대한 저작권을 비롯한
								지적재산권은 당연히 권리자가 계속하여 보유합니다.
								{`\n`}③ 회원이 서비스 내에 콘텐츠를 게시하는 경우, 해당 콘텐츠가
								서비스에 노출될 수 있고 이에 필요한 범위 내에서 사용, 저장,
								수정, 복제, 공중 송신, 전시, 배포 등의 방식으로 이용할 수 있도록
								사용을 허락하는 전 세계적인 라이선스를 회사에게 제공하게 됩니다.
								본 라이선스에서 회원이 회사에게 부여하는 권리는 저작권법 등 관련
								법령에서 정하는 바에 따라 하우투약 서비스 내 노출, 서비스 홍보를
								위한 활용, 서비스 운영, 서비스 개선 및 새로운 서비스 개발을 위한
								연구, 웹 접근성 등 법률상 의무 준수, 외부 사이트에서의 검색,
								수집 및 링크 허용을 위해서만 제한적으로 행사할 것입니다. 만약 그
								밖의 목적을 위해 부득이 회원의 콘텐츠를 이용하고자 할 경우엔
								사전에 회원에게 설명을 드리고 동의를 받습니다.{`\n`}④ 하우투약은
								회원이 부여해주신 콘텐츠 이용 권한을 저작권법 등 관련 법령에서
								정하는 바에 따라 하우투약 서비스 내 노출, 서비스 홍보를 위한
								활용, 서비스 운영, 개선 및 새로운 서비스 개발을 위한 연구, 웹
								전근성 등 법률상 의무 준수, 외부 사이트에서의 검색, 수집 및 링크
								허용을 위해서만 제한적으로 행사할 것입니다. 만약 그 밖의 목적을
								위해 부득이 여러분의 콘텐츠를 이용하고자 할 경우엔 사전에
								여러분께 설명을 드리고 동의를 받도록 하겠습니다.
								{`\n`}⑤ 회원이 서비스 내에 게시하는 콘텐츠는 서비스 내 자체
								검색결과나 포털사이트의 검색결과에 노출될 수 있고, 회사와
								서비스에 관한 언론보도, PR, 출판, 콘테스트 홍보, 타
								애플리케이션과의 자료 공유 등의 목적으로 무상으로 사용될 수
								있으며, 이를 위해 회원이 게시판 게시물은 필요한 범위 내에서 일부
								수정, 복제, 편집될 수 있습니다.{`\n`}⑥ 회원이 서비스 내에
								게시하는 콘텐츠는 서비스의 인공지능 성능 향상을 위해 사용될 수
								있습니다.
								{`\n`}⑦ 회원이 서비스 내에 게시하는 콘텐츠 내의 음식 종류, 시간,
								위치 정보, 성별, 나이 정보가 서비스의 성능 향상을 위해 사용될 수
								있고 타 애플리케이션에 제공될 수 있습니다.{`\n`}⑧ 회원은 회사가
								제공하는 서비스 내에 콘텐츠 삭제, 비공개 등의 관리기능이
								제공되는 경우 이를 통해 직접 타인의 이용 또는 접근을 통제할 수
								있고, 고객 센터를 통해서도 콘텐츠의 삭제, 비공개, 검색결과 제외
								등의 조치를 요청할 수 있습니다. 다만, 일부 서비스의 경우 삭제,
								비공개 등의 처리가 어려울 수 있으며, 이러한 내용은 각 서비스
								상의 안내, 공지사항, 고객센터 도움말 등에서 확인해주시길
								부탁드립니다.{`\n`}⑨ 회원은 회사에 제공한 콘텐츠에 대해 회사에
								라이선스를 부여하기 위해 필요한 권리를 보유해야 합니다. 이러한
								권리를 보유하지 않아 발생하는 모든 문제에 대해서는 게시자가
								책임을 부담하게 됩니다. 또한, 회원은 음란하거나 폭력적이거나
								기타 공서양속 및 법령에 위반하는 콘텐츠를 공개 또는 게시할 수
								없습니다.{`\n`}⑩ 회사는 회원의 콘텐츠가 관련 법령에 위반되거나
								음란 또는 청소년에게 유해한 게시물, 차별 갈등을 조장하는 게시물,
								도배 · 광고 · 홍보 · 스팸성 게시물, 계정을 양도 또는 거래하는
								내용의 게시물, 타인을 사칭하는 게시물 등이라고 판단되는 경우
								이를 삭제하거나 게시를 거부할 수 있습니다. 다만 회사가 모든
								콘텐츠를 검토할 의무가 있는 것은 아닙니다. 누군가 여러분의
								권리를 침해하였다면, 고객센터를 통해 게시중단 요청에 대한 도움을
								받으실 수 있습니다.{`\n`}
								{`\n`}제 9 조 (유료 서비스의 이용){`\n`}① 서비스의 대부분은
								무료로 제공하고 있으나, 일부 하위 서비스는 유료로 제공될 수
								있습니다. 예를 들면 식사 기록 서비스는 무료로 사용할 수 있으나
								프리미엄 서비스는 유료로 구매해야 합니다.{`\n`}② 회원이 회사가
								제공하는 유료서비스를 이용하는 경우 이용대금을 납부한 후
								이용하는 것을 원칙으로 합니다. 회사가 제공하는 유료서비스에 대한
								이용요금의 결제 방법은 핸드폰결제, 신용카드결제,
								선불전자지급수단 결제 등이 있으며 각 유료서비스마다 결제 방법의
								차이가 있을 수 있습니다. 매월 정기적인 결제가 이루어지는
								서비스의 경우 회원 개인이 해당 서비스의 이용을 중단하고 정기
								결제의 취소를 요청하지 않는 한 매월 결제가 이루어집니다.{`\n`}③
								회사는 결제의 이행을 위하여 반드시 필요한 여러분의 개인정보를
								추가적으로 요구할 수 있으며, 회원은 회사가 요구하는 개인정보를
								정확하게 제공하여야 합니다.{`\n`}④ 본 조에서 정하지 않은 내용은
								개별 서비스 내의 각 세부 하위 서비스의 유료서비스 약관(예:
								당신의영양제 유료서비스 이용약관 등)에서 정하며, 본 조의 본 조의
								내용과 각 세부 하위 서비스의 유료서비스 약관의 내용이 충돌하는
								세부 하위 서비스의 유료서비스 약관의 규정에 따릅니다.{`\n`}
								{`\n`}제 10 조 (서비스 이용 방법 및 주의점){`\n`}① 회원은
								서비스를 자유롭게 이용할 수 있으나, 아래 각 호의 행위는 하여서는
								안 됩니다.
								{`\n`}
								1. 이용 신청 또는 변경 시 허위 사실을 기재하거나, 다른 사람의
								계정 및 비밀번호를 도용, 부정하게 사용하거나, 다른 사람의 명의를
								사용하는 행위
								{`\n`}
								2. 회사의 서비스 정보를 이용하여 얻은 정보를 회사의 사전 승낙
								없이 복제 또는 유통시키거나 상업적으로 이용하는 행위{`\n`}
								3. 타인의 명예를 손상시키거나 불이익을 주는 행위{`\n`}
								4. 회사 또는 제3자의 저작권 등 기타 권리를 침해하는 행위{`\n`}
								5. 회사가 안내하는 방법 이외의 다른 방법을 사용하여 서비스에
								접근하는 행위{`\n`}
								6. 서비스 내에 회사나 제3자 등에 대한 허위의 사실을 게시하는
								행위
								{`\n`}
								7. 공공질서 및 미풍양속에 위반되는 내용의 콘텐츠를 타인에게
								유포하는 행위{`\n`}
								8. 서비스와 관련된 설비의 오동작이나 정보 등의 파괴 및 혼란을
								유발시키는 컴퓨터 바이러스 감염 자료를 등록 또는 유포하는 행위
								{`\n`}
								9. 서비스의 운영을 고의로 방해하거나 안정적 운영을 방해하는 행위
								{`\n`}
								10. 회사의 동의 없이 서비스 또는 이에 포함된 소프트웨어의 일부를
								복사, 수정, 배포, 판매, 양도, 대여, 담보 제공하거나 타인에게 그
								이용을 허락하는 행위와 소프트웨어를 역 설계하거나 소스 코드의
								추출을 시도하는 등 서비스를 복제, 분해 또는 모방하거나 기타
								변형하는 행위{`\n`}
								11. 회사의 명시적인 동의 없이 상표 및 로고 등의 영업표지를
								무단으로 사용하는 행위{`\n`}
								12. 다른 이용자의 개인정보를 수집, 저장, 공개하는 행위{`\n`}
								13. 타인으로 가장하는 행위 및 타인과의 관계를 허위로 명시하는
								행위
								{`\n`}
								14. 자기 또는 타인에게 재산상의 이익을 주거나 타인에게 손해를
								가할 목적으로 허위의 정보를 유통시키는 행위{`\n`}
								15. 범죄행위를 목적으로 하거나 기타 범죄행위과 관련된 행위{`\n`}
								16. 재물을 걸고 도박하거나 사행행위를 하는 행위{`\n`}
								17. 윤락행위를 알선하거나 음행을 매개하는 내용의 정보를
								유통시키는 행위
								{`\n`}
								18. 수치심이나 혐오감 또는 공포심을 일으키는 말이나 이미지를
								계속하여 상대방에게 도달하게 하여 상대방의 일상적 생활을
								방해하는 행위{`\n`}
								19. 스토킹(stalking), 허위 또는 악의적 신고 남용 등 다른
								이용자를 괴롭히는 행위{`\n`}
								20. 관련 법령에 의하여 그 전송 또는 게시가 금지되는 정보(컴퓨터
								프로그램 포함)의 전송 또는 게시 행위{`\n`}
								21. 회사 또는 제휴사의 직원이나 운영자를 가장하거나 사칭하여
								또는 타임의 명의를 도용하여 글이나 이미지를 게시하거나 메시지
								등을 발송하는 행위
								{`\n`}
								22. 기타 현행 법령, 본 약관 및 회사가 제공하는 서비스 관련
								세부지침을 위반하는 행위{`\n`}② 회원은 서비스의 이용 권한, 기타
								이용계약상 지위를 타인에게 양도 · 증여할 수 없으며, 담보로
								제공할 수 없습니다.{`\n`}③ 혹시라도 회원이 관련 법령, 회사의
								모든 약관 또는 정책을 준수하지 않는다면, 회사는 여러분의
								위반행위 등을 조사할 수 있고, 해당 게시물 등을 삭제 또는 임시
								삭제하거나 여러분의 서비스 전체 또는 서비스를 구성하는 일부 개별
								서비스의 이용을 잠시 또는 계속하여 중단하거나, 서비스 재가입
								또는 일부 개별 서비스의 재이용에 제한을 둘 수도 있습니다.{`\n`}④
								이용 제한은 위반 활동의 누적 정도에 따라 한시적 제한에서 영구적
								제한으로 단계적 제한하는 것을 원칙으로 하지만, 음란한 내용의
								게시와 유포 및 사행성 도박 홍보 등 관련 법령에서 금지하는 명백한
								불법행위나 타인의 권리침해로서 긴급한 위험 또는 피해 차단이
								요구되는 사안에 대해서는 위반 활동 횟수의 누적 정도와 관계없이
								즉시 영구적으로 이용이 제한될 수 있습니다.{`\n`}⑤ 회원은 서비스
								제공 또는 이 약관 위반을 확인하기 위해 회사가 자료 또는
								접근권한의 제공 및 관련사실에 대한 소명을 요청하는 경우에는 이에
								성실히 임하여야 합니다.{`\n`}⑥ 회원은 회원이 운영하는 모바일
								애플리케이션에서의 개인정보 보호와 관련하여 다음 각 호의 사항을
								모두 준수하여야 합니다. 만약 본 항의 의무를 위반한 것을 회사가
								인지하게 되는 경우 회사는 해당 회원의 서비스 이용을 제한하거나
								이용계약을 해지할 수 있습니다.{`\n`}
								1. 모바일 애플리케이션 설정 페이지 내 회원의 모바일 애플리케이션
								동의항목 관리 화면에서 회원의 모바일 애플리케이션 실제 서비스
								내용과 일치하도록 항목을 입력하여야 합니다.{`\n`}
								2. 기타 정보통신망 이용촉진 및 정보보호 등에 관한 법률 등
								개인정보의 보호와 관련된 법령상 회원의 의무사항을 성실히
								준수하여야 합니다.{`\n`}
								{`\n`}제 11 조 (이용계약 해지){`\n`}① 회원이 하우투약 계정
								탈퇴를 하는 경우 서비스 이용계약도 자동으로 해지됩니다.{`\n`}②
								서비스 이용계약 해지를 원하는 경우 회원은 언제든지 서비스 내
								제공되는 메뉴를 이용하여 해지 신청을 할 수 있으며, 회사는 법령이
								정하는 바에 따라 신속히 처리하겠습니다.{`\n`}③ 서비스 이용계약이
								해지되면 관련 법령 및 하우투약 개인정보 처리방침에 따라 여러분의
								일정 정보를 보유하는 경우를 제외하고는 해당 회원 계정에 부속된
								게시물 일체를 포함한 회원의 모든 데이터는 소멸됨과 동시에 복구할
								수 없게 됩니다. 다만, 이 경우에도 다른 회원이 별도로 담아갔거나
								스크랩한 게시물과 공용 게시판에 등록한 게시물 등은 삭제되지
								않으므로 반드시 해지 신청 이전에 삭제하신 후 탈퇴하시기
								바랍니다.{`\n`}④ 전항에 따라 여러분이 삭제하지 않은 게시물은
								다른 이용자의 정상적 서비스 이용을 위하여 필요한 범위 내에서
								서비스 내에 삭제되지 않고 남아 있게 됩니다.{`\n`}⑤ 유료서비스
								이용계약의 해지는 회원의 여러분의 유료서비스 이용계약 해지 신청
								및 회사의 승낙에 의해 성립하게 되고, 환불할 금액이 있는 경우
								환불도 이루어 지게 됩니다. 이 때, 유료서비스 이용계약 해지
								신청은 서비스 이용계약 해지(회원 탈퇴)와는 별도로 이루어지며,
								회원을 탈퇴하신 후에는 유료서비스 이용 내역을 확인할 수 없기
								때문에 환불이 되지 않습니다. 유료서비스 이용계약의 해지 및
								환불을 원하실 경우 반드시 환불 처리 완료 후 탈퇴하시기 바랍니다.
								또한 각 세부 하위서비스의 유료서비스에서 본 약관과 다른 계약해지
								방법 및 효과를 규정하고 있는 경우 각 유료서비스 약관 및 관련
								세부지침에서 정한 바에 따릅니다{`\n`}⑥ 서비스를 구성하는 일부
								개별 서비스의 경우 일정기간 동안 해당 개별 서비스를 이용하지
								않을 경우 여러분의 정보를 파기하거나 분리 보관할 수 있으며, 또는
								해당 개별 서비스 기능의 일부 또는 전부를 이용할 수 없도록 제한할
								수 있습니다.{`\n`}⑦ 서비스 이용계약이 해지된 경우라도 여러분은
								다시 회사에 대하여 이용계약의 체결을 신청할 수 있습니다. 다만,
								여러분이 관련 법령, 본 약관 및 세부지침을 준수하지 않아 서비스의
								이용이 중단된 상태에서 이용계약을 해지한 후 다시 이용계약 체결을
								신청하는 경우에는 서비스 가입에 일정기간 시간적 제한이 있을 수
								있습니다. 또한 서비스를 구성하는 일부 개별 서비스의 경우 다시
								서비스 이용계약을 체결한 후에도 해당 개별 서비스를 바로 이용하는
								것에는 제10조 제3항에서 정한 바와 같이 일정한 시간적 제한 등이
								따를 수 있습니다.{`\n`}
								{`\n`}제 12 조 (개인 정보의 보호){`\n`}
								회사는 정보통신망이용촉진 및 정보보호 등에 관한 법률 등 관련
								법령에서 정하는 바에 따라 회원의 개인정보를 보호하기 위해
								노력합니다. 회원의 개인정보는 서비스의 원활한 제공을 위하여
								회원이 동의한 목적과 범위 내에서만 이용됩니다. 관련 법령에
								의하거나 회원이 별도로 동의하지 아니하는 한 회사가 회원의
								개인정보를 제3자에게 제공하는 일은 결코 없으므로, 안심하여도
								좋습니다. 회사가 회원의 개인정보를 안전하게 처리하기 위하여
								기울이는 노력이나 기타 자세한 사항은 하우투약 개인정보처리방침
								등을 참고해 주시기 바랍니다.{`\n`}
								{`\n`}제 4 장 기타{`\n`}
								{`\n`}제 13 조 (손해배상 등){`\n`}① 회사는 관련 법령상 허용되는
								한도 내에서 통합서비스와 관련하여 본 약관에 명시되지 않은 어떠한
								구체적인 사항에 대한 약정이나 보증을 하지 않습니다. 또한, 회사의
								과실 없이 발생된 회원의 손해에 대하여는 책임을 부담하지
								아니합니다.{`\n`}② 회사는 회사의 과실로 인하여 회원이 손해를
								입게 될 경우 본 약관 및 관련 법령에 따라 회원의 손해를
								배상하겠습니다. 다만 회사는 회사의 과실 없이 발생된 아래와 같은
								손해에 대해서는 책임을 부담하지 않습니다. 또한 회사는 법률상
								허용되는 한도 내에서 간접 손해, 특별 손해, 결과적 손해, 징계적
								손해, 및 징벌적 손해에 대한 책임을 부담하지 않습니다.{`\n`}
								1. 천재지변 또는 이에 준하는 불가항력의 상태에서 발생한 손해
								{`\n`}
								2. 회원의 귀책사유로 서비스 이용에 장애가 발생한 경우{`\n`}
								3. 서비스에 접속 또는 이용과정에서 발생하는 개인적인 손해{`\n`}
								4. 제3자가 불법적으로 회사의 서버에 접속하거나 서버를
								이용함으로써 발생하는 손해{`\n`}
								5. 제3자가 회사 서버에 대한 전송 또는 회사 서버로부터의 전송을
								방해함으로써 발생하는 손해{`\n`}
								6. 제3자가 악성 프로그램을 전송 또는 유포함으로써 발생하는 손해
								{`\n`}
								7. 전송된 데이터의 생략, 누락, 파괴 등으로 발생한 손해, 명예훼손
								등 제3자가 서비스를 이용하는 과정에서 발생된 손해{`\n`}
								8. 기타 회사의 고의 또는 과실이 없는 사유로 인해 발생한 손해
								{`\n`}③ 회사는 회원이 서비스를 이용하여 기대하는 수익을 상실한
								것에 대하여 책임을 지지 않으며 그 밖에 서비스를 통하여 얻은
								자료로 인한 손해 등에 대하여도 책임을 지지 않습니다.{`\n`}④
								회사는 회원의 귀책사유로 인한 서비스 이용의 장애에 대하여는
								책임을 부담하지 않습니다.{`\n`}⑤ 회사는 서비스를 통해 회원의
								모바일 애플리케이션 개발에 부가적인 기능을 제공할 뿐이며,
								서비스를 사용에 따른 어떠한 효과 향상도 보증하지 않습니다.{`\n`}
								⑥ 회사는 무료로 제공되는 서비스 이용과 관련하여 관련법령에
								특별한 규정이 없는 한 책임을 부담하지 않습니다.{`\n`}⑦ 회사는
								회사의 과실이 없는 한 회원 상호간 또는 회원과 제3자 상호간에
								서비스를 매개로 발생한 분쟁에 대해서는 개입할 의무가 없으며 이로
								인한 손해를 배상할 책임도 없습니다.
								{`\n`}
								{`\n`}제 14 조 (청소년 보호){`\n`}
								서비스는 기본적으로 모든 연령대가 자유롭게 이용할 수 있는
								공간으로서 유해 정보로부터 청소년을 보호하고 청소년의 안전한
								인터넷 사용을 돕기 위해 정보통신망법에서 정한 청소년보호정책을
								별도로 시행하고 있으며, 구체적인 내용은 서비스를 구성하는 개별
								서비스 초기 화면 등에서 확인할 수 있습니다.{`\n`}
								{`\n`}제 15 조 (통지 및 공지){`\n`}
								회사는 회원과의 의견 교환을 소중하게 생각합니다. 회원은 언제든지
								하우투약 고객센터에 의견을 개진할 수 있습니다. 서비스 이용자
								전체에 대한 공지는 7일 이상 서비스 공지사항 란에 게시함으로써
								효력이 발생합니다. 회원에게 중대한 영향을 미치는 사항의 경우에는
								하우투약 계정에 등록된 이메일 주소로 이메일 (이메일 주소가 없는
								경우 서비스 내 알림 메시지를 띄우는 등의 별도의 전자적 수단)
								발송 또는 회원이 등록한 휴대폰번호로 문자메시지를 발송하는 방법
								등으로 개별적으로 알려 드리겠습니다.{`\n`}
								{`\n`}제 16 조 (분쟁의 해결){`\n`}본 약관 또는 서비스는
								대한민국법령에 의하여 규정되고 이행됩니다. 서비스 이용과
								관련하여 회사와 회원 간에 분쟁이 발생하면 이의 해결을 위해
								성실히 협의할 것입니다. 그럼에도 불구하고 해결되지 않으면
								민사소송법의 관할법원에 소를 제기할 수 있습니다.{`\n`}
								1. 회사와 회원 간 제기된 소송은 대한민국법을 준거법으로 합니다.
								{`\n`}
								2. 회사와 회원간 발생한 분쟁에 관한 소송은 민사소송법 상의
								관할법원에 제소합니다.{`\n`}
								{`\n`}
								부칙{`\n`}이 약관은 2021년 10월 6일부터 적용됩니다.{`\n`}
								{`\n`}
								공고일자 : 2021년 10월 6일{`\n`}
								시행일자 : 2021년 10월 6일
							</p>

							<button
								type="button"
								onClick={() => history.back()}
								style={{
									display: "block",
									fontSize: "1.05em",
									padding: "0.8em 2em",
									background: "#117ffa",
									color: "#fff",
									borderRadius: "6px",
									margin: "10em auto 6em",
								}}
							>
								뒤로가기
							</button>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default Terms;
