import { combineReducers } from "redux";
import { authReducer } from "./auth/authDuck";
import { commonReducer } from "./common/commonDuck";
import { channelReducer } from "./channel/channelDuck";

const createRootReducer = (history) =>
	combineReducers({
		auth: authReducer,
		common: commonReducer,
		channel: channelReducer,
	});

export default createRootReducer;
