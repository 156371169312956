import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { Collapse } from "antd";
import CollapsePanel from "antd/es/collapse/CollapsePanel";

import LinkComponent from "../components/layout/LinkComponent";

const Notice = () => {
	const [mediaResult, setMediaResult] = useState(null);
	const [currentPanel, setCurrentPanel] = useState(null);
	const history = useHistory();
	useEffect(() => {
		window.scrollTo({
			top: 0,
		});
		if (typeof window !== "undefined") {
			const matchQueryList = window.matchMedia("all and (max-width: 768px)");
			setMediaResult(matchQueryList.matches);

			const media = function handleChange(e) {
				setMediaResult(e.matches);
			};

			matchQueryList.addEventListener("change", media);
		}
	}, []);

	// 광고 삽입 시 대가성 문구를 꼭 작성해 주세요.
	// 	당영 투게더의 활동은 공정거래위원회의 심사지침에 따라 추천, 보증인인 투게더 회원과 당사의 경제적 이해관계에 대하여 공개하여야 합니다.
	// 	따라서 당영 투게더 활동으로 게재된 모든 게시물에는 대가성 문구가 반드시 표기되어야 합니다.
	// 	권장 문구 : 이 포스팅은 당영 투게더로부터 지원을 받아 작성되었습니다
	//
	// 부당 표시 광고/허위 과장 광고 표현을 주의해 주세요.
	// 	당영 투게더는 회원이 당영 투게더 수익 링크를 포함한 게시글에 사실과 다르거나, 과장/왜곡/오인의 가능성이 있는 내용을 표시하는 등 표시광고법 기타 관련 법령에 위반되는 내용을 게시하는 행위를 금지하고 있습니다.
	// 	특히나 제품의 ‘효능’에 대한 직접적인 언급은 식품 광고법에 위반됩니다.
	// 	예시로 어떤 효과가 있었다는 후기 언급, 질병의 예방이나 치료에 효능이 있는 것을 암시, 의약품으로 오인될 수 있는 표현을 사용하는 등의 경우가 식품 광고법 위반에 해당됩니다.
	return (
		<>
			<div className="main-wrap notice">
				<div className="max-width-apply">
					<LinkComponent />
					<div
						style={{
							display: "flex",
							flexDirection: "column",
							gap: mediaResult ? 7 : "0.3em",
							paddingBottom: "1em",
						}}
					>
						<h1
							className={`main-title add-mobile-css`}
							id={`main-title`}
							style={{ margin: 0 }}
						>
							공지사항
						</h1>
						<p className={`main-description page`}>
							당영 투게더 활동 시 이것만큼은 꼭 알아두세요!
						</p>
					</div>
				</div>
			</div>

			{/* 본문 내용 */}
			<div className="dashboard-list-content-box">
				<Collapse
					accordion
					bordered={false}
					activeKey={currentPanel ? [currentPanel] : []}
					className="faq-custom-collapse page"
					onChange={(e) => {
						setCurrentPanel(e ? String(e) : null);
					}}
					expandIconPosition={"end"}
				>
					<CollapsePanel
						header={"광고 삽입 시 대가성 문구를 꼭 작성해 주세요."}
						className={`faq-header-custom faq-custom-collapse-panel ${
							currentPanel === "0" ? "clicked" : ""
						}`}
					>
						<p>
							당영 투게더의 활동은 공정거래위원회의 심사지침에 따라 추천,
							보증인인 투게더 회원과 당사의 경제적 이해관계에 대하여 공개하여야
							합니다.{"\n"}따라서{" "}
							<span style={{ color: "#117ffa" }}>
								당영 투게더 활동으로 게재된 모든 게시물에는 대가성 문구가 반드시
								표기되어야 합니다.
							</span>
							{"\n"}권장 문구 : 이 포스팅은 당영 투게더로부터 지원을 받아
							작성되었습니다
						</p>
					</CollapsePanel>
					<CollapsePanel
						header={"부당 표시 광고/허위 과장 광고 표현을 주의해 주세요."}
						className={`faq-custom-collapse-panel ${
							currentPanel === "1" ? "clicked" : ""
						}`}
					>
						<p>
							당영 투게더는 회원이 당영 투게더 수익 링크를 포함한 게시글에
							사실과 다르거나, 과장/왜곡/오인의 가능성이 있는 내용을 표시하는 등
							표시광고법 기타 관련 법령에 위반되는 내용을 게시하는 행위를
							금지하고 있습니다.{"\n"}
							특히나{" "}
							<span style={{ color: "#117ffa" }}>
								제품의 ‘효능’에 대한 직접적인 언급은 식품 광고법에 위반됩니다.
							</span>
							{"\n"}
							예시로 어떤 효과가 있었다는 후기 언급, 질병의 예방이나 치료에
							효능이 있는 것을 암시, 의약품으로 오인될 수 있는 표현을 사용하는
							등의 경우가 식품 광고법 위반에 해당됩니다.
						</p>
					</CollapsePanel>
				</Collapse>
			</div>
			<button
				style={{ margin: "0 auto 10em" }}
				type="button"
				className="inquiry-go-list-btn"
				onClick={() => history.push("/dashboard")}
			>
				홈으로
			</button>
		</>
	);
};
export default Notice;
