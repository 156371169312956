import "./User.scss";
import * as React from "react";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { Button, Input } from "antd";

import Swal from "sweetalert2";

import ApiService from "../../apis/ApiService_mall";
import Policy from "../../pages/common/Policy";
import Terms from "../../pages/common/Terms";
import { adjRandomList, nameRandomList } from "../../pages/user/LoginUitls";
import { handleIsMallUserWithPartners } from "../../pages/user/LoginUitls";
import { setLoading } from "../../redux/store/common/commonDuck";
import { loginLog } from "../../utils/useUser";
import CustomModal from "../common/CustomModal";

import SignUpHeader from "./SignUpHeader";

const api = new ApiService();
const SignUpComponent = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const isLoading = useSelector((state) => state.common.loading);
	const setIsLoading = (value) => dispatch(setLoading(value));
	/**
	 * 일반 로그인 데이터
	 */
	const [userLogin, setUserLogin] = useState({
		phone: "",
		password: "",
	});

	const [mediaResult, setMediaResult] = useState(null);
	const [userValue, setUserValue] = useState({
		name: "",
		phone: "",
		phone_valid: "",
		password: "",
		password_valid: "",
		web_url: "",
		sns_url: "",
		referral_code: "",
	});
	const [agreement, setAgreement] = useState({
		policy: false,
		terms: false,
	});
	const [deleteUrl, setDeleteUrl] = useState(""); // 삭제하려는 URL
	const [webUrlList, setWebUrlList] = useState([]); // 웹사이트 목록
	const [snsUrlList, setSnsUrlList] = useState([]); // SNS 목록

	const [phoneValid, setPhoneValid] = useState(false); // 인증번호 전송
	const [isPhoneValid, setIsPhoneValid] = useState(false); // 인증번호 확인

	const [passwordRegValid, setPasswordRegValid] = useState(false); // 비밀번호 대소문자,숫자,특수문자 true/false
	const [passwordCheckValid, setPasswordCheckValid] = useState(false); // 비밀번호 확인

	const [isShowPolicy, setIsShowPolicy] = useState(false);
	const [isShowTerms, setIsShowTerms] = useState(false);
	const [isShowUrlValidation, setIsShowUrlValidation] = useState(false);
	const [urlValidationText, setUrlValidationText] = useState(
		"입력 값이 비어있거나 유효하지 않은 주소입니다."
	);
	const [isShowUrlDelete, setIsShowUrlDelete] = useState(false);
	const [deleteUrlType, setDeleteUrlType] = useState("");

	const [inputValid, setInputValid] = useState(false); // 전체 의동의

	const [snsUserInfo, setSnsUserInfo] = useState(null);

	useEffect(() => {
		if (sessionStorage.getItem("referral_code")) {
			setUserValue({
				...userValue,
				referral_code: sessionStorage.getItem("referral_code"),
			});
		}
		window.scrollTo({ top: 0 });
		if (typeof window !== "undefined") {
			const matchQueryList = window.matchMedia("all and (max-width: 768px)");
			setMediaResult(matchQueryList.matches);

			const media = function handleChange(e) {
				setMediaResult(e.matches);
			};

			matchQueryList.addEventListener("change", media);
		}
		handleSnsUser();
	}, []);

	const handleSnsUser = () => {
		const snsUserInfoSession = JSON.parse(
			sessionStorage.getItem("snsUserInfo")
		);
		if (snsUserInfoSession) {
			setSnsUserInfo(snsUserInfoSession);
		}
	};

	// useEffect(() => {
	// 	if (userValue.name?.length === 0) {
	// 		randomNickname();
	// 	}
	// }, [userValue]);

	/**
	 * 회원 정보 받기
	 * @type {(function(*): void)|*}
	 */
	const handleInputUser = useCallback(
		(e) => {
			const { value, name } = e.target;
			if (name === "phone") {
				setPhoneValid(false);
				setIsPhoneValid(false);
				if (value.split("-").join("").length <= 11) {
					let phone = value
						.replace(/[^0-9]/g, "")
						.replace(/^(\d{2,3})(\d{3,4})(\d{4})$/g, "$1-$2-$3")
						.replace(/(-{1,2})$/g, "");
					setUserValue({
						...userValue,
						phone: phone,
					});
				}
				// if (value.split("-").join("").length <= 11) {
				// 	let phone = value.replace(/[^0-9]/g, "");
				// 	setUserValue({
				// 		...userValue,
				// 		phone: phone,
				// 	});
				// }
			} else if (name === "phone_valid") {
				if (value.split("-").join("").length <= 6) {
					let phone_valid = value.replace(/[^0-9]/g, "");
					setUserValue({
						...userValue,
						phone_valid: phone_valid,
					});
				}
			} else if (name === "password") {
				let password = value;
				// 특수문자 / 문자 / 숫자 포함 형태의 8~16 자리 이내의 암호 정규식
				let regPass =
					/^.*(?=^.{8,16}$)(?=.*\d)(?=.*[a-zA-Z])(?=.*[!@#$%^&+=]).*$/;
				if (password.match(regPass)) {
					setPasswordRegValid(true);
				} else {
					setPasswordRegValid(false);
				}

				// 숫자, 문자, 특수문자만 입력 가능
				const regExp = /[^0-9a-zA-Z!@#$%^&+=]/g;
				if (regExp.test(value)) {
					password = value.replace(regExp, "");
				}
				setUserValue({
					...userValue,
					[name]: password,
				});

				const regPassword =
					/^(?=.*[A-Za-z])(?=.*\d)[a-zA-Z\\d`~!@#$%^&*()-_=+(?=.*\d)]{8,}$/;
				if (regPassword.test(e.target.value)) {
					if (!passwordRegValid) setPasswordRegValid(true);
				} else {
					if (passwordRegValid) setPasswordRegValid(false);
				}
			} else if (name === "password_valid") {
				setUserValue({
					...userValue,
					[name]: value,
				});
				if (userValue.password === value) {
					setPasswordCheckValid(true);
				} else {
					setPasswordCheckValid(false);
				}
			} else {
				setUserValue({
					...userValue,
					[name]: value,
				});
			}
		},
		[userValue]
	);

	/**
	 * step 2-1 : 휴대폰 인증
	 */
	const sendPhoneValid = async () => {
		let regPhone = /010-?([0-9]{4})-?([0-9]{4})$/;
		if (regPhone.test(userValue.phone)) {
			setIsLoading(true);
			let param = {
				phone: userValue.phone.replaceAll("-", ""),
				is_user_valid: true,
			};
			let res = await api.smsCertifySend(param);

			if (res.data.code === 200) {
				if (res.data.result?.id_check === true) {
					const loginMethod =
						res.data.result.user?.provider === "kakao"
							? "카카오"
							: res.data.result.user?.provider === "naver"
							? "네이버"
							: "해당 번호";

					Swal.fire({
						customClass: "alert-custom-confirm",
						text: `${loginMethod}로 가입된 유저가 있습니다. 로그인 하시겠습니까?`,
						confirmButtonText: "확인",
						confirmButtonColor: "#117FFA",
					}).then((res) => {
						if (res.isConfirmed) {
							history.push(`/`);
						}
					});
				} else {
					setPhoneValid(true);
					setIsLoading(false);
					Swal.fire({
						customClass: "alert-custom-confirm",
						text: "입력하신 휴대폰 번호로\n 인증번호가 발송되었습니다.",
						confirmButtonText: "확인",
						confirmButtonColor: "#117FFA",
					});
				}
				setIsLoading(false);
			} else {
				setIsLoading(false);
			}
		} else {
			Swal.fire({
				customClass: "alert-custom-confirm",
				text: "휴대폰 번호를 정확하게 입력해주세요.",
				confirmButtonText: "확인",
				confirmButtonColor: "#117FFA",
			});
		}
	};

	/**
	 * step 2-2: 휴대폰 인증번호 일치 확인
	 */
	const checkPhoneValid = async () => {
		const regPhone = /([0-9]{6})$/;
		if (regPhone.test(userValue.phone_valid)) {
			setIsLoading(true);

			let param = {
				phone: userValue.phone.replaceAll("-", ""),
				sms_verify_code: userValue.phone_valid,
			};
			let res = await api.smsCertifyVerify(param);

			if (res.data.code === 200) {
				Swal.fire({
					customClass: "alert-custom-confirm",
					text: "휴대폰 인증이 완료되었습니다.",
					confirmButtonText: "확인",
					confirmButtonColor: "#117FFA",
				});
				setIsPhoneValid(true);
				setIsLoading(false);
			} else {
				Swal.fire({
					customClass: "alert-custom-confirm",
					text: "인증번호가 일치하지 않습니다.",
					confirmButtonText: "확인",
					confirmButtonColor: "#117FFA",
				});
				setIsLoading(false);
			}
		} else {
			Swal.fire({
				customClass: "alert-custom-confirm",
				text: "인증번호 6자리를 정확하게 입력해주세요.",
				confirmButtonText: "확인",
				confirmButtonColor: "#117FFA",
			});
		}
	};

	/**
	 * 입력 값 밸리데이션 -> signupValue 변할 때 마다 적용
	 */
	useEffect(() => {
		const { phone } = userValue;
		if (
			phone?.replaceAll("-", "").length !== 11 ||
			!phoneValid ||
			!isPhoneValid ||
			!passwordRegValid ||
			!passwordCheckValid
		) {
			if (inputValid) {
				setInputValid(false);
			}
		} else {
			if (agreement.terms && agreement.policy) {
				setInputValid(true);
			} else {
				setInputValid(false);
			}
		}
	}, [passwordRegValid, userValue, agreement]);

	/**
	 * 가입하기 버튼 클릭
	 * + 웹사이트&SNS 목록 처리 추가 (2024.09.10) - ksb
	 */
	const handleSignupButton = () => {
		let snsValid = snsUserInfo && agreement.policy && agreement.terms;
		let normalValid =
			isPhoneValid &&
			passwordCheckValid &&
			userValue.name?.length > 0 &&
			agreement.policy &&
			agreement.terms;

		if (snsValid || normalValid) {
			if (userValue.web_url || userValue.sns_url) {
				Swal.fire({
					customClass: "alert-custom-confirm",
					showCancelButton: true,
					text: "입력하신 URL은 추가 버튼을 클릭해 목록에 추가해야만 저장됩니다.\n이대로 회원가입을 진행하시겠습니까?",
					confirmButtonText: "확인",
					confirmButtonColor: "#117FFA",
					cancelButtonText: "취소",
				}).then((res) => {
					if (res.isConfirmed) {
						let query = sessionStorage.getItem("login-influencer");
						setIsLoading(true);
						const timer = setTimeout(() => {
							if (query) {
								postUserChannelJoin();
							} else {
								userJoin();
							}
						}, 100);
						return () => clearTimeout(timer);
					}
				});
			} else {
				let query = sessionStorage.getItem("login-influencer");
				setIsLoading(true);
				const timer = setTimeout(() => {
					if (query) {
						postUserChannelJoin();
					} else {
						userJoin();
					}
				}, 100);
				return () => clearTimeout(timer);
			}
		} else {
			Swal.fire({
				customClass: "alert-custom-confirm",
				text: "필수 입력 항목을 모두 적어주세요.",
				confirmButtonText: "확인",
				confirmButtonColor: "#117FFA",
			});
		}
	};

	/**
	 * 일반 로그인 버튼 클릭
	 */
	const submitUserLogin = async (phone, password) => {
		setIsLoading(true);
		const param = {
			phone: phone,
			password: password,
		};

		let res = await api.phoneLogin(param);
		if (res.data.code === 200) {
			const user = res.data.result.user;
			localStorage.removeItem("admin:accessToken");
			localStorage.removeItem("admin:refreshToken");
			localStorage.setItem(
				"admin:accessToken",
				res.data.result.tokenDto.accessToken
			);
			localStorage.setItem(
				"admin:refreshToken",
				res.data.result.tokenDto.refreshToken
			);
			setIsLoading(false);
			await handleIsMallUserWithPartners(user, dispatch, history);
		} else {
			Swal.fire({
				customClass: "alert-custom-confirm",
				text: `${res.data.msg}`,
				confirmButtonText: "확인",
				confirmButtonColor: "#117FFA",
			});
			setIsLoading(false);
		}
		setIsLoading(false);
	};

	// 인플루언서 확인 후 회원가입
	const postUserChannelJoin = async () => {
		let query = sessionStorage.getItem("login-influencer");
		let param = {
			provider: null,
			provider_id: null,
			phone: userValue.phone,
			password: userValue.password.replaceAll("-", ""),
			nickname: userValue.name.trim(),
			query: query,
		};
		if (snsUserInfo) {
			param = {
				...param,
				provider_id: snsUserInfo.provider_id,
				provider: snsUserInfo.provider,
				phone: snsUserInfo.phone_number
					?.replaceAll("-", "")
					?.replaceAll("+82 ", "0")
					.trim(),
			};
		}
		const res = await api.userChannelJoin(param);
		if (res.data.code === 200) {
			localStorage.removeItem("login-influencer");
			let result = res.data.result;

			// 회원가입 로그 남기기
			loginLog(
				result.user,
				"singup-dangyoung" + (query ? ` query: ${query}` : "")
			);

			localStorage.removeItem("admin:accessToken");
			localStorage.removeItem("admin:refreshToken");

			Swal.fire({
				customClass: "alert-custom-confirm2",
				title: "회원가입이 완료되었습니다.",
				text: "당영투게더 회원가입 시 당영몰과 자동 연동되며, \n 당영몰 아이디로도 당영투게더에 로그인할 수 있습니다.",
				confirmButtonText: "확인",
				confirmButtonColor: "#117FFA",
			}).then(() => {
				history.push(`/`);
			});

			setIsLoading(false);
		} else {
			setIsLoading(true);
			userJoin();
		}
		setIsLoading(false);
	};
	/**
	 * 회원가입 api 호출
	 * + 회원가입시, query가 있으면 해당값 param에 추가 (2024.01.29) - ksb
	 * + 회원가입시, 웹사이트 및 SNS URL 목록이 있으면 해당값 param에 추가 (2024.09.10) - ksb
	 */
	const userJoin = async () => {
		const referralCode = sessionStorage.getItem("referral_code");
		const webUrl = sessionStorage.getItem("web_url_list");
		const snsUrl = sessionStorage.getItem("sns_url_list");
		if (referralCode) {
			sessionStorage.removeItem("referral_code");
		}
		if (webUrl) {
			sessionStorage.removeItem("web_url_list");
		}
		if (snsUrl) {
			sessionStorage.removeItem("sns_url_list");
		}
		if (userValue.referral_code?.trim()) {
			sessionStorage.setItem("referral_code", userValue.referral_code.trim());
		}
		if (webUrlList?.length) {
			sessionStorage.setItem("web_url_list", JSON.stringify(webUrlList));
		}
		if (snsUrlList?.length) {
			sessionStorage.setItem("sns_url_list", JSON.stringify(snsUrlList));
		}
		const query = sessionStorage.getItem("query");
		let param = {
			nickname: userValue.name.trim(),
			gender: "",
			birthday: "",
			age: "",
			// provider: "user",
			password: userValue.password,
			phone: userValue.phone.replaceAll("-", ""),
		};
		if (query) {
			param["query"] = query;
		}
		if (snsUserInfo) {
			param = {
				...param,
				provider_id: snsUserInfo.provider_id,
				provider: snsUserInfo.provider,
				phone: snsUserInfo.phone_number
					?.replaceAll("-", "")
					?.replaceAll("+82 ", "0")
					.trim(),
			};
		}
		let res = await api.userJoin(param);
		if (res.data.code === 200) {
			const regSuccessData = res.data.result;

			// 회원가입 로그 남기기
			loginLog(
				regSuccessData.user,
				"singup-dangyoung" + (query ? ` query: ${query}` : "")
			);

			localStorage.removeItem("admin:accessToken");
			localStorage.removeItem("admin:refreshToken");

			Swal.fire({
				customClass: "alert-custom-confirm2",
				title: "회원가입이 완료되었습니다.",
				text: "당영투게더 회원가입 시 당영몰과 자동 연동되며, \n 당영몰 아이디로도 당영투게더에 로그인할 수 있습니다.",
				confirmButtonText: "확인",
				confirmButtonColor: "#117FFA",
			}).then(() => {
				//  로그인 로직 추가 - kth (24.09.03)
				submitUserLogin(
					userValue.phone.replaceAll("-", ""),
					userValue.password
				);
				localStorage.setItem("welcome-modal", true);
				localStorage.setItem("modal-step", 1);
			});

			setIsLoading(false);
		} else {
			Swal.fire({
				customClass: "alert-custom-confirm",
				text: "일시적인 오류로 실패하였습니다. 다시 시도해주세요.",
				confirmButtonText: "확인",
				confirmButtonColor: "#117FFA",
			});
			setIsLoading(false);
		}
	};

	/**
	 * 랜덤 닉네임
	 * @returns {Promise<string>}
	 */
	const randomNickname = async () => {
		let random_nickname = "";

		random_nickname +=
			adjRandomList[Math.floor(Math.random() * adjRandomList.length)];
		random_nickname +=
			nameRandomList[Math.floor(Math.random() * nameRandomList.length)];

		const random = (Math.random() * 10000).toFixed();
		random_nickname += random;

		let param = {
			nickname: random_nickname,
		};
		let res = await api.nicknameValid(param);

		if (res.data.code === 200) {
			setUserValue({
				...userValue,
				name: random_nickname,
			});
			return random_nickname;
		} else {
			randomNickname();
		}
	};

	/**
	 * 웹사이트&SNS URL 추가하기 버튼 클릭 이벤트 핸들러
	 * @param type web, sns
	 * @returns {Promise<void>}
	 */
	const addUrlList = async (type) => {
		if (type === "web") {
			const findIdx = webUrlList.findIndex((url) => url === userValue.web_url);
			if (
				userValue.web_url &&
				userValue.web_url.includes("http") &&
				findIdx === -1
			) {
				setWebUrlList([...webUrlList, userValue.web_url]);
				setUserValue({
					...userValue,
					web_url: "",
				});
			} else {
				if (findIdx > -1) {
					setUrlValidationText("이미 등록된 URL입니다.");
				} else {
					setUrlValidationText(
						"입력 값이 비어있거나 유효하지 않은 주소입니다."
					);
				}
				setIsShowUrlValidation(true);
			}
		} else {
			const findIdx = snsUrlList.findIndex((url) => url === userValue.sns_url);
			if (
				userValue.sns_url &&
				(userValue.sns_url.includes("https://apps.apple.com") ||
					userValue.sns_url.includes("https://play.google.com/store/apps")) &&
				findIdx === -1
			) {
				setSnsUrlList([...snsUrlList, userValue.sns_url]);
				setUserValue({
					...userValue,
					sns_url: "",
				});
			} else {
				if (findIdx > -1) {
					setUrlValidationText("이미 등록된 URL입니다.");
				} else {
					setUrlValidationText(
						"입력 값이 비어있거나 유효하지 않은 주소입니다."
					);
				}
				setIsShowUrlValidation(true);
			}
		}
	};

	/**
	 * 웹사이트&SNS URL 삭제하기 버튼 클릭 이벤트 핸들러
	 * @param type
	 */
	const handleDeleteUrlClick = (type, url) => {
		setDeleteUrlType(type);
		setDeleteUrl(url);
		setIsShowUrlDelete(true);
	};

	/**
	 * 웹사이트&SNS URL 목록에서 삭제 완료 처리
	 */
	const deleteUrlList = () => {
		if (deleteUrlType === "웹사이트") {
			const findIdx = webUrlList.findIndex((url) => url === deleteUrl);
			if (findIdx > -1) {
				let list = webUrlList;
				list.splice(findIdx, 1);
				setWebUrlList(list);
				setDeleteUrlType("");
				setDeleteUrl("");
			}
		} else {
			const findIdx = snsUrlList.findIndex((url) => url === deleteUrl);
			if (findIdx > -1) {
				let list = snsUrlList;
				list.splice(findIdx, 1);
				setSnsUrlList(list);
				setDeleteUrlType("");
				setDeleteUrl("");
			}
		}
	};

	/**
	 * 동의 페이지 확인 버튼 클릭 이벤트 핸들러
	 */
	const handleAgreeClick = () => {
		if (isShowPolicy) {
			setIsShowPolicy(false);
		} else if (isShowTerms) {
			setIsShowTerms(false);
		}
	};

	/**
	 * 회원가입 버튼 disabled 유무 확인
	 */
	const disabledSignupBtn = () => {
		if (
			isPhoneValid &&
			passwordCheckValid &&
			userValue.name?.length > 0 &&
			agreement.policy &&
			agreement.terms
		) {
			return false;
		} else {
			return true;
		}
	};

	return (
		<div className="main-wrap">
			{/* 모바일 헤더 추가 */}
			<SignUpHeader
				title={
					isShowPolicy
						? `개인정보 처리방침`
						: isShowTerms
						? `이용약관`
						: `회원가입`
				}
				back={isShowPolicy || isShowTerms ? false : true}
				close={false}
			/>
			<div className="max-width-apply">
				{/* 기본 정보 */}
				{isShowPolicy ? (
					<Policy pageType={"component"} />
				) : isShowTerms ? (
					<Terms pageType={"component"} />
				) : (
					<div className={`signup-form-parents`}>
						<h1
							className={`main-title`}
							id={`main-title`}
							style={{ margin: 0, paddingBottom: "0.5em" }}
						>
							{snsUserInfo?.provider === "kakao"
								? "카카오 계정으로 가입하기"
								: snsUserInfo?.provider === "naver"
								? "네이버 계정으로 가입하기"
								: "회원가입"}
						</h1>
						{!snsUserInfo && (
							<>
								{/* <div className={`signup-component-list`}>
									<h2>기본 정보</h2>
								</div> */}

								<div className={`signup-form-wrap`}>
									<div className={`signup-form-item divider`}>
										<label>
											이름 <span>*</span>
										</label>
										<div className={`input-div phone`}>
											<Input
												type={`text`}
												placeholder={`이름을 입력해주세요.`}
												name={"name"}
												value={userValue.name}
												onChange={handleInputUser}
											/>
										</div>
									</div>
									<div
										className={`signup-form-item ${
											phoneValid ? "padding" : "divider"
										}`}
									>
										<label>
											휴대폰 번호 <span>*</span>
										</label>
										<div className={`input-div phone`}>
											<Input
												type={`text`}
												placeholder={`-를 생략하고 입력해주세요.`}
												value={userValue.phone}
												name={"phone"}
												onChange={handleInputUser}
												pattern={`/d*`}
												disabled={isPhoneValid || !!snsUserInfo}
											/>
											{isPhoneValid ? (
												<div
													className="input-div-button-success signup-btn"
													style={{ padding: !mediaResult && "12px 0" }}
												>
													<img
														src={`/assets/images/success_button_icon.png`}
														alt={`success_button_icon`}
														style={{
															width: "1.2em",
															height: "auto",
														}}
													/>
													<span>인증완료</span>
												</div>
											) : (
												<Button
													className={`certification-request-btn signup-btn`}
													loading={isLoading}
													disabled={phoneValid || !!snsUserInfo}
													onClick={sendPhoneValid}
													style={{
														// padding: "0 1.3em",
														minWidth: 100,
													}}
												>
													{!isLoading && "인증요청"}
												</Button>
											)}
										</div>
									</div>
									{phoneValid && !isPhoneValid && (
										<div className={`signup-form-item divider`}>
											<label>인증번호</label>
											<div className={`input-div phone`}>
												<Input
													type={`text`}
													placeholder={`인증번호 6자리를 입력해주세요.`}
													value={userValue.phone_valid}
													name={"phone_valid"}
													onChange={handleInputUser}
													pattern={`/d*`}
													disabled={isPhoneValid || !!snsUserInfo}
												/>
												<Button
													className={`certification-request-btn`}
													loading={isLoading}
													disabled={isPhoneValid || !!snsUserInfo}
													onClick={checkPhoneValid}
													style={{
														// padding: "0 1.3em",
														minWidth: 100,
													}}
												>
													확인
												</Button>
											</div>
										</div>
									)}
									{!snsUserInfo && (
										<div className={`signup-form-item padding`}>
											<label>
												비밀번호 <span>*</span>
											</label>
											<div className={`input-div`}>
												<Input
													type={`password`}
													placeholder={`비밀번호를 입력해주세요.`}
													value={userValue.password}
													name={"password"}
													onChange={handleInputUser}
													pattern={`/d*`}
													style={{
														borderColor:
															userValue.password?.length > 0 &&
															!passwordRegValid &&
															"#FF0000",
														boxShadow:
															userValue.password?.length > 0 &&
															!passwordRegValid &&
															"0 0 0 2px rgba(255, 77, 79, .2)",
													}}
												/>
											</div>
											{mediaResult && (
												<div className={`help-text-wrap`}>
													<p className={`help-text`}>
														8자리 이상의 비밀번호를 입력해주세요.
													</p>
													<p className={`help-text`}>
														(최소 영문, 숫자 1개 이상 포함)
													</p>
												</div>
											)}
										</div>
									)}
									{!snsUserInfo && (
										<div className={`signup-form-item divider`}>
											<label>
												비밀번호 확인 <span>*</span>
											</label>
											<div className={`input-div`}>
												<Input
													type={`password`}
													placeholder={`비밀번호를 입력해주세요.`}
													value={userValue.password_valid}
													name={"password_valid"}
													onChange={handleInputUser}
													pattern={`/d*`}
													style={{
														borderColor:
															userValue.password_valid?.length > 0 &&
															!passwordCheckValid &&
															"#FF0000",
														boxShadow:
															userValue.password_valid?.length > 0 &&
															!passwordCheckValid &&
															"0 0 0 2px rgba(255, 77, 79, .2)",
													}}
												/>
											</div>
										</div>
									)}
									<div className={`signup-form-item divider`}>
										<label>웹사이트 목록</label>
										<div className={`input-div phone`}>
											<Input
												type={`text`}
												placeholder={`http://`}
												name={"web_url"}
												value={userValue.web_url}
												onChange={handleInputUser}
											/>
											<Button
												className={`certification-request-btn signup-btn`}
												loading={isLoading}
												onClick={() => addUrlList("web")}
												style={{
													width: "20%",
													minWidth: 80,
												}}
											>
												{!isLoading && "추가"}
											</Button>
										</div>
										{webUrlList.length > 0 && (
											<div className={`url-list-wrap`}>
												{webUrlList.map((url, idx) => (
													<div
														key={`web_url_item_${idx}`}
														className={`url-list-item`}
													>
														<p className={`url-text`}>{url}</p>
														<img
															className={`url-delete-img`}
															src={`/assets/images/login/delete-icon.png`}
															alt={"delete"}
															onClick={() =>
																handleDeleteUrlClick("웹사이트", url)
															}
														/>
													</div>
												))}
											</div>
										)}
									</div>
									<div className={`signup-form-item divider`}>
										<label>SNS 목록</label>
										<div className={`input-div phone`}>
											<Input
												type={`text`}
												placeholder={`플레이스토어 or 앱스토어의 앱 URL을 입력해주세요.`}
												name={"sns_url"}
												value={userValue.sns_url}
												onChange={handleInputUser}
											/>
											<Button
												className={`certification-request-btn signup-btn`}
												loading={isLoading}
												onClick={() => addUrlList("sns")}
												style={{
													width: "20%",
													minWidth: 80,
												}}
											>
												{!isLoading && "추가"}
											</Button>
										</div>
										{snsUrlList.length > 0 && (
											<div className={`url-list-wrap`}>
												{snsUrlList.map((url, idx) => (
													<div
														key={`sns_url_item_${idx}`}
														className={`url-list-item`}
													>
														<p className={`url-text`}>{url}</p>
														<img
															className={`url-delete-img`}
															src={`/assets/images/login/delete-icon.png`}
															alt={"delete"}
															onClick={() => handleDeleteUrlClick("SNS", url)}
														/>
													</div>
												))}
											</div>
										)}
									</div>
									<div
										className={`signup-form-item padding`}
										style={{ paddingTop: mediaResult ? 0 : "1.5em" }}
									>
										<label>추천인 코드</label>
										<div className={`input-div phone`}>
											<Input
												type={`text`}
												placeholder={`추천인 코드를 입력해주세요.`}
												name={"referral_code"}
												value={userValue.referral_code}
												onChange={handleInputUser}
											/>
										</div>
									</div>
								</div>
							</>
						)}
					</div>
				)}

				{mediaResult && !isShowPolicy && !isShowTerms && (
					<div className={`signup-form-divider`} />
				)}

				{/* 약관동의 */}
				{((mediaResult && !isShowPolicy && !isShowTerms) || !mediaResult) && (
					<div
						className={`signup-form-parents`}
						style={{
							marginTop: mediaResult ? 0 : "3em",
						}}
					>
						{!isShowPolicy && !isShowTerms && (
							<div className={`signup-component-list`}>
								<h2>약관동의</h2>
							</div>
						)}

						{!isShowPolicy && !isShowTerms && (
							<div
								className={`signup-form-wrap`}
								style={{
									gap: mediaResult ? 9 : 15,
								}}
							>
								{/* [필수] 개인정보 처리방침 */}
								{!isShowTerms && (
									<div
										className={`signup-agreement-wrap`}
										onClick={() => {
											if (isShowPolicy) {
												setAgreement({
													...agreement,
													policy: !agreement?.policy,
												});
											} else {
												//  누르면 자동으로 개인정보처리 내용 열고 & 맨 위로
												window.scrollTo({ top: 0, behavior: "smooth" });
												setIsShowPolicy(true);
											}
										}}
										style={{ cursor: "pointer" }}
									>
										{mediaResult ? (
											<>
												<img
													src={`/assets/images/check-${
														agreement.policy ? "active" : "disabled"
													}.png`}
													alt={`약관 동의 1`}
													style={{
														marginTop: 4,
													}}
												/>
												<div className={`signup-agreement-text-wrap`}>
													<div className={`signup-agreement-div`}>
														<p>[필수] 개인정보 처리방침</p>
														{!isShowPolicy && !mediaResult && (
															<img
																src={`/assets/images/channel/right-arrow.png`}
																alt={`화살표`}
																style={{
																	height: "2em",
																	width: "2em",
																	marginLeft: "auto",
																}}
															/>
														)}
													</div>
													<p style={{ color: "#a9b0b7" }}>
														회원님의 소중한 정보를 안전하게 지키겠다는
														약속이에요.
													</p>
												</div>
											</>
										) : (
											<>
												<div className={`signup-agreement-div`}>
													<img
														src={`/assets/images/check-${
															agreement.policy ? "active" : "disabled"
														}.png`}
														alt={`약관 동의 1`}
													/>
													<p>[필수] 개인정보 처리방침</p>
													{!isShowPolicy && !mediaResult && (
														<img
															src={`/assets/images/channel/right-arrow.png`}
															alt={`화살표`}
															style={{
																height: "2em",
																width: "2em",
																marginLeft: "auto",
															}}
														/>
													)}
												</div>
												<p style={{ color: "#a9b0b7" }}>
													회원님의 소중한 정보를 안전하게 지키겠다는 약속이에요.
												</p>
											</>
										)}
									</div>
								)}
								{/* [필수] 서비스 이용약관 */}
								{!isShowPolicy && (
									<div
										className={`signup-agreement-wrap`}
										onClick={() => {
											if (isShowTerms) {
												setAgreement({
													...agreement,
													terms: !agreement?.terms,
												});
											} else {
												window.scrollTo({ top: 0, behavior: "smooth" });
												setIsShowTerms(true);
											}
										}}
										style={{ cursor: "pointer" }}
									>
										{mediaResult ? (
											<>
												<img
													src={`/assets/images/check-${
														agreement.terms ? "active" : "disabled"
													}.png`}
													alt={`약관 동의 2`}
													style={{
														marginTop: 4,
													}}
												/>
												<div className={`signup-agreement-text-wrap`}>
													<div className={`signup-agreement-div`}>
														<p>[필수] 서비스 이용약관</p>
														{!isShowTerms && !mediaResult && (
															<img
																src={`/assets/images/channel/right-arrow.png`}
																alt={`화살표`}
																style={{
																	height: "2em",
																	width: "2em",
																	marginLeft: "auto",
																}}
															/>
														)}
													</div>
													<p style={{ color: "#a9b0b7" }}>
														회원님에게 안정적인 서비스를 제공드리겠다는
														약속이에요.
													</p>
												</div>
											</>
										) : (
											<>
												<div className={`signup-agreement-div`}>
													<img
														src={`/assets/images/check-${
															agreement.terms ? "active" : "disabled"
														}.png`}
														alt={`약관 동의 2`}
													/>
													<p>[필수] 서비스 이용약관</p>
													{!isShowTerms && !mediaResult && (
														<img
															src={`/assets/images/channel/right-arrow.png`}
															alt={`화살표`}
															style={{
																height: "2em",
																width: "2em",
																marginLeft: "auto",
															}}
														/>
													)}
												</div>
												<p style={{ color: "#a9b0b7" }}>
													회원님에게 안정적인 서비스를 제공드리겠다는
													약속이에요.
												</p>
											</>
										)}
									</div>
								)}
							</div>
						)}
						{(isShowPolicy || isShowTerms) && !mediaResult && (
							<p
								style={{
									color: "#1A1A1A",
									textAlign: "center",
									fontSize: "1.4em",
									marginTop: "1em",
								}}
							>
								회원님에게 안정적인 서비스를 제공드리겠다는 약속이에요.
							</p>
						)}
					</div>
				)}
				<div
					className={`signup-blue-button-wrap`}
					style={isShowPolicy || isShowTerms ? { paddingTop: 0 } : {}}
				>
					{isShowPolicy || isShowTerms ? (
						<>
							{mediaResult ? (
								<div className={`signup-agreement-btn-wrap`}>
									<Button
										className={`signup-blue-button`}
										onClick={() => {
											if (isShowPolicy) {
												setAgreement({
													...agreement,
													policy: true,
												});
											} else if (isShowTerms) {
												setAgreement({
													...agreement,
													terms: true,
												});
											}
											handleAgreeClick();
										}}
									>
										{mediaResult ? "동의합니다" : "확인"}
									</Button>
									{mediaResult && (
										<Button
											className={`signup-agreement-cancel-button`}
											onClick={() => {
												if (isShowPolicy) {
													setAgreement({
														...agreement,
														policy: false,
													});
												} else if (isShowTerms) {
													setAgreement({
														...agreement,
														terms: false,
													});
												}
												handleAgreeClick();
											}}
										>
											취소
										</Button>
									)}
								</div>
							) : (
								<div className={`signup-agreement-btn-wrap`}>
									<Button
										className={`signup-agreement-cancel-button`}
										onClick={() => {
											if (isShowPolicy) {
												setAgreement({
													...agreement,
													policy: false,
												});
											} else if (isShowTerms) {
												setAgreement({
													...agreement,
													terms: false,
												});
											}
											handleAgreeClick();
										}}
									>
										취소
									</Button>
									<Button
										className={`signup-blue-button`}
										onClick={() => {
											handleAgreeClick();
											if (isShowPolicy) {
												setAgreement({
													...agreement,
													policy: true,
												});
											} else if (isShowTerms) {
												setAgreement({
													...agreement,
													terms: true,
												});
											}
											handleAgreeClick();
										}}
									>
										{mediaResult ? "동의합니다" : "동의"}
									</Button>
								</div>
							)}
						</>
					) : (
						<>
							{!mediaResult && (
								<Button
									style={{ background: "#fff" }}
									loading={isLoading}
									// disabled={disabledSignupBtn()}
									className={`signup-blue-button white`}
									onClick={() => history.goBack()}
								>
									취소
								</Button>
							)}
							<Button
								loading={isLoading}
								// disabled={disabledSignupBtn()}
								className={`signup-blue-button`}
								onClick={handleSignupButton}
							>
								확인
							</Button>
						</>
					)}
				</div>
			</div>

			{/*모달 - URL 목록 밸리데이션 */}
			<CustomModal
				show={isShowUrlValidation}
				setShow={setIsShowUrlValidation}
				mediaResult={mediaResult}
				title={urlValidationText}
			/>

			{/*모달 - URL 삭제*/}
			<CustomModal
				show={isShowUrlDelete}
				setShow={setIsShowUrlDelete}
				mediaResult={mediaResult}
				title={`${
					deleteUrlType ? `${deleteUrlType} ` : ""
				}URL을 삭제하시겠습니까?`}
				handleClick={deleteUrlList}
				isCancelShow={true}
			/>
		</div>
	);
};

export default SignUpComponent;
