import { useEffect } from "react";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";

import { Button } from "antd";

import dayjs from "dayjs";
import Swal from "sweetalert2";

import partnersApi from "../../apis/Partners.api";
import { setUser } from "../../redux/store/auth/authDuck";
import { setLoading } from "../../redux/store/common/commonDuck";

const PersonalAuthentication = ({
	certifiedData,
	setCertifiedData,
	disabled = false,
}) => {
	const user = useSelector((state) => state.auth.user);
	const dispatch = useDispatch();

	const isLoading = useSelector((state) => state.common.loading);
	const setIsLoading = (value) => dispatch(setLoading(value));

	const IMP = window.IMP; // 생략 가능
	useEffect(() => {
		if (IMP) {
			IMP.init(process.env.REACT_APP_APP_IMP_UID); // 예: imp00000000
		}
	}, [IMP]);

	const handlePersonalCheck = async () => {
		setIsLoading(true);
		const MID = process.env.REACT_APP_PORTONE_MID;
		let param = {
			// param
			// pg: "inicis_unified.{CPID}", //본인인증 설정이 2개이상 되어 있는 경우 필수
			pg: `inicis_unified.${MID}`,
			// pg: "inicis_unified.MIIiasTest",
			merchant_uid: `Together-${user.mall_id}-${user.id}`, // 주문 번호
			// m_redirect_url: "{리디렉션 될 URL}", // 모바일환경에서 popup:false(기본값) 인 경우 필수, 예: https://www.myservice.com/payments/complete/mobile
			popup: true, // PC환경에서는 popup 파라미터가 무시되고 항상 true 로 적용됨
		};

		IMP.certification(param, (rsp) => {
			// callback
			// 성공
			if (rsp.success) {
				checkPersonalAuthenticationOnBack(rsp);
				console.log("handlePersonalCheck :: res = ", rsp);
			}
			// 실패
			else {
				Swal.fire({
					customClass: "alert-custom",
					text: rsp.error_msg,
					confirmButtonText: "확인",
				}).then(() => {});
				setIsLoading(false);
			}
		});
	};

	/**
	 * 통합 본인인증 확인하기 (백엔드 더블 체크)
	 * @param rsp
	 * @returns {Promise<void>}
	 */
	const checkPersonalAuthenticationOnBack = async (rsp) => {
		setIsLoading(true);
		const param = {
			id: user.id,
			imp_certifications_uid: rsp?.imp_uid,
			is_app: false,
		};
		const res = await partnersApi.postPersonalAuthentication(param);
		if (res.code === 200) {
			let result = res.result;
			let param = {
				mall_id: result.mall_id,
				name: result.nickname,
				phone: result.phone,
				certified_at: dayjs(result.certified_at).format("YYYYMMDDHHmmss"),
				imp_uid: result.imp_certifications_uid,
				is_certified: result.is_certified,
			};
			setCertifiedData(param);
			// user 업데이트
			const paramUser = {
				type: "mallId",
				userId: user.mall_id,
			};
			partnersApi.readMallPartnersId(paramUser).then((res) => {
				if (res.code !== 400) {
					// dispatch(setUser(...res.result));
					setUser({
						...user,
						is_certified: false,
					});
				}
			});
		} else {
			Swal.fire({
				customClass: "alert-custom",
				text: "다시 시도해주세요.",
				confirmButtonText: "확인",
			}).then(() => {
				console.log("checkPersonalAuthenticationOnBack");
			});
		}
		setIsLoading(false);
	};

	if (certifiedData?.is_certified) {
		return (
			<div className="input-div-button-success">
				<img
					src={`/assets/images/success_button_icon.png`}
					alt={`success_button_icon`}
					style={{
						width: "1em",
						height: "auto",
					}}
				/>
				<span>인증완료</span>
			</div>
		);
	} else {
		return (
			<Button
				onClick={handlePersonalCheck}
				loading={isLoading}
				disabled={certifiedData?.is_certified || disabled}
				className="certification-request-btn"
			>
				{!isLoading && "인증요청"}
			</Button>
		);
	}
};

export default PersonalAuthentication;
