import { useSelector } from "react-redux";
import { Redirect, Route } from "react-router-dom";

const AuthRoute = ({ component: Component, ...rest }) => {
	const user = useSelector((state) => state.auth.user);

	const accessToken = localStorage.getItem("admin:accessToken");
	const refreshToken = localStorage.getItem("admin:refreshToken");

	return (
		<Route
			{...rest}
			render={(props) =>
				!!user?.is_influencer && accessToken && refreshToken ? (
					<Component {...props} />
				) : (
					<Redirect to="/" />
				)
			}
		/>
	);
};

export default AuthRoute;
