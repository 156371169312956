import React, { useState } from "react";

import { Row, Radio, DatePicker, Collapse, Button } from "antd";
import moment from "moment";

const { Panel } = Collapse;

const ReportDateSearchComponent = (props) => {
	const {
		searchOptions,
		setSearchOptions,
		updateDate,
		handleSearch,
		// handleCancelBtnClick,
		navClicked,
		dateType,
		setDateType,
		mediaResult,
		groupType,
		setGroupType,
	} = props;
	const [isCollapse, setIsCollapse] = useState(["1"]);

	// const [groupType, setGroupType] = useState("sale");

	const dateTypeItems = [
		{ label: "이번달", value: "thisMonth" },
		{ label: "오늘", value: "today" },
		{ label: "1주일", value: "1week" },
		{ label: "1개월", value: "1month" },
		{ label: "3개월", value: "3month" },
		// { label: "6개월", value: "6month" },
	];
	const groupTypeItems = [
		{ label: "판매", value: "sale" },
		{ label: "클릭", value: "click" },
		{ label: "친구추천", value: "suggest" },
	];

	/**
	 * handle dateType change
	 */
	const handleDateTypeChange = (e) => {
		const value = e.target.value;
		setDateType(value);
		switch (value) {
			case "thisMonth":
				setSearchOptions({
					...searchOptions,
					startDate: moment().startOf("month"),
					endDate: moment().endOf("day"),
				});
				break;
			case "today":
				setSearchOptions({
					...searchOptions,
					startDate: moment().startOf("day"),
					endDate: moment().endOf("day"),
				});
				break;
			// case "yesterday":
			//     setSearchOptions({
			//         ...searchOptions,
			//         startDate: moment().subtract(1, 'days').startOf('day'),
			//         endDate: moment().endOf("day")
			//     })
			//     break;
			case "1week":
				if (searchOptions.endDate === null || searchOptions.endDate === "") {
					setSearchOptions({
						...searchOptions,
						startDate: moment().subtract(7, "days").startOf("day"),
						endDate: moment().endOf("day"),
					});
				} else {
					setSearchOptions({
						...searchOptions,
						startDate: moment(searchOptions.endDate.format("YYYY/MM/DD"))
							.subtract(7, "days")
							.startOf("day"),
					});
				}
				break;
			case "1month":
				if (searchOptions.endDate === null || searchOptions.endDate === "") {
					setSearchOptions({
						...searchOptions,
						startDate: moment().subtract(1, "months").startOf("day"),
						endDate: moment().endOf("day"),
					});
				} else {
					setSearchOptions({
						...searchOptions,
						startDate: moment(searchOptions.endDate.format("YYYY/MM/DD"))
							.subtract(1, "months")
							.startOf("day"),
					});
				}
				break;
			case "3month":
				if (searchOptions.endDate === null || searchOptions.endDate === "") {
					setSearchOptions({
						...searchOptions,
						startDate: moment().subtract(3, "months").startOf("day"),
						endDate: moment().endOf("day"),
					});
				} else {
					setSearchOptions({
						...searchOptions,
						startDate: moment(searchOptions.endDate.format("YYYY/MM/DD"))
							.subtract(3, "months")
							.startOf("day"),
					});
				}
				break;
			case "6month":
				if (searchOptions.endDate === null || searchOptions.endDate === "") {
					setSearchOptions({
						...searchOptions,
						startDate: moment().subtract(6, "months").startOf("day"),
						endDate: moment().endOf("day"),
					});
				} else {
					setSearchOptions({
						...searchOptions,
						startDate: moment(searchOptions.endDate.format("YYYY/MM/DD"))
							.subtract(6, "months")
							.startOf("day"),
					});
				}
				break;
		}
	};

	const handleGroupTypeChange = (e) => {
		const value = e.target.value;
		setGroupType(value);
		// props.setGroupType(value);
	};

	return (
		<Collapse
			className="report-date-search-wrap"
			expandIcon={() => <></>}
			activeKey={["1"]}
			defaultActiveKey={["1"]}
			ghost
		>
			<Panel key="1">
				<div className="report-date-search-content-wrap">
					{/* 분류 선택 */}
					{navClicked === "1" && (
						<Row
							justify="start"
							align="middle"
							gutter={[0, 16]}
							style={{
								rowGap: mediaResult ? 10 : 16,
							}}
						>
							<div className="report-date-search-title">분류선택</div>
							<Radio.Group
								className="report-date-search-select-type-wrap report-date-search-select-type-wrap-group"
								options={groupTypeItems}
								onChange={handleGroupTypeChange}
								value={groupType}
								optionType="button"
							/>
						</Row>
					)}
					{/* 조회 기간 */}
					<Row
						justify="start"
						align="middle"
						gutter={[0, 16]}
						style={{
							borderBottom: "1px solid #D9D9D9",
							paddingBottom: mediaResult ? "1.2em" : "2em",
							rowGap: mediaResult ? 5 : 16,
						}}
					>
						<div
							className="report-date-search-title"
							style={{
								marginBottom: mediaResult ? 5 : 0,
							}}
						>
							조회기간
						</div>
						<Radio.Group
							className="report-date-search-select-type-wrap"
							options={dateTypeItems}
							onChange={handleDateTypeChange}
							value={dateType}
							optionType="button"
						/>
						<div className={`report-search-period-wrap`}>
							<DatePicker
								className="report-search-date-wrap"
								format="YYYY/MM/DD"
								value={searchOptions.startDate}
								onChange={(value) => {
									setDateType("");
									setSearchOptions({ ...searchOptions, startDate: value });
								}}
								allowClear={false}
							/>
							<span className={`report-search-period-text`}>-</span>
							<DatePicker
								className="report-search-date-wrap"
								format="YYYY/MM/DD"
								value={searchOptions.endDate}
								onChange={(value) => {
									setDateType("");
									setSearchOptions({ ...searchOptions, endDate: value });
								}}
								style={{ marginRight: 2 }}
								allowClear={false}
							/>
						</div>
					</Row>
					{/* 조회 버튼 */}
					<Row className="report-date-search-button-wrap" justify="center">
						<Button
							className="report-date-search-button confirm"
							onClick={() => {
								const result = handleSearch();
								if (result) {
									setIsCollapse([]);
								}
							}}
						>
							조회
						</Button>
						{/* <Button
							className="report-date-search-button cancel"
							onClick={() => {
								setDateType(dateTypeItems[1].value);
								handleCancelBtnClick();
								setIsCollapse([]);
							}}
						>
							취소
						</Button> */}
					</Row>
				</div>
			</Panel>
		</Collapse>
	);
};

export default ReportDateSearchComponent;
