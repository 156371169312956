import { Header } from "antd/lib/layout/layout";

import "../Calculate.scss";
import SignUpHeader from "../components/user/SignUpHeader";
import UserEdit from "../components/user/UserEdit";

import { useEffect, useState } from "react";

const Calculate = () => {
	const [mediaResult, setMediaResult] = useState(null);
	useEffect(() => {
		if (typeof window !== "undefined") {
			const matchQueryList = window.matchMedia("all and (max-width: 768px)");
			setMediaResult(matchQueryList.matches);

			const media = function handleChange(e) {
				setMediaResult(e.matches);
			};

			matchQueryList.addEventListener("change", media);
		}
	}, []);
	return (
		<>
			{mediaResult && (
				<SignUpHeader title={"내 정보수정"} back={true} close={false} />
			)}
			<UserEdit />
		</>
	);
};

export default Calculate;
