import "../../Event.scss";
import { useEffect, useState } from "react";

const RecommendPage = () => {
	const [mediaResult, setMediaResult] = useState(false);

	useEffect(() => {
		if (typeof window !== "undefined") {
			const matchQueryList = window.matchMedia("all and (max-width: 768px)");
			setMediaResult(matchQueryList.matches);

			const handleChange = (e) => {
				setMediaResult(e.matches);
			};

			matchQueryList.addEventListener("change", handleChange);
		}
	}, []);

	return (
		<div className="main-wrap event-banner-page">
			{/*배너*/}
			<div className={`banner-wrap event-recommend`}>
				<img
					src={`/assets/images/event/recommend-friend-banner${
						mediaResult ? "-m.jpg" : ".png"
					}`}
					alt={`recommend_banner`}
					style={{
						width: "100%",
						height: "auto",
					}}
				/>
				<div className={`banner-title-wrap recommend-friend`}>
					<h1 className={`banner-title`}>친구추천 이벤트!</h1>
					<p className={`banner-description`}>
						단 3개월 동안만 추천하신분, 추천 받은 분 모두에게 10,000 Gold씩
						쏩니다!
					</p>
				</div>
			</div>
			{/*내용*/}
			<div className={`max-width-apply content-wrap event-banner-page`}>
				{/*내용 메인 텍스트*/}
				<div
					className={`content-title-wrap pos-relative`}
					style={{
						marginBottom: mediaResult ? 24 : 48,
					}}
				>
					<img
						className={`content-title-bg-img`}
						src={`/assets/images/event/background-up-arrow.png`}
						alt={`bg-up-arrow`}
					/>
					<h2 className={`content-title`}>
						친구가 많으면 많을수록{"\n"}
						<span>나의 수익율은 UP!</span>
					</h2>
				</div>
				{/*내용 설명*/}
				<div className={`content-description-wrap`}>
					<div className={`content-description-div`}>
						<p className={`content-description-text`}>
							나는 친구가 많으면 많을 수록{mediaResult ? " " : `\n`}
							<span>친구 수만큼 10,000 Gold씩 적립</span>되어 수익이 쭉쭉
							올라가고
						</p>
					</div>
					<div className={`content-description-div`}>
						<p className={`content-description-text`}>
							내 친구는 내 추천인 코드를 회원가입시 입력해
							{mediaResult ? " " : `\n`}70,000 Gold가 아닌{" "}
							<span>80,000 Gold! 무조건 받고 시작</span>합니다.
						</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default RecommendPage;
