import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { Button, Dropdown, Input, Modal, Row, Spin } from "antd";

import Swal from "sweetalert2";

import ApiService2 from "../../apis/ApiService_mall";
import channelUserApi from "../../apis/ChannelUser.api";
import partnersApi from "../../apis/Partners.api";
import { setChannelUser, setUser } from "../../redux/store/auth/authDuck";
import { setLoading } from "../../redux/store/common/commonDuck";
import ApiService from "../../utils/ApiService";
import { isGeneralBank } from "../../utils/status";

import UserVerificationModal from "./UserVerificationModal";

const api = new ApiService();
const api2 = new ApiService2();
function UserEdit() {
	const [openMyAccount, setOpenMyAccount] = useState(false);
	const initialFormValue = {
		name: "",
		phone: "",
		phone_valid: "",
		password: "",
		password_valid: "",
		birth: "",
		bank_code: "",
		bank_name: "",
		account_number: "",
		account_holder_name: "",
		is_verify_account: true,
	};
	const [snsUserInfo, setSnsUserInfo] = useState(null);
	const [openPhoneModal, setOpenPhoneModal] = useState(false); // 인증요청 전 모달
	const [phoneValid, setPhoneValid] = useState(false); // 인증번호 전송
	const [isPhoneValid, setIsPhoneValid] = useState(true); // 인증번호 확인
	const phonePattern = /^01([0|1|6|7|8|9])([0-9]{3,4})([0-9]{4})$/;
	const history = useHistory();
	const dispatch = useDispatch();
	const user = useSelector((state) => state.auth.user);
	const isLoading = useSelector((state) => state.common.loading);
	const setIsLoading = (value) => dispatch(setLoading(value));

	const [mediaResult, setMediaResult] = useState(null);
	const [currUserValue, setCurrUserValue] = useState(null);
	const [formValue, setFormValue] = useState(initialFormValue);
	const [formLoading, setFormLoading] = useState(false);
	const [bankList, setBankList] = useState([]);
	const [submitBankLoading, setSubmitBankLoading] = useState(false);
	const [checkBankAccountNumber, setCheckBankAccountNumber] =
		useState(undefined);
	const [isShowModalSuccessBankAccount, setIsShowModalSuccessBankAccount] =
		useState(false);
	const [isShowModalCheckBankAccount, setIsShowModalCheckBankAccount] =
		useState(false);
	const [successEditInfo, setSuccessEditInfo] = useState(false);
	const [secession, setSecession] = useState(false);
	const [passwordRegValid, setPasswordRegValid] = useState(false); // 비밀번호 대소문자,숫자,특수문자 true/false
	const [passwordCheckValid, setPasswordCheckValid] = useState(false); // 비밀번호 확인
	const [isShowModalUserCertify, setIsShowModalUserCertify] = useState(false); // 본인 인증 신청 모달
	const [isShowModalCertify, setIsShowModalCertify] = useState(false); // 본인 인증 모달
	const [isShowModalLowCash, setIsShowModalLowCash] = useState(false); // 1,000 골드 미만
	const [isShowMyInfo, setIsShowMyInfo] = useState(true);
	const [isShowMyAccount, setIsShowMyAccount] = useState(false);
	useEffect(() => {
		if (typeof window !== "undefined") {
			const matchQueryList = window.matchMedia("all and (max-width: 768px)");
			setMediaResult(matchQueryList.matches);

			const media = function handleChange(e) {
				setMediaResult(e.matches);
			};

			matchQueryList.addEventListener("change", media);
		}
		getBankList();
	}, []);
	useEffect(() => {
		if (mediaResult) {
			setIsShowMyAccount(false);
			setIsShowMyInfo(true);
		} else {
			setIsShowMyAccount(false);
			setOpenMyAccount(false);
			setIsShowMyInfo(true);
		}
	}, [mediaResult]);

	useEffect(() => {
		readUserInfo();
	}, [user, bankList]);
	const suggestVerification = () => {
		Swal.fire({
			customClass: "alert-custom-confirm",
			text: `인증계좌가 없습니다.\n본인인증 하시겠습니까?`,
			confirmButtonText: "확인",
			showCancelButton: true,
			confirmButtonColor: "#117FFA",
			cancelButtonText: "다음에",
			cancelButtonColor: "#FFFFFF",
		}).then((res) => {
			if (res.isConfirmed) {
				setIsShowModalUserCertify(true);
			}
		});
	};
	const resetPhoneInput = () => {
		formValue.phone = "";
		setIsPhoneValid(false);
		setOpenPhoneModal(false);
	};
	/**
	 * step 2-1 : 휴대폰 인증
	 */
	const sendPhoneValid = async () => {
		let regPhone = /010-?([0-9]{4})-?([0-9]{4})$/;
		if (regPhone.test(formValue.phone)) {
			setIsLoading(true);
			let param = {
				phone: formValue.phone.replaceAll("-", ""),
				is_user_valid: true,
			};
			let res = await api.smsCertifySend(param);

			if (res.data.code === 200) {
				if (res.data.result?.id_check === true) {
					const loginMethod =
						res.data.result.user?.provider === "kakao"
							? "카카오"
							: res.data.result.user?.provider === "naver"
							? "네이버"
							: "해당 번호";

					Swal.fire({
						customClass: "alert-custom-confirm",
						text: `${loginMethod}로 가입된 유저가 있습니다.`,
						confirmButtonText: "확인",
						confirmButtonColor: "#117FFA",
					}).then((res) => {
						if (res.isConfirmed) {
							return false;
						}
					});
				} else {
					setPhoneValid(true);
					setIsLoading(false);
				}
				setIsLoading(false);
			} else {
				setIsLoading(false);
			}
		} else {
			Swal.fire({
				customClass: "alert-custom-confirm",
				text: "휴대폰 번호를 정확하게 입력해주세요.",
				confirmButtonText: "확인",
				confirmButtonColor: "#117FFA",
			});
		}
	};

	/**
	 * step 2-2: 휴대폰 인증번호 일치 확인
	 */
	const checkPhoneValid = async () => {
		const regPhone = /([0-9]{6})$/;
		if (regPhone.test(formValue.phone_valid)) {
			setIsLoading(true);

			let param = {
				phone: formValue.phone.replaceAll("-", ""),
				sms_verify_code: formValue.phone_valid,
			};
			let res = await api.smsCertifyVerify(param);

			if (res.data.code === 200) {
				Swal.fire({
					customClass: "alert-custom-confirm",
					text: "인증번호가 일치합니다.",
					confirmButtonText: "확인",
					confirmButtonColor: "#117FFA",
				});
				setIsPhoneValid(true);
				setIsLoading(false);
				setUser({
					...user,
					phone: formValue.phone,
				});
				// formValue.phone = user.phone;
			} else {
				Swal.fire({
					customClass: "alert-custom-confirm",
					text: "인증번호가 일치하지 않습니다.",
					confirmButtonText: "확인",
					confirmButtonColor: "#117FFA",
				});
				setIsLoading(false);
			}
		} else {
			Swal.fire({
				customClass: "alert-custom-confirm",
				text: "인증번호 6자리를 정확하게 입력해주세요.",
				confirmButtonText: "확인",
				confirmButtonColor: "#117FFA",
			});
		}
	};

	/**
	 * 탈퇴시, 주의사항 안내 팝업 > "탈퇴" 버튼 클릭시
	 * @param e
	 */
	const handleSecession = () => {
		setSecession(false);
		Swal.fire({
			customClass: "alert-custom-confirm",
			text: `탈퇴 버튼 선택시\n계정은 삭제되며 복구되지 않습니다.`,
			showCancelButton: true,
			confirmButtonText: "탈퇴",
			confirmButtonColor: "#117FFA",
			cancelButtonText: "취소",
			cancelButtonColor: "#FFFFFF",
		}).then(async (res) => {
			if (res.isConfirmed) {
				setIsLoading(true);
				try {
					const param = {
						userId: user.id,
						isInfluencer: true,
					};
					const leaveRes = await channelUserApi.leave(param);
					if (leaveRes.code === 200) {
						Swal.fire({
							customClass: "alert-custom-confirm",
							text: `회원탈퇴가 완료되었습니다.`,
							showCancelButton: false,
							confirmButtonText: "확인",
							confirmButtonColor: "#117FFA",
						}).then(() => {
							localStorage.removeItem("admin:accessToken");
							localStorage.removeItem("admin:refreshToken");
							dispatch(setUser(null));
							setIsLoading(false);
							history.push(`/`);
						});
					}
				} catch (e) {
					console.log("handleSecession :: error = ", e);
				} finally {
					setIsLoading(false);
				}
			}
		});
	};

	/**
	 * 포트원 은행 리스트 조회
	 * @returns {Promise<void>}
	 * [{
	 * code: 은행코드
	 * name: 은행명
	 * }]
	 */
	const getBankList = async () => {
		setIsLoading(true);
		try {
			const res = await partnersApi.readBankList();
			if (res.code === 200) {
				let list = res.result;
				setBankList(list);
			}
		} catch (e) {
			console.log("e", e);
		} finally {
			setIsLoading(false);
		}
	};

	/**
	 * 사용자 기본 정보 조회하기
	 * @type {(function(): Promise<void>)|*}
	 */
	const readUserInfo = useCallback(async () => {
		if (!bankList.length) return;
		setIsLoading(true);
		try {
			const param = {
				userId: user?.id,
				type: "basic_info",
			};
			const res = await api.getUserInfoByType(param);
			if (res.data.code === 200) {
				let bankNameStr = "";
				let birthDayStr = "";

				if (res.data.result?.bank_code) {
					const { name: bankName } = bankList.find(
						(v) => v.code === res.data.result.bank_code
					);
					bankNameStr = bankName;

					birthDayStr = res.data.result?.encrypt_birthday?.replaceAll("-", "");
					if (birthDayStr.length === 8) {
						birthDayStr = birthDayStr.substring(2);
					}
				}
				setCurrUserValue({
					...res.data.result,
					birth_day: birthDayStr,
				});
				setFormValue({
					name: res.data.result.name,
					phone: res.data.result.phone,
					brith: res.data.result.encrypt_birthday,
					bank_code: res.data.result.bank_code,
					bank_name: bankNameStr,
					account_number: res.data.result.account_number,
					account_holder_name:
						res.data.result.account_holder_name ?? res.data.result.name,
					is_verify_account: !!(
						res.data.result.account_number && res.data.result.bank_code
					),
				});
			}
		} catch (e) {
			console.log("readUserInfo :: error = ", e);
		} finally {
			setIsLoading(false);
		}
	}, [user, bankList]);
	/**
	 * 계좌인증
	 * @param type account-입력한 계좌번호로 1원 전송하기 / code-1원 전송한 계좌 코드 입력하기
	 * @returns {Promise<void>}
	 */
	const verifyAccount = async (type = "account") => {
		try {
			setSubmitBankLoading(true);
			let param = {
				...formValue,
				type: type,
				user_id: user?.id,
			};
			if (type === "account") {
				param["name"] = formValue.name;
				param["birthday"] = currUserValue.birth_day;
			} else {
				param["code"] = checkBankAccountNumber;
			}
			const res = await partnersApi.verifyAccount(param);
			if (res.data.code === 200) {
				if (type === "account") {
					setIsShowModalCheckBankAccount(true);
					setCheckBankAccountNumber(null);
				} else {
					setIsShowModalSuccessBankAccount(true);
					setCheckBankAccountNumber(undefined);
					// 계좌 소유자 본명으로 처리
					setFormValue({
						...formValue,
						account_holder_name: formValue.name,
						is_verify_account: true,
					});
				}
			} else {
				Swal.fire({
					customClass: "alert-custom-confirm",
					text: `${res.data.msg}`,
					confirmButtonText: "확인",
					confirmButtonColor: "#117FFA",
				});
			}
		} catch (e) {
			console.log("verifyAccount :: e = ", e);
		} finally {
			setSubmitBankLoading(false);
		}
	};

	/**
	 * 폼 입력창 handler
	 * @param e
	 */
	const handleChange = (e) => {
		const { name, value } = e.target;
		if (name === "phone") {
			if (value?.length <= 11) {
				setFormValue({
					...formValue,
					[name]: value?.replaceAll(/[^0-9]/g, ""),
				});
			}
		} else if (name === "phone_valid") {
			if (value.split("-").join("").length <= 6) {
				let phone_valid = value.replace(/[^0-9]/g, "");
				setFormValue({
					...formValue,
					phone_valid: phone_valid,
				});
			}
		} else if (name === "account_number") {
			if (formValue.is_verify_account) {
				if (currUserValue && currUserValue?.account_number) {
					if (currUserValue.account_number === value) {
						setFormValue({
							...formValue,
							[name]: value,
						});
					} else {
						setFormValue({
							...formValue,
							[name]: value,
							account_holder_name: "",
							is_verify_account: false,
						});
					}
				} else {
					if (formValue.account_number === value) {
						setFormValue({
							...formValue,
							[name]: value,
						});
					} else {
						setFormValue({
							...formValue,
							[name]: value,
							account_holder_name: "",
							is_verify_account: false,
						});
					}
				}
			} else {
				setFormValue({
					...formValue,
					[name]: value,
				});
			}
		} else if (name === "password") {
			//비밀번호
			let password = value;
			// 특수문자 / 문자 / 숫자 포함 형태의 8~16 자리 이내의 암호 정규식
			let regPass =
				/^.*(?=^.{8,16}$)(?=.*\d)(?=.*[a-zA-Z])(?=.*[!@#$%^&+=]).*$/;
			if (password.match(regPass)) {
				setPasswordRegValid(true);
			} else {
				setPasswordRegValid(false);
			}

			// 숫자, 문자, 특수문자만 입력 가능
			const regExp = /[^0-9a-zA-Z!@#$%^&+=]/g;
			if (regExp.test(value)) {
				password = value.replace(regExp, "");
			}
			setFormValue({
				...formValue,
				[name]: password,
			});

			const regPassword =
				/^(?=.*[A-Za-z])(?=.*\d)[a-zA-Z\\d`~!@#$%^&*()-_=+(?=.*\d)]{8,}$/;
			if (regPassword.test(e.target.value)) {
				if (!passwordRegValid) setPasswordRegValid(true);
			} else {
				if (passwordRegValid) setPasswordRegValid(false);
			}
		} else if (name === "password_valid") {
			setFormValue({
				...formValue,
				[name]: value,
			});
			if (formValue.password === value) {
				setPasswordCheckValid(true);
			} else {
				setPasswordCheckValid(false);
			}
		} else {
			setFormValue({
				...formValue,
				[name]: value,
			});
		}
	};

	/**
	 * 정보 수정 취소하기
	 */
	const handleCancel = () => {
		if (
			formValue.name ||
			formValue.phone ||
			formValue.bank_code ||
			formValue.account_number ||
			formValue.account_holder_name
		) {
			// 입력한 값이 하나라도 있으면 취소하겠냐는 팝업 띄우기
			Swal.fire({
				customClass: "alert-custom-confirm",
				text: `작성중인 내용이 모두 사라집니다.\n정말 취소하시겠습니까?`,
				showCancelButton: true,
				confirmButtonText: "확인",
				confirmButtonColor: "#117FFA",
				cancelButtonText: "취소",
				cancelButtonColor: "#FFFFFF",
			}).then((res) => {
				if (res.isConfirmed) {
					setFormValue(initialFormValue);
					window.scrollTo(0, 0);
					history.push("/user");
				}
			});
		} else {
			setFormValue(initialFormValue);
			window.scrollTo(0, 0);
			history.push("/user");
		}
	};
	/**
	 * 비밀번호 변경
	 */
	const updatePassword = async () => {
		setIsLoading(true);
		const regPassword =
			/^(?=.*[A-Za-z])(?=.*\d)[a-zA-Z\\d`~!@#$%^&*()-_=+(?=.*\d)]{8,}$/;
		if (regPassword.test(formValue.password) && !!passwordCheckValid) {
			let param = {
				phone: formValue.phone.replaceAll("-", ""),
				password: formValue.password,
			};
			let res = await api2.updatePhonePassword(param);

			if (res.data.code === 200) {
				Swal.fire({
					customClass: "alert-custom-confirm",
					text: "내 정보수정이 완료되었습니다.",
					confirmButtonText: "확인",
					confirmButtonColor: "#117FFA",
				}).then(() => {
					setIsLoading(false);
					history.replace(`/user`);
				});
			} else {
				Swal.fire({
					customClass: "alert-custom-confirm",
					text: "일시적인 오류로 실패하였습니다. 다시 시도해주세요.",
					confirmButtonText: "확인",
					confirmButtonColor: "#117FFA",
				});
				setIsLoading(false);
			}
		} else {
			Swal.fire({
				customClass: "alert-custom-confirm",
				text: "8자리 이상 비밀번호를 입력해주세요. (최소 영문, 숫자 1개 이상 포함)",
				confirmButtonText: "확인",
				confirmButtonColor: "#117FFA",
			});
			setIsLoading(false);
		}
	};

	/**
	 * 정보 수정하기
	 */
	const handleClick = async () => {
		// 정보 수정하기 위한 값이 전부 유효한지 확인

		const valid = isValidForm();
		if (valid) {
			setFormLoading(true);
			try {
				let param = {
					...formValue,
					id: user?.id,
					key: formValue.is_verify_account
						? "basic_profile_info"
						: "profile_account",
					mall_id: formValue.is_verify_account ? null : user?.mall_id,
				};

				const res = await api.editUserByKey(param);

				if (res.data.code === 200) {
					dispatch(
						setUser({
							...user,
							bank_code: res.result?.bank_code,
							account_number: res.result?.account_number,
							account_holder_name: res.result?.account_holder_name,
							// 변경 가능 번호
							name: res.result?.name ? res.result?.name : formValue.name,
							phone: res.result?.phone ? res.result?.phone : formValue.phone,
						})
					);
					Swal.fire({
						customClass: "alert-custom-confirm",
						text: "저장되었습니다.",
						confirmButtonText: "확인",
						confirmButtonColor: "#117FFA",
					}).then(() => {
						setFormValue(initialFormValue);
						window.scrollTo(0, 0);
						history.push("/user");
					});
				} else {
					Swal.fire({
						customClass: "alert-custom-confirm",
						text: `일시적인 장애로 새로고침 후 다시 시도해주세요.`,
						confirmButtonText: "확인",
						confirmButtonColor: "#117FFA",
					});
				}
			} catch (e) {
				console.log("handleClick :: error = ", e);
			} finally {
				setFormLoading(false);
			}
		}
	};

	/**
	 * 로그아웃
	 * @returns {Promise<void>}
	 */
	const logout = async () => {
		setIsLoading(true);
		Swal.fire({
			customClass: "alert-custom-confirm",
			text: `정말 로그아웃 하시겠습니까?`,
			showCancelButton: true,
			confirmButtonText: "확인",
			confirmButtonColor: "#117FFA",
			cancelButtonText: "취소",
			cancelButtonColor: "#FFFFFF",
		}).then(async (result) => {
			if (result.isConfirmed) {
				const param = {
					id: user.id,
				};
				await partnersApi.logout(param);
				localStorage.removeItem("admin:accessToken");
				localStorage.removeItem("admin:refreshToken");

				dispatch(setChannelUser(null));
				dispatch(setUser(null));
				setIsLoading(false);
				history.push(`/`);
			}
		});
		setIsLoading(false);
	};

	/**
	 * 정보 저장 버튼 Disabled
	 * @returns {boolean}
	 */
	const isDisabled = () => {
		if (
			// formValue.password?.trim() &&
			// formValue.password_valid?.trim()
			formValue.password_valid?.trim() ||
			formValue.name?.trim() ||
			formValue.phone?.trim() ||
			formValue.bank_code?.trim() ||
			formValue.account_number?.trim() ||
			formValue.account_holder_name?.trim()
		) {
			return true;
		} else {
			return false;
		}
	};

	/**
	 * 정보 수정 내용 유효성 검사
	 * @returns {boolean}
	 */
	const isValidForm = () => {
		// step 1. 모든 항목에 입력값이 있는지 확인하기
		if (
			formValue.is_verify_account
				? formValue.name?.trim() &&
				  formValue.phone?.trim() &&
				  formValue.bank_code?.trim() &&
				  formValue.account_number?.trim()
				: formValue.name?.trim() && formValue.phone?.trim()
		) {
			// step 2. 전화번호 형식에 맞는지 확인하기
			if (phonePattern.exec(formValue.phone)) {
				// step3. 휴대폰 번호 미인증 (추가)
				if (!isPhoneValid) {
					Swal.fire({
						customClass: "alert-custom-confirm",
						text: `휴대폰 번호를 인증해주세요.`,
						showCancelButton: false,
						confirmButtonText: "확인",
						confirmButtonColor: "#117FFA",
					});
					return false;
				} else {
					return true;
				}
				// step 4. 인증된 계좌인지 확인하기
				// if (formValue.is_verify_account) {
				// 	return true;
				// } else {
				// 	Swal.fire({
				// 		customClass: "alert-custom-confirm",
				// 		text: `입력하신 계좌를 인증해주세요.`,
				// 		showCancelButton: false,
				// 		confirmButtonText: "확인",
				// 		confirmButtonColor: "#117FFA",
				// 	});
				// 	return false;
				// }
			} else {
				Swal.fire({
					customClass: "alert-custom-confirm",
					text: `휴대폰 형식에 맞춰서 입력해주세요.`,
					showCancelButton: false,
					confirmButtonText: "확인",
					confirmButtonColor: "#117FFA",
				});
				return false;
			}
		} else {
			Swal.fire({
				customClass: "alert-custom-confirm",
				text: `정보를 수정하기 위한 필수 입력 사항들을 채워주세요.`,
				showCancelButton: false,
				confirmButtonText: "확인",
				confirmButtonColor: "#117FFA",
			});
			return false;
		}
	};

	/**
	 * 은행 목록화
	 * @type {unknown[]|undefined|*[]}
	 */
	const items =
		bankList?.length > 0
			? bankList?.filter(isGeneralBank)?.map((item) => ({
					label: item.name,
					key: item.code,
					icon: (
						<img
							src={`/assets/images/bank/${item.code}.png`}
							style={{
								width: 16,
								height: 16,
							}}
							onError={(e) => {
								e.currentTarget.style.visibility = "hidden";
							}}
						/>
					),
			  }))
			: [];

	/**
	 * 은행 목록 선택하기 handler
	 * @param key
	 */
	const onClick = ({ key }) => {
		let clickBank = bankList.filter((item) => item.code === key);
		if (clickBank?.[0]?.code) {
			if (formValue.is_verify_account) {
				if (currUserValue && currUserValue?.bank_code) {
					if (currUserValue.bank_code !== clickBank[0].code) {
						setFormValue({
							...formValue,
							bank_code: clickBank[0].code,
							bank_name: clickBank[0].name,
							account_holder_name: "",
							is_verify_account: false,
						});
					} else {
						setFormValue({
							...formValue,
							bank_code: clickBank[0].code,
							bank_name: clickBank[0].name,
						});
					}
				} else {
					if (formValue.bank_code !== clickBank[0].code) {
						setFormValue({
							...formValue,
							bank_code: clickBank[0].code,
							bank_name: clickBank[0].name,
							account_holder_name: "",
							is_verify_account: false,
						});
					} else {
						setFormValue({
							...formValue,
							bank_code: clickBank[0].code,
							bank_name: clickBank[0].name,
						});
					}
				}
			} else {
				setFormValue({
					...formValue,
					bank_code: clickBank[0].code,
					bank_name: clickBank[0].name,
				});
			}
		}
	};
	return (
		<div className="layout user-layout">
			{!!isLoading && (
				<div
					style={{
						width: "100%",
						position: "fixed",
						top: 0,
						left: 0,
						right: 0,
						bottom: 0,
						zIndex: 999,
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						backgroundColor: "rgba(0,0,0,0.2)",
					}}
				>
					<Spin size={"large"} />
				</div>
			)}
			<div className="calculate-inquiry-title ">
				{mediaResult ? "내 정보" : "내정보 수정"}
			</div>
			{!mediaResult && (
				<div className="user-edit-tab-wrap">
					<button
						type="button"
						className={isShowMyInfo && "active"}
						onClick={() => {
							setIsShowMyInfo(true);
							setIsShowMyAccount(false);
						}}
					>
						내 정보
					</button>
					<button
						type="button"
						className={isShowMyAccount && "active"}
						onClick={() => {
							setIsShowMyInfo(false);
							setIsShowMyAccount(true);
						}}
					>
						내 계좌
					</button>
				</div>
			)}
			{isShowMyInfo && (
				<div className="calculate-inquiry-table">
					<div className="calculate-inquiry-add-wrapper">
						<div className="calculate-inquiry-add-wrap">
							<div className="calculate-inquiry-add-wrap-title">이름</div>
							<div className="wrap-input user-edit">
								<Input
									type="text"
									placeholder="성함을 입력해주세요"
									name={"name"}
									value={formValue.name}
									onChange={handleChange}
								/>
							</div>
						</div>

						<div className="calculate-inquiry-add-wrap">
							<div className="calculate-inquiry-add-wrap-title">
								휴대폰 번호
							</div>

							<div
								className="wrap-input user-edit"
								style={{
									width: "100%",
									display: "flex",
									alignItems: "center",
									gap: "10px",
								}}
							>
								<Input
									type="text"
									placeholder="-를 생략하고 입력해주세요."
									name={"phone"}
									value={formValue.phone}
									onChange={handleChange}
									readOnly={phoneValid && !isPhoneValid}
									onClick={isPhoneValid ? () => setOpenPhoneModal(true) : null}
								/>
								{isPhoneValid ? (
									<div
										className="input-div-button-success signup-btn"
										style={{
											padding: "14px 0",
											width: mediaResult ? "18%" : "109px",
										}}
									>
										<img
											src={`/assets/images/success_button_icon.png`}
											alt={`success_button_icon`}
											style={{
												width: "1.2em",
												height: "auto",
											}}
										/>
										<span style={{ fontSize: "14px" }}>인증완료</span>
									</div>
								) : (
									<Button
										className={`certification-request-btn signup-btn`}
										loading={isLoading}
										disabled={phoneValid || !!snsUserInfo}
										onClick={sendPhoneValid}
										style={{
											minWidth: 100,
											width: mediaResult ? "18%" : "109px",
										}}
									>
										{!isLoading && "인증요청"}
									</Button>
								)}
							</div>
						</div>
						{phoneValid && !isPhoneValid && (
							<div className="calculate-inquiry-add-wrap">
								<div className="calculate-inquiry-add-wrap-title">인증번호</div>
								<div
									className={`input-div phone`}
									style={{
										width: "100%",
										display: "flex",
										alignItems: "center",
										gap: "10px",
									}}
								>
									<Input
										type={`text`}
										placeholder={`인증번호 6자리를 입력해주세요.`}
										value={formValue.phone_valid}
										name={"phone_valid"}
										onChange={handleChange}
										pattern={`/d*`}
										disabled={isPhoneValid || !!snsUserInfo}
									/>
									<Button
										className={`certification-request-btn`}
										loading={isLoading}
										disabled={isPhoneValid || !!snsUserInfo}
										onClick={checkPhoneValid}
										style={{
											minWidth: 100,
											width: mediaResult ? "18%" : "109px",
										}}
									>
										확인
									</Button>
								</div>
							</div>
						)}

						{/* <div className="calculate-inquiry-add-wrap">
          <div className="calculate-inquiry-add-wrap-title">비밀번호</div>
          <div className="wrap-input">
            <Input
              type="password"
              placeholder="비밀번호를 입력해주세요."
              name={"password"}
              value={formValue.password}
              onChange={handleChange}
            />
          </div>
          <p
            className="calculate-inquiry-desc-text "
            style={{ textAlign: "left", marginBottom: "0" }}
          >
            8자리 이상의 비밀번호를 입력해주세요. (최소 영문, 숫자 1개 이상
            포함)
            <br />
            <span>비밀번호 변경시에만 입력해주세요.</span>
          </p>
        </div> */}

						{/* <div className="calculate-inquiry-add-wrap">
          <div className="calculate-inquiry-add-wrap-title">
            비밀번호 확인
          </div>
          <div className="wrap-input">
            <Input
              type="password"
              placeholder="비밀번호를 입력해주세요."
              name={"password_valid"}
              value={formValue.password_valid}
              onChange={handleChange}
            />
          </div>
        </div> */}
					</div>
					{!mediaResult && (
						<div
							className="calculate-inquiry-button-wrap user-edit"
							style={{ marginTop: "100px" }}
						>
							<Button
								className="calculate-inquiry-write-button"
								type={"primary"}
								disabled={!isDisabled() || formLoading}
								onClick={() => {
									if (!formLoading) {
										handleClick();
										// updatePassword();
									}
								}}
							>
								저장
							</Button>
							<Button
								className="calculate-inquiry-cancel-button"
								onClick={handleCancel}
							>
								취소
							</Button>
						</div>
					)}
				</div>
			)}
			{mediaResult && (
				<div
					style={{
						width: "100%",
						height: "1px",
						background: "#d9d9d9",
						margin: "2.2em 0 2em",
					}}
				></div>
			)}
			{!user?.is_certified && mediaResult && (
				<div
					style={{ cursor: "pointer" }}
					className="calculate-inquiry-title"
					onClick={suggestVerification}
				>
					내 계좌
					<img
						style={{ rotate: "90deg" }}
						src="/assets/images/right-arrow-black.svg"
						alt=""
					/>
				</div>
			)}
			{!user?.is_certified && !mediaResult && isShowMyAccount && (
				<div
					style={{
						display: "flex",
						alignItems: "center",
						flexDirection: "column",
					}}
				>
					<p
						style={{
							padding: "100px 0 100px",
							textAlign: "center",
							fontSize: "18px",
							fontWeight: 600,
						}}
					>
						인증된 계좌가 없습니다. 본인인증 하시겠습니까?
					</p>
					<Button
						onClick={suggestVerification}
						className="certification-request-btn"
						style={{ width: "124px", background: "#117ffa", color: "#fff" }}
					>
						확인
					</Button>
				</div>
			)}
			{user?.is_certified && (
				<>
					{mediaResult && (
						<div
							onClick={() =>
								openMyAccount ? setOpenMyAccount(false) : setOpenMyAccount(true)
							}
							className="calculate-inquiry-title"
							style={{ cursor: "pointer" }}
						>
							내 계좌
							<img
								style={{ rotate: openMyAccount ? "-90deg" : "90deg" }}
								src="/assets/images/right-arrow-black.svg"
								alt=""
							/>
						</div>
					)}
					{(isShowMyAccount || openMyAccount) && (
						<div className="calculate-inquiry-table user-edit">
							<div className="calculate-inquiry-add-wrapper">
								<div
									className="calculate-inquiry-add-wrap"
									style={{
										border: !mediaResult && "none",
										paddingBottom: !mediaResult && "0em",
									}}
								>
									<div className="calculate-inquiry-add-wrap-title">
										주민등록번호
									</div>
									<div className="wrap-input user-edit personal-number">
										<div
											style={{
												display: "flex",
												alignItems: "center",
												gap: mediaResult ? "6px" : "10px",
												margin: mediaResult && "0 0 10px",
												width: "100%",
											}}
										>
											<Input
												style={{ width: !mediaResult && "177px" }}
												type="text"
												placeholder="앞자리 6글자"
												name={"birth"}
												value={currUserValue?.birth_day}
												readOnly
												disabled
											/>
											<span>-</span>
											<Input
												style={{ width: !mediaResult && "177px" }}
												type="text"
												placeholder="뒷자리 7글자"
												name={"birth2"}
												value={"*******"}
												readOnly
												disabled
											/>
											{!mediaResult && (
												<div
													className="input-div-button-success"
													style={{
														padding: "10px",
														width: "109px",
														minWidth: "105px",
														cursor: "unset",
													}}
												>
													<img
														src={`/assets/images/success_button_icon.png`}
														alt={`success_button_icon`}
														style={{
															width: "1em",
															height: "auto",
														}}
													/>
													<span>인증완료</span>
												</div>
											)}
										</div>
										{mediaResult && (
											<div
												className="input-div-button-success"
												style={{
													padding: "10px",
													width: "100%",
													minWidth: "105px",
													cursor: "unset",
													marginBottom: "10px",
												}}
											>
												<img
													src={`/assets/images/success_button_icon.png`}
													alt={`success_button_icon`}
													style={{
														width: "1em",
														height: "auto",
													}}
												/>
												<span>인증완료</span>
											</div>
										)}
										{mediaResult && (
											<p
												className="calculate-inquiry-desc-text"
												style={{ textAlign: "left" }}
											>
												<span>대금 정산</span>을 위하여 주민등록번호를 소득세법
												제 145조에 따라 수집이용 하며, 이 법에 따라 5년 동안
												보관합니다.
												<br />
												가입시 확인한 본인 인증 정보와 동일한 주민등록 정보가
												제공되어야 합니다.
											</p>
										)}
									</div>
								</div>
								{!mediaResult && (
									<div
										className="calculate-inquiry-add-wrap"
										style={{ paddingTop: 0, paddingBottom: "0em" }}
									>
										<p
											className="calculate-inquiry-desc-text"
											style={{
												textAlign: "left",
												marginBottom: 0,
												fontSize: "14px",
											}}
										>
											<span>대금 정산</span>을 위하여 주민등록번호를 소득세법 제
											145조에 따라 수집이용 하며, 이 법에 따라 5년 동안
											보관합니다.
											<br />
											가입시 확인한 본인 인증 정보와 동일한 주민등록 정보가
											제공되어야 합니다.
										</p>
									</div>
								)}
								<div className="calculate-inquiry-add-wrap">
									<div className="calculate-inquiry-add-wrap-title">
										계좌 소유자
									</div>
									<div className="wrap-input user-edit">
										<Input
											type="text"
											placeholder="계좌 소유자를 입력해주세요."
											name={"account_holder_name"}
											value={formValue.account_holder_name}
											// disabled={true}
											readOnly
										/>
									</div>
								</div>
								<div className="calculate-inquiry-add-wrap">
									<div className="calculate-inquiry-add-wrap-title">은행</div>
									<div className="wrap-input user-edit">
										<div>
											<Dropdown
												overlayClassName={`bank-dropdown-wrap`}
												menu={{
													items,
													onClick,
												}}
												trigger={["click"]}
												disabled
												overlayStyle={{
													maxHeight: 200,
													overflow: "auto",
												}}
											>
												<Input
													loading={formLoading}
													placeholder={"은행을 선택해주세요"}
													value={formValue.bank_name}
													disabled
												/>
											</Dropdown>
										</div>
									</div>
								</div>

								<div className="calculate-inquiry-add-wrap">
									<div className="calculate-inquiry-add-wrap-title">
										계좌번호
									</div>
									<div
										style={{
											width: "100%",
											display: "flex",
											alignItems: "center",
											gap: "10px",
										}}
									>
										<Input
											className={`custom-text-input`}
											type="text"
											placeholder="-를 생략하고 입력해주세요."
											name={"account_number"}
											value={formValue.account_number}
											onChange={handleChange}
											disabled
											style={{
												width: "380px",
											}}
										/>
										{formValue.is_verify_account ? (
											<div
												className="input-div-button-success signup-btn"
												style={{
													padding: "10px",
													width: mediaResult ? "18%" : "109px",
													minWidth: "105px",
													cursor: "unset",
												}}
											>
												<img
													src={`/assets/images/success_button_icon.png`}
													alt={`success_button_icon`}
													style={{
														width: "1em",
														height: "auto",
													}}
												/>
												<span>인증완료</span>
											</div>
										) : (
											<Button
												className="certification-request-btn"
												onClick={() => {
													if (!submitBankLoading) {
														verifyAccount("account");
													}
												}}
												loading={submitBankLoading}
												disabled={
													!formValue.name.length ||
													!formValue.bank_code ||
													!formValue.account_number ||
													submitBankLoading
												}
											>
												{!submitBankLoading && "인증요청"}
											</Button>
										)}
									</div>
								</div>
								{checkBankAccountNumber !== undefined &&
									!formValue.is_verify_account && (
										<div
											style={{
												width: "100%",
												display: "flex",
												alignItems: "center",
												marginTop: "12px",
											}}
										>
											<div className="calculate-inquiry-add-wrap-title">
												인증 번호
											</div>
											<div
												style={{
													display: "flex",
													width: "500px",
													justifyContent: "space-between",
													gap: "10px",
												}}
											>
												<Input
													type="text"
													placeholder="4자리 숫자를 입력해주세요."
													value={checkBankAccountNumber}
													onChange={(e) => {
														if (e.target.value?.length <= 4) {
															setCheckBankAccountNumber(
																e.target.value?.replace(/[^0-9]/g, "")
															);
														}
													}}
													style={{
														width: "380px",
													}}
												/>
												<Button
													onClick={() => {
														if (!submitBankLoading) {
															verifyAccount("code");
														}
														// setIsShowModalCheckBankAccount(true);
														// setCheckBankAccountNumber(null);
													}}
													loading={submitBankLoading}
													disabled={
														!formValue.bank_code ||
														!formValue.account_number ||
														!checkBankAccountNumber
													}
													className="certification-request-btn"
												>
													{!submitBankLoading && "확인"}
												</Button>
											</div>
										</div>
									)}
								{checkBankAccountNumber !== undefined &&
									!formValue.is_verify_account && (
										<img
											src={`/assets/images/bank_account_image.png`}
											alt={`bank_account_image`}
											style={{
												width: "16em",
												height: "auto",
												position: "absolute",
												right: -40,
											}}
										/>
									)}
								{/* <p
								className="calculate-inquiry-desc-text star"
								style={{ textAlign: "left", marginBottom: "15px" }}
							>
								<span>계좌번호 오기입</span>으로 인해 다른계좌로 송금 시
								당영투게더에서는 책임지지 않습니다.
								<br />
								다시 한번 더 확인 부탁드립니다.
							</p> */}
							</div>
						</div>
					)}
				</>
			)}
			{/* 버튼 */}
			{mediaResult && (
				<div className="calculate-inquiry-button-wrap user-edit">
					<Button
						className="calculate-inquiry-write-button"
						type={"primary"}
						disabled={!isDisabled() || formLoading}
						onClick={() => {
							if (!formLoading) {
								handleClick();
								// updatePassword();
							}
						}}
					>
						저장
					</Button>
					<Button
						className="calculate-inquiry-cancel-button"
						onClick={handleCancel}
					>
						취소
					</Button>
				</div>
			)}

			{/* <Row justify={"center"}>
				<button
					className={`channel-withdraw`}
					style={{ color: "#ced4da" }}
					onClick={logout}
				>
					로그아웃
				</button>
				<p
					style={{
						color: "#D9D9D9",
					}}
				>
					/
				</p>
				<button
					className={`channel-withdraw`}
					style={{ color: "#ced4da" }}
					onClick={() => setSecession(true)}
				>
					회원탈퇴
				</button>
			</Row> */}

			{/* 모달 - 1원 입금 */}
			<Modal
				title=""
				open={isShowModalCheckBankAccount}
				footer={<></>}
				closable={false}
				wrapClassName="modal-wrap seller-grade-modal-wrap"
				width={400}
			>
				<div className="modal-title" style={{ marginBottom: "10px" }}>
					입력하신 계좌로 1원을 보냈습니다.
				</div>
				<div
					className="modal-title"
					style={{ marginBottom: "-4px", paddingTop: 0 }}
				>
					거래내역 내 입금자명에 적힌
				</div>
				<div
					className="modal-title"
					style={{ paddingTop: 0, marginBottom: "20px" }}
				>
					<span>숫자 4자리</span>를 확인하여 입력해주세요.
				</div>
				<div className="modal-button-wrap">
					<div
						onClick={() => setIsShowModalCheckBankAccount(false)}
						className="modal-button-success"
						style={{ width: "50%" }}
					>
						확인
					</div>
				</div>
			</Modal>
			{/* 모달 - 계좌 인증 완료 */}
			<Modal
				title=""
				open={isShowModalSuccessBankAccount}
				footer={<></>}
				closable={false}
				wrapClassName="modal-wrap seller-grade-modal-wrap"
				width={400}
			>
				<div className="modal-title">계좌인증이 완료되었습니다.</div>
				<div className="modal-button-wrap">
					<div
						onClick={() => setIsShowModalSuccessBankAccount(false)}
						className="modal-button-success"
						style={{ width: "50%" }}
					>
						확인
					</div>
				</div>
			</Modal>
			<Modal
				title=""
				open={successEditInfo}
				footer={<></>}
				closable={false}
				wrapClassName="modal-wrap seller-grade-modal-wrap"
				width={400}
			>
				<div className="modal-title">저장되었습니다.</div>
				<div className="modal-button-wrap">
					<div
						onClick={() => {
							setSuccessEditInfo(false);
							history.replace("/dashboard");
						}}
						className="modal-button-success"
						style={{ width: "50%" }}
					>
						확인
					</div>
				</div>
			</Modal>

			{/* 탈퇴하기 및 관련 정보 확인 모달 */}
			<Modal
				title=""
				open={secession}
				footer={null}
				closable={false}
				wrapClassName="modal-wrap seller-grade-modal-wrap custom-modal-wrap secession-modal"
				width={400}
				centered={true}
			>
				<div className="modal-title secession">탈퇴하기</div>
				{!mediaResult ? (
					<p className="modal-title-sub secession" style={{ color: "#979797" }}>
						잠깐 당영투게더 탈퇴하기 전에 아래 정보를 확인해주세요
					</p>
				) : (
					<p className="modal-title-sub secession">
						잠깐 당영투게더 탈퇴하기 전에
						<br />
						아래 정보를 확인해주세요
					</p>
				)}
				<div className="secession-modal-cont-flex">
					<div
						style={{
							width: "100%",
							padding: "2rem 2rem ",
							marginBottom: "1em",
							background: "#F6F6F6",
							borderRadius: "16px",
						}}
					>
						{mediaResult ? (
							<p className="secession-title">
								그동안 쌓아놓은 골드가 모두 사라져요
							</p>
						) : (
							<p className="secession-title">
								그동안 쌓아놓은 골드가
								<br /> 모두 사라져요
							</p>
						)}
						<p className="secession-desc">
							자진 탈퇴 시, 정산되지 않은 금액이 있더라도 탈퇴가 이루어지면,
							정산 예정금은 받을 수 없습니다.
						</p>
					</div>
					<div
						style={{
							width: "100%",
							padding: "2rem 2rem ",
							marginBottom: "2.5em",
							background: "#F6F6F6",
							borderRadius: "16px",
						}}
					>
						{mediaResult ? (
							<p className="secession-title">
								처음부터 다시 가입하고 인증해야 해요
							</p>
						) : (
							<p className="secession-title">
								처음부터 다시 가입하고
								<br />
								인증해야 해요
							</p>
						)}
						<p className="secession-desc">
							탈퇴 시 회원님의 계정 정보는 모두 삭제되며 복구가 불가능합니다.
							다시 한번 더 고민해 보세요
						</p>
					</div>
				</div>
				<div className="modal-button-wrap">
					<div
						onClick={() => setSecession(false)}
						className="modal-button-success"
					>
						취소
					</div>
					<div className="modal-button-cancel" onClick={handleSecession}>
						탈퇴
					</div>
				</div>
			</Modal>

			{/* 휴대폰 번호를 변경하시겠습니까? */}
			<Modal
				title=""
				open={openPhoneModal}
				footer={null}
				closable={false}
				wrapClassName="modal-wrap seller-grade-modal-wrap custom-modal-wrap"
				width={400}
			>
				<div className="modal-title" style={{ marginBottom: "2.4em" }}>
					휴대폰 번호를 변경하시겠습니까?
				</div>

				<div className="modal-button-wrap">
					<div onClick={resetPhoneInput} className="modal-button-success">
						확인
					</div>
					<div
						className="modal-button-cancel"
						onClick={() => setOpenPhoneModal(false)}
					>
						취소
					</div>
				</div>
			</Modal>
			{/* 모달 - 본인인증 */}
			<Modal
				title=""
				open={isShowModalCertify}
				footer={null}
				closable={false}
				wrapClassName="modal-wrap seller-grade-modal-wrap custom-modal-wrap"
				width={400}
				centered={mediaResult}
			>
				<div className="modal-title">
					정산을 받으려면{"\n"}본인인증이 필요합니다.
				</div>
				<div className="modal-button-wrap">
					<div
						className="modal-button-success"
						onClick={() => {
							if (mediaResult) {
								setIsShowModalCertify(false);
								setIsShowModalUserCertify(true);
							} else {
								history.push("/user/together/signup");
							}
						}}
					>
						인증
					</div>
					<div
						className="modal-button-cancel"
						onClick={() => setIsShowModalCertify(false)}
					>
						취소
					</div>
				</div>
			</Modal>
			{/*모달 - 본인인증 모달*/}
			<UserVerificationModal
				show={isShowModalUserCertify}
				setShow={setIsShowModalUserCertify}
				mediaResult={mediaResult}
				bankList={bankList}
			/>
		</div>
	);
}

export default UserEdit;
