import { useLayoutEffect } from "react";
import { useSelector } from "react-redux";
import { Redirect, Route, useHistory } from "react-router-dom";

const PublicRoute = ({ component: Component, restricted, ...rest }) => {
	const user = useSelector((state) => state.auth.user);

	const accessToken = localStorage.getItem("admin:accessToken");
	const refreshToken = localStorage.getItem("admin:refreshToken");

	let isUser = user?.id && !!accessToken && !!refreshToken;

	// let beforeSignupUser = user?.id && !user?.bank_code;

	// const history = useHistory();
	// useLayoutEffect(() => {
	// 	if (beforeSignupUser) {
	// 		history.replace("/user/together/signup");
	// 	}
	// }, [beforeSignupUser]);

	return (
		<Route
			{...rest}
			render={(props) =>
				isUser &&
				(rest.location?.pathname === "/" ||
					rest.location.pathname.includes("signup")) ? (
					<>
						<Redirect to="/dashboard" />
					</>
				) : (
					<Component {...props} />
				)
			}
		/>
	);
};

export default PublicRoute;
