import axios from "axios";

let BASE_URL = process.env.REACT_APP_BACKEND_HOST;
let PLUTUS_BASE_URL = process.env.REACT_APP_PLUTUS_BACKEND_HOST;
// var _ACCESS_KEY = "x-vuon-accesstoken";
// var _REFRESY_KEY = "x-vuon-refreshtoken";

/*//axios 통신 전 해더 작업;
axios.interceptors.request.use(function (config) {
  //카카오톡 이랑 애플 api주소가 아닐경우
  if (
    config.url !== "https://kauth.kakao.com/oauth/token" &&
    config.url !== "https://appleid.apple.com/auth/token"
  ) {
    if (
      localStorage.getItem("accessToken") &&
      localStorage.getItem("refreshToken")
    ) {
      config.headers[_ACCESS_KEY] = localStorage.getItem("accessToken");
      config.headers[_REFRESY_KEY] = localStorage.getItem("refreshToken");
    }
  }

  return config;
});

axios.interceptors.response.use(
  function (res) {
    const headers = res.headers;
    if (headers) {
      if (headers[_ACCESS_KEY] && headers[_REFRESY_KEY]) {
        localStorage.setItem("accessToken", headers[_ACCESS_KEY]);
        localStorage.setItem("refreshToken", headers[_REFRESY_KEY]);
      }
    }
    return res;
  },
  function (error) {
    return Promise.reject(error);
  }
);
*/
/**
 * API SERVICE
 *
 * writer : 하우투약 CNS
 * version : 0.0.1
 * date : 2021-09-03
 *
 * - API 서비스 유틸
 */

class ApiService {
	/**
	 * ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	 *                                     [ IamPORT - 아임포트 결제 ]
	 * ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	 */

	/**
	 * 당영픽 카테고리 리스트 가져오기
	 * @returns {Promise<unknown>}
	 */
	readPickCategoryList() {
		return new Promise((resolve) => {
			axios
				.get(`${BASE_URL}/dangyoungPick/category/list`)
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					} else if (res.status === 401) {
						resolve(res);
					}
				})
				.catch((error) => {
					resolve("error");
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * 당영픽 카테고리 가져오기
	 * @param param
	 * categoryId
	 * @returns {Promise<unknown>}
	 */
	readPickCategory(param) {
		return new Promise((resolve) => {
			axios
				.get(`${BASE_URL}/dangyoungPick/category`, {
					params: param,
				})
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					} else if (res.status === 401) {
						resolve(res);
					}
				})
				.catch((error) => {
					resolve("error");
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	 *                                     [ Naver - 네이버 비로그인 api ]
	 * ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	 */

	/**
	 * NaverApi
	 * @param param
	 * url, query
	 * news, encyc, blog, shop, movie, webkr, image, doc, kin, cafearticle, adult, errata, local
	 * @returns {Promise<unknown>}
	 */
	naverApiSearch(param) {
		return new Promise((resolve) => {
			axios
				.get(`${BASE_URL}/naverApi/search`, {
					params: param,
				})
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					} else if (res.status === 401) {
						resolve(res);
					}
				})
				.catch((error) => {
					resolve("error");
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	 *                                     [ IamPORT - 아임포트 결제 ]
	 * ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	 */

	/**
	 * 무통장 입금 payment
	 * @param param
	 * @returns {Promise<unknown>}
	 */
	depositPayment(param) {
		return new Promise((resolve) => {
			axios
				.post(`${BASE_URL}/payment/create`, param)
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					} else if (res.status === 401) {
						resolve(res);
					}
				})
				.catch((error) => {
					resolve("error");
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * 주문 결제하기
	 * @param param
	 * @returns {Promise<unknown>}
	 */
	getPaymentComplete(param) {
		return new Promise((resolve) => {
			axios
				.post(`${BASE_URL}/payment/payment/complete`, param)
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					} else if (res.status === 401) {
						resolve(res);
					}
				})
				.catch((error) => {
					resolve("error");
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * 단건 IamPort 결제 정보 찾기
	 * @param param
	 * @returns {Promise<unknown>}
	 */
	getPaymentSearch(param) {
		return new Promise((resolve) => {
			axios
				.get(`${BASE_URL}/payment/payment/search`, { params: param })
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					} else if (res.status === 401) {
						resolve(res);
					}
				})
				.catch((error) => {
					resolve("error");
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * 단건 DB 결제 정보 찾기
	 * @param param user_id, order_id
	 * @returns {Promise<unknown>}
	 */
	getPaymentSearchResult(param) {
		return new Promise((resolve) => {
			axios
				.get(`${BASE_URL}/payment/payment/searchResult`, { params: param })
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					} else if (res.status === 401) {
						resolve(res);
					}
				})
				.catch((error) => {
					resolve("error");
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * 결제 환불(취소)하기 (order 전체금액)
	 * @param param
	 * @returns {Promise<unknown>}
	 */
	cancelPaymentAll(param) {
		return new Promise((resolve) => {
			axios
				.post(`${BASE_URL}/payment/payment/cancel/all`, param)
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					} else if (res.status === 401) {
						resolve(res);
					}
				})
				.catch((error) => {
					resolve("error");
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	 *                                     [ Tracking - 배송조회 ]
	 * ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	 */

	/**
	 * 배송사 리스트 조희
	 * @param param
	 * @returns {Promise<unknown>}
	 */
	getTrackerList() {
		return new Promise((resolve) => {
			axios
				.get(`https://apis.tracker.delivery/carriers`)
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					} else if (res.status === 401) {
						resolve(res);
					}
				})
				.catch((error) => {
					resolve("error");
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * 배송 현황 조희
	 * @param param /:carrier_id/tracks/:track_id
	 * @returns {Promise<unknown>}
	 */
	getTrackerLookup(param) {
		return new Promise((resolve) => {
			axios
				.get(
					`https://apis.tracker.delivery/carriers/${param.tracker_id}/tracks/${param.track_id}`
				)
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					} else if (res.status === 401) {
						resolve(res);
					}
				})
				.catch((error) => {
					resolve("error");
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	 *                                     [ Order - 주문하기 ]
	 * ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	 */

	/**
	 * 주문 상세 쿠폰 적용하기
	 * @param param UserOrderDetail
	 * @returns {Promise<unknown>}
	 */
	updateOrderDetailCoupon(param) {
		return new Promise((resolve) => {
			axios
				.post(`${BASE_URL}/userOrder/applyCoupon`, param)
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * 주문지 생성
	 * @param param
	 * @returns {Promise<unknown>}
	 */
	userOrderCreate(param) {
		return new Promise((resolve) => {
			axios
				.post(`${BASE_URL}/userOrder/create`, param)
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					} else if (res.status === 401) {
						resolve(res);
					}
				})
				.catch((error) => {
					resolve("error");
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * 주문지 수정
	 * @param param
	 * @returns {Promise<unknown>}
	 */
	userOrderUpdate(param) {
		return new Promise((resolve) => {
			axios
				.post(`${BASE_URL}/userOrder/edit`, param)
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					} else if (res.status === 401) {
						resolve(res);
					}
				})
				.catch((error) => {
					resolve("error");
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * 해당 주문 정보 가져오기
	 * @param param user_id, order_id
	 * @returns {Promise<unknown>}
	 */
	getUserOrderRead(param) {
		return new Promise((resolve) => {
			axios
				.get(`${BASE_URL}/userOrder/getOne`, { params: param })
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					} else if (res.status === 401) {
						resolve(res);
					}
				})
				.catch((error) => {
					resolve("error");
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * 구매확정된 상품 리스트 가져오기
	 * @param param user_id
	 * @returns {Promise<unknown>}
	 */
	getUserOrderComplete(param) {
		return new Promise((resolve) => {
			axios
				.get(`${BASE_URL}/userOrder/complete/list`, { params: param })
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					} else if (res.status === 401) {
						resolve(res);
					}
				})
				.catch((error) => {
					resolve("error");
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * 주문 정보 전체 가져오기
	 * @param param
	 * @returns {Promise<unknown>}
	 */
	getUserOrderAll(param) {
		return new Promise((resolve) => {
			axios
				.get(`${BASE_URL}/userOrder/list`, { params: param })
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					} else if (res.status === 401) {
						resolve(res);
					}
				})
				.catch((error) => {
					resolve("error");
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * 주문 삭제하기
	 * @param param
	 * @returns {Promise<unknown>}
	 */
	deleteUserOrder(param) {
		return new Promise((resolve) => {
			axios
				.delete(`${BASE_URL}/userOrder/delete`, { params: param })
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					} else if (res.status === 401) {
						resolve(res);
					}
				})
				.catch((error) => {
					resolve("error");
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * 배송지 정보 가져오기
	 * @param param
	 * @returns {Promise<unknown>}
	 */
	getUserOrderAddress(param) {
		return new Promise((resolve) => {
			axios
				.get(`${BASE_URL}/userOrderAddress/list`, { params: param })
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					} else if (res.status === 401) {
						resolve(res);
					}
				})
				.catch((error) => {
					resolve("error");
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * 배송지 추가하기
	 * @param param
	 * @returns {Promise<unknown>}
	 */
	addUserOrderAddress(param) {
		return new Promise((resolve) => {
			axios
				.post(`${BASE_URL}/userOrderAddress/create`, param)
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					} else if (res.status === 401) {
						resolve(res);
					}
				})
				.catch((error) => {
					resolve("error");
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * 배송지 수정하기
	 * @param param
	 * @returns {Promise<unknown>}
	 */
	editUserOrderAddress(param) {
		return new Promise((resolve) => {
			axios
				.post(`${BASE_URL}/userOrderAddress/edit`, param)
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					} else if (res.status === 401) {
						resolve(res);
					}
				})
				.catch((error) => {
					resolve("error");
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * 배송지 삭제하기
	 * @param param
	 * @returns {Promise<unknown>}
	 */
	deleteUserOrderAddress(param) {
		return new Promise((resolve) => {
			axios
				.delete(`${BASE_URL}/userOrderAddress/delete/${param}`)
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	 *                                     [ User - 로그인 / 회원가입 / 찾기 ]
	 * ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	 */

	/**
	 * 카카오 알림톡 보내기
	 * @param param
	 * @returns {Promise<unknown>}
	 */
	kakaoSend(param) {
		return new Promise((resolve) => {
			axios
				.post(`${BASE_URL}/user/kakaoSend`, param)
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					} else if (res.status === 401) {
						resolve(res);
					}
				})
				.catch((error) => {
					resolve("error");
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * 유저 정보 가져오기
	 * @param param
	 * @returns {Promise<unknown>}
	 */
	userRead(param) {
		return new Promise((resolve) => {
			axios
				.get(`${BASE_URL}/user/read`, { params: param })
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					} else if (res.status === 401) {
						resolve(res);
					}
				})
				.catch((error) => {
					resolve("error");
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * 카카오 토큰 받아오기
	 * @param {*} param
	 * @returns
	 */
	kakaoToken(param) {
		return new Promise((resolve) => {
			axios
				.get(`https://kauth.kakao.com/oauth/token`, { params: param })
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					} else if (res.status === 401) {
						resolve(res);
					}
				})
				.catch((error) => {
					resolve("error");
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * 애플 토큰 받아오기
	 * @param {*} param
	 * @returns
	 */
	appleToken(param) {
		return new Promise((resolve) => {
			axios
				.post(`https://appleid.apple.com/auth/token`, param)
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					} else if (res.status === 401) {
						resolve(res);
					}
				})
				.catch((error) => {
					resolve("error");
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * 로그인
	 * @param {*} param
	 */
	login(param) {
		return new Promise((resolve) => {
			axios
				.post(`${BASE_URL}/user/login`, param)
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * 자동 로그인
	 * @param {*} param
	 */
	autoLogin(param) {
		return new Promise((resolve) => {
			axios
				.post(`${BASE_URL}/user/auto_login`, param)
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * 로그아웃
	 * @param {*} param
	 */
	logout(param) {
		return new Promise((resolve) => {
			axios
				.post(`${BASE_URL}/user/logout`, param)
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * 인증번호 문자보내기
	 * @param {*} param
	 * @returns
	 */
	smsCertifySend(param) {
		return new Promise((resolve) => {
			axios
				.post(`${BASE_URL}/user/sms_certify_send`, param)
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * 아이디 / 인증번호 문자보내기 (비밀번호 찾기)
	 * @param {*} param
	 * @returns
	 */
	smsUserIdCertifySend(param) {
		return new Promise((resolve) => {
			axios
				.post(`${BASE_URL}/user/sms_user_id_certify_send`, param)
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * 인증번호 확인
	 * @param {*} param
	 * @returns
	 */
	smsCertifyVerify(param) {
		return new Promise((resolve) => {
			axios
				.post(`${BASE_URL}/user/sms_certify_verify`, param)
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * SNS 가입여부 검토
	 * @param {*} param
	 */
	snsValid(param) {
		return new Promise((resolve) => {
			axios
				.post(`${BASE_URL}/user/sns_valid`, param)
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * user_id 중복여부 검토
	 * @param {*} param
	 */
	userIdValid(param) {
		return new Promise((resolve) => {
			axios
				.post(`${BASE_URL}/user/user_id_valid`, param)
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * nickname 중복여부 검토
	 * @param {*} param
	 */
	nicknameValid(param) {
		return new Promise((resolve) => {
			axios
				.post(`${BASE_URL}/user/nickname_valid`, param)
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * email 중복여부 검토
	 */

	emailValid(param) {
		return new Promise((resolve) => {
			axios
				.post(`${BASE_URL}/user/email_valid`, param)
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * 계정 생성
	 * @param {*} param
	 */
	userJoin(param) {
		return new Promise((resolve) => {
			axios
				.post(`${BASE_URL}/user/user_join`, param)
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * 폰번호로 계정찾기
	 * @param {*} param
	 */
	phoneUser(param) {
		return new Promise((resolve) => {
			axios
				.post(`${BASE_URL}/user/phone_user`, param)
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * 비밀번호 변경하기
	 * @param {*} param
	 */
	updatePassword(param) {
		return new Promise((resolve) => {
			axios
				.post(`${BASE_URL}/user/update_password`, param)
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * 프로필 추가 등록하기
	 * @param {*} param
	 */
	userProfileAdd(param) {
		return new Promise((resolve) => {
			axios
				.post(`${BASE_URL}/user/profile/add`, param)
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * 사용자 정보 바꾸기 (프로필)
	 * @param {*} param
	 */
	userEdit(param) {
		return new Promise((resolve) => {
			axios
				.post(`${BASE_URL}/user/edit`, param)
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * 사용자 정보 바꾸기 (프로필 - 핸드폰번호)
	 * @param {*} param
	 */
	phoneEdit(param) {
		return new Promise((resolve) => {
			axios
				.post(`${BASE_URL}/user/edit/phone`, param)
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * 사용자 패스워드 확인
	 * @param {*} param
	 */
	passwordValid(param) {
		return new Promise((resolve) => {
			axios
				.post(`${BASE_URL}/user/password/valid`, param)
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * 프로필 삭제하기
	 * @param {*} param
	 */

	userProfileRemove(param) {
		return new Promise((resolve) => {
			axios
				.post(`${BASE_URL}/user/profile/remove`, param)
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * 사용자 탈퇴하기
	 * @param {*} param
	 */

	leave(param) {
		return new Promise((resolve) => {
			axios
				.post(`${BASE_URL}/user/leave`, param)
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * 나의 다짐 추가
	 * @param {*} param
	 */
	promiseAdd(param) {
		return new Promise((resolve) => {
			axios
				.post(`${BASE_URL}/user/promise/add`, param)
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * 나의 다짐 수정
	 * @param {*} param
	 */
	promiseEdit(param) {
		return new Promise((resolve) => {
			axios
				.post(`${BASE_URL}/user/promise/edit`, param)
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * 나의 다짐 데이터 가져오기
	 * @param {*} param
	 */
	promiseSelect(param) {
		return new Promise((resolve) => {
			axios
				.get(`${BASE_URL}/user/promise`, { params: param })
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * 디바이스 정보 추가/수정
	 * @param {*} param
	 */
	appUserAdd(param) {
		return new Promise((resolve) => {
			axios
				.post(`${BASE_URL}/user/app/add`, param)
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * 멀티프로필 정보 가져오기
	 * @param {*} param
	 */
	multiprofiles(param) {
		return new Promise((resolve) => {
			axios
				.get(`${BASE_URL}/user/multiprofiles`, { params: param })
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	 *                                     [ QueryLog - 링크를 통한 로그인 체크 ]
	 * ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	 */
	/**
	 * queryLog
	 * @param param
	 * created_by, created_at, log
	 * @returns {Promise<unknown>}
	 */
	queryLogCreate(param) {
		return new Promise((resolve) => {
			axios
				.post(`${BASE_URL}/queryLog/create`, param)
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					} else if (res.status === 401) {
						resolve(res);
					}
				})
				.catch((error) => {
					resolve("error");
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	 *                                     [ Record - Food 식단 ]
	 * ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	 */

	/**
	 * 음식검색
	 * @param {*} param
	 */
	foodSearch(param) {
		return new Promise((resolve) => {
			axios
				.get(`${BASE_URL}/food/search`, { params: param })
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * 재료 검색
	 * @param {*} param
	 */
	foodIngredientSearch(param) {
		return new Promise((resolve) => {
			axios
				.get(`${BASE_URL}/food/ingredient/search`, { params: param })
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * 음식 영양성분 가져오기
	 * @param {*} param
	 */
	getFoodNutrition(param) {
		return new Promise((resolve) => {
			axios
				.get(`${BASE_URL}/food/nutrition/${param.food_id}`)
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * 재료에 맞는 영양성분 가져오기
	 * @param {*} param
	 */
	getFoodIngredientNutrition(param) {
		return new Promise((resolve) => {
			axios
				.get(
					`${BASE_URL}/food/ingredient/nutrition/${param.food_ingredient_id}`
				)
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * 음식 추가
	 * @param {*} param
	 */
	foodAdd(param) {
		return new Promise((resolve) => {
			axios
				.post(`${BASE_URL}/food/add`, param)
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * 음식 재료 추가
	 * @param {*} param
	 */
	foodIngredientsAdd(param) {
		return new Promise((resolve) => {
			axios
				.post(`${BASE_URL}/food/ingredient/add`, param)
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * 나만의 레시피 추가
	 * @param {*} param
	 */
	foodRecipeAdd(param) {
		return new Promise((resolve) => {
			axios
				.post(`${BASE_URL}/food/recipe/add`, param)
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * 나만의 레시피 수정
	 * @param {*} param
	 */
	foodRecipeEdit(param) {
		return new Promise((resolve) => {
			axios
				.post(`${BASE_URL}/food/recipe/edit`, param)
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * 나만의 레시피 삭제
	 * @param {*} param
	 */
	foodRecipeDelete(param) {
		return new Promise((resolve) => {
			axios
				.delete(`${BASE_URL}/food/recipe/delete`, { params: param })
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * 레시피 순서변경
	 * @param {*} param
	 */
	recipeSort(param) {
		return new Promise((resolve) => {
			axios
				.post(`${BASE_URL}/food/recipe/sort`, param)
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * 섭취 기준 가져오기
	 * @param {*} param
	 */
	nutritionStandard(param) {
		return new Promise((resolve) => {
			axios
				.get(`${BASE_URL}/nutrition/standard`, { params: param })
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * 음식 기록하기
	 * @param {*} param
	 */
	recordFoodAdd(param) {
		return new Promise((resolve) => {
			axios
				.post(`${BASE_URL}/record/food/add`, param)
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * 음식 기록 수정하기
	 * @param {*} param
	 */
	recordFoodEdit(param) {
		return new Promise((resolve) => {
			axios
				.post(`${BASE_URL}/record/food/edit`, param)
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * 음식 기록 삭제하기
	 * @param {*} param
	 */
	recordFoodDelete(param) {
		return new Promise((resolve) => {
			axios
				.delete(`${BASE_URL}/record/food/delete`, { params: param })
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * 음식 기록 데이터 가져오기
	 * @param {*} param
	 */
	recordFoodList(param) {
		return new Promise((resolve) => {
			axios
				.get(`${BASE_URL}/record/food/user`, { params: param })
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * 내가만든 레시피 목록 가져오기
	 * @param {*} param
	 */
	recipeList(param) {
		return new Promise((resolve) => {
			axios
				.get(`${BASE_URL}/food/recipe/list`, { params: param })
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * 내가만든 레시피 재료 목록 가져오기
	 * @param {*} param
	 */
	getFoodIngredients(param) {
		return new Promise((resolve) => {
			axios
				.get(`${BASE_URL}/food/recipe/ingredients`, { params: param })
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * 식사기록 사진 불러오기
	 * @param {*} param
	 * @returns
	 */
	recordImageList(param) {
		return new Promise((resolve) => {
			axios
				.get(`${BASE_URL}/record/recordImageList`, { params: param })
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	 *                                     [ Record - Body 신체 ]
	 * ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	 */

	/**
	 * 신체 기록 데이터 가져오기
	 * @param {*} param
	 */
	recordBodyList(param) {
		return new Promise((resolve) => {
			axios
				.get(`${BASE_URL}/record/body/user`, { params: param })
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * 신체 기록 데이터 등록
	 * @param {*} param
	 */
	recordBodyAdd(param) {
		return new Promise((resolve) => {
			axios
				.post(`${BASE_URL}/record/body/add`, param)
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * 신체 기록 데이터 수정
	 * @param {*} param
	 */
	recordBodyEdit(param) {
		return new Promise((resolve) => {
			axios
				.post(`${BASE_URL}/record/body/edit`, param)
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * 신체 기록 데이터 최신꺼 가져오기
	 * @param {*} param
	 */
	recordBodyUserToNew(param) {
		return new Promise((resolve) => {
			axios
				.get(`${BASE_URL}/record/body/userToNew`, { params: param })
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * 기록된 달력 데이터 가져오기
	 * @param {*} p_created_by
	 * @param {*} p_start_date
	 * @param {*} p_end_date
	 */
	recordedByDate(param) {
		return new Promise((resolve) => {
			axios
				.get(`${BASE_URL}/record/byDate`, { params: param })
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * 최근 신체기록 이미지 가져오기
	 */
	recentRecordBodyImage(param) {
		return new Promise((resolve) => {
			axios
				.get(`${BASE_URL}/record/body/recent/image`, { params: param })
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * 날짜에 맞는 신체기록 이미지 가져오기
	 */
	dateRecordBodyImage(param) {
		return new Promise((resolve) => {
			axios
				.get(`${BASE_URL}/record/body/date/image`, { params: param })
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	 *                                     [ Record - nutrients 영양제 ]
	 * ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	 */

	/**
	 * 영양제 신청하기
	 * @param {*} param
	 */
	nutrientsAdd(param) {
		return new Promise((resolve) => {
			axios
				.post(`${BASE_URL}/nutrients/add`, param)
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * 영양제 찾기
	 * @param {*} param
	 */
	nutrientsSearch(param) {
		return new Promise((resolve) => {
			axios
				.get(`${BASE_URL}/nutrients/search`, { params: param })
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	getNutrientsSelect(param) {
		return new Promise((resolve) => {
			axios
				.get(`${BASE_URL}/admin/nutrients`, { params: param })
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * 영양제 영양성분 가져오기
	 * @param {*} param
	 */
	getNutrientsNutritions(param) {
		return new Promise((resolve) => {
			axios
				.get(`${BASE_URL}/nutrients/nutrition/${param.nutrients_id}`)
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * 영양제 루틴 기록하기
	 * @param {*} param
	 */
	recordNutrientsAdd(param) {
		return new Promise((resolve) => {
			axios
				.post(`${BASE_URL}/record/nutrients/add`, param)
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * 영양제 루틴 수정하기
	 * @param param
	 * @returns {Promise<unknown>}
	 */
	recordNutrientsEdit(param) {
		return new Promise((resolve) => {
			axios
				.post(`${BASE_URL}/record/nutrients/edit`, param)
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * 영양제 루틴 기록 삭제하기
	 * @param {*} param
	 */
	recordNutrientsDelete(param) {
		return new Promise((resolve) => {
			axios
				.delete(`${BASE_URL}/record/nutrients/delete`, { params: param })
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * 영양제 루틴 기록 가져오기
	 * @param {*} param
	 */
	recordNutrientsList(param) {
		return new Promise((resolve) => {
			axios
				.get(`${BASE_URL}/record/nutrients/user`, { params: param })
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * 멀티 프로필 영양제 기록 전부 불러오기
	 * @param param
	 * @returns {Promise<unknown>}
	 */
	recordNutrientsListMulti(param) {
		return new Promise((resolve) => {
			axios
				.get(`${BASE_URL}/record/nutrients/userMulti`, { params: param })
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * 영양제 섭취 기록 가져오기
	 * @param {*} param
	 */
	recordNutrientsEatList(param) {
		return new Promise((resolve) => {
			axios
				.get(`${BASE_URL}/record/nutrients/eat`, { params: param })
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * 영양제 알림 수정
	 * @param {*} param
	 */
	recordNutrientsAlramEdit(param) {
		return new Promise((resolve) => {
			axios
				.post(`${BASE_URL}/record/nutrients/alram`, param)
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * 영양제 알림 삭제
	 * @param {*} param
	 */
	recordNutrientsAlramDelete(param) {
		return new Promise((resolve) => {
			axios
				.delete(`${BASE_URL}/record/nutrients/alram`, { params: param })
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * 영양제 섭취 추가
	 * @param {*} param
	 */
	recordNutrientsEatAdd(param) {
		return new Promise((resolve) => {
			axios
				.post(`${BASE_URL}/record/nutrients/eat`, param)
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * 영양제 섭취 삭제
	 * @param {*} param
	 */
	recordNutrientsEatDelete(param) {
		return new Promise((resolve) => {
			axios
				.delete(`${BASE_URL}/record/nutrients/eat`, { params: param })
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	 *                                     [ nutritionScore - 영양점수 ]
	 * ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	 */

	/**
	 * 영양점수 가져오기( 구간 )
	 * @param param
	 * @returns {Promise<unknown>}
	 */
	readNutritionRangeScore(param) {
		return new Promise((resolve) => {
			axios
				.get(`${BASE_URL}/nutritionScore/range/read`, { params: param })
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * 영양점수 가져오기( 전2일 포함 총 3일 )
	 * @param param
	 * @returns {Promise<unknown>}
	 */
	readNutritionScore(param) {
		return new Promise((resolve) => {
			axios
				.get(`${BASE_URL}/nutritionScore/read`, { params: param })
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * 영양점수 만들기
	 * @param param
	 * @returns {Promise<unknown>}
	 */
	recordNutritionScore(param) {
		return new Promise((resolve) => {
			axios
				.post(`${BASE_URL}/nutritionScore/record`, param)
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * 영양점수 업데이트
	 * @param param
	 * @returns {Promise<unknown>}
	 */
	updateNutritionScore(param) {
		return new Promise((resolve) => {
			axios
				.post(`${BASE_URL}/nutritionScore/update`, param)
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	 *                                     [ statistics - 차트 ]
	 * ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	 */

	/**
	 * 영양소 기본 정보 가져오기
	 * @param {*} param
	 * @returns
	 */
	descriptionList(param) {
		return new Promise((resolve) => {
			axios
				.get(`${BASE_URL}/nutrition/descriptionList`, { params: param })
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * 기록 날짜별 섭취 기준량 가져오기
	 * @param {*} param
	 */
	nutritionRecordAt(param) {
		return new Promise((resolve) => {
			axios
				.get(`${BASE_URL}/nutrition/recordAt`, { params: param })
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * 기록 날짜 기준으로 섭취 음식 데이터 가져오기
	 * @param {*} param
	 */
	nutritionFoodRecordAt(param) {
		return new Promise((resolve) => {
			axios
				.get(`${BASE_URL}/nutrition/food/recordAt`, { params: param })
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * 기록 날짜 기준으로 섭취 음식 탄단지 랭킹 음식
	 * @param {*} param
	 */
	nutritionFoodRank(param) {
		return new Promise((resolve) => {
			axios
				.get(`${BASE_URL}/nutrition/food/rank`, { params: param })
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * 기록 날짜 기준으로 최신 신체 데이터 가져오기
	 * @param {*} param
	 */
	rangeBodyRecordAt(param) {
		return new Promise((resolve) => {
			axios
				.get(`${BASE_URL}/record/body/rangeToNew`, { params: param })
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * 기록 날짜 기준으로 신체 데이터 전체체 가져오기
	 * @param {*} param
	 */
	recordBodyRecordAt(param) {
		return new Promise((resolve) => {
			axios
				.get(`${BASE_URL}/record/body/recordAt`, { params: param })
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * 기록 날짜 기준으로 영양제 섭취 데이터 가져오기
	 * @param {*} param
	 */
	nutritionRecordAtAll(param) {
		return new Promise((resolve) => {
			axios
				.get(`${BASE_URL}/nutrition/nutrients/recordAtAll`, { params: param })
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * 기록 날짜 기준으로 영양제 섭취 카운팅 갯수 가져오기
	 * @param {*} param
	 */
	nutritionRecordAtCnt(param) {
		return new Promise((resolve) => {
			axios
				.get(`${BASE_URL}/nutrition/nutrients/recordAtCnt`, { params: param })
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * 다짐에 맞는 영양소 가져오기
	 * @param {*} param
	 */
	nutritientsPromiseList(param) {
		return new Promise((resolve) => {
			axios
				.get(`${BASE_URL}/nutrition/promiseList`, { params: param })
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * 다짐에 맞는 블로그 리스트 가져오기
	 * @param {*} param
	 */
	blogList(param) {
		return new Promise((resolve) => {
			axios
				.get(`${BASE_URL}/blog/list`, { params: param })
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	blogImage(param) {
		return new Promise((resolve) => {
			axios
				.get(`${BASE_URL}/file/fileSelect`, { params: param })
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	 *                                     [ TodayComment - 오늘의 한마디 ]
	 * ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	 */

	/**
	 * 오늘의 한마디 오늘기준으로 가져오기
	 * @param {*} param
	 */
	todayCommentNow(param) {
		return new Promise((resolve) => {
			axios
				.get(`${BASE_URL}/todaycomment/todayCommentNow`, { params: param })
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	 *                                     [ Banner - 배너 정보 ]
	 * ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	 */

	/**
	 * 배너정보 가져오기
	 * @param {*} param
	 */
	bannerList(param) {
		return new Promise((resolve) => {
			axios
				.get(`${BASE_URL}/banner/bannerList`, { params: param })
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	 *                                     [ recommendNutrients - 영양제 추천 ]
	 * ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	 */

	/**
	 * 기본 영양제 추천 (30대여성 기준) (2022.11.15)
	 */
	getRecommendNeedReasonDefault(param) {
		return new Promise((resolve) => {
			axios
				.get(
					`${BASE_URL}/recommendNeedReasonVertical/recommendNeedReasonVerticalList/default`,
					{ params: param }
				)
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("getActionLog getRecommendNutrientsListRenewal", error);
				});
		});
	}

	/**
	 * 사용자별 영양제 추천 (2022.09.21)
	 */
	getRecommendNeedReason(param) {
		return new Promise((resolve) => {
			axios
				.get(
					`${BASE_URL}/recommendNeedReasonVertical/recommendNeedReasonVerticalList`,
					{ params: param }
				)
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("getActionLog getRecommendNutrientsListRenewal", error);
				});
		});
	}

	/**
	 * 사용자별 영양제 추천 (2022.08.15)
	 * 영양제 추천 정보 가져오기 (신 버전)
	 */
	getRecommendNutrientsLists(param) {
		return new Promise((resolve) => {
			axios
				.get(`${BASE_URL}/admin/recommendNutrientsList`, { params: param })
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * 영양제 추천 정보 가져오기(구 버전)
	 * @param param
	 * @returns {Promise<unknown>}
	 */
	getRecommendNutrientsList(param) {
		return new Promise((resolve) => {
			axios
				.get(`${BASE_URL}/recommendSystem/get/nutrients`, { params: param })
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * 추천결과 가져오기
	 * @param param
	 * @returns {Promise<unknown>}
	 */
	getRecommendResult(param) {
		return new Promise((resolve) => {
			axios
				.get(`${BASE_URL}/recommendSystem/get/recommend/result`, {
					params: param,
				})
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * 추천결과 저장
	 * @param param
	 * @returns {Promise<unknown>}
	 */
	postRecommendNutrientsResult(param) {
		return new Promise((resolve) => {
			axios
				.post(`${BASE_URL}/recommendSystem/post/result`, param)
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * 영양소가이드 영양소 가져오기
	 * @param param
	 * @returns {Promise<unknown>}
	 */
	getRecommendNutrientResult(param) {
		return new Promise((resolve) => {
			axios
				.get(`${BASE_URL}/recommendSystem/get/recommend/nutrient/by/id`, {
					params: param,
				})
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * 추천 이유 데이터 가져오기
	 * @param param
	 * @returns {Promise<unknown>}
	 */
	getRecommendNeedReasonVerticalList(param) {
		return new Promise((resolve) => {
			axios
				.get(
					`${BASE_URL}/recommendNeedReasonVertical/recommendNeedReasonVerticalList`,
					{
						params: param,
					}
				)
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	 *                                     [ Search - 영양제 검색 ]
	 * ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	 */

	/**
	 * 상품 문의
	 * @returns {Promise<unknown>}
	 * searchType,
	 * searchId,
	 * offset,
	 * pageSize
	 */
	getProductQuestionList(param) {
		return new Promise((resolve) => {
			axios
				.get(`${BASE_URL}/userProductQuestionHistory/read/mall/productList`, {
					params: param,
				})
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	getProductCategoryAll() {
		return new Promise((resolve) => {
			axios
				.get(`${BASE_URL}/productCategory/level`)
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * 상품 카테고리 리스트 가져오기
	 * @param param category_level, category_parent
	 * @returns {Promise<unknown>}
	 */
	// getProductCategoryList(param) {
	//     return new Promise((resolve) => {
	//         axios
	//             .get(`${BASE_URL}/productCategory/read/mall`, {
	//                 params: param,
	//             })
	//             .then((res) => {
	//                 //성공
	//                 if (res.status === 200) {
	//                     resolve(res);
	//                     //실패
	//                 }
	//             })
	//             .catch((error) => {
	//                 this.catchLog("createActionLog", error);
	//             });
	//     });
	// }

	/**
	 * 상품 카테고리 리스트 가져오기 for together
	 * @param param category_level, category_parent
	 * @returns {Promise<unknown>}
	 */
	getProductCategoryList(param) {
		return new Promise((resolve) => {
			axios
				.get(`${BASE_URL}/productCategory/read/together`, {
					params: param,
				})
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * 카테고리 전체 리스트
	 * @param param
	 * @returns {Promise<unknown>}
	 */
	NutrientCategoryList() {
		return new Promise((resolve) => {
			axios
				.get(`${BASE_URL}/admin/CategoryList`)
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * 영양소 카테고리 검색 (2022.09.02)
	 * @param {string} nutrientCategory_id
	 * @returns {Promise<unknown>}
	 */
	getNutrientCategorySelect(param) {
		return new Promise((resolve) => {
			axios
				.get(`${BASE_URL}/admin/nutrientCategorySelect`, {
					params: param,
				})
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * 검색 기록 추가 (2022.09.02)
	 * @param UserSearchHistory
	 * @returns {Promise<unknown>}
	 */
	userSearchHistoryAdd(param) {
		return new Promise((resolve) => {
			axios
				.post(`${BASE_URL}/userSearchHistory/add`, param)
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * 검색 기록 가져오기 (2022.09.02)
	 * @param {integer} user_id
	 * @param {integer} product_yn
	 * @returns {Promise<unknown>}
	 */
	userSearchHistoryRead(param) {
		return new Promise((resolve) => {
			axios
				.get(`${BASE_URL}/userSearchHistory/read`, {
					params: param,
				})
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * 검색 기록 삭제하기 (2022.09.02)
	 * @param {integer} userSearchHistory_id
	 * @returns {Promise<unknown>}
	 */
	userSearchHistoryDelete(param) {
		return new Promise((resolve) => {
			axios
				.delete(`${BASE_URL}/userSearchHistory/delete/${param}`)
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * 찜 기록 추가 (2022.09.04)
	 * @param UserFavoriteHistory
	 * @returns {Promise<unknown>}
	 */
	userFavoriteHistoryAdd(param) {
		return new Promise((resolve) => {
			axios
				.post(`${BASE_URL}/userFavoriteHistory/add`, param)
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * 찜 기록 가져오기 (2022.09.04)
	 * @param {integer} user_id
	 * @param {integer} nutrients_yn (0:영양제,1:영양소, default : 0)
	 * @returns {Promise<unknown>}
	 */
	userFavoriteHistoryRead(param) {
		return new Promise((resolve) => {
			axios
				.get(`${BASE_URL}/userFavoriteHistory/read`, {
					params: param,
				})
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * 찜 기록 삭제하기 (2022.09.04)
	 * @param {integer} userFavoriteHistory_id
	 * @returns {Promise<unknown>}
	 */
	userFavoriteHistoryDelete(param) {
		return new Promise((resolve) => {
			axios
				.delete(`${BASE_URL}/userFavoriteHistory/delete/${param}`)
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * 찜 정보 list 가져오
	 * @param param
	 * @returns {Promise<unknown>}
	 */
	userFavoriteHistoryReadList(param) {
		return new Promise((resolve) => {
			axios
				.get(`${BASE_URL}/userFavoriteHistory/readList`, {
					params: param,
				})
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * New 검색 기록 가져오기 (2022.09.05)
	 * @param
	 *       searchType: "category",
	 *       searchText: "vitamin_multi",
	 *       user_id: user.id,
	 *       pageSize: 10,
	 *       offset: 0,
	 *       search_age: 3,
	 *       gender_id: 1,
	 *       orderType: "kpi",
	 *       orderBy: "desc",
	 * @returns {Promise<unknown>}
	 */
	// getSearchListNew(param) {
	//     return new Promise((resolve) => {
	//         axios.get(`${BASE_URL}/nutrientsKpi/search`, {
	//             params: param,
	//         })
	//             .then((res) => {
	//                 //성공
	//                 if (res.status === 200) {
	//                     resolve(res);
	//                     //실패
	//                 }
	//             })
	//             .catch((error) => {
	//                 this.catchLog("createActionLog", error);
	//             });
	//     });
	// }

	/**
	 * New 검색 기록 가져오기 (2022.09.05)
	 * @param
	 *       searchType: "category",
	 *       searchText: "vitamin_multi",
	 *       user_id: user.id,
	 *       pageSize: 10,
	 *       offset: 0,
	 *       search_age: 3,
	 *       gender_id: 1,
	 *       orderType: "kpi",
	 *       orderBy: "desc",
	 * @returns {Promise<unknown>}
	 */
	getSearchListNew(param) {
		return new Promise((resolve) => {
			axios
				.get(`${BASE_URL}/nutrientsKpi/together/search`, {
					params: param,
				})
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * 비교하기 추가 (2022.09.15)
	 * @param param
	 * @returns {Promise<unknown>}
	 */
	postCompareAdd(param) {
		return new Promise((resolve) => {
			axios
				.post(`${BASE_URL}/userNutrientsCompare/add`, param)
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * 비교하기 가져오기 (2022.09.15)
	 * @param param
	 * @returns {Promise<unknown>}
	 */
	getCompareRead(param) {
		return new Promise((resolve) => {
			axios
				.get(`${BASE_URL}/userNutrientsCompare/read`, {
					params: param,
				})
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * 비교하기 삭제 (2022.09.15)
	 * @param param
	 * @returns {Promise<unknown>}
	 */
	deleteCompareDel(param) {
		return new Promise((resolve) => {
			axios
				.delete(`${BASE_URL}/userNutrientsCompare/delete/${param}`)
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * 유저에 해당하는 비교하기 값 전부 삭제
	 * @param param
	 * @returns {Promise<unknown>}
	 */
	deleteCompareByUser(param) {
		return new Promise((resolve) => {
			axios
				.delete(`${BASE_URL}/userNutrientsCompare/delete/user/${param}`)
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	 *                                     [ Inquiry - Q&A ]
	 * ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	 */
	postInquiry(param) {
		return new Promise((resolve) => {
			axios
				.post(`${BASE_URL}/admin/inquiry/add`, param)
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	readInquiry(param) {
		return new Promise((resolve) => {
			axios
				.get(`${BASE_URL}/admin/user/inquiry/list`, {
					params: param,
				})
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	deleteInquiry(param) {
		return new Promise((resolve) => {
			axios
				.delete(`${BASE_URL}/admin/inquiry/delete/${param}`)
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	getInquiryAnswer(param) {
		return new Promise((resolve) => {
			axios
				.get(`${BASE_URL}/inquiry/answer/list`, {
					params: param,
				})
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	getMonthTotalCount(param) {
		return new Promise((resolve) => {
			axios
				.get(`${BASE_URL}/admin/inquiry/count/month/${param}`)
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	 */

	/**
	 * 제품 문의 등록하기
	 * @param param
	 * @returns {Promise<unknown>}
	 */
	addUserProductInquiry(param) {
		return new Promise((resolve) => {
			axios
				.post(`${BASE_URL}/userProductQuestionHistory/add`, param)
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * 상품별 문의 가져오기
	 * @param param searchType, searchId
	 * @returns {Promise<unknown>}
	 */
	readProductInquiry(param) {
		return new Promise((resolve) => {
			axios
				.get(`${BASE_URL}/userProductQuestionHistory/read/product/list`, {
					params: param,
				})
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * 유저별 문의 가져오기
	 * @param param user_id
	 * @returns {Promise<unknown>}
	 */
	readProductInquiryByUser(param) {
		return new Promise((resolve) => {
			axios
				.get(`${BASE_URL}/userProductQuestionHistory/read/user/list`, {
					params: param,
				})
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * 제품 문의 수정하기
	 * @param param
	 * @returns {Promise<unknown>}
	 */
	updateProductInquiry(param) {
		return new Promise((resolve) => {
			axios
				.post(`${BASE_URL}/userProductQuestionHistory/update`, param)
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * 제품 문의 삭제하기
	 * @param param userProductQ_id
	 * @returns {Promise<unknown>}
	 */
	deleteProductInquiry(param) {
		return new Promise((resolve) => {
			axios
				.delete(`${BASE_URL}/userProductQuestionHistory/delete/${param}`)
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	 *                                     [ inquiry - 게시판 ver ]
	 * ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	 */

	/**
	 * 추천 상담 가져오기
	 * @param param
	 * pageSize, offset
	 * @returns {Promise<unknown>}
	 */
	readNoticeInquiry(param) {
		return new Promise((resolve) => {
			axios
				.get(`${BASE_URL}/recommendInquiry/list`, {
					params: param,
				})
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * 추천 상담 한개 가져오기
	 * @param param inquiry_id
	 * @returns {Promise<unknown>}
	 */
	readNoticeInquiryOne(param) {
		return new Promise((resolve) => {
			axios
				.get(`${BASE_URL}/recommendInquiry/one`, {
					params: param,
				})
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * 추천 상담 작성하기
	 * @param param RecommendInquiry
	 * @returns {Promise<unknown>}
	 */
	createNoticeInquiry(param) {
		return new Promise((resolve) => {
			axios
				.post(`${BASE_URL}/recommendInquiry/create`, param)
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	 *                                     [ coupon - 쿠폰 ]
	 * ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	 */

	/**
	 * 쿠폰 리스트 가져오기
	 * @param param user_id
	 * @returns {Promise<unknown>}
	 */
	getCouponList(param) {
		return new Promise((resolve) => {
			axios
				.get(`${BASE_URL}/promotion/couponMaster/couponList/all`, {
					params: param,
				})
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * 인플루언서 쿠폰 가져오기
	 * @param param searchWord, user_id
	 * @returns {Promise<unknown>}
	 */
	getCouponListInfluencer(param) {
		return new Promise((resolve) => {
			axios
				.get(`${BASE_URL}/promotion/couponMaster/search`, {
					params: param,
				})
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * 다운 가능한 쿠폰 가져오기
	 * @param param
	 * order_id
	 * user_id
	 * event_id
	 * category_id
	 * brand_id
	 * product_id
	 * @returns {Promise<unknown>}
	 */
	getCouponReceiveProduct(param) {
		return new Promise((resolve) => {
			axios
				.get(`${BASE_URL}/promotion/couponMaster/couponList/applicable`, {
					params: param,
				})
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * 유저 쿠폰 등록
	 * @param param coupon_id, user_id
	 * @returns {Promise<unknown>}
	 */
	addUserCoupon(param) {
		return new Promise((resolve) => {
			axios
				.post(`${BASE_URL}/userCouponCart/add`, param)
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * 사용자별 쿠폰 가져오기
	 * @param param user_id
	 * @returns {Promise<unknown>}
	 */
	getUserCoupon(param) {
		return new Promise((resolve) => {
			axios
				.get(`${BASE_URL}/userCouponCart/couponList`, {
					params: param,
				})
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * 사용 가능한 쿠폰 가져오기
	 * @param param
	 * order_id, user_id, event_id, category_id, brand_id, product_id
	 * @returns {Promise<unknown>}
	 */
	getUsageCouponList(param) {
		return new Promise((resolve) => {
			axios
				.get(`${BASE_URL}/userCouponCart/applicable`, {
					params: param,
				})
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * 쿠폰 사용 가능 여부 업데이트
	 * @param param
	 * couponIdList: [],
	 * user_coupon_is_available: true/false
	 * created_by: user.id
	 * user_id: user.id
	 * @returns {Promise<unknown>}
	 */
	updateCouponAvailable(param) {
		return new Promise((resolve) => {
			axios
				.post(`${BASE_URL}/userCouponCart/changeAvailable`, param)
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	 *                                     [ Contents - 콘텐츠 ]
	 * ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	 */

	/**
	 * 추천 영양제 글이 있는지 확인 - ksb
	 * @returns {Promise<unknown>}
	 */
	checkRecommendNutrients(param) {
		return new Promise((resolve) => {
			axios
				.get(`${BASE_URL}/content/check/recommendNutrients`, { params: param })
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * 증상 목록 조회 - ksb
	 * @param param
	 * nutrients_id: ""
	 * @returns {Promise<unknown>}
	 */
	searchNutrientList(param) {
		return new Promise((resolve) => {
			axios
				.get(`${PLUTUS_BASE_URL}/nutrientsKpi/searchOption`, {
					params: param,
				})
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * 콘텐츠 리스트 가져오기
	 * @param param
	 * content_title: null,
	 * hashtag: null,
	 * is_exposure: true,
	 * exposure_place: null,
	 * category: null,
	 * sub_category: null,
	 * offset: 0,
	 * pageSize: 10,
	 * orderType: "created_at",
	 * orderBy: "desc"
	 * @returns {Promise<unknown>}
	 */
	readContentList(param) {
		return new Promise((resolve) => {
			axios
				.get(`${BASE_URL}/content/admin/list`, {
					params: param,
				})
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * 콘텐츠 리스트 가져오기 for Together admin - ksb
	 * @param param
	 * offset: 0,
	 * pageSize: 10,
	 * orderType: "created_at",
	 * orderBy: "desc"
	 * partnersId: 1
	 * @returns {Promise<unknown>}
	 */
	readContentListForTogetherAdmin(param) {
		return new Promise((resolve) => {
			axios
				.get(`${BASE_URL}/content/together/admin/list`, {
					params: param,
				})
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * 콘텐츠 전부 가져오기
	 * @returns {Promise<unknown>}
	 */
	readContentListAll() {
		return new Promise((resolve) => {
			axios
				.get(`${BASE_URL}/content/list/all`)
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * 콘텐츠 가져오기 for Togehter Admin - ksb
	 * @param param
	 * id
	 * @returns {Promise<unknown>}
	 */
	readContentOneForTogetherAdmin(param) {
		return new Promise((resolve) => {
			axios
				.get(`${BASE_URL}/content/together/admin/one`, {
					params: param,
				})
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * 콘텐츠 가져오기
	 * @param param
	 * id
	 * @returns {Promise<unknown>}
	 */
	readContentOne(param) {
		return new Promise((resolve) => {
			axios
				.get(`${BASE_URL}/content/mall/one`, {
					params: param,
				})
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * 콘텐츠 TopBanner 가져오기
	 * @returns {Promise<unknown>}
	 */
	readContentBanner() {
		return new Promise((resolve) => {
			axios
				.get(`${BASE_URL}/content/topBanner`)
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * searchCategory: [content > category] || null,
	 * searchSubCategory: [content > sub_category] || null
	 * @param param
	 * @returns {Promise<unknown>}
	 */
	readContentCategory(param) {
		return new Promise((resolve) => {
			axios
				.get(`${BASE_URL}/content/category`, {
					params: param,
				})
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * 콘텐츠 검색하기
	 * @param param
	 * Integer offset,
	 * Integer pageSize,
	 * String searchText,
	 * String searchType,
	 * Integer user_id,
	 * Integer search_age,
	 * Integer gender_id,
	 * String orderType,
	 * String orderBy
	 * @returns {Promise<unknown>}
	 */
	searchContent(param) {
		return new Promise((resolve) => {
			axios
				.get(`${BASE_URL}/nutrientsKpi/mall/search`, {
					params: param,
				})
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * 건강매거진(콘텐츠) 등록하기
	 * @param param
	 * @returns {Promise<unknown>}
	 */
	createContent(param) {
		return new Promise((resolve) => {
			axios
				.post(`${BASE_URL}/content/create/together`, param)
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * 건강매거진(콘텐츠) 수정하기
	 * @param param
	 * @returns {Promise<unknown>}
	 */
	editContent(param) {
		return new Promise((resolve) => {
			axios
				.post(`${BASE_URL}/content/edit/together`, param)
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * 건강매거진(콘텐츠) 삭제하기
	 * @param {*} param
	 */
	deleteContent(param) {
		return new Promise((resolve) => {
			axios
				.delete(`${BASE_URL}/content/delete/together`, { params: param })
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	 *                                     [ Point - 포인트 ]
	 * ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	 */

	/**
	 * 구매시 포인트 사용
	 * @param param user_id
	 * @returns {Promise<unknown>}
	 */
	purchasePointUse(param) {
		return new Promise((resolve) => {
			axios
				.post(`${BASE_URL}/point/point/use`, param)
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * 구매확정 포인트 적립
	 * @param param
	 * @returns {Promise<unknown>}
	 */
	purchasePointComplete(param) {
		return new Promise((resolve) => {
			axios
				.post(`${BASE_URL}/point/point/purchase/complete`, param)
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * 취소환불 포인트 환불
	 * @param param
	 * @returns {Promise<unknown>}
	 */
	purchasePointCancel(param) {
		return new Promise((resolve) => {
			axios
				.post(`${BASE_URL}/point/point/purchase/cancel`, param)
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * 리뷰 포인트 적립
	 * @param param
	 * user_id;
	 * point_score;
	 * point_type;
	 * point_type_detail;
	 * target_type;
	 * target_id;
	 * product_id;
	 * @returns {Promise<unknown>}
	 */
	reviewPointComplete(param) {
		return new Promise((resolve) => {
			axios
				.post(`${BASE_URL}/point/point/review/complete`, param)
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * 리뷰 포인트 차감
	 * @param param
	 * user_id;
	 * point_score;
	 * point_type;
	 * point_type_detail;
	 * target_type;
	 * target_id;
	 * product_id;
	 * @returns {Promise<unknown>}
	 */
	reviewPointCancel(param) {
		return new Promise((resolve) => {
			axios
				.post(`${BASE_URL}/point/point/review/cancel`, param)
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * 유저 포인트 이력 정보 찾기
	 * @param param
	 * user_id
	 * @returns {Promise<unknown>}
	 */
	getPointResult(param) {
		return new Promise((resolve) => {
			axios
				.get(`${BASE_URL}/point/point/history/search`, {
					params: param,
				})
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	 *                                     [ event - 이벤트 api ]
	 * ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	 */
	eventSurveyAdd(param) {
		return new Promise((resolve) => {
			axios
				.post(`${BASE_URL}/eventSurvey/add`, param)
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	eventSurveyRead(param) {
		return new Promise((resolve) => {
			axios
				.get(`${BASE_URL}/eventSurvey/read/${param}`)
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	eventSurveyDelete(param) {
		return new Promise((resolve) => {
			axios
				.delete(`${BASE_URL}/eventSurvey/delete/${param}`)
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	eventSurveyEdit(param) {
		return new Promise((resolve) => {
			axios
				.post(`${BASE_URL}/eventSurvey/edit`, param)
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	eventInquiryAdd(param) {
		return new Promise((resolve) => {
			axios
				.post(`${BASE_URL}/eventInquiry/add`, param)
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	readEventInquiry(param) {
		return new Promise((resolve) => {
			axios
				.get(`${BASE_URL}/eventInquiry/read/${param}`)
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	eventInquiryRemove(param) {
		return new Promise((resolve) => {
			axios
				.post(`${BASE_URL}/eventInquiry/delete/${param}`)
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	getEventInquiryAnswer(param) {
		return new Promise((resolve) => {
			axios
				.get(`${BASE_URL}/eventInquiry/answer/${param}`)
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	eventInquiryEdit(param) {
		return new Promise((resolve) => {
			axios
				.post(`${BASE_URL}/eventInquiry/edit`, param)
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	 *                                     [ INTERLOCK - 연동 ]
	 * ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	 */

	/**
	 * 연동정보 추가
	 * @param {*} param
	 */
	interlockAdd(param) {
		return new Promise((resolve) => {
			axios
				.post(`${BASE_URL}/interlock/add`, param)
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	interlockRead(param) {
		return new Promise((resolve) => {
			axios
				.get(`${BASE_URL}/interlock/read`, { params: param })
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	interlockNutrientsAdd(param) {
		return new Promise((resolve) => {
			axios
				.post(`${BASE_URL}/interlock/nutrients/add`, param)
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	interlockNutrientsDelete(param) {
		return new Promise((resolve) => {
			axios
				.post(`${BASE_URL}/interlock/nutrients/delete/${param}`)
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	interlockNutrientsRead(param) {
		return new Promise((resolve) => {
			axios
				.get(`${BASE_URL}/interlock/nutrients/read/${param.user_id}`)
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	nutrientsAllergyRead(param) {
		return new Promise((resolve) => {
			axios
				.get(`${BASE_URL}/admin/nutrientsAllergy`, { params: param })
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	 *                                     [ Notification - 알림 ]
	 * ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	 */

	/**
	 * 사용자의 맞는 공지사항 가져오기
	 * @param {*} param
	 */
	notificationList(param) {
		return new Promise((resolve) => {
			axios
				.get(`${BASE_URL}/notification/notificationList`, { params: param })
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * 사용자의 맞는 공지사항 읽음 처리
	 * @param {*} param
	 */
	notificationRead(param) {
		return new Promise((resolve) => {
			axios
				.post(`${BASE_URL}/notification/read`, param)
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	 *                                     [ CSCENTER - 고객센터 ]
	 * ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	 */

	/**
	 * CS 질문 정보 가져오기
	 * @param param user_id
	 * @returns {Promise<unknown>}
	 */
	getCsCenterRead(param) {
		return new Promise((resolve) => {
			axios
				.get(`${BASE_URL}/csCenter/read`, {
					params: param,
				})
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("getCsCenterRead", error);
				});
		});
	}

	/**
	 * CS 질문 추가
	 * @param
	 *       title: title,
	 *       category: categoryType,
	 *       company_name: formValue.company,
	 *       company_part: formValue.department,
	 *       company_contact: formValue.phone,
	 *       question_content: formValue.content,
	 *       created_at: now,
	 *       created_by: user.id,
	 *       updated_at: now,
	 *       updated_by: user.id,
	 * @returns {Promise<unknown>}
	 */

	addCsCenterQ(param) {
		return new Promise((resolve) => {
			axios
				.post(`${BASE_URL}/csCenter/add`, param)
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("addCsCenterQ", error);
				});
		});
	}

	/**
	 * ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	 *                                     [ Purchase - 상품 구매 ]
	 * ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	 */

	/**
	 * 카테고리 id 별 상품 가져오기
	 * @param param
	 * @returns {Promise<unknown>}
	 * offset,
	 * pageSize,
	 * categoryId,
	 * userId,
	 * orderType,
	 * orderBy
	 */
	// getProductItemCategory(param) {
	//     return new Promise((resolve) => {
	//         axios
	//             .get(`${BASE_URL}/productItem/list/byCategory`, {
	//                 params: param,
	//             })
	//             .then((res) => {
	//                 //성공
	//                 if (res.status === 200) {
	//                     resolve(res);
	//                     //실패
	//                 }
	//             })
	//             .catch((error) => {
	//                 this.catchLog("getCsCenterRead", error);
	//             });
	//     });
	// }

	/**
	 * 카테고리 id 별 상품 가져오기
	 * @param param
	 * @returns {Promise<unknown>}
	 * offset,
	 * pageSize,
	 * categoryId,
	 * userId,
	 * orderType,
	 * orderBy
	 */
	getProductItemCategory(param) {
		return new Promise((resolve) => {
			axios
				.get(`${BASE_URL}/productItem/list/together/byCategory`, {
					params: param,
				})
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("getCsCenterRead", error);
				});
		});
	}

	/**
	 * 구매 가능한 상품 리스트 가져오기
	 * @returns {Promise<unknown>}
	 */
	getProductItemList() {
		return new Promise((resolve) => {
			axios
				.get(`${BASE_URL}/productItem/availablePurchase`)
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("getCsCenterRead", error);
				});
		});
	}

	/**
	 * 프로덕트 아이템 가져오기
	 * @param param
	 * @returns {Promise<unknown>}
	 */
	getProductItemSearch(param) {
		return new Promise((resolve) => {
			axios
				.get(`${BASE_URL}/productItem/search`, {
					params: param,
				})
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("getCsCenterRead", error);
				});
		});
	}

	/**
	 * 브랜드별 상품 아이템 가져오기
	 * @param param
	 * @returns {Promise<unknown>}
	 */
	getBrandItemSearch(param) {
		return new Promise((resolve) => {
			axios
				.post(`${BASE_URL}/productItem/list/brandIdList`, param)
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("getCsCenterRead", error);
				});
		});
	}

	/**
	 * 연관 상품 가져오기
	 * @param param main_product_id
	 * @returns {Promise<unknown>}
	 */
	getProductRelated(param) {
		return new Promise((resolve) => {
			axios
				.get(`${BASE_URL}/productItem/relatedProduct/read`, {
					params: param,
				})
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("getCsCenterRead", error);
				});
		});
	}

	/**
	 * 판매자 리스트 (단건)
	 * @param param seller_id
	 * @returns {Promise<unknown>}
	 */
	getProductSellerList(param) {
		return new Promise((resolve) => {
			axios
				.get(`${BASE_URL}/productSeller/read`, {
					params: param,
				})
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("getCsCenterRead", error);
				});
		});
	}

	/**
	 * ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	 *                                     [ REVIEW - 리뷰 ]
	 * ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	 */

	/**
	 * 유저에 따른 리뷰 추가
	 * @param param
	 * @returns {Promise<unknown>}
	 */
	userReviewHistoryAdd(param) {
		return new Promise((resolve) => {
			axios
				.post(`${BASE_URL}/userReviewHistory/add`, param)
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * 유저에 따른 리뷰 수정
	 * @param param
	 * @returns {Promise<unknown>}
	 */
	userReviewHistoryEdit(param) {
		return new Promise((resolve) => {
			axios
				.post(`${BASE_URL}/userReviewHistory/edit`, param)
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * 리뷰 전체 가져오기
	 * @param param
	 * user_id
	 * @returns {Promise<unknown>}
	 */
	getUserReviewList(param) {
		return new Promise((resolve) => {
			axios
				.get(`${BASE_URL}/userReviewHistory/review/list`, {
					params: param,
				})
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * 리뷰 삭제
	 * @param param userReviewHistory_id
	 * @returns {Promise<unknown>}
	 */
	deleteUserReview(param) {
		return new Promise((resolve) => {
			axios
				.delete(`${BASE_URL}/userReviewHistory/delete/${param}`)
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	 *                                     [ CART - 장바구니 ]
	 * ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	 */

	/**
	 * 유저, 상품에 따른 장바구니 추가
	 * @param param
	 * @returns {Promise<unknown>}
	 */
	userProductItemCartAdd(param) {
		return new Promise((resolve) => {
			axios
				.post(`${BASE_URL}/productItem/cart/add`, param)
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * 유저에 따른 장바구니 조회
	 * @param param
	 * @returns {Promise<unknown>}
	 */
	userProductItemCartRead(param) {
		return new Promise((resolve) => {
			axios
				.get(`${BASE_URL}/productItem/cart/search`, { params: param })
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * 유저에 따른 장바구니 목록 판매자별로 나누기
	 * @param param
	 * @returns {Promise<unknown>}
	 */
	userProductItemCartBySellerRead(param) {
		return new Promise((resolve) => {
			axios
				.get(`${BASE_URL}/productItem/cart/searchBySeller`, { params: param })
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * 장바구니 선택 삭제
	 * @param param
	 * @returns {Promise<unknown>}
	 */
	userProductItemCartSelectDelete(param) {
		return new Promise((resolve) => {
			axios
				.delete(`${BASE_URL}/productItem/cart/delete/${param}`)
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	updateUserCart(param) {
		return new Promise((resolve) => {
			axios
				.post(`${BASE_URL}/productItem/cart/updateUser`, param)
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * 상품 및 콘텐츠 클릭수
	 */
	updateClickTarget(param) {
		return new Promise((resolve) => {
			axios
				.post(`${BASE_URL}/clickHistory/create`, param)
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	 *                                     [ Together - 당영투게더 ]
	 * ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	 */

	/**
	 * 상품 링크 조회 (2023.03.19 - ksb
	 */
	getProductLinkList(param) {
		return new Promise((resolve) => {
			axios
				.get(`${BASE_URL}/productLink/list`, { params: param })
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * 상품 링크 중복 체크 확인 (2023.03.16) - ksb
	 * @param param
	 * query
	 * @returns {Promise<unknown>}
	 */
	checkDuplicateProductLink(param) {
		return new Promise((resolve) => {
			axios
				.get(`${BASE_URL}/productLink/duplicate/link`, { params: param })
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * 상품 링크 발급 (2023.03.16) - ksb
	 * @param param
	 * product_id, user_id, query
	 * @returns {Promise<unknown>}
	 */
	issueProductLink(param) {
		return new Promise((resolve) => {
			axios
				.post(`${BASE_URL}/productLink/issue`, param)
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	 *                                     [ COMMON - 공통 ]
	 * ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	 */

	/**
	 * 파일 디비 추가
	 * @param {*} param
	 */
	fileAdd(param) {
		return new Promise((resolve) => {
			axios
				.post(`${BASE_URL}/file/add`, param)
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * 파일 디비 수정
	 * @param {*} param
	 */
	fileEdit(param) {
		return new Promise((resolve) => {
			axios
				.post(`${BASE_URL}/file/edit`, param)
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * 파일 디비 삭제
	 * @param {*} param
	 */
	fileDelete(param) {
		return new Promise((resolve) => {
			axios
				.post(`${BASE_URL}/file/delete`, param)
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * 파일 불러오기
	 * @param {*} param
	 * @returns
	 */
	fileSelect(param) {
		return new Promise((resolve) => {
			axios
				.get(`${BASE_URL}/file/fileSelect`, { params: param })
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * 국가 정보 가져오기
	 * @returns
	 */
	getLocations() {
		return new Promise((resolve) => {
			axios
				.get(`https://api.ip.pe.kr/json`)
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * 대시보드 - 금일 클릭 실적
	 * @returns
	 */
	getPartnersClickSummaryForDashboard(param) {
		return new Promise((resolve) => {
			axios
				.get(`${BASE_URL}/partnersSummary/dashboard/read/click`, {
					params: param,
				})
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * 대시보드 - 기간별 클릭 실적
	 * @returns
	 */
	getPartnersClickSummaryForDashboardByPeriod(param) {
		return new Promise((resolve) => {
			axios
				.get(`${BASE_URL}/partnersSummary/dashboard/read/click/period`, {
					params: param,
				})
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * 대시보드 - 유형별 사용자 조회
	 * @returns
	 */
	getUserInfoByType(param) {
		return new Promise((resolve) => {
			axios
				.get(`${BASE_URL}/partners/read/userInfo/type`, {
					params: param,
				})
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * 대시보드 - 골드 전환하기
	 * @returns
	 */
	goldChange(param) {
		return new Promise((resolve) => {
			axios
				.post(`${BASE_URL}/settlement/gold/request`, param)
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * 유형별 사용자 정보 수정
	 * @returns
	 */
	editUserByKey(param) {
		return new Promise((resolve) => {
			axios
				.post(`${BASE_URL}/partners/edit/user/byKey`, param)
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * 리포트 - 수익 - 클릭 - 통계값 조회
	 * @returns
	 */
	getClickSummaryStatistics(param) {
		return new Promise((resolve) => {
			axios
				.get(`${BASE_URL}/partnersSummary/read/summary/click/statistics`, {
					params: param,
				})
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * 리포트 - 수익 - 클릭 - 상품 리스트
	 * @returns
	 */
	getClickSummaryProducts(param) {
		return new Promise((resolve) => {
			axios
				.get(`${BASE_URL}/partnersSummary/read/summary/click`, {
					params: param,
				})
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * 리포트 - 수익 - 친구추천 - 통계값 조회
	 * @returns
	 */
	getRecommendSummaryStatistics(param) {
		return new Promise((resolve) => {
			axios
				.get(`${BASE_URL}/partnersSummary/read/summary/recommend/statistics`, {
					params: param,
				})
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * 리포트 - 수익 - 친구추천 내역
	 * @returns
	 */
	getRecommendSummary(param) {
		return new Promise((resolve) => {
			axios
				.get(`${BASE_URL}/partnersSummary/read/summary/recommend`, {
					params: param,
				})
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * 리포트 - 정산 리스트
	 * @returns
	 */
	getGoldSettlementList(param) {
		return new Promise((resolve) => {
			axios
				.get(`${BASE_URL}/settlement/gold/list`, {
					params: param,
				})
				.then((res) => {
					//성공
					if (res.status === 200) {
						resolve(res);
						//실패
					}
				})
				.catch((error) => {
					this.catchLog("createActionLog", error);
				});
		});
	}

	/**
	 * Api exception 로그 통합
	 * @param {*} methodName
	 * @param {*} error
	 */
	catchLog = (methodName, error) => {
		console.log(error);

		//토큰 인증 만료 시 로그아웃 처리
		if (error.response?.status === 401) {
			this.reLogin();
		}
		console.log(`======= ${methodName} catch log START =======`);

		if (error.response) {
			// 요청이 이루어졌으며 서버가 2xx의 범위를 벗어나는 상태 코드로 응답했습니다.
			console.log("E01");
			console.log(error.response.data);
			console.log(error.response.status);
			console.log(error.response.headers);
		} else if (error.request) {
			// 요청이 이루어 졌으나 응답을 받지 못했습니다.
			// `error.request`는 브라우저의 XMLHttpRequest 인스턴스 또는
			// Node.js의 http.ClientRequest 인스턴스입니다.
			console.log("E02");
			console.log(error.request);
		} else {
			// 오류를 발생시킨 요청을 설정하는 중에 문제가 발생했습니다.
			console.log("E03");
			console.log(error.message);
		}

		console.log(`======= ${methodName} catch log END =========`);
	};

	/**
	 * Api Result Error 로그 통합
	 * @param {*} methodName
	 * @param {*} result
	 */
	resultErrorLog = (methodName, result) => {
		console.log(`======= ${methodName} result error log START =======`);
		console.log(result);
		console.log(`======= ${methodName} result error log END =========`);
	};

	/**
	 * 토큰 만료시 다시 로그인
	 */
	reLogin = () => {
		document.location.href = "/logout";
	};

	/**
	 * 토큰 재갱신 확인
	 */
	reToken = (header) => {
		console.log(header);
	};
}

export default ApiService;
