import "../../Gold.scss";
import * as React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

import { Button, Input, Modal } from "antd";

import ApiService from "../../utils/ApiService";
import SignUpHeader from "../user/SignUpHeader";

import CheckGoldUnitModal from "./CheckGoldUnitModal";
import EarnGoldCheckModal from "./EarnGoldCheckModal";
import LessGoldModal from "./LessGoldModal";

const api = new ApiService();

const GoldSettlementRequestComponent = () => {
	const history = useHistory();
	const location = useLocation();
	const state = location.state;
	const user = useSelector((state) => state.auth.user);

	const [loading, setLoading] = useState(false);
	const [changeGold, setChangeGold] = useState(0);
	const [showCheckGoldUnitModal, setShowCheckGoldUnitModal] = useState(false); // 10 Gold 단위
	const [showLessGoldModal, setShowLessGoldModal] = useState(false); // 100,000 Gold 이상 정산 요청 가능
	const [showCheckGoldModal, setShowCheckGoldModal] = useState(false); // 보유 골드 확인 요청
	const [isShowModalNextMonth, setIsShowModalNextMonth] = useState(false); // 익월 정산 요청 가능

	const changeGoldReq = async () => {
		// 10 골드 -> 1원 -->> 1의자리 골드 막아야 함
		if (changeGold % 10 !== 0) {
			setShowCheckGoldUnitModal(true);
			return;
		}
		setLoading(true);
		const param = {
			created_by: user?.id,
			settlement_amount: Number(changeGold) / 10,
			bank_code: state?.bankInfo.bank_code,
			account_number: state?.bankInfo.account_number,
		};

		const res = await api.goldChange(param);

		if (res.data.code === 200) {
			history.replace("/gold/settlement/complete", {
				changeGold: changeGold,
			});
		} else if (res.data.code === 405) {
			setShowLessGoldModal(true);
		} else if (res.data.code === 423) {
			setShowCheckGoldModal(true);
		} else if (res.data.code === 418) {
			setIsShowModalNextMonth(true);
		}

		setLoading(false);
	};

	return (
		<div
			className="main-wrap"
			style={{
				overflow: "scroll",
			}}
		>
			{/* 모바일 헤더 추가 */}
			<SignUpHeader
				title={"정산하기"}
				back={true}
				close={false}
				handleGoBack={() => history.replace("/dashboard")}
			/>
			<div className={`gold-settlement-request-wrap`}>
				<div className={`gold-settlement-request-form-wrap`}>
					<div className={`gold-settlement-request-item`}>
						<p className={`gold-info-text`}>
							정산 가능한 골드는{" "}
							<span>{state?.goldInfo.settled_gold?.toLocaleString() ?? 0}</span>{" "}
							Gold입니다.
						</p>
						<div>
							<div className={`gold-input-wrap`}>
								<Input
									placeholder={`정산`}
									suffix={"Gold"}
									onChange={(e) => {
										if (isNaN(e.currentTarget.value.replaceAll(",", ""))) {
											return;
										} else if (
											state?.goldInfo.settled_gold <
											Number(e.currentTarget.value.replaceAll(",", ""))
										) {
											setChangeGold(state?.goldInfo.settled_gold.toString());
										} else {
											setChangeGold(e.currentTarget.value.replaceAll(",", ""));
										}
									}}
									value={changeGold ? Number(changeGold).toLocaleString() : ""}
								/>
								<Button
									className={`full-settlement-button`}
									disabled={
										changeGold === state?.goldInfo.settled_gold.toString() ||
										!state?.goldInfo.settled_gold
									}
									onClick={() =>
										setChangeGold(state?.goldInfo.settled_gold.toString())
									}
								>
									전액 정산
								</Button>
							</div>
							<div className="help-text-wrap">
								<p>* 100 Gold = 10원</p>
								<p>* 100,000 Gold 이상부터 정산 가능합니다</p>
							</div>
						</div>
					</div>
					<div className={`divider`} />
					<div className={`gold-settlement-request-item`}>
						<div className={`gold-settlement-request-input-wrap`}>
							<label>은행</label>
							<Input disabled value={state?.bankInfo.account_number} />
						</div>
						<div className={`gold-settlement-request-input-wrap`}>
							<label>계좌번호</label>
							<Input disabled value={state?.bankInfo.bank_name} />
						</div>
					</div>
				</div>
				<div className={`product-link-btn-wrap`}>
					<Button
						loading={loading}
						className={`confirm`}
						onClick={() => {
							if (!loading) {
								changeGoldReq();
							}
						}}
					>
						{!loading && "정산"}
					</Button>
					<Button
						className={`cancel`}
						disabled={loading}
						onClick={() => {
							history.replace("/dashboard");
						}}
					>
						취소
					</Button>
				</div>
			</div>

			{/*모달 - 10 골드 단위 정산 가능*/}
			<CheckGoldUnitModal
				show={showCheckGoldUnitModal}
				setShow={setShowCheckGoldUnitModal}
			/>
			{/*모달 - 100,000 골드 이상 정산 가능*/}
			<LessGoldModal show={showLessGoldModal} setShow={setShowLessGoldModal} />
			{/*모달 - 보유 골드 확인*/}
			<EarnGoldCheckModal
				show={showCheckGoldModal}
				setShow={setShowCheckGoldModal}
			/>
			{/* 모달 - 익월 정산 가능 */}
			<Modal
				title=""
				open={isShowModalNextMonth}
				footer={null}
				closable={false}
				wrapClassName="modal-wrap seller-grade-modal-wrap custom-modal-wrap"
				width={400}
				centered={true}
			>
				<div className="modal-title" style={{ marginBottom: 0 }}>
					익월 정산 요청가능합니다.
				</div>
				<div className="modal-title-sub">*정산은 한달에 한번만 가능합니다.</div>
				<div className="modal-button-wrap">
					<div className="modal-button-success" onClick={() => {}}>
						확인
					</div>
				</div>
			</Modal>
		</div>
	);
};

export default GoldSettlementRequestComponent;
