import { useEffect } from "react";

import SignUpHeader from "../../components/user/SignUpHeader";

const Policy = ({ pageType }) => {
	useEffect(() => {
		if (!(pageType && pageType === "component")) {
			window.scrollTo({ top: 0 });
		}
	}, []);

	return (
		<>
			{pageType && pageType === "component" ? (
				<div className={`product-body`}>
					<p
						style={{
							color: "#979797",
							fontWeight: 600,
							fontSize: "1.2em",
							lineHeight: "1.2",
						}}
					>
						[필수] 개인정보 처리방침
					</p>
					<p
						style={{
							whiteSpace: "pre-line",
							fontSize: "1em",
							color: "#979797",
							fontWeight: 500,
							lineHeight: "1.2",
						}}
					>
						{/*개인정보 처리방침{`\n`}*/}
						{/*{`\n`}*/}
						휴대전화번호, 성별, 생년월일과 같은 개인정보를 식별 및 맞춤 서비스를
						제공하기 위해 수집하고 있으며, 요건만 갖춘 사람만 정보에 접근할 수
						있으며, 해킹등으로부터 유출을 방지하기 위해 기술적 장치들을 갖추고
						있습니다. 또한, 법에서 규정한 기간이후 복원불가능한 방법으로
						파기하고 있습니다.{`\n`}
						{`\n`}
						하우투약은 개인정보보호법, 정보통신망 이용촉진 및 정보보호 등에 관한
						법률 등 관련 법률에서 정보통신서비스제공자가 준수하여야 할
						개인정보보호 관련사항을 아래와 같이 준수하고 있습니다.{`\n`}
						{`\n`}
						1.개인정보처리방침이란{`\n`}
						{`\n`}
						개인정보처리방침이란 하우투약이 어떤 개인정보를 수집하고, 수집한
						정보를 어떻게 이용하며, 필요에 따라 누구와 공유하는지, 이용목적이
						달성된 개인정보를 언제, 어떻게 파기하는지 등 개인정보 라이프사이클에
						관한 정보를 제공합니다.또한, 이용자가 자신의 개인정보에 대해 어떠한
						권리를 가지고 있으며, 이를 어떻게 행사할 수 있는지, 개인정보
						침해사고가 발생할 경우 피해예방을 위해 누구에게 연락하고 도움을 받을
						수 있는지도 알려드립니다.{`\n`}
						개인정보처리방침은 이와 같이 이용자의 개인정보에 대한 라이프사이클과
						더불어 하우투약이 이용자에게 제공하는 영양관리 서비스
						당신의영양제(이하 “서비스”라 합니다)에서 하우투약과 이용자의
						권리/의무 이용자의 개인정보 자기결정권을 보장하는 역할을 합니다.
						{`\n`}
						{`\n`}
						{`\n`}
						2.수집하는 개인정보의 항목 및 수집방법{`\n`}
						{`\n`}
						개인정보 수집에 대한 동의{`\n`}
						하우투약은 고객님께서 개인정보취급방침 또는 이용약관의 내용에 대해
						「동의합니다」 또는 「동의하지 않습니다」 버튼을 클릭할 수 있는
						절차를 마련하고 있으며,「동의합니다」 버튼을 클릭하면 개인정보
						수집에 대해 동의한 것으로 봅니다.{`\n`}
						하우투약이 서비스 이용과 관련하여 수집하는 개인정보의 범위는 아래와
						같습니다.{`\n`}
						{`\n`}
						[필수 입력 사항] 이메일, 휴대전화번호, 비밀번호, 로그인ID, 성별,
						생년월일, 이름, 서비스 이용 기록, 접속 로그, 쿠키, 접속 IP 정보
						{`\n`}
						[선택 입력 사항] 취미, 신체정보{`\n`}
						{`\n`}
						하우투약은 이용자가 플랫폼사업자(예:카카오 등)의 서비스에서 사용하는
						이용자의 닉네임(이름), 회원번호, 친구목록, 프로필 사진을
						플랫폼사업자(예 : 카카오 등)로부터 제공 받으며, 플랫폼사업자는
						이러한 정보를 이용자가 서비스를 이용하는 시점에 정보통신망법에 따른
						개인정보 제공 동의를 받아 하우투약에게 제공합니다.{`\n`}
						하우투약은 플랫폼사업자로부터 제공받는 개인정보 외에 서비스를
						제공하는 과정에서 혹은 이용자가 서비스를 이용하는 과정에서 서비스
						이용기록, 접속로그, 불량이용기록, 통신사 정보, OS정보 및 기기정보,
						언어 정보, 결제기록, 쿠키, 접속IP정보, 이용정지기록을
						수집(저장)합니다.{`\n`}
						또한, 이용자가 서비스에서 진행하는 이벤트에 응모하거나 경품을
						신청하는 경우 혹은 서비스 이용 문의 과정에서 추가 정보가 수집될 수
						있습니다. 이 경우 하우투약은 수집하는 개인정보, 이용목적, 보관기간
						등을 이용자에게 안내하고 별도 동의를 받아 수집합니다.{`\n`}
						하우투약은 아래와 같은 방법으로 개인정보를 수집합니다.{`\n`}
						홈페이지, 모바일 애플리케이션, 서면양식, 전화, 팩스를 통한 회원가입,
						고객센터 상담(전화 또는 이메일), 제휴사로부터의 제공, 이벤트 응모,
						서비스 이용 기록 수집 툴을 통한 수집, 협력{`\n`}
						{`\n`}
						[사용자 분석 사항]{`\n`}
						당사는 서비스를 방문하는 사용자 수, 가장 많이 요청된 정보 및
						사용자와 서비스의 상호 작용 방법 등, 당사 서비스와 사용자의 상호
						작용을 여러 가지 방법으로 분석합니다.{`\n`}
						당사가 수집하는 정보에는 참조하는 웹 사이트, 당사 서비스의 내부
						페이지 접근 횟수 및 빈도, 페이지 조회 시간 등이 포함됩니다. 이를
						통해 당사는 서비스에 대한 사용자의 경험과 서비스를 개선할 수
						있습니다. 정보는 가명으로 처리됩니다.{`\n`}
						법적 근거: GDPR 제6조 (1)항 f호{`\n`}
						{`\n`}
						(1) Google 애널리틱스{`\n`}
						Google은 당신의영양제 사용자가 서비스와 상호 작용하는 방식을 이해할
						수 있도록 Google Analytics를 사용합니다.{`\n`}
						Google은 이 데이터를 사용하여 어떤 페이지가 가장 인기가 있는지, 어떤
						기능이 사용자에게 작동하는지 (또는 작동하지 않는지), 사이트가 다른
						위치 및 언어에서 얼마나 빠르거나 느린지를 결정합니다.{`\n`}
						Google에 보내는 모든 데이터는 익명 처리됩니다. 우리는 사이트에서
						귀하 또는 다른 사용자를 개인적으로 식별하는 데 사용할 수 있는 정보를
						보내지 않습니다.{`\n`}
						귀하의 웹 사이트 사용 (귀하의 IP 주소 포함)에 대한 쿠키에 의해
						생성된 정보는 Google 서버에 전송되어 미국의 서버에 저장됩니다.
						{`\n`}
						Google은 귀하의 웹 사이트 사용을 평가하고 보고서를 작성하기 위해이
						정보를 사용합니다.{`\n`}웹 사이트 운영자를 위한 웹 사이트 활동 및 웹
						사이트 활동 및 인터넷 사용과 관련된 기타 서비스 제공.{`\n`}
						Google은 법에 의해 요구되는 경우 또는 제3자가 Google을 대신하여
						정보를 처리하는 경우 제 3자에게 이 정보를 전송할 수 있습니다.
						{`\n`}
						Google은 IP 주소를 Google이 보유한 다른 데이터와 연결하지 않습니다.
						{`\n`}
						브라우저에서 적절한 설정을 선택하여 쿠키 사용을 거부할 수 있지만 이
						경우이 웹 사이트의 모든 기능을 사용하지 못할 수도 있습니다.
						{`\n`}이 웹 사이트를 사용함으로써 위에 명시된 방식 및 목적으로
						귀하가 Google에서 귀하의 데이터를 처리하는 데 동의하게 됩니다.
						{`\n`}
						{`\n`}
						Google 애널리틱스 인구 통계 및 관심 분야 보고서 -이 기능을 통해
						방문자 나이, 성별 및 관심 분야와 관련된 행동 정보를 익명 및 총체적
						수준에서 파악할 수 있습니다.{`\n`}이 정보는 특정 사용자의 레벨에서
						제공되지 않습니다. 이 기능은 Google 사이트를 방문하는 동안
						사용자에게 더 나은 경험을 제공하기 위해 탐색 행동을 이해하는 데
						도움이 됩니다.{`\n`}
						Google 애널리틱스를 통한 DoubleClick 플랫폼 및 리 마케팅 -이 기능은
						웹 분석 및 유료 광고 플랫폼을 통합하여 추가적인 통찰력을 제공합니다.
						{`\n`}
						이를 통해 우리는 광고를 더 잘 맞추고 다른 사이트를 방문하는 동안
						광고를 볼 수 있습니다.{`\n`}
						{`\n`}
						(2) Google 태그 매니저{`\n`}
						당사는 투명성을 위해 Google 태그 매니저를 사용하고 있음을
						알려드립니다.
						{`\n`}
						Google 태그 매니저는 자체적으로 개인 식별 정보를 수집하지 않습니다.
						태그 매니저를 사용하면 태그를 쉽게 통합하고 관리할 수 있습니다.
						{`\n`}
						태그는 트래픽 및 사용자 행동을 측정하고, 온라인 광고 및 소셜 채널의
						효과를 기록하며, 리마케팅을 설정하고, 대상 그룹에 집중하며,
						웹사이트와 다른 사항 간에 테스트하고 최적화하는 데 사용되는 소형의
						코드 요소입니다.
						{`\n`}
						비활성화하는 경우 Google 태그 매니저에서 이를 고려하게 됩니다.
						Google 태그 매니저에 관한 자세한 내용은 다음을 참조해주세요.{`\n`}
						https://www.google.com/analytics/tag-manager/use-policy/{`\n`}
						{`\n`}
						{`\n`}
						(3) Hotjar{`\n`}본 서비스를 최적화하기 위해 HotjarL Ltd.(
						https://www.hotjar.com)의 기록을 사용해 데이터를 수집하고
						저장합니다.
						{`\n`}이 데이터는 익명으로 사용 프로필을 생성하는 데 사용할 수
						있습니다. 쿠키는 해당 용도로 사용될 수 있습니다. Hotjar 기술로
						수집된 데이터는 이 웹사이트 방문자를 개인적으로 식별하기 위해
						사용되지 않으며, 해당 개인의 명시적인 동의 없이 가명 소지자에 관한
						개인 식별 정보와 결합되지 않습니다. {`\n`}
						귀하는 Hotjar 수신 거부 페이지
						(https://www.hotjar.com/legal/compliance/opt-out)에서 ‘Disable
						Hotjar’를 클릭하거나 브라우저의 Do Not Track을 활성화함으로써 Hotjar
						사용 가능 사이트를 방문할 때 Hotjar가 귀하의 정보를 수집하지
						못하도록 선택할 수 있습니다.{`\n`}
						{`\n`}
						하우투약은 이용자가 플랫폼사업자(예:카카오 등)의 서비스에서 사용하는
						이용자의 닉네임(이름), 회원번호, 친구목록, 프로필 사진을
						플랫폼사업자(예:카카오 등)로부터 제공 받으며, 플랫폼사업자는 이러한
						정보를 이용자가 서비스를 이용하는 시점에 정보통신망법에 따른
						개인정보 제공 동의를 받아 하우투약에게 제공합니다.{`\n`}
						하우투약은 플랫폼사업자로부터 제공받는 개인정보 외에 서비스를
						제공하는 과정에서 혹은 이용자가 서비스를 이용하는 과정에서 서비스
						이용기록, 접속로그, 불량이용기록, 통신사 정보, OS정보 및 기기정보,
						언어 정보, 결제기록, 쿠키, 접속IP정보, 이용정지기록을
						수집(저장)합니다.{`\n`}
						또한, 이용자가 서비스에서 진행하는 이벤트에 응모하거나 경품을
						신청하는 경우 혹은 서비스 이용 문의 과정에서 추가 정보가 수집될 수
						있습니다. 이 경우 하우투약은 수집하는 개인정보, 이용목적, 보관기간
						등을 이용자에게 안내하고 별도 동의를 받아 수집합니다.{`\n`}
						하우투약은 아래와 같은 방법으로 개인정보를 수집합니다.{`\n`}
						홈페이지, 모바일 애플리케이션, 서면양식, 전화, 팩스를 통한 회원가입,
						고객센터 상담(전화 또는 이메일), 제휴사로부터의 제공, 이벤트 응모,
						서비스 이용 기록 수집 툴을 통한 수집, 협력{`\n`}
						{`\n`}
						{`\n`}
						3.수집한 개인정보의 이용{`\n`}
						{`\n`}
						하우투약은 수집한 개인정보를 아래와 같이 이용합니다.{`\n`}
						서비스 제공에 필요한 회원 식별 및 친구초대, 친구추천, 유료 멤버십
						구매, 결제, 알림 서비스 제공 등을 위해 개인정보를 이용합니다.
						{`\n`}
						그리고, 가입 의사 또는 탈퇴 의사 확인, 서비스 이용에 따른 이용자
						확인 및 식별, 약관 또는 이용정책을 위반하는 이용자의 이용제한조치 등
						서비스 운영에 지장을 주는 행위 방지 및 부정이용방지, 분쟁조정을 위한
						기록보존, 민원처리, 공지사항 전달 등을 위해 개인정보를 이용합니다.
						{`\n`}
						또한, 서비스 이용기록 분석 및 통계와 그에 따른 맞춤형 서비스(상담 및
						광고 포함) 제공, 인구통계학적 특성에 따른 서비스 제공 및 맞춤형 광고
						게재, 서비스 개발/제공 또는 업데이트, 이벤트 또는 광고성 정보 제공
						등 위해 개인정보를 이용합니다.{`\n`}
						{`\n`}
						{`\n`}
						4.개인정보의 제공 및 위탁{`\n`}
						하우투약은 이용자의 개인정보를 수집 및 이용 목적 범위 내에서
						이용하며, 이용자의 사전 동의 없이는 그 범위를 초과하여 이용하거나
						제공하지 않습니다. 다만, 아래의 경우에는 주의를 기울여 개인정보를
						이용 또는 제공할 수 있습니다.{`\n`}
						하우투약은 개인정보 수집 또는 제공 이전에 이용자에게 수집/제공하는
						파트너가 누구인지, 필요한 정보가 무엇인지, 언제까지 보관하는지를
						알려드리고 동의를 구하며, 이용자가 동의를 하지 않는 경우에는 수집
						또는 제공하지 않습니다.{`\n`}
						하우투약은 법률에 특별한 규정이 있는 경우 또는 수사기관이 수사
						목적으로 법률에 정해진 절차와 방법에 따라 요구하는 경우에는 제공할
						수 있습니다.
						{`\n`}
						{`\n`}
						{`\n`}
						5.광고성 정보 전송의 제한{`\n`}
						{`\n`}
						하우투약은 회원의 명시적인 수신거부 의사에 반하여 영리목적의 광고성
						정보를 전송하지 않습니다. 회원이 뉴스레터 등 전자우편 전송에 대한
						동의를 한 경우 전자우편의 제목란 및 본문란에 다음 사항과 같이 회원이
						쉽게 알아볼 수 있도록 조치합니다.{`\n`}
						전자우편의 제목란: (광고)라는 문구를 제목란에 표시하지 않을 수
						있으며, 전자우편 본문란의 주요 내용을 표시합니다.{`\n`}
						전자우편의 본문란: 수신거부의 의사표시를 할 수 있는 전송자의 명칭,
						전자우편주소, 전화번호 및 주소를 명시하며 수신 거부의 의사를 쉽게
						표시할 수 있는 방법 및 회원이 동의를 한 시기 및 내용을 명시합니다.
						{`\n`}
						팩스, 휴대폰 문자전송 등 전자우편 이외의 문자전송을 통해 영리목적의
						광고성 정보를 전송하는 경우에는 전송내용 처음에 (광고)라는 문구를
						표기하고 전송내용 중에 전송자의 연락처를 명시하도록 조치합니다.
						{`\n`}
						회원은 영리목적의 광고성 정보를 전송받은 경우 언제든지 이에 대해
						수신거부의 의사표시를 할 수 있고, 하우투약은 즉각 전송중단의 조치를
						취한 후 이를 회원에게 알립니다.{`\n`}
						{`\n`}
						{`\n`}
						6. 개인정보 파기{`\n`}
						{`\n`}
						하우투약은 원칙적으로 이용자가 서비스에서 탈퇴하는 경우 이용자의
						개인정보를 지체없이 파기합니다. 다만, 이용자에게 개인정보의
						보관기간에 대해 별도 동의를 받은 경우 또는 관련 법률에서 일정
						기간동안 보관의무를 명시하고 있는 경우에는 해당 기간동안 보관 후
						지체없이 파기합니다. 또한, 정보통신망 이용촉진 및 정보보호 등에 관한
						법률에 따라 1년간 서비스를 이용하지 않은 이용자의 개인정보는 별도로
						분리하여 보관하며, 분리 보관된 개인정보는 5년간 보관 후 지체없이
						파기합니다.하우투약은 이용자의 서비스 탈퇴, 서비스 종료 혹은
						보관기간 도래 등과 같은 개인정보의 수집 및 이용목적이 달성된
						개인정보에 대해서는 복구 및 재생이 불가능한 방법으로 파기합니다.
						인쇄물 등 파쇄가 가능한 형태인 경우에는 분쇄기 등을 이용하여
						재조합이 불가능하도록 물리적으로 파쇄 하거나 소각 등을 하며, 전자적
						파일 형태로 저장된 개인정보는 재생할 수 없는 기술적
						방법(예:로우레벨포멧, 와이핑 등)을 사용하여 파기 합니다.
						{`\n`}
						관련 법률에서 일정 기간동안 개인정보의 보관을 규정하고 있는 경우는
						아래와 같습니다. 하우투약은 관련 법률에서 정한 기간 동안 개인정보를
						보관하며, 보관 목적 이외의 다른 목적으로는 사용하지 않습니다.{`\n`}
						{`\n`}
						[전자상거래 등에서의 소비자 보호에 관한 법률]{`\n`}
						계약 또는 청약철회 등에 관한 기록 : 5년 보관{`\n`}
						대금결제 및 재화 등의 공급에 관한 기록 : 5년 보관{`\n`}
						소비자의 불만 또는 분쟁처리에 관한 기록 : 3년 보관{`\n`}
						{`\n`}
						[통신비밀보호법]{`\n`}
						접속기록 : 3개월 보관{`\n`}
						{`\n`}
						{`\n`}
						7.이용자 및 법정대리인의 권리와 그 행사방법{`\n`}
						{`\n`}
						이용자 또는 법정대리인은 언제든지 자신 혹은 만 14세 미만 아동의
						개인정보를 플랫폼사업자 또는 앱스토어 사업자를 통해 조회하거나
						수정할 수 있습니다. 또한, 서비스 애플리케이션의 환경설정에서
						플랫폼사업자가 하우투약에게 제공한 정보를 열람할 수 있으며, \탈퇴\를
						이용하여 개인정보의 수집 및 이용 동의를 철회할 수 있습니다.{`\n`}
						이용자 혹은 만 14세 미만 아동의 개인정보의 정정(수정)은 플랫폼사업자
						또는 앱스토어 사업자가 제공하는 회원정보 수정 기능을 이용하여
						정정하여야 하며, 개인정보의 오류에 대한 정정을 요청하신 경우에는
						정정이 완료되기 전까지 개인정보를 이용하거나 제공하지 않습니다.
						{`\n`}
						{`\n`}
						{`\n`}
						8.개인정보 자동 수집 장치의 설치/운영 및 거부에 관한 사항{`\n`}
						{`\n`}
						회사는 개인화되고 맞춤화 된 서비스를 제공하기 위해서 이용자의 정보를
						저장하고 수시로 불러오는 '쿠키(cookie)'를 사용합니다. 쿠키는
						웹사이트를 운영하는데 이용되는 서버가 이용자의 브라우저에게 보내는
						아주 작은 텍스트 파일로 이용자 컴퓨터의 하드디스크에 저장됩니다.
						{`\n`}
						쿠키는 이용자들이 방문한 회사의 각 서비스와 웹 사이트들에 대한 방문
						및 이용형태, 인기 검색어, 보안접속 여부, 뉴스편집, 이용자 규모 등을
						파악하여 이용자에게 최적화된 정보 제공을 위하여 사용합니다.{`\n`}
						쿠키의 설치/운영 및 거부- 이용자는 쿠키 설치에 대한 선택권을 가지고
						있습니다. 따라서 이용자는 웹 브라우저에서 옵션을 설정함으로써 모든
						쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 아니면 모든
						쿠키의 저장을 거부할 수도 있습니다.- 쿠키 설치 허용 여부를 지정하는
						방법(Internet Explorer의 경우)① [도구] 메뉴에서 [인터넷 옵션]을
						선택합니다.② [개인정보 탭]을 클릭합니다.③ [개인정보취급 수준]을
						설정하시면 됩니다.{`\n`}
						{`\n`}
						{`\n`}
						9.개인정보 보호를 위한 활동과 노력{`\n`}
						{`\n`}
						하우투약은 이용자의 개인정보를 처리함에 있어 개인정보가 분실, 도난,
						누출, 변조 또는 훼손되지 않도록 다음과 같은 노력을 다하고 있습니다.
						{`\n`}
						하우투약은 이용자의 개인정보를 관련 법률 및 내부정책에 따른 보안
						시스템을 이용하여 안전하게 보호하고 있습니다.{`\n`}
						하우투약은 이용자의 개인정보를 안전하게 저장 및 관리하고 있으며,
						네트워크상의 개인정보를 안전하게 전송할 수 있는 보안 시스템을
						채택하고 있습니다.{`\n`}
						하우투약은 해킹 등에 의해 이용자의 개인정보가 유출되는 것을 방지하기
						위하여 외부로부터의 침입을 차단하는 기술적 장치들을 갖추기 위해
						노력하고 있습니다.{`\n`}
						하우투약은 개인정보를 보관하는 데이터베이스시스템과 개인정보를
						처리하는 시스템에 대한 비밀번호 생성, 변경 및 접근할 수 있는 권한에
						대한 체계적인 기준을 마련하여 담당 직원이 준수할 수 있도록 하고
						있습니다.{`\n`}
						하우투약은 개인정보를 취급하는 직원을 개인정보 관리업무를 수행하는
						자 및 업무상 개인정보의 취급이 불가피 한 자로 엄격히 제한하고, 담당
						직원에 대한 정기적인 교육을 통해 개인정보를 보호할 수 있도록 하고
						있습니다.
						{`\n`}
						{`\n`}
						{`\n`}
						10.개인정보 보호 책임자 안내{`\n`}
						{`\n`}
						하우투약은 이용자의 개인정보 관련 문의 및 불만 사항 처리를 위해
						아래와 같이 개인정보 보호 책임자를 지정하고 있습니다.{`\n`}
						{`\n`}
						[개인정보관리책임자]{`\n`}
						이름 : 김경열{`\n`}
						소속 및 직위 : 대표{`\n`}
						이메일 : how2yak@howtoyak.com{`\n`}
						전화번호 : 1660-1024{`\n`}
						기타 개인정보침해에 관한 신고나 상담이 필요하신 경우에는 아래 기관에
						문의하여 주시기 바랍니다.{`\n`}
						개인정보침해신고센터(privacy.kisa.or.kr / 국번없이 118){`\n`}
						대검찰청 사이버수사과(www.spo.go.kr / 국번없이 1301){`\n`}
						경찰청 사이버안전국(www.cyber.go.kr / 국번없이 182){`\n`}
						개인정보 분쟁조정위원회(www.kopico.go.kr/ 1833-6972){`\n`}
						{`\n`}
						{`\n`}
						[부칙]{`\n`}
						(시행일) 이 약관은 2021년 10월 6일부터 적용됩니다.
					</p>
				</div>
			) : (
				<div className="main-wrap">
					<SignUpHeader title={"개인정보 처리방침"} back={true} close={false} />
					<div className="max-width-apply">
						<div className={`product-body`}>
							{/*<h1*/}
							{/*	style={{*/}
							{/*		fontSize: "2em",*/}
							{/*		fontWeight: "bold",*/}
							{/*		textAlign: "center",*/}
							{/*		margin: "0.7em 0",*/}
							{/*	}}*/}
							{/*>*/}
							{/*	개인정보 처리방침*/}
							{/*</h1>*/}
							<p
								style={{
									whiteSpace: "pre-line",
									fontSize: `1.05em`,
									color: `#000000`,
									fontWeight: 500,
									lineHeight: "1.3",
									letterSpacing: "-0.478px",
								}}
							>
								<span
									style={{
										display: "block",
										fontSize: "1.5em",
										fontWeight: 700,
									}}
								>
									개인정보 처리방침{`\n`}
								</span>
								{`\n`}
								휴대전화번호, 성별, 생년월일과 같은 개인정보를 식별 및 맞춤
								서비스를 제공하기 위해 수집하고 있으며, 요건만 갖춘 사람만
								정보에 접근할 수 있으며, 해킹등으로부터 유출을 방지하기 위해
								기술적 장치들을 갖추고 있습니다. 또한, 법에서 규정한 기간이후
								복원불가능한 방법으로 파기하고 있습니다.{`\n`}
								{`\n`}
								하우투약은 개인정보보호법, 정보통신망 이용촉진 및 정보보호 등에
								관한 법률 등 관련 법률에서 정보통신서비스제공자가 준수하여야 할
								개인정보보호 관련사항을 아래와 같이 준수하고 있습니다.{`\n`}
								{`\n`}
								1.개인정보처리방침이란{`\n`}
								{`\n`}
								개인정보처리방침이란 하우투약이 어떤 개인정보를 수집하고, 수집한
								정보를 어떻게 이용하며, 필요에 따라 누구와 공유하는지,
								이용목적이 달성된 개인정보를 언제, 어떻게 파기하는지 등 개인정보
								라이프사이클에 관한 정보를 제공합니다.또한, 이용자가 자신의
								개인정보에 대해 어떠한 권리를 가지고 있으며, 이를 어떻게 행사할
								수 있는지, 개인정보 침해사고가 발생할 경우 피해예방을 위해
								누구에게 연락하고 도움을 받을 수 있는지도 알려드립니다.{`\n`}
								개인정보처리방침은 이와 같이 이용자의 개인정보에 대한
								라이프사이클과 더불어 하우투약이 이용자에게 제공하는 영양관리
								서비스 당신의영양제(이하 “서비스”라 합니다)에서 하우투약과
								이용자의 권리/의무 이용자의 개인정보 자기결정권을 보장하는
								역할을 합니다.
								{`\n`}
								{`\n`}
								{`\n`}
								2.수집하는 개인정보의 항목 및 수집방법{`\n`}
								{`\n`}
								개인정보 수집에 대한 동의{`\n`}
								하우투약은 고객님께서 개인정보취급방침 또는 이용약관의 내용에
								대해 「동의합니다」 또는 「동의하지 않습니다」 버튼을 클릭할 수
								있는 절차를 마련하고 있으며,「동의합니다」 버튼을 클릭하면
								개인정보 수집에 대해 동의한 것으로 봅니다.{`\n`}
								하우투약이 서비스 이용과 관련하여 수집하는 개인정보의 범위는
								아래와 같습니다.{`\n`}
								{`\n`}
								[필수 입력 사항] 이메일, 휴대전화번호, 비밀번호, 로그인ID, 성별,
								생년월일, 이름, 서비스 이용 기록, 접속 로그, 쿠키, 접속 IP 정보
								{`\n`}
								[선택 입력 사항] 취미, 신체정보{`\n`}
								{`\n`}
								하우투약은 이용자가 플랫폼사업자(예:카카오 등)의 서비스에서
								사용하는 이용자의 닉네임(이름), 회원번호, 친구목록, 프로필
								사진을 플랫폼사업자(예 : 카카오 등)로부터 제공 받으며,
								플랫폼사업자는 이러한 정보를 이용자가 서비스를 이용하는 시점에
								정보통신망법에 따른 개인정보 제공 동의를 받아 하우투약에게
								제공합니다.{`\n`}
								하우투약은 플랫폼사업자로부터 제공받는 개인정보 외에 서비스를
								제공하는 과정에서 혹은 이용자가 서비스를 이용하는 과정에서
								서비스 이용기록, 접속로그, 불량이용기록, 통신사 정보, OS정보 및
								기기정보, 언어 정보, 결제기록, 쿠키, 접속IP정보, 이용정지기록을
								수집(저장)합니다.{`\n`}
								또한, 이용자가 서비스에서 진행하는 이벤트에 응모하거나 경품을
								신청하는 경우 혹은 서비스 이용 문의 과정에서 추가 정보가 수집될
								수 있습니다. 이 경우 하우투약은 수집하는 개인정보, 이용목적,
								보관기간 등을 이용자에게 안내하고 별도 동의를 받아 수집합니다.
								{`\n`}
								하우투약은 아래와 같은 방법으로 개인정보를 수집합니다.{`\n`}
								홈페이지, 모바일 애플리케이션, 서면양식, 전화, 팩스를 통한
								회원가입, 고객센터 상담(전화 또는 이메일), 제휴사로부터의 제공,
								이벤트 응모, 서비스 이용 기록 수집 툴을 통한 수집, 협력{`\n`}
								{`\n`}
								[사용자 분석 사항]{`\n`}
								당사는 서비스를 방문하는 사용자 수, 가장 많이 요청된 정보 및
								사용자와 서비스의 상호 작용 방법 등, 당사 서비스와 사용자의 상호
								작용을 여러 가지 방법으로 분석합니다.{`\n`}
								당사가 수집하는 정보에는 참조하는 웹 사이트, 당사 서비스의 내부
								페이지 접근 횟수 및 빈도, 페이지 조회 시간 등이 포함됩니다. 이를
								통해 당사는 서비스에 대한 사용자의 경험과 서비스를 개선할 수
								있습니다. 정보는 가명으로 처리됩니다.{`\n`}
								법적 근거: GDPR 제6조 (1)항 f호{`\n`}
								{`\n`}
								(1) Google 애널리틱스{`\n`}
								Google은 당신의영양제 사용자가 서비스와 상호 작용하는 방식을
								이해할 수 있도록 Google Analytics를 사용합니다.{`\n`}
								Google은 이 데이터를 사용하여 어떤 페이지가 가장 인기가 있는지,
								어떤 기능이 사용자에게 작동하는지 (또는 작동하지 않는지),
								사이트가 다른 위치 및 언어에서 얼마나 빠르거나 느린지를
								결정합니다.{`\n`}
								Google에 보내는 모든 데이터는 익명 처리됩니다. 우리는 사이트에서
								귀하 또는 다른 사용자를 개인적으로 식별하는 데 사용할 수 있는
								정보를 보내지 않습니다.{`\n`}
								귀하의 웹 사이트 사용 (귀하의 IP 주소 포함)에 대한 쿠키에 의해
								생성된 정보는 Google 서버에 전송되어 미국의 서버에 저장됩니다.
								{`\n`}
								Google은 귀하의 웹 사이트 사용을 평가하고 보고서를 작성하기
								위해이 정보를 사용합니다.{`\n`}웹 사이트 운영자를 위한 웹 사이트
								활동 및 웹 사이트 활동 및 인터넷 사용과 관련된 기타 서비스 제공.
								{`\n`}
								Google은 법에 의해 요구되는 경우 또는 제3자가 Google을 대신하여
								정보를 처리하는 경우 제 3자에게 이 정보를 전송할 수 있습니다.
								{`\n`}
								Google은 IP 주소를 Google이 보유한 다른 데이터와 연결하지
								않습니다.
								{`\n`}
								브라우저에서 적절한 설정을 선택하여 쿠키 사용을 거부할 수 있지만
								이 경우이 웹 사이트의 모든 기능을 사용하지 못할 수도 있습니다.
								{`\n`}이 웹 사이트를 사용함으로써 위에 명시된 방식 및 목적으로
								귀하가 Google에서 귀하의 데이터를 처리하는 데 동의하게 됩니다.
								{`\n`}
								{`\n`}
								Google 애널리틱스 인구 통계 및 관심 분야 보고서 -이 기능을 통해
								방문자 나이, 성별 및 관심 분야와 관련된 행동 정보를 익명 및
								총체적 수준에서 파악할 수 있습니다.{`\n`}이 정보는 특정 사용자의
								레벨에서 제공되지 않습니다. 이 기능은 Google 사이트를 방문하는
								동안 사용자에게 더 나은 경험을 제공하기 위해 탐색 행동을
								이해하는 데 도움이 됩니다.{`\n`}
								Google 애널리틱스를 통한 DoubleClick 플랫폼 및 리 마케팅 -이
								기능은 웹 분석 및 유료 광고 플랫폼을 통합하여 추가적인 통찰력을
								제공합니다.
								{`\n`}
								이를 통해 우리는 광고를 더 잘 맞추고 다른 사이트를 방문하는 동안
								광고를 볼 수 있습니다.{`\n`}
								{`\n`}
								(2) Google 태그 매니저{`\n`}
								당사는 투명성을 위해 Google 태그 매니저를 사용하고 있음을
								알려드립니다.
								{`\n`}
								Google 태그 매니저는 자체적으로 개인 식별 정보를 수집하지
								않습니다. 태그 매니저를 사용하면 태그를 쉽게 통합하고 관리할 수
								있습니다.
								{`\n`}
								태그는 트래픽 및 사용자 행동을 측정하고, 온라인 광고 및 소셜
								채널의 효과를 기록하며, 리마케팅을 설정하고, 대상 그룹에
								집중하며, 웹사이트와 다른 사항 간에 테스트하고 최적화하는 데
								사용되는 소형의 코드 요소입니다.
								{`\n`}
								비활성화하는 경우 Google 태그 매니저에서 이를 고려하게 됩니다.
								Google 태그 매니저에 관한 자세한 내용은 다음을 참조해주세요.
								{`\n`}
								https://www.google.com/analytics/tag-manager/use-policy/{`\n`}
								{`\n`}
								{`\n`}
								(3) Hotjar{`\n`}본 서비스를 최적화하기 위해 HotjarL Ltd.(
								https://www.hotjar.com)의 기록을 사용해 데이터를 수집하고
								저장합니다.
								{`\n`}이 데이터는 익명으로 사용 프로필을 생성하는 데 사용할 수
								있습니다. 쿠키는 해당 용도로 사용될 수 있습니다. Hotjar 기술로
								수집된 데이터는 이 웹사이트 방문자를 개인적으로 식별하기 위해
								사용되지 않으며, 해당 개인의 명시적인 동의 없이 가명 소지자에
								관한 개인 식별 정보와 결합되지 않습니다. {`\n`}
								귀하는 Hotjar 수신 거부 페이지
								(https://www.hotjar.com/legal/compliance/opt-out)에서 ‘Disable
								Hotjar’를 클릭하거나 브라우저의 Do Not Track을 활성화함으로써
								Hotjar 사용 가능 사이트를 방문할 때 Hotjar가 귀하의 정보를
								수집하지 못하도록 선택할 수 있습니다.{`\n`}
								{`\n`}
								하우투약은 이용자가 플랫폼사업자(예:카카오 등)의 서비스에서
								사용하는 이용자의 닉네임(이름), 회원번호, 친구목록, 프로필
								사진을 플랫폼사업자(예:카카오 등)로부터 제공 받으며,
								플랫폼사업자는 이러한 정보를 이용자가 서비스를 이용하는 시점에
								정보통신망법에 따른 개인정보 제공 동의를 받아 하우투약에게
								제공합니다.{`\n`}
								하우투약은 플랫폼사업자로부터 제공받는 개인정보 외에 서비스를
								제공하는 과정에서 혹은 이용자가 서비스를 이용하는 과정에서
								서비스 이용기록, 접속로그, 불량이용기록, 통신사 정보, OS정보 및
								기기정보, 언어 정보, 결제기록, 쿠키, 접속IP정보, 이용정지기록을
								수집(저장)합니다.{`\n`}
								또한, 이용자가 서비스에서 진행하는 이벤트에 응모하거나 경품을
								신청하는 경우 혹은 서비스 이용 문의 과정에서 추가 정보가 수집될
								수 있습니다. 이 경우 하우투약은 수집하는 개인정보, 이용목적,
								보관기간 등을 이용자에게 안내하고 별도 동의를 받아 수집합니다.
								{`\n`}
								하우투약은 아래와 같은 방법으로 개인정보를 수집합니다.{`\n`}
								홈페이지, 모바일 애플리케이션, 서면양식, 전화, 팩스를 통한
								회원가입, 고객센터 상담(전화 또는 이메일), 제휴사로부터의 제공,
								이벤트 응모, 서비스 이용 기록 수집 툴을 통한 수집, 협력{`\n`}
								{`\n`}
								{`\n`}
								3.수집한 개인정보의 이용{`\n`}
								{`\n`}
								하우투약은 수집한 개인정보를 아래와 같이 이용합니다.{`\n`}
								서비스 제공에 필요한 회원 식별 및 친구초대, 친구추천, 유료
								멤버십 구매, 결제, 알림 서비스 제공 등을 위해 개인정보를
								이용합니다.
								{`\n`}
								그리고, 가입 의사 또는 탈퇴 의사 확인, 서비스 이용에 따른 이용자
								확인 및 식별, 약관 또는 이용정책을 위반하는 이용자의
								이용제한조치 등 서비스 운영에 지장을 주는 행위 방지 및
								부정이용방지, 분쟁조정을 위한 기록보존, 민원처리, 공지사항 전달
								등을 위해 개인정보를 이용합니다.
								{`\n`}
								또한, 서비스 이용기록 분석 및 통계와 그에 따른 맞춤형
								서비스(상담 및 광고 포함) 제공, 인구통계학적 특성에 따른 서비스
								제공 및 맞춤형 광고 게재, 서비스 개발/제공 또는 업데이트, 이벤트
								또는 광고성 정보 제공 등 위해 개인정보를 이용합니다.{`\n`}
								{`\n`}
								{`\n`}
								4.개인정보의 제공 및 위탁{`\n`}
								하우투약은 이용자의 개인정보를 수집 및 이용 목적 범위 내에서
								이용하며, 이용자의 사전 동의 없이는 그 범위를 초과하여
								이용하거나 제공하지 않습니다. 다만, 아래의 경우에는 주의를
								기울여 개인정보를 이용 또는 제공할 수 있습니다.{`\n`}
								하우투약은 개인정보 수집 또는 제공 이전에 이용자에게
								수집/제공하는 파트너가 누구인지, 필요한 정보가 무엇인지,
								언제까지 보관하는지를 알려드리고 동의를 구하며, 이용자가 동의를
								하지 않는 경우에는 수집 또는 제공하지 않습니다.{`\n`}
								하우투약은 법률에 특별한 규정이 있는 경우 또는 수사기관이 수사
								목적으로 법률에 정해진 절차와 방법에 따라 요구하는 경우에는
								제공할 수 있습니다.
								{`\n`}
								{`\n`}
								{`\n`}
								5.광고성 정보 전송의 제한{`\n`}
								{`\n`}
								하우투약은 회원의 명시적인 수신거부 의사에 반하여 영리목적의
								광고성 정보를 전송하지 않습니다. 회원이 뉴스레터 등 전자우편
								전송에 대한 동의를 한 경우 전자우편의 제목란 및 본문란에 다음
								사항과 같이 회원이 쉽게 알아볼 수 있도록 조치합니다.{`\n`}
								전자우편의 제목란: (광고)라는 문구를 제목란에 표시하지 않을 수
								있으며, 전자우편 본문란의 주요 내용을 표시합니다.{`\n`}
								전자우편의 본문란: 수신거부의 의사표시를 할 수 있는 전송자의
								명칭, 전자우편주소, 전화번호 및 주소를 명시하며 수신 거부의
								의사를 쉽게 표시할 수 있는 방법 및 회원이 동의를 한 시기 및
								내용을 명시합니다.
								{`\n`}
								팩스, 휴대폰 문자전송 등 전자우편 이외의 문자전송을 통해
								영리목적의 광고성 정보를 전송하는 경우에는 전송내용 처음에
								(광고)라는 문구를 표기하고 전송내용 중에 전송자의 연락처를
								명시하도록 조치합니다.
								{`\n`}
								회원은 영리목적의 광고성 정보를 전송받은 경우 언제든지 이에 대해
								수신거부의 의사표시를 할 수 있고, 하우투약은 즉각 전송중단의
								조치를 취한 후 이를 회원에게 알립니다.{`\n`}
								{`\n`}
								{`\n`}
								6. 개인정보 파기{`\n`}
								{`\n`}
								하우투약은 원칙적으로 이용자가 서비스에서 탈퇴하는 경우 이용자의
								개인정보를 지체없이 파기합니다. 다만, 이용자에게 개인정보의
								보관기간에 대해 별도 동의를 받은 경우 또는 관련 법률에서 일정
								기간동안 보관의무를 명시하고 있는 경우에는 해당 기간동안 보관 후
								지체없이 파기합니다. 또한, 정보통신망 이용촉진 및 정보보호 등에
								관한 법률에 따라 1년간 서비스를 이용하지 않은 이용자의
								개인정보는 별도로 분리하여 보관하며, 분리 보관된 개인정보는
								5년간 보관 후 지체없이 파기합니다.하우투약은 이용자의 서비스
								탈퇴, 서비스 종료 혹은 보관기간 도래 등과 같은 개인정보의 수집
								및 이용목적이 달성된 개인정보에 대해서는 복구 및 재생이 불가능한
								방법으로 파기합니다. 인쇄물 등 파쇄가 가능한 형태인 경우에는
								분쇄기 등을 이용하여 재조합이 불가능하도록 물리적으로 파쇄
								하거나 소각 등을 하며, 전자적 파일 형태로 저장된 개인정보는
								재생할 수 없는 기술적 방법(예:로우레벨포멧, 와이핑 등)을
								사용하여 파기 합니다.
								{`\n`}
								관련 법률에서 일정 기간동안 개인정보의 보관을 규정하고 있는
								경우는 아래와 같습니다. 하우투약은 관련 법률에서 정한 기간 동안
								개인정보를 보관하며, 보관 목적 이외의 다른 목적으로는 사용하지
								않습니다.{`\n`}
								{`\n`}
								[전자상거래 등에서의 소비자 보호에 관한 법률]{`\n`}
								계약 또는 청약철회 등에 관한 기록 : 5년 보관{`\n`}
								대금결제 및 재화 등의 공급에 관한 기록 : 5년 보관{`\n`}
								소비자의 불만 또는 분쟁처리에 관한 기록 : 3년 보관{`\n`}
								{`\n`}
								[통신비밀보호법]{`\n`}
								접속기록 : 3개월 보관{`\n`}
								{`\n`}
								{`\n`}
								7.이용자 및 법정대리인의 권리와 그 행사방법{`\n`}
								{`\n`}
								이용자 또는 법정대리인은 언제든지 자신 혹은 만 14세 미만 아동의
								개인정보를 플랫폼사업자 또는 앱스토어 사업자를 통해 조회하거나
								수정할 수 있습니다. 또한, 서비스 애플리케이션의 환경설정에서
								플랫폼사업자가 하우투약에게 제공한 정보를 열람할 수 있으며,
								\탈퇴\를 이용하여 개인정보의 수집 및 이용 동의를 철회할 수
								있습니다.{`\n`}
								이용자 혹은 만 14세 미만 아동의 개인정보의 정정(수정)은
								플랫폼사업자 또는 앱스토어 사업자가 제공하는 회원정보 수정
								기능을 이용하여 정정하여야 하며, 개인정보의 오류에 대한 정정을
								요청하신 경우에는 정정이 완료되기 전까지 개인정보를 이용하거나
								제공하지 않습니다.
								{`\n`}
								{`\n`}
								{`\n`}
								8.개인정보 자동 수집 장치의 설치/운영 및 거부에 관한 사항{`\n`}
								{`\n`}
								회사는 개인화되고 맞춤화 된 서비스를 제공하기 위해서 이용자의
								정보를 저장하고 수시로 불러오는 '쿠키(cookie)'를 사용합니다.
								쿠키는 웹사이트를 운영하는데 이용되는 서버가 이용자의
								브라우저에게 보내는 아주 작은 텍스트 파일로 이용자 컴퓨터의
								하드디스크에 저장됩니다.
								{`\n`}
								쿠키는 이용자들이 방문한 회사의 각 서비스와 웹 사이트들에 대한
								방문 및 이용형태, 인기 검색어, 보안접속 여부, 뉴스편집, 이용자
								규모 등을 파악하여 이용자에게 최적화된 정보 제공을 위하여
								사용합니다.{`\n`}
								쿠키의 설치/운영 및 거부- 이용자는 쿠키 설치에 대한 선택권을
								가지고 있습니다. 따라서 이용자는 웹 브라우저에서 옵션을
								설정함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을
								거치거나, 아니면 모든 쿠키의 저장을 거부할 수도 있습니다.- 쿠키
								설치 허용 여부를 지정하는 방법(Internet Explorer의 경우)① [도구]
								메뉴에서 [인터넷 옵션]을 선택합니다.② [개인정보 탭]을
								클릭합니다.③ [개인정보취급 수준]을 설정하시면 됩니다.{`\n`}
								{`\n`}
								{`\n`}
								9.개인정보 보호를 위한 활동과 노력{`\n`}
								{`\n`}
								하우투약은 이용자의 개인정보를 처리함에 있어 개인정보가 분실,
								도난, 누출, 변조 또는 훼손되지 않도록 다음과 같은 노력을 다하고
								있습니다.
								{`\n`}
								하우투약은 이용자의 개인정보를 관련 법률 및 내부정책에 따른 보안
								시스템을 이용하여 안전하게 보호하고 있습니다.{`\n`}
								하우투약은 이용자의 개인정보를 안전하게 저장 및 관리하고 있으며,
								네트워크상의 개인정보를 안전하게 전송할 수 있는 보안 시스템을
								채택하고 있습니다.{`\n`}
								하우투약은 해킹 등에 의해 이용자의 개인정보가 유출되는 것을
								방지하기 위하여 외부로부터의 침입을 차단하는 기술적 장치들을
								갖추기 위해 노력하고 있습니다.{`\n`}
								하우투약은 개인정보를 보관하는 데이터베이스시스템과 개인정보를
								처리하는 시스템에 대한 비밀번호 생성, 변경 및 접근할 수 있는
								권한에 대한 체계적인 기준을 마련하여 담당 직원이 준수할 수
								있도록 하고 있습니다.{`\n`}
								하우투약은 개인정보를 취급하는 직원을 개인정보 관리업무를
								수행하는 자 및 업무상 개인정보의 취급이 불가피 한 자로 엄격히
								제한하고, 담당 직원에 대한 정기적인 교육을 통해 개인정보를
								보호할 수 있도록 하고 있습니다.
								{`\n`}
								{`\n`}
								{`\n`}
								10.개인정보 보호 책임자 안내{`\n`}
								{`\n`}
								하우투약은 이용자의 개인정보 관련 문의 및 불만 사항 처리를 위해
								아래와 같이 개인정보 보호 책임자를 지정하고 있습니다.{`\n`}
								{`\n`}
								[개인정보관리책임자]{`\n`}
								이름 : 김경열{`\n`}
								소속 및 직위 : 대표{`\n`}
								이메일 : how2yak@howtoyak.com{`\n`}
								전화번호 : 1660-1024{`\n`}
								기타 개인정보침해에 관한 신고나 상담이 필요하신 경우에는 아래
								기관에 문의하여 주시기 바랍니다.{`\n`}
								개인정보침해신고센터(privacy.kisa.or.kr / 국번없이 118){`\n`}
								대검찰청 사이버수사과(www.spo.go.kr / 국번없이 1301){`\n`}
								경찰청 사이버안전국(www.cyber.go.kr / 국번없이 182){`\n`}
								개인정보 분쟁조정위원회(www.kopico.go.kr/ 1833-6972){`\n`}
								{`\n`}
								{`\n`}
								[부칙]{`\n`}
								(시행일) 이 약관은 2021년 10월 6일부터 적용됩니다.
							</p>

							<button
								type="button"
								onClick={() => history.back()}
								style={{
									display: "block",
									fontSize: "1.05em",
									padding: "0.8em 2em",
									background: "#117ffa",
									color: "#fff",
									borderRadius: "6px",
									margin: "10em auto 6em",
								}}
							>
								뒤로가기
							</button>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default Policy;
