import axios from "axios";
const baseUrl = `${process.env.REACT_APP_BACKEND_HOST}`;

const readSettlementList = async (param) => {
	const { data } = await axios.get(`${baseUrl}/partners/read/settlement`, {
		params: param,
	});
	return data;
};

/**
 * step 2) 정산 확인
 * 브랜드에서 정산확인
 * @param param
 * id : table 아이디
 * created_by: user.id
 * @returns {Promise<*>}
 */
const postReportsSecond = async (param) => {
	const { data } = await axios.post(`${baseUrl}/settlement/confirm`, param);
	return data;
};

/**
 * 정산에서 가장 큰 값 표기하기
 * @param param
 * userId,
 * startDate,
 * endDate
 * @returns {Promise<never>}
 */
const readLargestSettlement = async (param) => {
	const { data } = await axios.get(
		`${baseUrl}/settlement/dashboard/settlement`,
		{ params: param }
	);
	return data;
};

/**
 * 매출 내 가장 큰 값 표기
 * @param param
 * @param param.userId
 * @returns {Promise<never>}
 * date
 * month
 * year
 * purchases_num
 * total_purchase_amount
 * expected_profit_amount
 */
const readLargestSales = async (param) => {
	const { data } = await axios.get(
		`${baseUrl}/partnersSummary/dashboard/read/sales/largest`,
		{ params: param }
	);
	return data;
};

const settlementApi = {
	readSettlementList,
	postReportsSecond,
	readLargestSettlement,
	readLargestSales,
};

export default settlementApi;
