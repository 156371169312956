import "../../Event.scss";
import { useEffect, useState } from "react";

const BenefitPage = () => {
	const [mediaResult, setMediaResult] = useState(false);
	const [semiMediaResult, setSemiMediaResult] = useState(false);

	useEffect(() => {
		if (typeof window !== "undefined") {
			const matchQueryList = window.matchMedia("all and (max-width: 768px)");
			const matchQueryListWithSemi = window.matchMedia(
				"all and (max-width: 296px)"
			);
			setMediaResult(matchQueryList.matches);
			setSemiMediaResult(matchQueryListWithSemi.matches);

			const handleChange = (e) => {
				setMediaResult(e.matches);
			};

			matchQueryList.addEventListener("change", handleChange);
			matchQueryListWithSemi.addEventListener("change", (e) =>
				setSemiMediaResult(e.matches)
			);
		}
	}, []);

	return (
		<div className="main-wrap event-banner-page">
			{/*배너*/}
			<div className={`banner-wrap using-guide`}>
				<img
					src={`/assets/images/event/benefit-banner${
						mediaResult ? "-m" : ""
					}.jpg`}
					alt={`benefit_banner`}
					style={{
						width: "100%",
						height: "auto",
					}}
				/>
				<div className={`banner-title-wrap user-guide`}>
					<h1 className={`banner-title`}>
						클릭만 해도 쌓이는 현금,{"\n"}
						<span>당영투게더</span>
					</h1>
					<p className={`banner-description`}>
						당영투게더에서 시간과 장소에 상관없이{mediaResult ? `\n` : " "}높은
						수익을 만들어 보세요
					</p>
				</div>
			</div>
			{/*내용-1*/}
			<div className={`content-wrap`}>
				{/*내용 메인 텍스트*/}
				<div className={`content-title-wrap`}>
					<p className={`content-title-category`}>Benefit</p>
					<h2 className={`content-title user-guide-page benefit-page`}>
						어디서도 찾을 수 없는{semiMediaResult ? `\n` : " "}
						<span>차별화된 혜택,</span>
						{"\n"}
						당영투게더를 선택하는 이유입니다
					</h2>
				</div>
				<div className={`content-wrap benefit-page`}>
					<div className={`benefit-list-wrap`}>
						{/*7,000원*/}
						<div className={`benefit-list-item`}>
							<p className={`title`}>7,000원</p>
							<p className={`description`}>
								회원가입만 해도{"\n"}
								<span>무조건 7,000원{mediaResult ? " " : `\n`}적립</span>
								해드려요
							</p>
						</div>
						{/*100원*/}
						<div className={`benefit-list-item`}>
							<p className={`title`}>100원</p>
							<p className={`description`}>
								클릭만 해도{"\n"}최대 100원씩
								{mediaResult ? " " : `\n`}
								<span>현금이 쌓여가요</span>
							</p>
						</div>
						{/*30%*/}
						<div className={`benefit-list-item`}>
							<p className={`title`}>30%</p>
							<p className={`description`}>
								최대 30%의{"\n"}
								<span>높은 수수료율</span>로{mediaResult ? " " : `\n`}목표에
								쉽게 도달해요
							</p>
						</div>
					</div>
					<img
						className={`benefit-item-line-img`}
						src={`/assets/images/event/benefit-item-line${
							mediaResult ? "-m" : ""
						}.png`}
						alt={`benefit_item_line`}
					/>
				</div>
			</div>
			{/*내용-2*/}
			<div className={`content-wrap bg-gray add-padding`}>
				{/*내용 메인 텍스트*/}
				<div className={`content-title-wrap`}>
					<p className={`content-title-category`}>Check</p>
					<h2 className={`content-title user-guide-page benefit-page-2`}>
						<span>높은 수익 창출</span>을 위해{"\n"}
						꼼꼼하게 확인해보세요!
					</h2>
				</div>
				<div className={`content-description-wrap benefit`}>
					{/*1. 가입만 해도 무조건 7000원 적립*/}
					<div className={`content-description-item`}>
						<h3 className={`title`}>가입만 해도 무조건 7000원 적립</h3>
						<div className={`description`}>
							<p style={mediaResult ? { width: "80%" } : {}}>
								시작하시는 분들의 더욱 힘찬 시작을 위해
								{mediaResult ? " " : `\n`}회원가입만 해도 7,000원 적립해드려요.
							</p>
							<p>시작부터 든든하게, 준비된 선물 받고 시작하세요</p>
						</div>
					</div>
					{/*2. 링크 클릭만으로 쌓이는 현금*/}
					<div className={`content-description-item`}>
						<h3 className={`title`}>링크 클릭만으로 쌓이는 현금</h3>
						<div className={`description`}>
							<p>당영투게더에서만 만나볼 수 있는 새로운 혜택이에요</p>
							<p style={mediaResult ? { width: "70%" } : {}}>
								링크를 클릭할 때마다 최소 10원부터 최대 100원씩
								{mediaResult ? " " : `\n`}
								현금을 바로 적립해드려요.
							</p>
						</div>
					</div>
					{/*3. 최대 30%의 높은 수수료율*/}
					<div className={`content-description-item`}>
						<h3 className={`title`}>최대 30%의 높은 수수료율</h3>
						<div className={`description`}>
							<p style={mediaResult ? { width: "103%" } : {}}>
								당영투게더는 타사 수수료율 3~15%에 비해
								{mediaResult ? " " : `\n`}최대 30%의 압도적인 수수료로 10배 쉽게
								수익을 올릴 수 있어요.
							</p>
							<p>손쉽게 목표 수익 창출이 가능해요.</p>
						</div>
					</div>
					{/*4. 하나의 제품 링크로 다른 제품 수수료까지*/}
					<div className={`content-description-item`}>
						<h3 className={`title`}>하나의 제품 링크로 다른 제품 수수료까지</h3>
						<div
							className={`description`}
							style={mediaResult ? { width: "95%" } : {}}
						>
							A제품 링크로 들어와 B,C 제품까지 구매한 경우
							{mediaResult ? " " : `\n`}3가지 제품 수수료를 모두 적립해드려요.
						</div>
					</div>
					{/*5. 실시간으로 투명하게 수익 확인 가능*/}
					<div className={`content-description-item`}>
						<h3 className={`title`}>실시간으로 투명하게 수익 확인 가능</h3>
						<div
							className={`description`}
							style={mediaResult ? { width: "95%" } : {}}
						>
							실시간으로 창출된 수익 확인이 가능한 시스템을 가지고 있어
							{mediaResult ? " " : `\n`}큰 수수료와 투명하지 못한 정산에 대한
							불안감 없이 사용하실 수 있어요.
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default BenefitPage;
