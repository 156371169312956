import React from "react";

const matchingMenuUrl = {
	product_lookup: "/product/view",
	product_register: "/product/register",
	sales_search: "/salesManage/search",
	sales_order: "/salesManage/order",
	sales_delivery: "/salesManage/delivery",
	purchase: "/salesManage/purchaseComfirmation",
	sales_cancel: "/salesManage/cancel",
	sales_return: "/salesManage/return",
	sales_exchange: "/salesManage/exchange",
	inquiry_product: "/inquiries/product",
	inquiry_lookup: "/inquiries/view",
	inquiry_board: "/inquiries/board",
	inquiry_service: "/inquiries/service",
	inquiry_review: "/inquiries/review",
	coupon_register: "/promotion/coupon/register",
	coupon_lookup: "/promotion/coupon/view",
	content_register: "/promotion/content/register",
	content_lookup: "/promotion/content/view",
	nutrient_info_manage: "/nutrients/view/certified",
};

const matchingKey = {
	order_id: "상품주문번호",
	order_detail_id: "세부상품주문번호",
	payment_created_at: "결제일",
	order_detail_status: "주문/배송상태",
	product_id: "상품번호",
	product_name: "상품명",
	option_id: "옵션ID",
	// option_title: "옵션상품명",
	option_title: "옵션명",
	quantity: "수량",
	// total_price: "주문금액",
	total_price: "판매금액",
	price: "상품금액",
	coupon_discount_price: "할인금액",
	created_by_name: "구매자ID",
	use_point: "포인트사용유무",
	name: "수취인명",
	phone: "수취인연락처",
	address: "배송지",
	address_request: "배송메시지",
	delivery_company: "택배사",
	tracking_number: "송장번호",
	ship_date: "발송일",

	brand_name: "브랜드명",
	purchase_path: "구매 경로",
	profit_price: "정산 금액",
	total_sales_price: "판매 금액",
	fee_total: "수수료 합계",
	commission_dy: "당영 관리 수수료",
	commission_sell: "매출 연동 수수료",
	delivery_price: "배송 관리 수수료",
	corporation_vat: "부가가치세",
	individual_tax: "원천세",

	created_at: "구매일자",
	complete_date: "구매확정일자",
	commission_rate: "수수료율",
	profit: "수익",
	status: "정산상태",
};

const matchingOrderStatus = {
	order_request: "주문생성",
	ready: "입금대기",
	paid: "입금완료",
	failed: "결제실패",
	cancelled: "결제취소",
	delivery_ready: "배송준비중",
	delivery_shipping: "배송중",
	delivery_complete: "배송완료",
	complete: "구매확정",
	cancel_request: "취소요청",
	cancel_complete: "취소완료",
	exchange_request: "교환요청",
	exchange_complete: "교환완료",
	return_request: "반품요청",
	return_receive: "반품승인",
	return_refusal: "반품거부/철회",
	return_collection_complete: "반품수거완료",
	return_collection_fail: "반품수거취소",
	return_delivery: "반품배송중",
	return_delivery_complete: "반품배송완료",
	return_complete: "반품완료",
};

const matchingDeliveryCompany = {
	"de.dhl": "DHL",
	"jp.sagawa": "Sagawa",
	"jp.yamato": "Kuroneko Yamato",
	"jp.yuubin": "Japan Post",
	"kr.chunilps": "천일택배",
	"kr.cjlogistics": "CJ대한통운",
	"kr.cupost": "CU 편의점택배",
	"kr.cvsnet": "GS Postbox 택배",
	"kr.cway": "CWAY (Woori Express)",
	"kr.daesin": "대신택배",
	"kr.epost": "우체국 택배",
	"kr.hanips": "한의사랑택배",
	"kr.hanjin": "한진택배",
	"kr.hdexp": "합동택배",
	"kr.homepick": "홈픽",
	"kr.honamlogis": "한서호남택배",
	"kr.ilyanglogis": "일양로지스",
	"kr.kdexp": "경동택배",
	"kr.kunyoung": "건영택배",
	"kr.logen": "로젠택배",
	"kr.lotte": "롯데택배",
	"kr.slx": "SLX",
	"kr.swgexp": "성원글로벌카고",
	"nl.tnt": "TNT",
	"un.upu.ems": "EMS",
	"us.fedex": "Fedex",
	"us.ups": "UPS",
	"us.usps": "USPS",
};

const matchingProvider = {
	guest: "게스트로그인",
	kakao: "카카오로그인",
	naver: "네이버로그인",
	apple: "애플로그인",
};

const matchingPointHistory = {
	purchase_use: "상품 구매에 사용",
	review_complete: "리뷰 작성 포인트 지급",
	review_cancel: "리뷰 삭제 포인트 차감",
	purchase_complete: "상품 구매 확정 포인트 지급",
	purchase_saving_point_complete: "상품 구매 확정 포인트 지급",
	purchase_cancel: "상품 구매 취소로 포인트 회수",
};

const orderStatusChange = (value) => {
	switch (value) {
		case "order_request":
			return <span>주문생성</span>;
		case "ready":
			return <span>입금대기</span>;
		case "paid":
			return <span>입금완료</span>;
		case "cancelled":
			return <span>결제취소</span>;
		case "failed":
			return <span>결제실패</span>;
		case "delivery_ready":
			return <span>배송준비중</span>;
		case "delivery_shipping":
			return <span>배송중</span>;
		case "delivery_complete":
			return <span>배송완료</span>;
		case "complete":
			return <span>구매확정</span>;
		case "cancel_request":
			return <span>취소요청</span>;
		case "cancel_complete":
			return <span>취소완료</span>;
		case "exchange_request":
			return <span>교환요청</span>;
		case "exchange_complete":
			return <span>교환완료</span>;
		case "return_request":
			return <span>반품요청</span>;
		case "return_receive":
			return <span>반품승인</span>;
		case "return_refusal":
			return <span>반품거부/철회</span>;
		case "return_collection_complete":
			return <span>반품수거완료</span>;
		case "return_collection_fail":
			return <span>반품수거취소</span>;
		case "return_delivery":
			return <span>반품배송중</span>;
		case "return_delivery_complete":
			return <span>반품배송완료</span>;
		case "return_complete":
			return <span>반품완료</span>;
		default:
			return <span>-</span>;
	}
};

export function isEndWithConsonant(korStr) {
	const finalChrCode = korStr?.charCodeAt(korStr.length - 1);
	if (finalChrCode) {
		// 0 = 받침 없음, 그 외 = 받침 있음
		const finalConsonantCode = (finalChrCode - 44032) % 28;
		return finalConsonantCode !== 0;
	} else return true;
}

export const historyList = (list) => {
	let countList = [];
	for (let i = 0; i < list.length; i++) {
		let isValid = false;
		let item = list[i];
		let obj =
			countList.find((cur) => {
				if (cur.target_type) {
					if (cur.target_type === item.target_type) {
						return cur.action === item.action;
					} else {
						return false;
					}
				} else {
					return cur.action === item.action;
				}
			}) ?? {};
		let objIdx =
			countList.findIndex((cur) => {
				if (cur.target_type) {
					if (cur.target_type === item.target_type) {
						return cur.action === item.action;
					} else {
						return false;
					}
				} else {
					return cur.action === item.action;
				}
			}) ?? {};
		if (!obj.action) {
			isValid = true;
		}
		obj = {
			action: obj?.action ?? item.action,
			target_type: obj?.target_type ?? item.target_type,
			name: obj?.name ?? convertUserHistoryTitle(item),
			count: obj?.count ? obj?.count + 1 : 1,
		};
		if (isValid) {
			countList.push(obj);
		} else {
			let counts = [...countList];
			counts[objIdx] = obj;
			countList = counts;
		}
	}
	return countList.sort((a, b) => b.count - a.count);
};

export const convertUserHistoryTitle = (item) => {
	const action = item.action;
	const target_type = item.target_type;
	if (target_type === "comment") {
		switch (action) {
			case "create":
				return "댓글 작성";
			case "delete":
				return "댓글 삭제";
			case "edit":
				return "댓글 수정";
			default:
				return "";
		}
	} else if (target_type === "reply") {
		switch (action) {
			case "create":
				return "답글 작성";
			case "delete":
				return "답글 삭제";
			case "edit":
				return "답글 수정";
			default:
				return "";
		}
	} else {
		switch (action) {
			case "first_inflow":
				return "유입량";
			case "ingredient_read":
				return "성분별 카테고리 조회";
			case "function_read":
				return "고민별 카테고리 조회";
			case "combination_read":
				return "조합별 카테고리 조회";
			case "read":
				return "커뮤니티 게시글 조회";
			case "login":
				return "로그인";
			case "login_by_phone":
				return "일반 로그인";
			case "login_by_naver":
				return "네이버 로그인";
			case "login_by_kakao":
				return "카카오 로그인";
			case "logout":
				return "로그아웃";
			case "signup":
				return "회원가입";
			case "favorite_add":
				return "좋아요 등록";
			case "favorite_delete":
				return "좋아요 삭제";
			default:
				return "";
		}
	}
};

export const isGeneralBank = (item) => {
	if (
		item.name.includes("증권") ||
		item.name.includes("선물") ||
		item.name.includes("모건스탠리") ||
		item.name.includes("HSBC") ||
		item.name.includes("모건스탠리") ||
		item.name.includes("도이치") ||
		item.name.includes("알비에스피엘씨") ||
		item.name.includes("제이피모간") ||
		item.name.includes("미즈호") ||
		item.name.includes("엠유에프지") ||
		item.name.includes("BOA") ||
		item.name.includes("비엔피파리바") ||
		item.name.includes("중국") ||
		item.name.includes("산림조합중앙회") ||
		item.name.includes("대화") ||
		item.name.includes("교통") ||
		item.name.includes("보증기금") ||
		item.name.includes("금융") ||
		item.name.includes("경찰") ||
		item.name.includes("농축협") ||
		item.name.includes("수협중앙회") ||
		item.name.includes("수출입") ||
		item.name.includes("한국") ||
		item.name.includes("보증")
	) {
		return false;
	} else {
		return true;
	}
};

const status = {
	matchingMenuUrl,
	matchingKey,
	matchingOrderStatus,
	matchingDeliveryCompany,
	matchingProvider,
	matchingPointHistory,
	orderStatusChange,
};

export default status;
