import React from "react";

import moment from "moment";

export const positiveColor = "#117ffa";
export const negativeColor = "#df1d1d";

const BannerAlgorithm = ({ mention, type }) => {
	const positiveMentionList = [
		<h2>
			전일 기준 유입량이{" "}
			<span style={{ color: positiveColor }}>
				{mention?.count ? mention?.count?.toLocaleString() : 0}%
			</span>{" "}
			증가했습니다. 유입량을 늘린 방법을 꾸준히 유지해보세요!
		</h2>,
		!mention?.count ? (
			<h2>
				{moment().format("M")}월 매출이{" "}
				<span style={{ color: positiveColor }}>0원</span>
				입니다. 매출로 이어질 수 있는 활동을 해보는 건 어떨까요?
			</h2>
		) : (
			<h2>
				축하합니다! {moment().format("M")}월 매출
				<span style={{ color: positiveColor }}>
					{" "}
					{mention?.count?.toLocaleString() ?? 0}원
				</span>
				으로 역대 최고 월 매출을 달성했습니다.
			</h2>
		),
		<h2>
			어제보다 금일 거래액이{" "}
			<span style={{ color: positiveColor }}>
				{mention?.count ? mention?.count?.toLocaleString() : 0}%
			</span>{" "}
			증가했습니다. 이 기세를 꾸준하게 유지해 보세요!
		</h2>,
	];

	const negativeMentionList = [
		<h2>
			전일 기준 유입량이{" "}
			<span style={{ color: negativeColor }}>
				{mention?.count ? mention?.count?.toLocaleString() : 0}%
			</span>{" "}
			감소했습니다. 유입량 증대를 위한 노력에 힘써보세요!
		</h2>,
		<h2>
			이번 달 매출은{" "}
			<span style={{ color: negativeColor }}>
				{mention?.count ? mention?.count?.toLocaleString() : 0}원
			</span>
			입니다.
		</h2>,
		mention?.count === 0 ? (
			<h2>
				2일 연속 매출{" "}
				<span style={{ color: negativeColor }}>
					{mention?.count?.toLocaleString() ?? 0}원
				</span>
				입니다. 매출로 이어질 수 있는 활동을 해보는 건 어떨까요?
			</h2>
		) : (
			<h2>
				어제보다 금일 거래액이{" "}
				<span style={{ color: negativeColor }}>
					{mention?.count?.toLocaleString() ?? 0}%
				</span>{" "}
				감소했습니다. 매출로 이어질 수 있는 활동을 해보는 건 어떨까요?
			</h2>
		),
		<h2>
			답변하지 않은 댓글이{" "}
			<span style={{ color: negativeColor }}>
				{mention?.count?.toLocaleString() ?? 0}개
			</span>{" "}
			입니다. 양방향 소통은 채널 유지에 큰 도움이 됩니다.
		</h2>,
	];

	const positiveSubList = [
		<>
			금일 유입량 {mention?.inflow_now?.toLocaleString() ?? 0}회로, 전일
			유입량인 {mention?.inflow_before?.toLocaleString() ?? 0}회보다
			증가했습니다.
		</>,
		<>
			최고 매출을 달성할 수 있었던 제품이나 홍보 방법을 기억해두고 앞으로
			적극적으로 활용해 보세요.
		</>,
		<>
			금일 거래액 {mention?.today?.toLocaleString() ?? 0}원으로, 전일 거래액인{" "}
			{mention?.yesterday?.toLocaleString() ?? 0}
			원보다 {mention?.compare_total?.toLocaleString() ?? 0}원 증가했습니다.
		</>,
	];
	const negativeSubList = [
		<>
			금일 유입량 {mention?.inflow_now?.toLocaleString() ?? 0}회로, 전일
			유입량인 {mention?.inflow_before?.toLocaleString() ?? 0}회보다 부족합니다.
		</>,
		<>
			역대 최고 월 매출은{" "}
			<b>
				{mention?.best_month} {mention?.best_amount?.toLocaleString() ?? 0}원
			</b>
			이였습니다. 최고 매출 당시 활용했던 비결을 활용해보세요!
		</>,
		<>공동구매 활동과 SNS 홍보 활동을 적극적으로 활용해보세요!</>,
		<>가입자들과 1:1 소통하는 느낌을 받을 수 있도록 답변을 달아보세요!</>,
	];

	if (
		mention?.id === 0 ||
		mention?.id === 1 ||
		mention?.id === 2 ||
		mention?.id === 3
	) {
		if (type === "dashboard") {
			return (
				<div>
					{mention.condition ? (
						<p className={`light-gray`} style={{ fontWeight: 500 }}>
							{positiveSubList[mention?.id]}
						</p>
					) : (
						<p className={`light-gray`} style={{ fontWeight: 500 }}>
							{negativeSubList[mention?.id]}
						</p>
					)}
				</div>
			);
		} else if (type === "calculate") {
			return <></>;
		} else {
			return (
				<div className={`dashboard-component`}>
					<div className={`dashboard-banner-div`}>
						<img
							src={`/assets/images/dashboard/${
								mention.condition ? "good" : "warning"
							}.png`}
							alt={`대시보드 경고 아이콘`}
							style={{
								width: "3em",
								height: "3em",
							}}
						/>
						<div>
							{mention.condition ? (
								<>
									{
										positiveMentionList[
											mention?.id
												? mention.id
													? mention.id !== 3
														? mention.id
														: 0
													: 0
												: 0
										]
									}
								</>
							) : (
								<>{negativeMentionList[mention?.id]}</>
							)}
							{mention.condition ? (
								<p className={`light-gray`} style={{ fontWeight: 500 }}>
									{positiveSubList[mention?.id]}
								</p>
							) : (
								<p className={`light-gray`} style={{ fontWeight: 500 }}>
									{negativeSubList[mention?.id]}
								</p>
							)}
						</div>
					</div>
				</div>
			);
		}
	} else {
		return <></>;
	}
};
export default BannerAlgorithm;
