import { useHistory } from "react-router-dom";

const FooterComponent = () => {
	const history = useHistory();

	return (
		<div className="together-footer-wrap">
			<img
				src={`${process.env.REACT_APP_HOST}/assets/images/main/togetherLogo3.png`}
				className="footer-dangyoung-logo"
			/>
			<div className={`footer-information`}>
				<span className="footer-address-text">
					서울 강남구 테헤란로 501, 브이플렉스 5층 516
				</span>
				<span className="footer-address-text-mobile">
					서울 강남구 테헤란로 501, 브이플렉스 5층 516
				</span>
				<div style={{ display: "flex", flexDirection: "column" }}>
					<a
						className="footer-terms-of-service-text"
						onClick={() => history.push("/terms")}
						// href="https://dangyoung.com/terms"
						// target="_blank"
					>
						이용약관
					</a>
					<a
						className="footer-privacy-policy-text"
						onClick={() => history.push("/policy")}
						// href="https://dangyoung.com/policy"
						// target="_blank"
					>
						개인정보처리방침
					</a>
				</div>
			</div>
			<div className="footer-phone-email-wrap">
				T : 1660 - 1024
				<span style={{ marginLeft: 40 }}>E : how2yak@howtoyak.com</span>
			</div>
			<div className="footer-inquiry-wrap">
				<a href="mailto:recruit@howtoyak.com">
					채용문의 : recruit@howtoyak.com
				</a>
				<span className={`division`}>|</span>
				<a href="mailto:biz@howtoyak.com">제휴제안문의 : biz@howtoyak.com</a>
			</div>
			<div className="footer-inquiry-wrap">
				<a href="mailto:ad@howtoyak.com">광고문의 : ad@howtoyak.com</a>
				<span className={`division`}>|</span>
				<a href="mailto:contact@howtoyak.com">
					기타문의 : contact@howtoyak.com
				</a>
			</div>
		</div>
	);
};

export default FooterComponent;
