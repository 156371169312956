import * as React from "react";

import { Modal } from "antd";

const CheckGoldUnitModal = ({ show, setShow }) => {
	return (
		<Modal
			title=""
			open={show}
			footer={null}
			closable={false}
			wrapClassName="modal-wrap seller-grade-modal-wrap custom-modal-wrap"
			width={400}
			centered={true}
		>
			<div
				className="modal-title"
				style={{ width: "60%", wordBreak: "keep-all" }}
			>
				10 Gold 단위로 정산가능합니다.
			</div>
			<div className="modal-button-wrap">
				<div className="modal-button-success" onClick={() => setShow(false)}>
					확인
				</div>
			</div>
		</Modal>
	);
};

export default CheckGoldUnitModal;
