import { Redirect, Route, Switch, useLocation } from "react-router-dom";

import BenefitInformation from "../../pages/BenefitInformation";
import Calculate from "../../pages/Calculate";
import Policy from "../../pages/common/Policy";
import Terms from "../../pages/common/Terms";
import DashboardNew from "../../pages/DashboardNew";
import BenefitPage from "../../pages/event/BenefitPage";
import RecommendPage from "../../pages/event/RecommendPage";
import SupporterPage from "../../pages/event/SupporterPage";
import Faq from "../../pages/Faq";
import LandingPage from "../../pages/LandingPage";
import Notice from "../../pages/Notice";
import Page404 from "../../pages/Page404";
import Products from "../../pages/Products";
import Reports from "../../pages/reports/Reports";
import SignInPage from "../../pages/SignInPage";
import Recommend from "../../pages/user/Recommend";
import UserInquiry from "../../pages/user/UserInquiry";
import UserEdit from "../../pages/UserEdit";
import UserInquiryList from "../../pages/UserInquiryList";
import AuthRoute from "../../utils/AuthRoute";
import PublicRoute from "../../utils/PublicRoute";
import GoldSettlementRequestCompleteComponent from "../gold/GoldSettlementRequestCompleteComponent";
import GoldSettlementRequestComponent from "../gold/GoldSettlementRequestComponent";
import LandingLoginComponent from "../landing/LandingLoginComponent";
import ProductLinkComponent from "../products/ProductLinkComponent";
import SignInComponent from "../signIn/SignInComponent";
import FindPasswordComponent from "../user/FindPasswordComponent";
import SellerMyGrade from "../user/SellerMyGrade";
import SellerMypage from "../user/SellerMypage";
import SignUpComponent from "../user/SignUpComponent";
import TogetherSignup from "../user/TogetherSignup";

const ContentComponent = () => {
	const location = useLocation();
	const path = location.pathname;

	return (
		<Switch>
			<Route path="/benefit/information" component={BenefitInformation} />

			<PublicRoute exact path="/" restricted={true} component={SignInPage} />
			{/*<PublicRoute*/}
			{/*	exact*/}
			{/*	path="/login"*/}
			{/*	restricted={true}*/}
			{/*	component={SignInPage}*/}
			{/*/>*/}
			<Route path="/faq" component={Faq} />
			<Route path="/notice" component={Notice} />
			<Route path="/policy" component={Policy} />
			<Route path="/terms" component={Terms} />
			<Route path="/event/supporter" component={SupporterPage} />
			<Route path="/event/recommend" component={RecommendPage} />
			<Route path="/event/benefit" component={BenefitPage} />
			<PublicRoute path="/user/signup" component={SignUpComponent} />
			<Route path="/user/together/signup" component={TogetherSignup} />
			<PublicRoute path="/user/find" component={FindPasswordComponent} />

			{/*<AuthRoute path="/user/inquiry/list" component={UserInquiryList} />*/}
			<AuthRoute path="/user/inquiry" component={UserInquiry} />
			<AuthRoute path="/user/recommend" component={Recommend} />
			<AuthRoute path="/user/edit" component={UserEdit} />
			<AuthRoute path="/user" component={Calculate} />
			{/*<AuthRoute path="/user/mygrade" component={SellerMyGrade} />*/}
			{/* <AuthRoute path="/user/inquiry" component={UserEdit} /> */}

			{/* 로그인 필요 */}
			<AuthRoute exact path="/dashboard" component={DashboardNew} />
			<AuthRoute exact path="/products/link" component={ProductLinkComponent} />
			<AuthRoute exact path="/products" component={Products} />
			<AuthRoute exact path="/reports" component={Reports} />
			<AuthRoute
				exact
				path="/gold/settlement/request"
				component={GoldSettlementRequestComponent}
			/>
			<AuthRoute
				exact
				path="/gold/settlement/complete"
				component={GoldSettlementRequestCompleteComponent}
			/>

			<Route path="/404" component={Page404} />

			{path.includes("/together") ? (
				<Route path="*">
					<Redirect to={`/404`} />
				</Route>
			) : (
				<Route path="*">
					<PublicRoute component={SignInPage} restricted={true} />
				</Route>
			)}
		</Switch>
	);
};

export default ContentComponent;
