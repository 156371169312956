import React from "react";
import { hydrate, render } from "react-dom";
import { HelmetProvider } from "react-helmet-async";
import { Provider } from "react-redux";

import { ConfigProvider, message } from "antd";
import koKR from "antd/lib/locale/ko_KR";
import moment from "moment";
import "./index.css";

import { PersistGate } from "redux-persist/integration/react";
import "dayjs/locale/ko";
import "moment/locale/ko";

import App from "./App";
import configureStore from "./redux/store/configureStore";

moment.locale("ko");

const configStore = configureStore();

message.config({
	maxCount: 1,
	duration: 1,
});

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
	hydrate(
		<React.StrictMode>
			<Provider store={configStore.store}>
				<PersistGate persistor={configStore.persistor}>
					<HelmetProvider>
						<ConfigProvider locale={koKR}>
							<App />
						</ConfigProvider>
					</HelmetProvider>
				</PersistGate>
			</Provider>
		</React.StrictMode>,
		rootElement
	);
} else {
	render(
		<React.StrictMode>
			<Provider store={configStore.store}>
				<PersistGate persistor={configStore.persistor}>
					<HelmetProvider>
						<ConfigProvider locale={koKR}>
							<App />
						</ConfigProvider>
					</HelmetProvider>
				</PersistGate>
			</Provider>
		</React.StrictMode>,
		rootElement
	);
}
