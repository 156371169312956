import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";

import { Button, Input, Modal, Row, Skeleton, Tooltip } from "antd";
import moment from "moment";

import dayjs from "dayjs";

import orderApi from "../../apis/Order.api";
import partnersApi from "../../apis/Partners.api";
import settlementApi from "../../apis/Settlement.api";
import summaryApi from "../../apis/Summary.api";
import ApiService from "../../utils/ApiService";
import { toastAlert } from "../AlertComponent";
import BannerAlgorithm from "../dashboard/BannerAlgorithm";
import UserVerificationModal from "../user/UserVerificationModal";

const api = new ApiService();

const GoldInfo = ({ type, mediaResult }) => {
	const history = useHistory();
	const user = useSelector((state) => state.auth.user);
	const [loading, setLoading] = useState(false);
	const [pickDate, setPickDate] = useState(null); // 0 : 유입량, 1: 월매출, 2: 일매출
	const [selectMention, setSelectMention] = useState({
		id: 0,
		name: "",
		condition: true,
		count: 0,
	});
	const [isSettlement, setIsSettlement] = useState(false);

	const [isShowModalCertify, setIsShowModalCertify] = useState(false); // 본인 인증 모달
	const [isShowModalUserCertify, setIsShowModalUserCertify] = useState(false); // 본인 인증 신청 모달
	const [isShowModalLowCash, setIsShowModalLowCash] = useState(false); // 1,000 골드 미만
	const [isShowModalNextMonth, setIsShowModalNextMonth] = useState(false); // 익월 정산 요청 가능
	const [isShowModalChangeCash, setIsShowModalChangeCash] = useState(false); // 정산하기
	const [isShowModalSuccess, setIsShowModalSuccess] = useState(false); // 전환 신청 완료
	const [gold, setGold] = useState({
		gold: 0,
		settled_gold: 0,
		cash: 0,
	});
	const [changeGold, setChangeGold] = useState(0);
	const [bankList, setBankList] = useState([]);
	const [bankInfo, setBankInfo] = useState({
		bank_code: "",
		account_number: "",
		account_holder_name: "",
	});

	const getBankList = async () => {
		try {
			const res = await partnersApi.readBankList();
			if (res.code === 200) {
				let list = res.result;
				setBankList(list);
			}
		} catch (e) {
			console.log("e", e);
		}
	};
	useEffect(() => {
		getBankList();
	}, []);

	useEffect(() => {
		// 날짜 % 3
		// const date = new Date();
		// setPickDate(date.getDate() % 3);
		setPickDate(1); // 0 : 유입량, 1: 월매출, 2: 일매출
	}, [user]);
	// console.log(user);
	useEffect(() => {
		// 0 : 유입량, 1: 월매출, 2: 일매출, 3: 댓글
		if (user?.id) {
			if (pickDate === 0) {
				const timer = setTimeout(() => {
					getUserThatTime();
				}, 100);
				return () => clearTimeout(timer);
			} else if (pickDate === 1) {
				const timer = setTimeout(() => {
					getLargestAmount();
				}, 100);
				return () => clearTimeout(timer);
			} else if (pickDate === 2) {
				const timer = setTimeout(() => {
					getStatistics();
				}, 100);
				return () => clearTimeout(timer);
			}
		}
	}, [pickDate, user]);

	/**
	 * 0) 유입량
	 * @type {(function(): Promise<void>)|*}
	 */
	const getUserThatTime = useCallback(async () => {
		setLoading(true);
		const paramNow = {
			userId:
				user?.role === "level_1" || user?.role === "super_admin"
					? user?.id
					: user?.partners_id,
			startDate: moment()
				.subtract(1, "days")
				.startOf("days")
				.format("YYYYMMDDHHmm"),
			endDate: moment().endOf("days").format("YYYYMMDDHHmm"),
			dateType: "weekly",
		};
		const res = await summaryApi.readDashboardUserThatTime(paramNow);
		if (res.code === 200) {
			let list = res.result?.userSummaryList;
			let now = list[list?.length - 1];
			let before = list[list?.length - 2];

			// 시간 대비 계산 -> ex) 17시면, 전날 유입량 * 17/24
			before = {
				...before,
				inflow_num: (
					(before.inflow_num / 24) *
					Number(dayjs().format("HH"))
				).toFixed(0),
			};

			setSelectMention({
				id: 0,
				name: "inflow",
				condition: Number(now?.inflow_num) > Number(before?.inflow_num),
				count: Number(before?.inflow_num)
					? Math.abs(
							(1 - now?.inflow_num / Number(before?.inflow_num)) * 100
					  ).toFixed(1)
					: 100,
				inflow_now: Number(now?.inflow_num),
				inflow_before: Number(before?.inflow_num),
			});
		}
		setLoading(false);
	}, [user, pickDate]);
	/**
	 * 1) 월매출 비교
	 * @type {(function(): Promise<void>)|*}
	 */
	const getLargestAmount = useCallback(async () => {
		setLoading(true);
		const param = {
			userId: user?.id,
		};
		let bestMonth = {
			settlement_month: moment().format("YYYYMMDDHHmm"),
			total_purchase_amount: 0,
		};
		let thisMonth = {};

		// 정산 내 가장 큰 데이터
		const res = await settlementApi.readLargestSales(param);
		// 1) 이번달 실시간 데이터 - 쿼리
		const rsp = await api.getProductLinkList(param);
		if (res.code === 200) {
			if (rsp.data.code === 200 && rsp.data.result?.length) {
				const linkList = rsp.data.result.map((link) => link.query);
				const query = linkList.join(",");
				const paramNow = {
					startDate: moment().startOf("months").format("YYYYMMDDHHmm"),
					endDate: moment().endOf("months").format("YYYYMMDDHHmm"),
					queryList: query,
				};

				// 2) 이번달 실시간 데이터 - 통계
				const rspN = await orderApi.orderStatistics(paramNow);
				if (rspN.code === 200) {
					let settleTotal = res.result?.total_purchase_amount;
					let thisTotal = rspN.result?.total_purchase_amount;
					thisMonth = { ...rspN.result };

					if (settleTotal > thisTotal) {
						let year = String(res.result?.year);
						let month = String(res.result?.month);
						bestMonth = {
							settlement_month: moment(year + month, "YYYYMM")
								.endOf("month")
								.format("YYYYMMDDhhmm"),
							...res.result,
						};
					} else {
						bestMonth = {
							...bestMonth,
							...rspN.result,
						};
					}
				}
			}
		}
		let bestMonthBest =
			moment(bestMonth?.settlement_month, "YYYYMMDDhhmm").format("M") ===
			moment().format("M");
		setSelectMention({
			id: 1,
			name: "monthly",
			condition: !!bestMonthBest && thisMonth.total_purchase_amount > 0,
			count: thisMonth.total_purchase_amount,
			best_amount: bestMonth.total_purchase_amount,
			best_month: moment(bestMonth?.settlement_month, "YYYYMMDDhhmm").format(
				"YY년 M월"
			),
		});
		setLoading(false);
	}, [user]);

	/**
	 * 2) 일매출 비교
	 * @type {(function(): Promise<void>)|*}
	 */
	const getStatistics = useCallback(async () => {
		setLoading(true);
		const param = {
			userId: user?.id,
		};
		const res = await api.getProductLinkList(param);
		if (res.data.code === 200 && res.data.result?.length) {
			const linkList = res.data.result.map((link) => link.query);
			const query = linkList.join(",");

			const paramBefore = {
				startDate: moment()
					.subtract(1, "days")
					.startOf("days")
					.format("YYYYMMDDHHmm"),
				endDate: moment()
					.subtract(1, "days")
					.endOf("days")
					.format("YYYYMMDDHHmm"),
				queryList: query,
			};
			const paramNow = {
				startDate: moment().startOf("days").format("YYYYMMDDHHmm"),
				endDate: moment().endOf("days").format("YYYYMMDDHHmm"),
				queryList: query,
			};

			const resB = await orderApi.orderStatistics(paramBefore);
			const resN = await orderApi.orderStatistics(paramNow);

			let beforeValue = {
				total: 0,
				count: 0,
				expect: 0,
				combine: 0,
			};
			let nowValue = {
				total: 0,
				count: 0,
				expect: 0,
				combine: 0,
			};

			if (resB.code === 200) {
				const before = resB.result;

				beforeValue = {
					total: before?.total_purchase_amount ?? 0,
					count: before?.purchases_num ?? 0,
					expect: before?.expected_profit_amount ?? 0,
					combine: before?.combined_amount ?? 0,
				};
			}
			if (resN.code === 200) {
				const now = resN.result;
				nowValue = {
					total: now?.total_purchase_amount ?? 0,
					count: now?.purchases_num ?? 0,
					expect: now?.expected_profit_amount ?? 0,
					combine: now?.combined_amount ?? 0,
				};
			}

			// setStatistics({
			// 	yesterday_total: beforeValue?.total,
			// 	total: nowValue?.total,
			// 	compare_total: nowValue?.total - beforeValue?.total,
			// 	compare_count: nowValue?.count - beforeValue?.count,
			// 	compare_expect: nowValue?.expect - beforeValue?.expect,
			// });

			if (nowValue?.total === 0 && beforeValue?.total === 0) {
				setSelectMention({
					id: 2,
					name: "daily_sales",
					condition: false,
					count: 0,
				});
			} else {
				setSelectMention({
					id: 2,
					name: "daily_sales",
					condition: nowValue?.total > beforeValue?.total,
					count:
						beforeValue?.total !== 0
							? Math.abs(
									(1 - nowValue?.total / beforeValue?.total) * 100
							  ).toFixed(1)
							: 100,
					compare_total: nowValue?.total - beforeValue?.total,
					today: nowValue?.total,
					yesterday: beforeValue?.total,
				});
			}
		} else {
			setSelectMention({
				id: 2,
				name: "daily_sales",
				condition: 0,
				count: 0,
			});
		}
		setLoading(false);
	}, [user]);

	// 전환하기
	const withdrawalCash = () => {
		// 1. 최소 금액 안내
		if (gold.gold < 100000) {
			setIsShowModalLowCash(true);
			return;
		}
		// 2. 익월 정산 안내
		if (isSettlement) {
			setIsShowModalNextMonth(true);
			return;
		}
		// 3. 본인 인증 체크
		if (!user.is_certified) {
			setIsShowModalCertify(true);
			return;
		}

		// 정산하기
		if (mediaResult) {
			history.push("/gold/settlement/request", {
				bankInfo: bankInfo,
				goldInfo: gold,
			});
		} else {
			setIsShowModalChangeCash(true);
		}
	};

	/**
	 * 골드 관련 정보 조회
	 * @type {(function(): Promise<void>)|*}
	 */
	const getGold = useCallback(async () => {
		if (!bankList.length) return;
		setLoading(true);

		const goldParam = {
			userId: user?.id,
			type: "goldInfo",
		};
		const goldRes = await api.getUserInfoByType(goldParam);
		if (goldRes.data.code === 200) {
			setGold({
				gold: goldRes.data.result?.total_earn_gold,
				settled_gold: goldRes.data.result?.settled_gold,
				cash: goldRes.data.result?.cash,
			});
			setIsSettlement(!!goldRes.data.result?.is_settlement);
		}

		const bankParam = {
			userId: user?.id,
			type: "basic_info",
		};
		const bankRes = await api.getUserInfoByType(bankParam);

		if (bankRes.data.code === 200 && bankRes.data.result?.bank_code) {
			const { name: bankName } = bankList.find(
				(v) => v.code === bankRes.data.result.bank_code
			);
			setBankInfo({
				bank_code: bankRes.data.result.bank_code,
				account_number: bankRes.data.result.account_number,
				bank_name: bankName,
			});
		}
		setLoading(false);
	}, [user, bankList]);

	useEffect(() => {
		getGold();
	}, [user, bankList]);

	useEffect(() => {
		setChangeGold(0);
	}, [isShowModalChangeCash]);

	const changeGoldReq = async () => {
		// 10 골드 -> 1원 -->> 1의자리 골드 막아야 함
		if (changeGold % 10 !== 0) {
			toastAlert("10 Gold 단위로 정산가능합니다.");
			return;
		}
		setLoading(true);
		const param = {
			created_by: user?.id,
			settlement_amount: Number(changeGold) / 10,
			bank_code: bankInfo.bank_code,
			account_number: bankInfo.account_number,
		};

		const res = await api.goldChange(param);

		if (res.data.code === 200) {
			setIsShowModalChangeCash(false);
			setIsShowModalSuccess(true);
		} else if (res.data.code === 405) {
			toastAlert(`100,000 Gold 이상부터\n정산 가능합니다.`);
		} else if (res.data.code === 423) {
			toastAlert(
				"100,000 Gold 이상의 Gold를 입력하시거나 보유 Gold를 확인해주세요."
			);
		} else if (res.data.code === 418) {
			setIsShowModalNextMonth(true);
		}

		setLoading(false);
	};

	return (
		<div
			className={`dashboard-component gold-component-wrap ${
				type !== "dashboard" ? "not-dashboard" : ""
			} ${type === "calculate" && "calculate-dashboard-component"}`}
			style={
				type === "calculate"
					? { padding: "74px 4em 1.5em 4em" }
					: type === "dashboard"
					? { gap: 0 }
					: {}
			}
		>
			{/*제목*/}
			{type === "dashboard" ? (
				<h3>
					회원님의 현재 누적 골드입니다.
					<Tooltip
						overlayClassName={`gold-help-tooltip-wrap`}
						trigger={`click`}
						arrowPointAtCenter
						placement={"topLeft"}
						title={
							<p>
								정산은 <span>전월 말일까지 확정된</span> 골드를 기준으로 합니다.
							</p>
						}
						color={"#FFFFFF"}
					>
						{mediaResult && <span className={`help-div`}>?</span>}
					</Tooltip>
				</h3>
			) : (
				<Row align={"middle"}>
					<p
						className={`${
							type === "calculate" && "calculate-dashboard-component-title"
						}`}
					>
						나의 당영골드
					</p>
					{/*{type === "calculate" && (*/}
					{/*	<div className="calculate-dashboard-questionMark">?</div>*/}
					{/*)}*/}
				</Row>
			)}
			{/*자세히 보기 - PC */}
			{type === "dashboard" && !mediaResult && (
				<Link to={`/reports?level=settlement`} className={`read-more`}>
					{`자세히보기 >`}
				</Link>
			)}
			{/*캐시*/}
			<Skeleton
				loading={type === "dashboard" && loading}
				active={loading}
				size={"large"}
				block={true}
				className={`gold-info-skeleton`}
				title={null}
				paragraph={{
					rows: mediaResult ? 2 : 3,
					width: "100%",
				}}
			>
				<div
					className={`gold-info-wrap`}
					style={
						type === "dashboard"
							? { paddingBottom: "1em", gap: mediaResult ? 8 : 0 }
							: {}
					}
				>
					<div
						className={`gold-info-div ${
							type !== "dashboard" ? "not-dashboard" : ""
						}`}
					>
						{/*<h1>{statistics?.total?.toLocaleString()}</h1>*/}
						{type === "dashboard" && (
							<img
								className={`gold-img`}
								src={`/assets/images/gold/gold-icon.png`}
								alt={`대시보드 경고 아이콘`}
							/>
						)}
						<div className={`gold-info-text-wrap`}>
							<h1 className={`gold-info calculate-gold-info`}>
								{gold.gold?.toLocaleString()}
							</h1>
							{mediaResult ? (
								<span className={`gold-unit`}>Gold</span>
							) : (
								<b>Gold</b>
							)}
						</div>
					</div>
					<div
						className="gold-info-desc"
						style={type !== "dashboard" ? { marginBottom: "1em" } : {}}
					>
						정산 가능한 골드는{" "}
						<span>{gold.settled_gold?.toLocaleString()}</span> Gold입니다.
					</div>
					{type === "dashboard" && !mediaResult && (
						<div className="gold-info-desc">
							정산은 전월 말일까지 확정 된 골드를 기준으로 합니다.
						</div>
					)}
					{/*<BannerAlgorithm mention={selectMention} type={type} />*/}
				</div>
			</Skeleton>
			{/*정산 버튼*/}
			<div className={`border-top gold-settlement-button-wrap`}>
				{type === "dashboard" && loading ? (
					<>
						<Skeleton.Button
							loading={loading}
							active={loading}
							className={`gold-settlement-button-skeleton`}
						>
							<Button
								className={`gold-settlement-button ${
									isSettlement ? "disabled" : "active"
								} ${gold?.gold < 100000 ? "settlement-request" : ""}`}
								onClick={withdrawalCash}
							>
								{isSettlement ? "정산 예정" : "정산하기"}
							</Button>
						</Skeleton.Button>
						{/*자세히 보기 - Mobile */}
						{mediaResult && (
							<Link
								to={`/reports?level=settlement`}
								className={`read-more dashboard-gold-info`}
							>
								{`자세히보기 >`}
							</Link>
						)}
					</>
				) : (
					<>
						<Button
							style={{ minWidth: "300px" }}
							className={`gold-settlement-button ${
								isSettlement ? "disabled" : "active"
							} ${gold?.gold < 100000 ? "settlement-request" : ""}`}
							onClick={withdrawalCash}
						>
							{isSettlement ? "정산 예정" : "정산하기"}
						</Button>
						{/*자세히 보기 - Mobile */}
						{mediaResult && (
							<Link
								to={`/reports?level=settlement`}
								className={`read-more dashboard-gold-info`}
							>
								{`자세히보기 >`}
							</Link>
						)}
					</>
				)}
			</div>

			{/* 모달 - 본인인증 */}
			<Modal
				title=""
				open={isShowModalCertify}
				footer={null}
				closable={false}
				wrapClassName="modal-wrap seller-grade-modal-wrap custom-modal-wrap"
				width={400}
				centered={mediaResult}
			>
				<div className="modal-title">
					정산을 받으려면{"\n"}본인인증이 필요합니다.
				</div>
				<div className="modal-button-wrap">
					<div
						className="modal-button-cancel"
						onClick={() => setIsShowModalCertify(false)}
					>
						취소
					</div>
					<div
						className="modal-button-success"
						onClick={() => {
							setIsShowModalCertify(false);
							setIsShowModalUserCertify(true);
						}}
					>
						인증
					</div>
				</div>
			</Modal>
			{/*모달 - 본인인증 모달*/}
			<UserVerificationModal
				show={isShowModalUserCertify}
				setShow={setIsShowModalUserCertify}
				mediaResult={mediaResult}
				bankList={bankList}
			/>
			{/* 모달 - 1,000 골드 이상 */}
			<Modal
				title=""
				open={isShowModalLowCash}
				footer={null}
				closable={false}
				wrapClassName="modal-wrap seller-grade-modal-wrap custom-modal-wrap"
				width={400}
				centered={mediaResult}
			>
				<div
					className="modal-title"
					style={{
						wordBreak: "keep-all",
					}}
				>
					100,000골드 이상부터 정산 가능합니다.
				</div>
				<div className="modal-button-wrap">
					<div
						className="modal-button-success"
						onClick={() => setIsShowModalLowCash(false)}
					>
						확인
					</div>
				</div>
			</Modal>
			{/* 모달 - 익월 정산 가능 */}
			<Modal
				title=""
				open={isShowModalNextMonth}
				footer={null}
				closable={false}
				wrapClassName="modal-wrap seller-grade-modal-wrap custom-modal-wrap"
				width={400}
				centered={mediaResult}
			>
				<div className="modal-title" style={{ marginBottom: 0 }}>
					익월 정산 요청가능합니다.
				</div>
				<div className="modal-title-sub">*정산은 한달에 한번만 가능합니다.</div>
				<div className="modal-button-wrap">
					<div
						className="modal-button-success"
						onClick={() => {
							setIsShowModalNextMonth(false);
							setIsShowModalChangeCash(false);
						}}
					>
						확인
					</div>
				</div>
			</Modal>
			{/* 모달 - 정산 */}
			<Modal
				title=""
				open={isShowModalChangeCash}
				footer={null}
				closable={false}
				wrapClassName="modal-wrap seller-grade-modal-wrap"
				width={515}
			>
				<div className="modal-change-cash-wrap" style={{ borderRadius: "6px" }}>
					<div className="modal-has-cash-text">
						정산 가능한 골드는{" "}
						<span style={{ color: "#117ffa" }}>
							{gold.gold?.toLocaleString()}{" "}
						</span>
						Gold입니다.
					</div>
					<div className="modal-input-wrap">
						<div
							style={{
								width: "79%",
								position: "relative",
							}}
						>
							<Input
								readOnly
								style={{ width: "290px", maxWidth: "100%" }}
								className="modal-input"
								// placeholder={`정산`}
								onChange={(e) => {
									if (isNaN(e.currentTarget.value.replaceAll(",", ""))) {
										return;
									}
									setChangeGold(e.currentTarget.value.replaceAll(",", ""));
								}}
								// value={changeGold ? Number(changeGold).toLocaleString() : ""}
							/>
							<div className="modal-cash-text">
								{`${Number(changeGold).toLocaleString()}`} <span>Gold</span>
							</div>
						</div>
						<div
							className={`modal-input-button ${
								changeGold === gold.gold ? "modal-input-button-all" : undefined
							}`}
							onClick={() => setChangeGold(gold.gold)}
						>
							전액 정산
						</div>
					</div>
					<div className="modal-sub-desc-text">
						*100 Gold = 10원 <br />
						*100,000 Gold 이상부터 정산 가능합니다
					</div>
					<div
						className="modal-input-wrap"
						style={{ marginBottom: "12px", justifyContent: "start" }}
					>
						<div
							className="modal-has-cash-text"
							style={{
								alignItems: "center",
								display: "flex",
								width: "13%",
								height: "100%",
							}}
						>
							은행
						</div>
						<Input
							className="modal-input"
							style={{ width: "62%", color: "#000" }}
							disabled
							value={bankInfo.account_number}
						/>
						{/* <div style={{ position: "relative", width: "62%" }}>
							<Dropdown
								menu={{
									items: [{ label: "카카오 뱅크", key: "카카오 뱅크" }],
									onClick: () => {},
								}}
								trigger={["click"]}
								overlayStyle={{
									maxHeight: 200,
									overflow: "auto",
								}}
							>
								<Input
									className="modal-input"
									placeholder={`은행`}
									style={{ width: "100%" }}
								/>
							</Dropdown>
							<img
								src={`/assets/images/down-arrow.png`}
								alt={`화살표`}
								style={{
									height: "0.5em",
									width: "1em",
									position: "absolute",
									top: "46%",
									right: "4%",
								}}
							/>
						</div> */}
						{/* <div className="modal-input-button">수정</div> */}
					</div>
					<div className="modal-input-wrap" style={{ justifyContent: "start" }}>
						<div
							className="modal-has-cash-text"
							style={{
								alignItems: "center",
								display: "flex",
								width: "13%",
								height: "100%",
							}}
						>
							계좌번호
						</div>
						<Input
							className="modal-input"
							style={{ width: "62%", color: "#000" }}
							disabled
							value={bankInfo.bank_name}
						/>
					</div>
				</div>
				<div className="modal-button-wrap" style={{ gap: "7px" }}>
					<div
						className="modal-button-cancel"
						onClick={() => setIsShowModalChangeCash(false)}
						style={{ width: "30%" }}
					>
						취소
					</div>
					<div
						className="modal-button-success"
						onClick={() => {
							if (!loading) {
								changeGoldReq();
							}
						}}
						style={{ width: "30%" }}
					>
						정산
					</div>
				</div>
			</Modal>
			{/* 모달 - 정산 신청 완료 */}
			<Modal
				title=""
				open={isShowModalSuccess}
				footer={null}
				closable={false}
				wrapClassName="modal-wrap seller-grade-modal-wrap"
				width={515}
			>
				<div className="modal-title" style={{ marginBottom: "1em" }}>
					정산 신청이 완료 되었습니다.
				</div>
				<div className="seller-grade-modal-cont">
					<div className="modal-gold-settlement-div tooltip">
						<span>{changeGold}</span>Gold
					</div>
					<div className="modal-gold-settlement-div">
						<span>{changeGold / 10}</span>원
					</div>
					<p className="modal-gold-settlement-help">*100 Gold = 10원</p>
				</div>
				<div className="modal-button-wrap" style={{ marginTop: "25px" }}>
					<div
						onClick={() => setIsShowModalSuccess(false)}
						className="modal-button-success"
						style={{ width: "50%" }}
					>
						확인
					</div>
				</div>
			</Modal>
		</div>
	);
};

export default GoldInfo;
