import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { Collapse, Row, Tabs } from "antd";

import partnersApi from "../../apis/Partners.api";
const { Panel } = Collapse;

const Faq = ({ mediaResult }) => {
	const user = useSelector((state) => state.auth.user);

	const [currentMenu, setCurrentMenu] = useState("service");
	const [currentPanel, setCurrentPanel] = useState(null);

	const [faqMenuList, setFaqMenuList] = useState([]);
	const [faqList, setFaqList] = useState([]);

	useEffect(() => {
		getMenuList();
	}, []);

	const handleMenu = (e) => {
		if (e !== currentMenu) {
			setCurrentMenu(e);
			getFaqList(e);
		}
		setCurrentPanel(null);
	};

	/**
	 * 메뉴 리스트 & faq 리스트 가져오기
	 * @returns {Promise<void>}
	 */
	const getMenuList = async () => {
		const res = await partnersApi.readFaqMenu();
		if (res.code === 200) {
			setFaqMenuList(res.result);
			const firstCategory = res.result[0].category_id;
			const rsq = await partnersApi.readFaqList({
				category_id: firstCategory,
			});
			if (rsq.code === 200) {
				setFaqList(rsq.result);
			}
		}
	};

	/**
	 * 해당 메뉴에 따른 faq 리스트 가져오기
	 * @param category_id
	 * @returns {Promise<void>}
	 */
	const getFaqList = async (category_id) => {
		let param = {
			category_id: category_id,
		};
		const res = await partnersApi.readFaqList(param);
		if (res.code === 200) {
			setFaqList(res.result);
		}
	};

	const menuItems = [
		...faqMenuList.map((menu) => {
			return {
				label: menu.category_name,
				key: menu.category_id,
				children: (
					<>
						{faqList?.length > 0 ? (
							<Collapse
								accordion
								bordered={false}
								activeKey={currentPanel ? [currentPanel] : []}
								className="faq-custom-collapse dashboard"
								onChange={(e) => setCurrentPanel(e ? String(e) : null)}
								expandIconPosition={"end"}
							>
								{faqList.slice(0, 2).map((item, idx) => (
									<Panel
										header={
											<div>
												<span
													className={
														"faq-custom-collapse-panel-header-question"
													}
												>
													Q
												</span>
												{item.question}
											</div>
										}
										key={idx}
										className={`faq-custom-collapse-panel dashboard ${
											currentPanel === String(idx) ? "clicked" : ""
										}`}
									>
										<p>{item.answer}</p>
									</Panel>
								))}
							</Collapse>
						) : (
							<Row
								align={"middle"}
								style={{
									width: "100%",
									flexDirection: "column",
									padding: "3em 0",
								}}
							>
								<img
									src={`/assets/images/warning.png`}
									alt={`warning-icon`}
									style={{
										width: "3.5em",
										height: "auto",
									}}
								/>
								<span
									style={{
										marginTop: "1em",
										color: "#adb5bd",
										fontSize: "1.2em",
										fontWeight: 500,
									}}
								>
									일치하는 질문이 없습니다.
								</span>
							</Row>
						)}
					</>
				),
			};
		}),
	];

	let normalUser = !user?.role || user?.role === "level_2";

	return (
		<div
			className="dashboard-component notice-component-wrap"
			style={
				!normalUser
					? mediaResult
						? { width: "100%", position: "relative" }
						: { width: "49%", position: "relative" }
					: { width: "100%", position: "relative" }
			}
		>
			<div className={`dashboard-component-header`}>
				<h3>자주묻는 질문</h3>
				<p className={`main-description dashboard`}>
					자세한 사항은 담당자에게 문의해주세요.
				</p>
			</div>
			<Link to={`/faq`} className={`read-more faq`}>
				{`자세히보기 >`}
			</Link>
			<Tabs
				className={`notice-tab-bar-wrap`}
				defaultActiveKey={currentMenu}
				items={menuItems}
				onChange={handleMenu}
			/>

			<div className="dashboard-component-footer no-line mobile">
				<Link to={`/faq`} className={`read-more dashboard-gold-info`}>
					{`자세히보기 >`}
				</Link>
			</div>
		</div>
	);
};
export default Faq;
