import { useHistory } from "react-router-dom";

const Page404 = () => {
	const history = useHistory();
	return (
		<div
			style={{
				width: "100vw",
				height: "100vh",
				position: "relative",
				background: `no-repeat url("${process.env.REACT_APP_HOST}/assets/images/channel/404-bg.png")`,
				backgroundSize: "cover",
				backgroundPosition: "center",
			}}
		>
			<div className={`not-found-wrap`}>
				<img
					src={`/assets/images/channel/404-together.png`}
					alt={`화살표`}
					width={60}
				/>
				<h1>원하시는 페이지를 찾을 수 없습니다.</h1>
				<p>
					찾으려는 페이지의 주소가 잘못 입력되었거나,{"\n"}
					주소의 변경 혹은 삭제로 인해 사용하실 수 없습니다.{"\n"}
					입력하신 페이지의 주소가 정확한지 다시 한번 확인해 주세요.
				</p>
				<button onClick={() => history.replace("/dashboard")}>메인으로</button>
			</div>
		</div>
	);
};
export default Page404;
