import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { Button, Space } from "antd";

import Swal from "sweetalert2";

import apiService from "../../utils/ApiService";

import ProductLinkInformationModal from "./ProductLinkInformationModal";
import ProductLinkModal from "./ProductLinkModal";

const api = new apiService();
const ProductComponent = ({
	item,
	handleChangePagination,
	currentPageNumber,
	selectedLinkItem,
	setSelectedLinkItem,
	type,
	mediaResult,
}) => {
	const history = useHistory();
	const user = useSelector((state) => state.auth.user);

	const [queryLoading, setQueryLoading] = useState(false);
	const [queryUrl, setQueryUrl] = useState(item.product_link ?? "");

	const [linkShow, setLinkShow] = useState(false);
	const [linkInfoShow, setLinkInfoShow] = useState(false);

	useEffect(() => {
		setLinkShow(false);
	}, [currentPageNumber]);

	const [returnQuery, setReturnQuery] = useState({});

	const onClickShowLink = () => {
		setSelectedLinkItem(item);
		let link = item?.product_link;
		if (link) {
			setQueryUrl(link);
			if (mediaResult) {
				history.push("/products/link", {
					item: item,
					url: queryUrl,
				});
			} else {
				setLinkShow(true);
			}
		} else {
			checkNotDuplication();
		}
	};

	/**
	 * 쿼리 중복 여부 체크
	 * @returns {Promise<{result, query: string}>}
	 * true : 중복 아님, false: 중복
	 */
	const checkNotDuplication = async () => {
		setQueryLoading(true);
		let text = "";
		const possible =
			"ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
		let isValid = false;
		while (!isValid) {
			for (let i = 0; i < 8; i++) {
				text += possible.charAt(Math.floor(Math.random() * possible.length));
			}
			let param = {
				user_id: user.id,
				product_id: item.product_id,
				query: text,
			};
			const res = await api.checkDuplicateProductLink(param);
			if (res.data.code === 200) {
				if (!isValid) {
					setReturnQuery({
						result: res.data.result,
						query: text,
					});
				}
				isValid = true;
			}
		}
	};

	useEffect(() => {
		if (
			Object.prototype.hasOwnProperty.call(returnQuery, "result") &&
			Object.prototype.hasOwnProperty.call(returnQuery, "query")
		) {
			createRandomQuery();
		}
	}, [returnQuery]);

	/**
	 * 쿼리 생성
	 * @returns {Promise<void>}
	 */
	const createRandomQuery = async () => {
		// true: 중복아님, false: 중복
		if (!queryLoading) {
			setQueryLoading(true);
		}
		const { result, query } = returnQuery;
		const dataQuery = !!item?.product_link;
		if (!dataQuery && result) {
			let param = {
				product_id: item?.product_id,
				user_id: user.id,
				query: query,
			};
			const res = await api.issueProductLink(param);
			if (res.data.code === 200) {
				setQueryUrl(res.data.result.query);
				if (mediaResult) {
					history.push("/products/link", {
						item: item,
						url: res.data.result.query,
					});
				} else {
					setLinkShow(true);
				}
				if (handleChangePagination) {
					handleChangePagination(currentPageNumber, "query");
				}
			}
		}
		setQueryLoading(false);
	};
	return (
		<>
			<div className={`commission-product-image-div`}>
				<img
					src={`${process.env.REACT_APP_S3_HOST}/image/recommend/${item.target_id}.jpg`}
					onError={({ currentTarget }) => {
						currentTarget.onerror = null; // prevents looping
						currentTarget.src = `/assets/images/no-product.png`;
					}}
					alt={`${item.brand_name} ${item.name}`}
					style={{
						objectFit: "cover",
					}}
				/>
			</div>

			<Space
				style={{ width: "100%", padding: "0.5em 0 18px 0" }}
				className={`product-info-wrap`}
			>
				<Button
					className={`product-info-btn`}
					style={{
						width: "100%",
					}}
					onClick={() => {
						setLinkInfoShow(true);
					}}
				>
					<span>상품정보</span>
				</Button>
				<Button
					className={`product-link-btn`}
					style={{
						width: "100%",
					}}
					onClick={onClickShowLink}
					loading={queryLoading}
				>
					<span>링크생성</span>
				</Button>
			</Space>

			{/* 상품 정보 모달 */}
			<ProductLinkInformationModal
				item={item}
				linkInfoShow={linkInfoShow}
				setLinkInfoShow={setLinkInfoShow}
				mediaResult={mediaResult}
			/>

			{/* 상품 링크 모달 */}
			<ProductLinkModal
				user={user}
				linkShow={linkShow}
				setLinkShow={setLinkShow}
				selectedLinkItem={selectedLinkItem}
				queryUrl={queryUrl}
				item={item}
			/>

			<div className={`product-name-wrap`}>
				{type && type === "dashboard" ? (
					<>
						<div className={`product-etc-info-wrap`}>
							<div className={`product-brand-name`}>
								[
								{item.product_brand_name
									? item.product_brand_name
									: item.brand_name}
								]
							</div>
							<div
								className={`commission-wrap ${
									item?.commission_rate >= 20.0 ? "high" : ""
								}`}
							>
								커미션 <span>{item?.commission_rate ?? 0}%</span>
							</div>
						</div>
						<span className={`product-name`}>
							{item.product_name ? item.product_name : item.name}
						</span>
					</>
				) : (
					<>
						<span className={`product-brand-name`}>
							{item.product_brand_name
								? item.product_brand_name
								: item.brand_name}
						</span>
						<span className={`product-name`}>
							{item.product_name ? item.product_name : item.name}
						</span>
						<div
							className={`commission-wrap ${
								item?.commission_rate >= 20.0 ? "high" : ""
							}`}
						>
							커미션 <span>{item?.commission_rate ?? 0}%</span>
						</div>
					</>
				)}
			</div>
		</>
	);
};

export default ProductComponent;
