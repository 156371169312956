import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import "../Calculate.scss";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";

import queryString from "querystring";

import { Modal } from "antd";

import Swal from "sweetalert2";

import channelUserApi from "../apis/ChannelUser.api";
import inquiryApi from "../apis/Inquiry.api";
import partnersApi from "../apis/Partners.api";
import EditInfo from "../components/calculate/EditInfo";
import Inquiry from "../components/calculate/Inquiry";
import Point from "../components/calculate/Point";
import { setUser, setChannelUser } from "../redux/store/auth/authDuck";
import { setLoading } from "../redux/store/common/commonDuck";
import ApiService from "../utils/ApiService";
const api = new ApiService();

const Calculate = () => {
	const [gold, setGold] = useState({
		gold: 0,
		settled_gold: 0,
		cash: 0,
	});
	const dispatch = useDispatch();
	const [mediaResult, setMediaResult] = useState(null);
	const [secession, setSecession] = useState(false);
	const setIsLoading = (value) => dispatch(setLoading(value));
	useEffect(() => {
		if (typeof window !== "undefined") {
			const matchQueryList = window.matchMedia("all and (max-width: 768px)");
			setMediaResult(matchQueryList.matches);

			const media = function handleChange(e) {
				setMediaResult(e.matches);
			};

			matchQueryList.addEventListener("change", media);
		}
	}, []);
	const history = useHistory();
	const user = useSelector((state) => state.auth.user);
	const queryJson = queryString.parse(
		history.location?.search?.replace("?", "")
	);

	const [active, setActive] = useState(1);
	const [unreadInquiry, setUnreadInquiry] = useState(false);

	useEffect(() => {
		if (queryJson && queryJson?.inquiry) {
			setActive(2);
		}
	}, [queryJson]);
	const getGold = useCallback(async () => {
		const goldParam = {
			userId: user?.id,
			type: "goldInfo",
		};
		const goldRes = await api.getUserInfoByType(goldParam);
		if (goldRes.data.code === 200) {
			setGold({
				gold: goldRes.data.result?.total_earn_gold,
				settled_gold: goldRes.data.result?.settled_gold,
				cash: goldRes.data.result?.cash,
			});
		}
	});
	useEffect(() => {
		if (user?.id) {
			readUnreadInquiryCnt();
			getGold();
		}
	}, [user]);

	/**
	 * 로그아웃
	 * @returns {Promise<void>}
	 */
	const logout = async () => {
		setIsLoading(true);
		Swal.fire({
			customClass: "alert-custom-confirm logout",
			text: `로그아웃 하시겠습니까?`,
			showCancelButton: true,
			cancelButtonText: "취소",
			cancelButtonColor: "#FFFFFF",
			confirmButtonText: "확인",
			confirmButtonColor: "#117FFA",
		}).then(async (result) => {
			if (result.isConfirmed) {
				const param = {
					id: user.id,
				};
				await partnersApi.logout(param);
				localStorage.removeItem("admin:accessToken");
				localStorage.removeItem("admin:refreshToken");

				dispatch(setChannelUser(null));
				dispatch(setUser(null));
				setIsLoading(false);
				history.push(`/`);
			}
		});
		setIsLoading(false);
	};

	/**
	 * 탈퇴시, 주의사항 안내 팝업 > "탈퇴" 버튼 클릭시
	 * @param e
	 */
	const handleSecession = () => {
		setSecession(false);
		Swal.fire({
			customClass: "alert-custom-confirm",
			text: `탈퇴 버튼 선택시\n계정은 삭제되며 복구되지 않습니다.`,
			showCancelButton: true,
			confirmButtonText: "탈퇴",
			confirmButtonColor: "#117FFA",
			cancelButtonText: "취소",
			cancelButtonColor: "#FFFFFF",
		}).then(async (res) => {
			if (res.isConfirmed) {
				setIsLoading(true);
				try {
					const param = {
						userId: user.id,
						isInfluencer: true,
					};
					const leaveRes = await channelUserApi.leave(param);
					if (leaveRes.code === 200) {
						Swal.fire({
							customClass: "alert-custom-confirm",
							text: `회원탈퇴가 완료되었습니다.`,
							showCancelButton: false,
							confirmButtonText: "확인",
							confirmButtonColor: "#117FFA",
						}).then(() => {
							localStorage.removeItem("admin:accessToken");
							localStorage.removeItem("admin:refreshToken");
							dispatch(setUser(null));
							setIsLoading(false);
							history.push(`/`);
						});
					}
				} catch (e) {
					console.log("handleSecession :: error = ", e);
				} finally {
					setIsLoading(false);
				}
			}
		});
	};

	/**
	 * 답변이 완료되었으나 읽지 않은 문의 갯수 조회
	 * @returns {Promise<void>}
	 */
	const readUnreadInquiryCnt = async () => {
		try {
			const param = {
				userId: user?.id ?? 0,
			};
			const res = await inquiryApi.readUnreadInquiryCnt(param);
			if (res.code === 200 && !!res.result?.total) {
				setUnreadInquiry(true);
			}
		} catch (e) {
			console.log("readUnreadInquiryCnt :: error = ", e);
		}
	};

	return (
		<div
			className={"max-width-apply"}
			style={{
				background: mediaResult ? "#fff" : "#f8f9fa",
				padding: mediaResult ? "0" : "1.3em 3em 0",
				// maxWidth: mediaResult ? "" : "1200px",
			}}
		>
			{/* 📌 헤더 */}
			{!mediaResult && <div className="calculate-head-title">마이페이지</div>}
			<div className="calculate-header">
				{mediaResult && (
					<img
						src={"/assets/images/ico-back-black.svg"}
						alt="뒤로가기"
						style={{ cursor: "pointer" }}
						onClick={() => history.goBack()}
					/>
				)}
				<div className="calculate-main-title">
					<div>
						<p>안녕하세요</p>
						<p>
							<span style={{ color: "#117ffa" }}>{user?.name}</span>님
						</p>
					</div>
					<button
						className="btn-user-edit"
						type="button"
						onClick={() => history.push("/user/edit")}
					>
						내 정보수정 {">"}
					</button>
				</div>
				<div className="gold-box">
					<p className="desc">회원님의 현재 누적 골드입니다.</p>
					<div className="gold-count-box">
						<img
							src={`/assets/images/gold/gold-icon-no-bg.png`}
							alt="골드 아이콘"
						/>
						<p>
							<span className="gold">{gold.gold?.toLocaleString()} </span>Gold
						</p>
					</div>
					{!mediaResult && (
						<p
							style={{
								marginTop: "15px",
								fontSize: "1.15em",
								color: "#d9d9d9d9",
							}}
						>
							정산 가능한 골드는{" "}
							<span style={{ color: "#117ffa", fontWeight: 500 }}>
								{gold.settled_gold?.toLocaleString()}
							</span>{" "}
							Gold입니다.
							<br />
							정산은 전월 말일까지 확정 된 골드를 기준으로 합니다.
						</p>
					)}
				</div>

				{/* {user?.referral_code && (
            <p className="calculate-referral-code">
              추천인 코드: {user?.referral_code}
            </p>
          )} */}
				{/* <div className="calculate-category-wrap">
            <div
              onClick={() => {
                history.push("/user");
                setActive(1);
              }}
              className={`calculate-category ${
                active === 1 && "calculate-active-category"
              }`}
            >
              골드
            </div>
            <div
              onClick={() => setActive(2)}
              className={`calculate-category ${
                active === 2 && "calculate-active-category"
              }`}
            >
              문의
            </div>
            <div
              onClick={() => {
                history.push("/user");
                setActive(3);
              }}
              className={`calculate-category ${
                active === 3 && "calculate-active-category"
              }`}
            >
              내 정보수정
            </div>
          </div> */}
			</div>
			{
				!mediaResult && <></>
				// <div className="calculate-header">
				// 	<img
				// 		src={"/assets/images/ico-back-white.svg"}
				// 		alt="뒤로가기"
				// 		style={{ cursor: "pointer" }}
				// 		onClick={() => history.goBack()}
				// 	/>
				// 	<div className="calculate-main-title">당영투게더</div>
				// 	<div
				// 		className="calculate-sub-title"
				// 		style={
				// 			user?.referral_code
				// 				? { marginBottom: "calc(38px - 1.5 * 15.6px)" }
				// 				: {}
				// 		}
				// 	>
				// 		높은 수익률 · 수익 실시간 확인 · 투명한 정산
				// 	</div>
				// 	{user?.referral_code && (
				// 		<p className="calculate-referral-code">
				// 			추천인 코드: {user?.referral_code}
				// 		</p>
				// 	)}
				// 	<div className="calculate-category-wrap">
				// 		<div
				// 			onClick={() => {
				// 				history.push("/user");
				// 				setActive(1);
				// 			}}
				// 			className={`calculate-category ${
				// 				active === 1 && "calculate-active-category"
				// 			}`}
				// 		>
				// 			골드
				// 		</div>
				// 		<div
				// 			onClick={() => setActive(2)}
				// 			className={`calculate-category ${
				// 				active === 2 && "calculate-active-category"
				// 			}`}
				// 		>
				// 			문의
				// 		</div>
				// 		<div
				// 			onClick={() => {
				// 				history.push("/user");
				// 				setActive(3);
				// 			}}
				// 			className={`calculate-category ${
				// 				active === 3 && "calculate-active-category"
				// 			}`}
				// 		>
				// 			내 정보수정
				// 		</div>
				// 	</div>
				// </div>
			}

			<div
				className="main-wrap caculate"
				style={
					active === 2
						? {
								marginTop: 0,
						  }
						: {}
				}
			>
				{/* <div className="pc-cont">
            {active === 1 && <Point />}
            {active === 2 && <Inquiry />}
            {active === 3 && <EditInfo setActive={setActive} />}
          </div> */}
				<div className="mobile-cont">
					<div className="calculate-category-wrap">
						<Link
							to={"/user/inquiry"}
							className={`calculate-category-item-wrap`}
						>
							<p className={`main-font`}>문의</p>
							<img src="/assets/images/right-arrow-black.svg" alt="" />
						</Link>
						<Link
							to={"/user/recommend"}
							className={`calculate-category-item-wrap`}
						>
							<p className="main-font">친구 추천</p>
							<img src="/assets/images/right-arrow-black.svg" alt="" />
						</Link>
						<Link to={"/terms"} className={`calculate-category-item-wrap`}>
							<p className="main-font">이용약관</p>
							<img src="/assets/images/right-arrow-black.svg" alt="" />
						</Link>
					</div>
					{mediaResult ? (
						<img
							style={{ width: "100%", margin: "4.2em 0 3em" }}
							src="/assets/images/user/banner1.png"
							alt=""
						/>
					) : (
						<img
							style={{ width: "100%", margin: "4.2em 0 3em" }}
							src="/assets/images/user/banner1-pc.png"
							alt=""
						/>
					)}
					<div className="bottom-btn-box">
						<button type="button" onClick={logout}>
							로그아웃
						</button>
						<span>/</span>
						<button type="button" onClick={() => setSecession(true)}>
							회원탈퇴
						</button>
					</div>
				</div>
			</div>

			{/* 탈퇴하기 및 관련 정보 확인 모달 */}
			<Modal
				title=""
				open={secession}
				footer={null}
				closable={false}
				wrapClassName="modal-wrap seller-grade-modal-wrap custom-modal-wrap secession-modal"
				width={400}
				centered={true}
			>
				<div className="modal-title secession">탈퇴하기</div>
				{!mediaResult ? (
					<p className="modal-title-sub secession" style={{ color: "#979797" }}>
						잠깐 당영투게더 탈퇴하기 전에 아래 정보를 확인해주세요
					</p>
				) : (
					<p className="modal-title-sub secession">
						잠깐 당영투게더 탈퇴하기 전에
						<br />
						아래 정보를 확인해주세요
					</p>
				)}
				<div className="secession-modal-cont-flex">
					<div
						style={{
							width: "100%",
							padding: "2rem 2rem ",
							marginBottom: "1em",
							background: "#F6F6F6",
							borderRadius: "16px",
						}}
					>
						{mediaResult ? (
							<p className="secession-title">
								그동안 쌓아놓은 골드가 모두 사라져요
							</p>
						) : (
							<p className="secession-title">
								그동안 쌓아놓은 골드가
								<br /> 모두 사라져요
							</p>
						)}
						<p className="secession-desc">
							자진 탈퇴 시, 정산되지 않은 금액이 있더라도 탈퇴가 이루어지면,
							정산 예정금은 받을 수 없습니다.
						</p>
					</div>
					<div
						style={{
							width: "100%",
							padding: "2rem 2rem ",
							marginBottom: "2.5em",
							background: "#F6F6F6",
							borderRadius: "16px",
						}}
					>
						{mediaResult ? (
							<p className="secession-title">
								처음부터 다시 가입하고 인증해야 해요
							</p>
						) : (
							<p className="secession-title">
								처음부터 다시 가입하고
								<br />
								인증해야 해요
							</p>
						)}
						<p className="secession-desc">
							탈퇴 시 회원님의 계정 정보는 모두 삭제되며 복구가 불가능합니다.
							다시 한번 더 고민해 보세요
						</p>
					</div>
				</div>
				<div className="modal-button-wrap">
					<div
						onClick={() => setSecession(false)}
						className="modal-button-success"
					>
						취소
					</div>
					<div className="modal-button-cancel" onClick={handleSecession}>
						탈퇴
					</div>
				</div>
			</Modal>
		</div>
	);
};

export default Calculate;
