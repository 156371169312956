import axios from "axios";
const baseUrl = `${process.env.REACT_APP_BACKEND_HOST}`;

const queryLogByInfluencer = async (param) => {
	const { data } = await axios.get(`${baseUrl}/queryLog/byInfluencer`, {
		params: param,
	});
	return data;
};

/**
 * 투게더 인기 상품 클릭 수 가져오기
 * @param param
 * offset
 * pageSize
 * userId
 * startDate
 * endDate
 * @returns {Promise<never>}
 */
const queryLogTogetherProducts = async (param) => {
	const { data } = await axios.get(`${baseUrl}/queryLog/together/products`, {
		params: param,
	});
	return data;
};

const queryLogApi = {
	queryLogByInfluencer,
	queryLogTogetherProducts,
};

export default queryLogApi;
