import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import moment from "moment";

import ApiService from "../../utils/ApiService";
import GoldInfo from "../gold/GoldInfo";

const api = new ApiService();

const Point = () => {
	const user = useSelector((state) => state.auth.user);

	const [mediaResult, setMediaResult] = useState(null);
	const [loading, setLoading] = useState(false);
	const [gold, setGold] = useState({
		earn_gold: 0,
		earn_gold_percentage: "-1.6em",
	});

	useEffect(() => {
		if (typeof window !== "undefined") {
			const matchQueryList = window.matchMedia("all and (max-width: 768px)");
			setMediaResult(matchQueryList.matches);

			const media = function handleChange(e) {
				setMediaResult(e.matches);
			};

			matchQueryList.addEventListener("change", media);
		}
	}, []);

	useEffect(() => {
		getGold();
	}, [user]);

	/**
	 * 골드 관련 정보 조회
	 * @type {(function(): Promise<void>)|*}
	 */
	const getGold = useCallback(async () => {
		setLoading(true);
		const goldParam = {
			userId: user?.id,
			type: "goldInfo",
		};
		const goldRes = await api.getUserInfoByType(goldParam);
		if (goldRes.data.code === 200) {
			const earnGold = goldRes.data.result?.monthly_earn_gold ?? 0;
			let percentage = "-1.6em";
			if (earnGold === 0) {
				percentage = "-1.6em";
			} else if (earnGold > 0 && earnGold < 5000) {
				let percentageValue = (earnGold / 10000) * 100;
				percentage = `calc(${percentageValue}% - 1.2em)`;
			} else if (earnGold === 5000) {
				percentage = "calc(50% - 1.2em)";
			} else if (earnGold > 5000 && earnGold < 10000) {
				let percentageValue = (earnGold / 10000) * 100;
				percentage = `calc(${percentageValue}% - 1.2em)`;
			} else {
				percentage = "calc(100% - 1.6em)";
			}
			setGold({
				earn_gold: earnGold,
				earn_gold_percentage: percentage,
			});
		}
		setLoading(false);
	}, [user]);

	return (
		<>
			<div className="max-width-apply">
				<div>
					<GoldInfo type={"calculate"} mediaResult={mediaResult} />
				</div>
			</div>
			<div className="calculate-point-wrapper">
				<div className="calculate-point-wrap">
					<div className="calculate-point-title-wrap">
						<div className="calculate-point-title">당영골드 적립현황</div>
						<div className="calculate-point-sub-title">
							{moment().format("YYYY년 M월 기준")}
						</div>
					</div>
					<div className="calculate-graph-wrap">
						<div className="calculate-graph-dot-wrap">
							<div className="calculate-graph-dot" style={{ left: "-5px" }} />
							<div className="calculate-graph-dot" style={{ left: "25%" }} />
							<div className="calculate-graph-dot" style={{ left: "50%" }} />
							<div className="calculate-graph-dot" style={{ left: "75%" }} />
							<div
								className="calculate-graph-out-dot"
								style={{ right: "-7.5px" }}
							/>
							<div className="calculate-graph-dot" style={{ right: "-5px" }} />
							<img
								className="calculate-graph-icon"
								src={`/assets/images/olive_character.png`}
								alt={`올리브캐릭터`}
								style={{
									height: "3em",
									width: "3em",
									left: gold.earn_gold_percentage,
								}}
							/>
						</div>
						<div className="calculate-graph-text-wrap">
							<div
								className="calculate-graph-text"
								style={{ marginRight: "5%" }}
							>
								0
							</div>
							<div className="calculate-graph-text center">5,000</div>
							<div className="calculate-graph-text">10,000</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Point;
