import axios from "axios";
const baseUrl = `${process.env.REACT_APP_BACKEND_HOST}`;

/**
 * 서버시간 가져오기
 * @returns {Promise<never>}
 */
const getServerTime = async () => {
	const { data } = await axios.get(`${baseUrl}/partners/read/serverTime`);
	return data;
};

const login = async (param) => {
	const { data } = await axios.post(`${baseUrl}/partners/login`, param);
	return data;
};

const logout = async (param) => {
	const { data } = await axios.post(`${baseUrl}/partners/logout`, param);
	return data;
};

const getQueryList = async () => {
	const { data } = await axios.get(`${baseUrl}/partners/queryList`);
	return data;
};

const getUserInfo = async (param) => {
	const { data } = await axios.get(`${baseUrl}/partners/read/userInfo`, {
		params: param,
	});
	return data;
};

const editProfile = async (param) => {
	const { data } = await axios.post(`${baseUrl}/partners/edit/profile`, param);
	return data;
};

/**
 * 채널 노출 여부 판단
 * @param param
 * @returns {Promise<never>}
 */
const getMenuChannel = async (param) => {
	const { data } = await axios.get(`${baseUrl}/partners/read/menuVisible`, {
		params: param,
	});
	return data;
};

/**
 * 채널 노출 여부 수정
 * @param param
 * @returns {Promise<*>}
 */
const editMenuChannel = async (param) => {
	const { data } = await axios.post(
		`${baseUrl}/partners/edit/menuVisible`,
		param
	);
	return data;
};

/**
 * 채널 한마디 수정
 * @param param
 * @returns {Promise<*>}
 */
const editGreetings = async (param) => {
	const { data } = await axios.post(
		`${baseUrl}/partners/edit/greetings`,
		param
	);
	return data;
};

/**
 * 채널 정보 조회 (사용자)
 * @param param
 * offset, pageSize
 * @returns {Promise<never>}
 */
const getChannel = async (param) => {
	const { data } = await axios.get(`${baseUrl}/partners/read/channel`, {
		params: param,
	});
	return data;
};

/**
 * 채널 정보 조회 (인플루언서)
 * @param param
 * offset, pageSize, userId
 * @returns {Promise<never>}
 */
const readChannel = async (param) => {
	const { data } = await axios.get(
		`${baseUrl}/partners/read/channelByInfluencer`,
		{
			params: param,
		}
	);
	return data;
};

/**
 * 게시글, 공지사항 등록
 * @param param
 * title, content, is_notice, created_by
 * @returns {Promise<*>}
 */
const createContent = async (param) => {
	const { data } = await axios.post(`${baseUrl}/partners/create/board`, param);
	return data;
};

/**
 * 게시글, 공지사항 수정
 * @param param
 * id, title, content, created_by, updated_by
 * @returns {Promise<*>}
 */
const editContent = async (param) => {
	const { data } = await axios.post(`${baseUrl}/partners/create/board`, param);
	return data;
};

/**
 * 댓글 남기기
 * @param param
 * board_id, phone, password, content
 * @returns {Promise<*>}
 */
const createComment = async (param) => {
	const { data } = await axios.post(`${baseUrl}/partners/leave/comment`, param);
	return data;
};

/**
 * 미답변 목록 조회
 * @param param
 * boardId
 * @returns {Promise<never>}
 */
const readUnAnswer = async (param) => {
	const { data } = await axios.get(
		`${baseUrl}/partners/read/commentList/unanswer`,
		{
			params: param,
		}
	);
	return data;
};

/**
 * 비밀댓글 확인하기
 * @param param
 * commentId, phoneCenter, password
 * @returns {Promise<never>}
 */
const readSecret = async (param) => {
	const { data } = await axios.get(`${baseUrl}/partners/read/comment/secret`, {
		params: param,
	});
	return data;
};

/**
 * 내가 쓴 글 확인하기
 * @param param
 * boardId, phoneCenter, password
 * @returns {Promise<never>}
 */
const readByCustomer = async (param) => {
	const { data } = await axios.get(
		`${baseUrl}/partners/read/commentListByWriter`,
		{
			params: param,
		}
	);
	return data;
};

/**
 * 인플루언서 답글 달기
 * @param param
 * comment_id, created_by, content
 * @returns {Promise<*>}
 */
const createReply = async (param) => {
	const { data } = await axios.post(`${baseUrl}/partners/create/reply`, param);
	return data;
};

/**
 * 인플루언서 id 변경
 * @param param
 * id, influencer_id, updated_by
 * @returns {Promise<*>}
 */
const updateChannelId = async (param) => {
	const { data } = await axios.post(
		`${baseUrl}/partners/edit/influencerId`,
		param
	);
	return data;
};

/**
 * 게시판 입장 시 방문자 수 증가
 * @param param
 * id: board_id
 * @returns {Promise<*>}
 */
const updateVisitorCount = async (param) => {
	const { data } = await axios.post(
		`${baseUrl}/partners/update/visitor`,
		param
	);
	return data;
};

/**
 * FAQ 메뉴 리스트 조회
 * @returns {Promise<never>}
 */
const readFaqMenu = async () => {
	const { data } = await axios.get(`${baseUrl}/partners/faq/menu/list`);
	return data;
};

/**
 * 메뉴에 해당하는 FAQ 리스트 조회
 * @param param
 * @returns {Promise<never>}
 */
const readFaqList = async (param) => {
	const { data } = await axios.get(`${baseUrl}/partners/faq/list`, {
		params: param,
	});
	return data;
};

/**
 * 공지사항 리스트
 * @param param
 * offset, pageSize
 * @returns {Promise<never>}
 */
const readNoticeList = async (param) => {
	const { data } = await axios.get(`${baseUrl}/partners/notice/list`, {
		params: param,
	});
	return data;
};

/**
 * 해당 공지사항
 * @param param
 * notice_id
 * @returns {Promise<never>}
 */
const readNoticeEach = async (param) => {
	const { data } = await axios.get(`${baseUrl}/partners/notice/read`, {
		params: param,
	});
	return data;
};
/**
 * 투게더 카테고리 불러오기
 * @param param
 * offset, pageSize, categoryType (home, ingredient, function), influencerId
 * @returns {Promise<never>}
 */
const togetherCategoryRead = async (param) => {
	const { data } = await axios.get(`${baseUrl}/partners/read/categoryList`, {
		params: param,
	});
	return data;
};

/**
 * 투게더 카테고리 해당 페이지 불러오기
 * @param param
 * categoryId, categoryType
 * @returns {Promise<never>}
 */
const togetherCategoryDetail = async (param) => {
	const { data } = await axios.get(`${baseUrl}/partners/read/category`, {
		params: param,
	});
	return data;
};

/**
 * 투게더 검색
 * @param param
 * offset, pageSize, searchWord
 * @returns {Promise<never>}
 */
const togetherSearch = async (param) => {
	const { data } = await axios.get(`${baseUrl}/partners/search/category`, {
		params: param,
	});
	return data;
};

/**
 * 인플루언서 1:1 문의, 유튜브 링크 수정
 * @param param
 * ...user, inquiry_link, youtube_link
 * @returns {Promise<*>}
 * @constructor
 */
const editUserLink = async (param) => {
	const { data } = await axios.post(`${baseUrl}/partners/edit/link`, param);
	return data;
};

/**
 * 인플루언서 성분 및 고민별 카테고리 목록 조회
 * @param param
 * Integer influencerId
 * @returns {Promise<never>}
 */
const readCategoryList = async (param) => {
	const { data } = await axios.get(
		`${baseUrl}/partners/read/admin/categoryList`,
		{
			params: param,
		}
	);
	return data;
};

/**
 * 카테고리별 추천 상품 정보
 * @param param
 * categoryId
 * @returns {Promise<never>}
 */
const readCategoryProductsList = async (param) => {
	const { data } = await axios.get(
		`${baseUrl}/partners/read/admin/productList`,
		{
			params: param,
		}
	);
	return data;
};

/**
 * 카테고리 생성
 * @param param
 * category_id,
 * category_name,
 * category_type,
 * influencer_id,
 * caution,
 * updated_by
 * @returns {Promise<*>}
 */
const createCategoryList = async (param) => {
	const { data } = await axios.post(
		`${baseUrl}/partners/create/category`,
		param
	);
	return data;
};

/**
 * 카테고리 수정
 * @param param
 * categoryId: table_id,
 * categoryType: ingredient,
 * userId:
 * @returns {Promise<never>}
 */
const deleteCategoryList = async (param) => {
	const { data } = await axios.delete(`${baseUrl}/partners/delete/category`, {
		params: param,
	});
	return data;
};

/**
 * 카테고리 연관 링크 수정
 * @param param
 * Integer influencer_id, category_id, category_type, associatedVideoList <List<PartnersCategoryLink>>, updated_by
 * <List<PartnersCategoryLink>>
 *     id, platform_id, platform_name, url, title, search_tag
 * @returns {Promise<*>}
 */
const postCategoryLinkList = async (param) => {
	const { data } = await axios.post(
		`${baseUrl}/partners/register/video`,
		param
	);
	return data;
};

/**
 * 추천 상품 삭제
 * @param param
 * productId, userId
 * @returns {Promise<never>}
 */
const deleteCategoryProduct = async (param) => {
	const { data } = await axios.delete(
		`${baseUrl}/partners/delete/recommendProduct`,
		{
			params: param,
		}
	);
	return data;
};

/**
 * 추천 상품 등록
 * @param param
 * category_id, brand_name, product_name, sub_title,
 * description1~6, search_tag, purchaseLinkList<List>, created_by
 * <List> platform_id (1 iHerb, 2 coupang, 3 셀로맥스, 4 dangyoung), platform_name, url, discount_code
 * @returns {Promise<*>}
 */
const createCategoryProduct = async (param) => {
	const { data } = await axios.post(
		`${baseUrl}/partners/create/recommendProduct`,
		param
	);
	return data;
};

/**
 * 추천 상품 수정
 * @param param
 * create 와 updated_by, link_id 다름
 * @returns {Promise<*>}
 */
const updateCategoryProduct = async (param) => {
	const { data } = await axios.post(
		`${baseUrl}/partners/edit/recommendProduct`,
		param
	);
	return data;
};

/**
 * 검색 결과 (영상, 상품)
 * @param param
 * searchWord, influencerId
 * @returns {Promise<never>}
 */
const readSearchList = async (param) => {
	const { data } = await axios.get(`${baseUrl}/partners/search/category`, {
		params: param,
	});
	return data;
};

/**
 * 조합 목록 조회
 * @param param
 * influencerId
 * @returns {Promise<never>}
 */
const readCombinationList = async (param) => {
	const { data } = await axios.get(
		`${baseUrl}/partners/read/admin/combinationList`,
		{
			params: param,
		}
	);
	return data;
};

/**
 * 조합 목록 생성
 * @param param
 * PartnersCategoryDTO
 * @returns {Promise<*>}
 */
const createdCombinationList = async (param) => {
	const { data } = await axios.post(
		`${baseUrl}/partners/register/combination`,
		param
	);
	return data;
};

/**
 * 조합 목록 삭제
 * @param param
 * combinationId, userId
 * @returns {Promise<never>}
 */
const deleteCombinationList = async (param) => {
	const { data } = await axios.delete(
		`${baseUrl}/partners/delete/combination`,
		{
			params: param,
		}
	);
	return data;
};

/**
 * ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
 *                                  [ GroupBuying - 공동구매 ]
 * ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
 */

/**
 * 공동구매 리스트 가져오기
 * @param param
 * influencerId
 * @returns {Promise<unknown>}
 */
const readGroupBuyingList = async (param) => {
	const { data } = await axios.get(`${baseUrl}/partners/cooperate/list`, {
		params: param,
	});
	return data;
};

/**
 * 게시글 작성을 위한 인플루언서별 진행중/예정인 공동구매 목록 조회
 * @param param
 * @param param.offset
 * @param param.pageSize
 * @param param.userId
 * @returns {Promise<never>}
 */
const readGroupBuyingListForContent = async (param) => {
	const { data } = await axios.get(
		`${baseUrl}/partners/cooperate/list/byContent`,
		{
			params: param,
		}
	);
	return data;
};

/**
 * 공동구매 클릭시 클릭 수 카운팅
 * @param param
 * targetId
 * @returns {Promise<never>}
 */
const addGroupBuyingClick = async (param) => {
	const { data } = await axios.get(`${baseUrl}/partners/cooperate/click/add`, {
		params: param,
	});
	return data;
};

/**
 * 인플루언서 - 공동구매 리스트 가져오기
 * @param param
 * influencerId
 * status
 * @returns {Promise<unknown>}
 */
const readGroupBuyingListInfluencer = async (param) => {
	const { data } = await axios.get(
		`${baseUrl}/partners/read/admin/cooperateListByStatus`,
		{
			params: param,
		}
	);
	return data;
};

/**
 * 인플루언서 - 공동구매 참여/참여취소
 * @param param
 * @returns {Promise<*>}
 */
const postGroupBuying = async (param) => {
	const { data } = await axios.post(
		`${baseUrl}/partners/register/cooperate/participation`,
		param
	);
	return data;
};

/**
 * 공동구매 알림 등록하기
 * @param param
 * @returns {Promise<*>}
 */
const createGroupBuyingAlert = async (param) => {
	const { data } = await axios.post(
		`${baseUrl}/partners/register/cooperate/userForAlert`,
		param
	);
	return data;
};

/**
 * sms 발송 가능 회원
 * @param param
 * @returns {Promise<never>}
 */
const smsReadCount = async (param) => {
	const { data } = await axios.get(
		`${baseUrl}/partners/read/admin/memberCntForNotifiedCooperate`,
		{
			params: param,
		}
	);
	return data;
};

/**
 * sms 보낸 리스트 가져오기
 * @param param
 * @returns {Promise<never>}
 */
const smsSendList = async (param) => {
	const { data } = await axios.get(
		`${baseUrl}/partners/read/admin/memberListForNotifiedCooperate`,
		{
			params: param,
		}
	);
	return data;
};

/**
 * sms 전송하기
 * @param param
 * @returns {Promise<*>}
 */
const postSmsSend = async (param) => {
	const { data } = await axios.post(
		`${baseUrl}/partners/send/cooperate/userForAlert`,
		param
	);
	return data;
};

/**
 * 인기검색어 등록
 * @param param
 * topSearchedList [
 * id : (수정시)
 * sort_order: 순위 1,2,3 (등록)
 * main_category: 0: 성분, 1: 고민, 2: 조합
 * sub_category: 카테고리 테이블 아이디
 * display_word: 검색어명
 * ],
 * influencer_id,
 * updated_by
 * @returns {Promise<*>}
 */
const postTopKeyword = async (param) => {
	const { data } = await axios.post(
		`${baseUrl}/partners/register/topSearched`,
		param
	);
	return data;
};

/**
 * 자주묻는 질문 등록/수정
 * @param param
 * id
 * influencer_id
 * question
 * answer
 * ingredientList [
 * {
 *     ingredient_id
 * }]
 * updated_by
 * @returns {Promise<*>}
 */
const postFaqList = async (param) => {
	const { data } = await axios.post(
		`${baseUrl}/partners/register/frequentlyFaq`,
		param
	);
	return data;
};

/**
 * 카테고리 순서 변경
 * @param param
 * @returns {Promise<*>}
 */
const postCategoryOrder = async (param) => {
	const { data } = await axios.post(
		`${baseUrl}/partners/change/category`,
		param
	);
	return data;
};

/**
 * 커뮤니티 단건 조회
 * @param param
 * influencerId
 * communityId
 * pageType: admin/user
 * @returns {Promise<never>}
 */
const readCommunityEach = async (param) => {
	const { data } = await axios.get(`${baseUrl}/partners/read/community`, {
		params: param,
	});
	return data;
};

/**
 * 커뮤니티 전체 조회
 * @param param
 * offset
 * pageSize
 * influencerId
 * pageType: admin/user
 * @returns {Promise<never>}
 */
const readCommunityList = async (param) => {
	const { data } = await axios.get(`${baseUrl}/partners/read/communityList`, {
		params: param,
	});
	return data;
};

/**
 * 좋아요 남기기
 * @param param
 * user_id
 * target_type : community
 * target_id
 * @returns {Promise<*>}
 */
const addFavoriteHistory = async (param) => {
	// const {data} = await axios.post(`${baseUrl}/togetherUserFavoriteHistory/add`, param)
	const { data } = await axios.post(
		`${baseUrl}/partnersUserFavoriteHistory/add`,
		param
	);
	return data;
};

/**
 * 좋아요 삭제하기
 * @param param
 * userId
 * targetType : community
 * targetId
 * @returns {Promise<never>}
 */
const deleteFavoriteHistory = async (param) => {
	// const {data} = await axios.delete(`${baseUrl}/togetherUserFavoriteHistory/delete`, {
	const { data } = await axios.delete(
		`${baseUrl}/partnersUserFavoriteHistory/delete`,
		{
			params: param,
		}
	);
	return data;
};

/**
 * 소통글 카테고리 목록 조회
 * @param param
 * influencerId
 * @returns {Promise<never>}
 */
const getCommunityCategoryList = async (param) => {
	const { data } = await axios.get(
		`${baseUrl}/partners/admin/community/categoryList`,
		{
			params: param,
		}
	);
	return data;
};

/**
 * 소통글 작성
 * @param param
 * influencer_id: created_by
 * category_id,
 * title,
 * boby
 * created_by: user.id
 * @returns {Promise<*>}
 */
const createCommunityContent = async (param) => {
	const { data } = await axios.post(
		`${baseUrl}/partners/create/community`,
		param
	);
	return data;
};

/**
 * 소통글 수정
 * @param param
 * id
 * category_id
 * title
 * body
 * updated_by
 * post_from
 * @returns {Promise<*>}
 */
const updateCommunityContent = async (param) => {
	const { data } = await axios.post(
		`${baseUrl}/partners/edit/community`,
		param
	);
	return data;
};

/**
 * 소통글 삭제
 * @param param
 * communityId
 * userId
 * @returns {Promise<never>}
 */
const deleteCommunityContent = async (param) => {
	const { data } = await axios.delete(`${baseUrl}/partners/delete/community`, {
		params: param,
	});
	return data;
};

/**
 * 소통 이미지 db 업로드
 * @param param
 * id
 * influencer_id
 * created_by
 * communityImageList : [
 * {
 *   title
 *   img_type
 * }]
 * @returns {Promise<*>}
 */
const createCommunityImages = async (param) => {
	const { data } = await axios.post(
		`${baseUrl}/partners/register/community/image`,
		param
	);
	return data;
};

/**
 * 소통 게시글 조회수
 * @param param
 * target_type : community
 * target_id: 테이블 아이디
 * action: read
 * created_by: user.id
 * @returns {Promise<*>}
 */
const createViewCommunity = async (param) => {
	// const {data} = await axios.post(`${baseUrl}/partners/record/history`, param)
	const { data } = await axios.post(
		`${baseUrl}/partners/community/add/view`,
		param
	);
	return data;
};

/**
 * 성분별/고민별/조합별 조회수
 * @param param
 * category_name
 * updated_by
 * id
 * influencer_id
 * @returns {Promise<*>}
 */
const updatePageViewNum = async (param) => {
	const { data } = await axios.post(
		`${baseUrl}/partners/pages/add/view`,
		param
	);
	return data;
};

/**
 * 소통 - 좋아요, 댓글, 조회수 가져오기
 * @param param
 * influencerId
 * communityId
 * userId
 * @returns {Promise<never>}
 */
const readCommunityDetail = async (param) => {
	const { data } = await axios.get(
		`${baseUrl}/partners/read/community/infoByCount`,
		{
			params: param,
		}
	);
	return data;
};

/**
 * 댓글/답글 좋아요 개수
 * @param param
 * id
 * userId
 * targetType
 * @returns {Promise<never>}
 */
const readCommentFavorite = async (param) => {
	const { data } = await axios.get(
		`${baseUrl}/partners/read/comment/infoByCount`,
		{
			params: param,
		}
	);
	return data;
};

/**
 * 회원 가입 유저 기간에 따라 인원수 조회하기
 * @param param
 * endDate - YYYYMMDDHHmm
 * userId
 * @returns {Promise<never>}
 */
const readSignUpUserCount = async (param) => {
	const { data } = await axios.get(`${baseUrl}/partners/user/count`, {
		params: param,
	});
	return data;
};

/**
 * 회원 가입 유저 인플루언서에 따라 조회하기
 * @param param
 * offset
 * pageSize
 * userId
 * @returns {Promise<never>}
 */
const readSignUpMembers = async (param) => {
	const { data } = await axios.get(`${baseUrl}/partners/signup/members`, {
		params: param,
	});
	return data;
};

/**
 * 포트원 은행 목록 조회
 * @returns {Promise<never>}
 */
const readBankList = async () => {
	const { data } = await axios.get(`${baseUrl}/partners/read/bankList`);
	return data;
};

/**
 * 당영몰 사용자로 파트너스 유저 정보 조회 - mallId
 * 목표 등급 및 다음달 등급 정보 조회 - sellerInfo
 * @param param
 * @param param.type mallId (당영몰) | isCertifiedByMallId (통합인증) | enterAccountByMallId (계좌정보) | sellerInfo
 * @param param.userId mallId | partnersId (type === sellerInfo)
 * @returns {Promise<never>}
 * 1-1. code
 * 1) 405: type이 지정된 값이 아님
 * 2) 400: 해당하는 사용자 정보를 찾을 수 없음
 * 3) 401: 인증 정보와 계좌 정보 둘 다 없음
 * 4) 402: 인증 정보가 없음
 * 5) 403: 계좌 정보가 없음
 */
const readMallPartnersId = async (param) => {
	const { data } = await axios.get(`${baseUrl}/partners/read/userInfo/type`, {
		params: param,
	});
	return data;
};

/**
 * 당영몰 통합 로그인/회원가입 (partnersUsers 계정 생성)
 * @param param
 * @param param.id
 * @param param.is_app
 * @returns {Promise<*>}
 */
const postCreatePartnersUsers = async (param) => {
	const { data } = await axios.post(`${baseUrl}/partners/create/user`, param);
	return data;
};

/**
 * 통합 본인인증 확인하기 (백엔드 더블 체크)
 * 업데이트 추가
 * @param param
 * @param param.mall_id
 * @param param.imp_certifications_uid
 * @param param.is_app
 * @returns {Promise<*>}
 */
const postPersonalAuthentication = async (param) => {
	const { data } = await axios.post(
		`${baseUrl}/partners/certifications`,
		param
	);
	return data;
};

/**
 * key에 따라 유저 업데이트
 * 1) 계좌정보 업데이트 하기 (탈퇴하지 않은 회원 대상)
 * @param param
 * @param param.id partners_id
 * @param param.key bank_account | basic_profile
 * @param param.bank_code
 * @param param.account_number
 * @param param.is_app
 * @returns {Promise<*>}
 */
const postPersonalBankAccount = async (param) => {
	const { data } = await axios.post(
		`${baseUrl}/partners/edit/user/byKey`,
		param
	);
	return data;
};

/**
 * key에 따라 유저 업데이트
 * 2) 투게더 유저 정보 업데이트하기
 * @param param
 * @param param.id partners_id
 * @param param.key additional_info
 * @param param.name
 * @param param.email
 * @param param.phone - 제외
 * @param param.personal_number ex) 123456-1234567
 * @param param.bank_code
 * @param param.account_number
 * @param param.is_app
 * @returns {Promise<*>}
 */
const postPersonalUserData = async (param) => {
	const { data } = await axios.post(
		`${baseUrl}/partners/edit/user/byKey`,
		param
	);
	return data;
};

/**
 * 계좌 인증하기
 * @param param.type 계좌 인증 유형 (account-입력한 계좌번호로 1원 전송하기 / code-1원 전송한 계좌 코드 입력하기)
 * @param param.bank_code 은행코드
 * @param param.account_number 계좌번호
 * @param param.name 계좌주
 * @param param.birthday 생년월일 (YYMMDD 형식)
 * @param param.code 계좌 인증 번호
 * @param param.transaction_id USEB API로그 아이디
 * @returns {Promise<*>}
 */
const verifyAccount = async (param) => {
	const res = await axios.post(`${baseUrl}/partners/verify/account`, param);
	return res;
};

const partnersApi = {
	getServerTime,
	login,
	logout,
	getQueryList,
	getUserInfo,
	getMenuChannel,
	editMenuChannel,
	editGreetings,
	editProfile,
	getChannel,
	createContent,
	editContent,
	createComment,
	readUnAnswer,
	readSecret,
	readByCustomer,
	createReply,
	readChannel,
	updateChannelId,
	updateVisitorCount,
	readFaqMenu,
	readFaqList,
	readNoticeList,
	readNoticeEach,
	togetherCategoryRead,
	togetherCategoryDetail,
	togetherSearch,
	editUserLink,
	readCategoryList,
	readCategoryProductsList,
	createCategoryList,
	postCategoryLinkList,
	deleteCategoryList,
	createCategoryProduct,
	updateCategoryProduct,
	deleteCategoryProduct,
	readSearchList,
	readCombinationList,
	createdCombinationList,
	deleteCombinationList,
	readGroupBuyingList,
	readGroupBuyingListForContent,
	addGroupBuyingClick,
	postGroupBuying,
	createGroupBuyingAlert,
	readGroupBuyingListInfluencer,
	smsReadCount,
	smsSendList,
	postSmsSend,
	postTopKeyword,
	postFaqList,
	postCategoryOrder,
	readCommunityEach,
	readCommunityList,
	addFavoriteHistory,
	deleteFavoriteHistory,
	getCommunityCategoryList,
	createCommunityContent,
	updateCommunityContent,
	deleteCommunityContent,
	createCommunityImages,
	createViewCommunity,
	updatePageViewNum,
	readCommunityDetail,
	readCommentFavorite,
	readSignUpUserCount,
	readSignUpMembers,
	readBankList,
	readMallPartnersId,
	postCreatePartnersUsers,
	postPersonalAuthentication,
	postPersonalBankAccount,
	postPersonalUserData,
	verifyAccount,
};

export default partnersApi;
