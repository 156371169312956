import "../Dashboard.scss";
import React, { useEffect, useState } from "react";

import {
	InduceUsingGuide1,
	InduceUsingGuide2,
	InduceUsingGuide3,
	InduceUsingGuide4,
	WelcomeEventModal,
} from "../components/common/EventModalComponent";
import Faq from "../components/dashboard/Faq";
import MonthSales from "../components/dashboard/MonthSales";
import Notice from "../components/dashboard/Notice";
import ProductLink from "../components/dashboard/ProductLink";
import TodayClickCount from "../components/dashboard/TodayClickCount";
import TodaySales from "../components/dashboard/TodaySales";
import GoldInfo from "../components/gold/GoldInfo";
import { getCookie, setCookie, setCookieOneDay } from "../utils/cookie";

const DashboardNew = () => {
	const [mediaResult, setMediaResult] = useState(null);
	const [radonModalIndex, setRandomModalIndex] = useState(null);

	useEffect(() => {
		window.scrollTo({
			top: 0,
		});
		if (typeof window !== "undefined") {
			const matchQueryList = window.matchMedia("all and (max-width: 768px)");
			setMediaResult(matchQueryList.matches);

			const media = function handleChange(e) {
				setMediaResult(e.matches);
			};

			matchQueryList.addEventListener("change", media);
		}
	}, []);
	const handleRandomModal = () => {
		let randomModalIndex = Math.floor(Math.random() * 3);
		setRandomModalIndex(randomModalIndex + 1);
	};
	useEffect(() => {
		if (!localStorage.getItem("welcome-modal")) {
			localStorage.setItem("modal-step", 2);
		} else {
			localStorage.setItem("modal-step", 1);
		}
		handleRandomModal();
	}, [localStorage]);

	return (
		<div
			className="main-wrap dashboard"
			style={{ paddingBottom: "10em", background: "none" }}
		>
			<div className={`max-width-apply`}>
				{mediaResult ? (
					// 모바일
					<div className={`dashboard-main-div`}>
						<InduceUsingGuide1
							modalIndex={radonModalIndex}
							isExpire={getCookie("read-random-modal")}
						/>
						<InduceUsingGuide2
							modalIndex={radonModalIndex}
							isExpire={getCookie("read-random-modal")}
						/>
						<InduceUsingGuide3
							modalIndex={radonModalIndex}
							isExpire={getCookie("read-random-modal")}
						/>
						<InduceUsingGuide4
							isRandomRead={getCookie("read-random-modal")}
							isExpire={getCookie("read-friend-modal")}
						/>
						<WelcomeEventModal open={localStorage.getItem("welcome-modal")} />
						<div
							style={{
								width: "100%",
								display: "flex",
								flexDirection: "column",
							}}
						>
							{/* 당영 골드 */}

							<GoldInfo type={"dashboard"} mediaResult={mediaResult} />
						</div>

						<div
							style={{
								width: "100%",
								display: "flex",
								flexDirection: "column",
							}}
						>
							{/*상품 링크*/}
							<img
								src={`/assets/images/products/product-banner-m2.png`}
								alt={`대시보드 경고 아이콘`}
							/>
						</div>
						<div
							style={{
								width: "100%",
								display: "flex",
								flexDirection: "column",
							}}
						>
							<ProductLink mediaResult={mediaResult} />
						</div>
						{/* 금일 매출 */}
						<div
							style={{
								width: "100%",
								display: "flex",
								gap: "2rem",
								flexDirection: "column",
							}}
						>
							<TodaySales mediaResult={mediaResult} />
							<TodayClickCount mediaResult={mediaResult} />
						</div>

						{/* 이번 달 매출 */}
						<MonthSales />

						<Faq mediaResult={mediaResult} />
						<Notice mediaResult={mediaResult} />
					</div>
				) : (
					// PC
					<div className={`dashboard-main-div`}>
						{/* 당영 골드 */}
						<GoldInfo type={"dashboard"} mediaResult={mediaResult} />

						{/*상품 링크*/}
						<img
							src={`/assets/images/products/product-banner.png`}
							alt={`대시보드 경고 아이콘`}
						/>
						<ProductLink mediaResult={mediaResult} />

						{/* 금일 매출 */}
						<div
							style={{
								display: "flex",
								width: "100%",
								gap: "20px",
								justifyContent: "space-between",
							}}
						>
							<TodaySales mediaResult={mediaResult} />
							<TodayClickCount mediaResult={mediaResult} />
						</div>

						{/* 이번 달 매출 */}
						<MonthSales />

						{/*자주 묻는 질문 & 공지사항*/}
						<div
							style={{
								display: "flex",
								width: "100%",
								gap: "20px",
								justifyContent: "space-between",
							}}
						>
							<Faq mediaResult={mediaResult} />
							<Notice mediaResult={mediaResult} />
						</div>
					</div>
				)}
			</div>
			{/* 팝업 */}
		</div>
	);
};
export default DashboardNew;
