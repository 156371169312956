import axios from "axios";
const baseUrl = `${process.env.REACT_APP_BACKEND_HOST}`;

/**
 * 대시보드 카테고리 분석
 * @param param
 * offset
 * pageSize
 * userId
 * startDate
 * endDate
 * @returns {Promise<never>}
 */
const readDashboardCategory = async (param) => {
	const { data } = await axios.get(
		`${baseUrl}/partnersSummary/dashboard/category`,
		{
			params: param,
		}
	);
	return data;
};

/**
 * 실시간 유저 히스토리 확인하기
 * @param param
 * userId
 * startDate
 * endDate
 * @returns {Promise<never>}
 */
const readDashboardUserThatTime = async (param) => {
	const { data } = await axios.get(
		`${baseUrl}/partnersSummary/dashboard/channel/user/thatTime`,
		{
			params: param,
		}
	);
	return data;
};

/**
 * 채널 분석 카테고리 리스트 조회
 * @param param
 * offset
 * pageSize
 * userId
 * orderType
 * orderBy
 * @returns {Promise<never>}
 */
const readAnalysisCategoryList = async (param) => {
	const { data } = await axios.get(`${baseUrl}/partnersSummary/read/category`, {
		params: param,
	});
	return data;
};

/**
 * 채널 분석 카테고리/콘텐츠(커뮤니티)
 * @param param
 * offset
 * pageSize
 * userId
 * id
 * categoryType
 * dateType
 * @returns {Promise<never>}
 */
const readAnalysisSummaryPeriod = async (param) => {
	const { data } = await axios.get(
		`${baseUrl}/partnersSummary/read/summary/period`,
		{
			params: param,
		}
	);
	return data;
};

/**
 * 채널 분석 콘텐츠 (커뮤니티)
 * @param param
 * offset
 * pageSize
 * userId
 * orderType
 * orderBy
 * @returns {Promise<never>}
 */
const readAnalysisContentsList = async (param) => {
	const { data } = await axios.get(`${baseUrl}/partnersSummary/read/contents`, {
		params: param,
	});
	return data;
};

const readDashboardCategorySoaring = async (param) => {
	const { data } = await axios.get(
		`${baseUrl}/partnersSummary/dashboard/category/soaring`,
		{
			params: param,
		}
	);
	return data;
};

/**
 * 영양제 클릭수 리스트
 * @param param
 * offset
 * pageSize
 * userId
 * orderType
 * orderBy
 * @returns {Promise<never>}
 */
const readAnalysisProductsList = async (param) => {
	const { data } = await axios.get(`${baseUrl}/partnersSummary/read/products`, {
		params: param,
	});
	return data;
};

const summaryApi = {
	readDashboardCategory,
	readAnalysisCategoryList,
	readAnalysisSummaryPeriod,
	readAnalysisContentsList,
	readDashboardCategorySoaring,
	readDashboardUserThatTime,
	readAnalysisProductsList,
};

export default summaryApi;
