import * as React from "react";

import { Modal } from "antd";

const UserVerificationCompleteModal = ({
	show,
	setShow,
	mediaResult,
	handleClickConfirm,
}) => {
	return (
		<Modal
			title=""
			open={show}
			footer={null}
			closable={false}
			wrapClassName="modal-wrap seller-grade-modal-wrap custom-modal-wrap"
			width={400}
			centered={mediaResult}
		>
			<div className="modal-title" style={{ marginBottom: "29px" }}>
				본인인증이 완료되었습니다.
			</div>
			<div className="modal-button-wrap">
				<div
					onClick={() => {
						setShow(false);
						if (handleClickConfirm) {
							handleClickConfirm();
						}
					}}
					className="modal-button-success"
				>
					확인
				</div>
			</div>
		</Modal>
	);
};

export default UserVerificationCompleteModal;
