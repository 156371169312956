import axios from "axios";
const baseUrl = `${process.env.REACT_APP_BACKEND_HOST}`;

/**
 * 유저 히스토리 추가
 * @param param
 * action
 * created_by
 * target_id
 * target_type
 * influencer_id
 * @returns {Promise<*>}
 */
const userHistory = async (param) => {
	const { data } = await axios.post(
		`${baseUrl}/partners/user/history/add`,
		param
	);
	return data;
};

/**
 * 휴대폰 번호 유저 로그인
 * @param param
 * @returns {Promise<*>}
 */
const login = async (param) => {
	const { data } = await axios.post(`${baseUrl}/partners/login/byPhone`, param);
	// const {data} = await axios.post(`${baseUrl}/together/partners/login/byPhone`, param)
	return data;
};

/**
 * 유저 로그아웃
 * @param param
 * id
 * @returns {Promise<*>}
 */
const logout = async (param) => {
	// const {data} = await axios.post(`${baseUrl}/partners/together/logout`, param)
	const { data } = await axios.post(`${baseUrl}/partners/logout`, param);
	return data;
};

/**
 * 탈퇴하기
 * @param param
 * userId
 * @returns {Promise<never>}
 */
const leave = async (param) => {
	const { data } = await axios.delete(`${baseUrl}/partners/leave/user`, {
		params: param,
	});
	return data;
};

/**
 * 유저 닉네임, 휴대폰 번호 수정
 * @param param
 * id,
 * phone,
 * nickname,
 * profile_img_type
 * @returns {Promise<*>}
 */
const editChannelUser = async (param) => {
	const { data } = await axios.post(`${baseUrl}/partners/edit/user`, param);
	return data;
};

/**
 * 휴대폰 번호 인증 문자 보내기
 * @param param
 * is_user_valid : true
 * phone
 * influencer_id
 * influencer_channel_name
 * @returns {Promise<*>}
 */
const sendSms = async (param) => {
	const { data } = await axios.post(
		`${baseUrl}/partners/sms_certify_send`,
		param
	);
	return data;
};

/**
 * 비밀번호 찾기 인증 문자 보내기
 * @param param
 * phone
 * influencer_channel_name
 * @returns {Promise<*>}
 */
const findPwSendSms = async (param) => {
	const { data } = await axios.post(
		`${baseUrl}/partners/sms_find_password`,
		param
	);
	return data;
};

/**
 * 비밀번호 변경 제출하기
 * @param param
 * @returns {Promise<*>}
 */
const postFindPw = async (param) => {
	const { data } = await axios.post(
		`${baseUrl}/partners/update_password_phone`,
		param
	);
	return data;
};

/**
 * 휴대폰 인증번호 확인
 * @param param
 * phone
 * sms_verify_code
 * @returns {Promise<*>}
 */
const sendSmsValid = async (param) => {
	const { data } = await axios.post(
		`${baseUrl}/partners/sms_certify_verify`,
		param
	);
	return data;
};

/**
 * 회원가입 질문 목록 가져오기
 * @param param
 * influencerId
 * type : signup
 * @returns {Promise<never>}
 */
const getSignUpQuestionList = async (param) => {
	const { data } = await axios.get(`${baseUrl}/partners/questionList`, {
		params: param,
	});
	return data;
};

/**
 * 휴대폰 회원가입
 * @param param
 * phone
 * provider
 * provider_id
 * password
 * influencer_id
 * answerList
 * @returns {Promise<*>}
 */
const signUp = async (param) => {
	const { data } = await axios.post(`${baseUrl}/partners/user_join`, param);
	return data;
};

/**
 * sns 사용자인지 확인
 * @param param
 * provider
 * provider_id
 * influenecer_id
 * @returns {Promise<*>}
 */
const snsValid = async (param) => {
	const { data } = await axios.post(`${baseUrl}/partners/sns_valid`, param);
	return data;
};

/**
 * sns 사용자 로그인 history
 * @param param
 * id
 * @returns {Promise<*>}
 */
const snsLoginHistory = async (param) => {
	const { data } = await axios.post(`${baseUrl}/partners/login/bySns`, param);
	return data;
};

/**
 * 사용자 IP 추적
 * @param param
 * @returns {Promise<*>}
 */
const postChannelUserIp = async (param) => {
	const { data } = await axios.post(`${baseUrl}/partners/record/ip`, param);
	return data;
};

const channelUserApi = {
	userHistory,

	login,
	logout,
	leave,
	editChannelUser,

	signUp,
	sendSms,
	findPwSendSms,
	sendSmsValid,

	postFindPw,
	getSignUpQuestionList,

	snsValid,
	snsLoginHistory,
	postChannelUserIp,
};

export default channelUserApi;
