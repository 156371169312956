import React, { useEffect, useState } from "react";
import "../../Event.scss";
import { useHistory } from "react-router-dom";

export default function SupporterPage() {
	const [mediaResult, setMediaResult] = useState(false);
	const history = useHistory();
	useEffect(() => {
		if (typeof window !== "undefined") {
			const matchQueryList = window.matchMedia("all and (max-width: 768px)");
			setMediaResult(matchQueryList.matches);

			const handleChange = (e) => {
				setMediaResult(e.matches);
			};

			matchQueryList.addEventListener("change", handleChange);
		}
	}, []);
	return (
		<div className="main-wrap event-supporter">
			<img
				className="support-event-banner"
				src="/assets/images/event/supporter-banner.jpg"
				alt="당신의 영양제 블로그, SNS, 메신저에 상품 추천하면 최대 수수료 30%, 무조건 7000원 받고 시작!"
			/>

			<h1>당영투게더 기자단</h1>
			<div className="summary-info-box">
				<div className="flex-summary-info">
					<p className="title">모집인원</p>
					<p className="desc">60 명</p>
				</div>
				<div className="flex-summary-info">
					<p className="title">제공내역</p>
					<p className="desc">50,000원</p>
				</div>
				<div className="flex-summary-info">
					<p className="title">신청기간</p>
					<p className="desc">2024.09.06. ~ 2024.09.10.</p>
				</div>
				<div className="flex-summary-info">
					<p className="title">리뷰어 발표</p>
					<p className="desc">2024.09.11.</p>
				</div>
				<div className="flex-summary-info">
					<p className="title">콘텐츠 등록기간</p>
					<p className="desc">2024.09.12. ~ 2024.09.18.</p>
				</div>
				<div className="flex-summary-info">
					<p className="title">캠페인 마감</p>
					<p className="desc">2024.09.19.</p>
				</div>
				<div className="flex-summary-info">
					<p className="title">제공 내역</p>
					<p className="desc">원고료 50,000원</p>
				</div>
			</div>

			<article className="support-detail-wrap" style={{ paddingBottom: "2em" }}>
				<h2 className="">원고 내용</h2>
				<div className="suport-icon-list">
					<div>
						<img src="/assets/images/event/ico-search.svg" alt="검색" />
						<p>키워드</p>
					</div>
					<div>
						<img src="/assets/images/event/ico-text.svg" alt="1000자 이상" />
						<p>1000자 이상</p>
					</div>
					<div>
						<img src="/assets/images/event/ico-image.svg" alt="10장 이상" />
						<p>10장 이상</p>
					</div>
				</div>
				<p className="highlight-text">
					당영투게더 회원 가입 & 리뷰 내 판매 링크 삽입 필수!
				</p>
				<div className="support-detail-desc">
					<div style={{ paddingBottom: "8px" }} className="style3">
						<span>1)</span>
						<p>
							당영 투게더 메인 페이지에서 회원 가입해주세요. (
							<a target="_blank" href="https://together.dangyoung.com">
								https://together.dangyoung.com/
							</a>
							)
						</p>
					</div>
					<p style={{ paddingBottom: "2.4em" }} className="style2">
						<span>2)</span> 판매 링크는 당영투게더 가입 후 홈 상품링크 부분에서
						링크 생성 버튼을 눌러 생성할 수 있어요.{"\n"}(생성한 링크를 리뷰에
						삽입해 클릭이나 구매로 발생하는 추가 수익도 잊지 말고 받아가세요!)
					</p>

					<div style={{ paddingLeft: "10px" }}>
						<p className="strong">
							01. 영양제 특화 몰인 ‘당신의 영양제’에서 판매하고 있는 상품의
							링크를 통해 수익을 가져갈 수 있는 서비스
						</p>
						<p className="strong">
							02. 무조건 7,000원 받고 시작
							<span className="line">
								회원 가입만 해도 최소 7,000원의 현금이 적립돼요!
							</span>
							<span className="line">
								10,000원만 쌓여도 즉시 정산 가능해요!
							</span>
						</p>
						<p className="strong">
							03. 클릭당 최대 100원 적립
							<span className="line">
								하루에 링크를 100번 클릭하면 한 달에 30만원을 벌 수 있어요!
							</span>
						</p>
						<p className="strong">
							04. 타사 대비 최대 10배 높은 판매 수수료로{"\n"}
							&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;평균 월 300만원 수익
							<span className="line">
								쿠팡 파트너스, 아이허브 같은 타사(3~15%) 대비 압도적으로 높은
								최대 30%의 판매 수수료가 지급돼요!
							</span>
							<span className="line">
								구매 1회당 평균 3만원이 적립되고 있어요!
							</span>
						</p>
						<p className="strong">
							05. 기타 혜택
							<span className="line">
								11월까지 3개월 동안만 진행되는 이벤트! 내 추천인 코드로 가입한
								친구의 수익금 30%가 나에게 추가 적립돼요
							</span>
							<span className="line">
								하나의 제품 링크로 여러 제품을 구매할 시 모든 제품의 수수료가
								지급돼요!
							</span>
							<span className="line">
								실시간으로 정산 내역을 확인할 수 있어요!
							</span>
						</p>
					</div>
				</div>
			</article>

			<article className="support-detail-wrap">
				<h2>키워드</h2>
				<h3>제목 키워드</h3>
				<div className="support-detail-desc">
					<div style={{ paddingLeft: "10px" }}>
						<p className="strong">
							부업, 앱테크, 높은 수익율, 당영투게더, 당신의영양제, 재택알바,
							재택부업
							<span
								className="line2"
								style={{
									marginTop: "0.6em",
								}}
							>
								안내드린 제목 키워드를 콘텐츠 제목에 꼭 넣어주세요. #태그에도
								넣어주시면 더욱 좋아요.
							</span>
							<span className="line2">
								키워드가 지켜지지 않으면 수정요청이 있을 수 있습니다.
							</span>
						</p>
					</div>
				</div>
				<h3>본문 키워드</h3>
				<div className="support-detail-desc">
					<div style={{ paddingLeft: "10px" }}>
						<p className="strong">
							부업,앱테크, 높은 수익율, 당영투게더, 당신의영양제, 재택알바,
							재택부업
							<span
								className="line2"
								style={{
									marginTop: "0.6em",
								}}
							>
								안내드린 본문키워드 중 1개 이상을 선택하여 총 5회 이상 본문에
								언급해 주세요.
							</span>
						</p>
					</div>
				</div>
			</article>
			<article className="support-detail-wrap" style={{ border: "none" }}>
				<h2>추가 안내사항</h2>
				<div className="support-detail-desc">
					<div style={{ paddingLeft: "10px", paddingTop: "10px" }}>
						<p className="strong">
							<span className="line2">
								원고 내용 또는 가이드라인이 지켜지지 않을 시에 수정 요청이 있을
								수 있습니다.
							</span>
							<span className="line2">
								협의 없이 취소는 불가하오니, 취소 사유를 반드시 알려 주시길
								바랍니다.
							</span>
							<span className="line2">
								원고료는 원고 확인 후 7일 이내로 지급됩니다.
							</span>
							<span className="line2">
								업체 사정에 따라 선정 체험단 수가 변경될 수 있습니다.
							</span>
							<span className="line2">
								작성하신 콘텐츠는 반드시 6개월 동안 유지하셔야 합니 다.
							</span>
							<span className="line2">
								리뷰 등록 시 맨 하단에 스폰서 배너 삽입 및 대가성 표 기는 필수로
								해주셔야 합니다.
							</span>
						</p>
					</div>
				</div>
			</article>
			<a
				target="_blank"
				href="https://docs.google.com/forms/d/e/1FAIpQLSezZI3y7RrvHmKS1m2IYMzPlk8mAin7vJQZRoFNh8o3k78UNg/viewform"
				className="submit-support-btn"
			>
				체험단 신청하기
			</a>
		</div>
	);
}

// 12 mediunm
// 14 mediunm
// 14 semibold
// 16 bold
