// import useSWR from "swr";
import ApiService from "./ApiService";
// import {decodeUserId, guestUserJoin} from "./convertUserId";

const api = new ApiService();

// const fetcher = async (userId) => {
//     const res = await api.userRead({user_id: userId});
//     return res.data.result;
// }

// export const useUser = () => {
//     let userId = "";
//     if (typeof window !== 'undefined') {
//         let encodeId = localStorage.getItem("userId");
//         if (encodeId !== null) {
//             userId = decodeUserId(encodeId);
//         } else {
//             guestUserJoin();
//         }
//     }
//     const {data} = useSWR(userId, fetcher);
//
//     return {data};
// }

// export const isUser = async () => {
//     let userInfo = null;
//     if (typeof window !== 'undefined') {
//         let encodeId = localStorage.getItem("userId");
//         if (encodeId == null) {
//             const result = await guestUserJoin();
//             encodeId = result.userId;
//         }
//         const userId = decodeUserId(encodeId);
//         userInfo = await api.userRead({user_id: userId});
//
//         // 탈퇴된 게스트회원 정보를 가져오는 경우 게스트 회원가입 진행
//         if (userInfo.data.result === null) {
//             const result = await guestUserJoin();
//             userInfo = await api.userRead({user_id: decodeUserId(result.userId)});
//         }
//     }
//
//     return userInfo;
// }

/**
 * 로그인 시 쿼리 확인
 * @param user
 * @returns {Promise<void>}
 */
export const loginLog = async (user, log, inflow) => {
	let location = document?.location;
	if (log) {
		let param = {
			log: log,
			created_by: user.id,
			created_at: new Date(),
			host: location?.host,
			hostname: location?.hostname,
			href: location?.href,
			origin: location?.origin,
			pathname: location?.pathname,
			inflow: inflow ?? null,
		};
		await api.queryLogCreate(param);
	}
};
