import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { Collapse, Row, Tabs } from "antd";

import partnersApi from "../apis/Partners.api";
import LinkComponent from "../components/layout/LinkComponent";
const { Panel } = Collapse;

const Faq = () => {
	const history = useHistory();
	const [mediaResult, setMediaResult] = useState(null);
	const [currentMenu, setCurrentMenu] = useState("service");
	const [currentPanel, setCurrentPanel] = useState(null);

	const [faqMenuList, setFaqMenuList] = useState([]);
	const [faqList, setFaqList] = useState([]);

	useEffect(() => {
		window.scrollTo({
			top: 0,
		});
		if (typeof window !== "undefined") {
			const matchQueryList = window.matchMedia("all and (max-width: 768px)");
			setMediaResult(matchQueryList.matches);

			const media = function handleChange(e) {
				setMediaResult(e.matches);
			};

			matchQueryList.addEventListener("change", media);
		}
	}, []);

	useEffect(() => {
		getMenuList();
	}, []);

	const handleMenu = (e) => {
		if (e !== currentMenu) {
			setCurrentMenu(e);
			getFaqList(e);
		}
		setCurrentPanel(null);
	};

	/**
	 * 메뉴 리스트 & faq 리스트 가져오기
	 * @returns {Promise<void>}
	 */
	const getMenuList = async () => {
		const res = await partnersApi.readFaqMenu();
		if (res.code === 200) {
			setFaqMenuList(res.result);
			const firstCategory = res.result[0].category_id;
			const rsq = await partnersApi.readFaqList({
				category_id: firstCategory,
			});
			if (rsq.code === 200) {
				setFaqList(rsq.result);
			}
		}
	};

	/**
	 * 해당 메뉴에 따른 faq 리스트 가져오기
	 * @param category_id
	 * @returns {Promise<void>}
	 */
	const getFaqList = async (category_id) => {
		let param = {
			category_id: category_id,
		};
		const res = await partnersApi.readFaqList(param);
		if (res.code === 200) {
			setFaqList(res.result);
		}
	};

	const menuItems = [
		...faqMenuList.map((menu) => {
			return {
				label: menu.category_name,
				key: menu.category_id,
				children: (
					<>
						{faqList?.length > 0 ? (
							<Collapse
								accordion
								bordered={false}
								activeKey={currentPanel ? [currentPanel] : []}
								className="faq-custom-collapse page"
								onChange={(e) => setCurrentPanel(e ? String(e) : null)}
								expandIconPosition={"end"}
							>
								{faqList.map((item, idx) => (
									<Panel
										header={
											<div>
												<span
													className={`faq-custom-collapse-panel-header-question`}
												>
													Q
												</span>
												{item.question}
											</div>
										}
										key={idx}
										className={`faq-custom-collapse-panel ${
											currentPanel === String(idx) ? "clicked" : ""
										}`}
									>
										<p>{item.answer}</p>
									</Panel>
								))}
							</Collapse>
						) : (
							<Row
								align={"middle"}
								style={{
									width: "100%",
									flexDirection: "column",
									padding: "3em 0",
								}}
							>
								<img
									src={`/assets/images/warning.png`}
									alt={`warning-icon`}
									style={{
										width: "3.5em",
										height: "auto",
									}}
								/>
								<span
									style={{
										marginTop: "1em",
										color: "#adb5bd",
										fontSize: "1.2em",
										fontWeight: 500,
									}}
								>
									일치하는 질문이 없습니다.
								</span>
							</Row>
						)}
					</>
				),
			};
		}),
	];

	return (
		<>
			<div className="main-wrap notice">
				<div className="max-width-apply">
					<LinkComponent />
					<div
						style={{
							display: "flex",
							flexDirection: "column",
							gap: mediaResult ? 7 : "0.3em",
							paddingBottom: "1em",
						}}
					>
						<h1
							className={`main-title add-mobile-css`}
							id={`main-title`}
							style={{ margin: 0 }}
						>
							자주묻는 질문
						</h1>
						<p className={`main-description page`}>
							자세한 사항은 담당자에게 문의해주세요.
						</p>
					</div>
					{/* <Tabs
						defaultActiveKey={currentMenu}
						items={menuItems}
						onChange={handleMenu}
					/> */}
				</div>
			</div>

			<div className="dashboard-list-content-box notice">
				<Tabs
					defaultActiveKey={currentMenu}
					items={menuItems}
					onChange={handleMenu}
				/>
			</div>
			<button
				style={{ margin: "0 auto 10em" }}
				type="button"
				className="inquiry-go-list-btn"
				onClick={() => history.push("/dashboard")}
			>
				홈으로
			</button>
		</>
	);
};
export default Faq;
