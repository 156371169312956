import * as React from "react";

import { Modal } from "antd";

const SendAccount1Modal = ({ show, setShow, mediaResult }) => {
	return (
		<Modal
			title=""
			open={show}
			footer={<></>}
			closable={false}
			wrapClassName="modal-wrap seller-grade-modal-wrap custom-modal-wrap"
			width={400}
			centered={mediaResult}
		>
			<div className="modal-title" style={{ marginBottom: "11px" }}>
				입력하신 계좌로 1원을 보냈습니다.
			</div>
			<div className="modal-title" style={{ marginBottom: 0 }}>
				거래내역 내 입금자명에 적힌
			</div>
			<div className="modal-title" style={{ marginBottom: "19px" }}>
				<span style={{ color: "#117FFA" }}>숫자 4자리</span>를 확인하여
				입력해주세요.
			</div>
			<div className="modal-button-wrap">
				<div onClick={() => setShow(false)} className="modal-button-success">
					확인
				</div>
			</div>
		</Modal>
	);
};

export default SendAccount1Modal;
