import * as React from "react";

import { Modal } from "antd";

const VerifyAccountModal = ({ show, setShow, mediaResult }) => {
	return (
		<Modal
			title=""
			open={show}
			footer={<></>}
			closable={false}
			wrapClassName="modal-wrap seller-grade-modal-wrap custom-modal-wrap"
			width={400}
			centered={mediaResult}
		>
			<div className="modal-title" style={{ marginBottom: 29 }}>
				계좌인증이 완료되었습니다.
			</div>
			<div className="modal-button-wrap">
				<div className="modal-button-success" onClick={() => setShow(false)}>
					확인
				</div>
			</div>
		</Modal>
	);
};

export default VerifyAccountModal;
