import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Col, Menu, Popover, Row, Spin } from "antd";

import CustomPagination from "../components/common/CustomPagination";
import LinkComponent from "../components/layout/LinkComponent";
import ProductComponent from "../components/products/ProductComponent";
import ProductNav from "../components/products/ProductNav";
import { setLoading } from "../redux/store/common/commonDuck";
import ApiService from "../utils/ApiService";

const api = new ApiService();
const Products = () => {
	const dispatch = useDispatch();
	const pageSize = 10;

	const user = useSelector((state) => state.auth.user);
	const isLoading = useSelector((state) => state.common.loading);
	const setIsLoading = (value) => dispatch(setLoading(value));

	const [mediaResult, setMediaResult] = useState(null);
	const [categoryList, setCategoryList] = useState([]);
	const [subCategoryList, setSubCategoryList] = useState([]);
	const [productList, setProductList] = useState([]);
	const [allCount, setAllCount] = useState(0);
	const [currentPageNumber, setCurrentPageNumber] = useState(1);

	const [selectedLinkItem, setSelectedLinkItem] = useState({});

	const [category, setCategory] = useState({
		category_id: "all",
		category_level: 2,
		category_name: "전체",
	});
	const [subCategory, setSubCategory] = useState({
		category_id: "all",
		category_name: "전체",
		category_parent: "vitamin",
	});
	const [sortOpen, setSortOpen] = useState(false);
	const [sortType, setSortType] = useState({
		name: "커미션순",
		orderType: "commission",
		orderBy: "desc",
	});

	const items = [
		{
			name: "커미션순",
			orderType: "commission",
			orderBy: "desc",
		},
		{
			name: "이름순",
			orderType: "name",
			orderBy: "asc",
		},
		{
			name: "브랜드순",
			orderType: "brand",
			orderBy: "asc",
		},
		{
			name: "추천순",
			orderType: "kpi",
			orderBy: "desc",
		},
	];

	const sortItem = (
		<Menu className={`product-sort-menu`}>
			{items?.map((item, idx) => (
				<Menu.Item
					key={`dropdown ${idx}`}
					onClick={(e) => handleDropdown(e, idx)}
				>
					{item.name}
				</Menu.Item>
			))}
		</Menu>
	);

	useEffect(() => {
		window.scrollTo({
			top: 0,
		});
		if (typeof window !== "undefined") {
			const matchQueryList = window.matchMedia("all and (max-width: 768px)");
			setMediaResult(matchQueryList.matches);

			const media = function handleChange(e) {
				setMediaResult(e.matches);
			};

			matchQueryList.addEventListener("change", media);
		}
		if (categoryList?.length === 0 || !subCategory?.category_id) {
			getCategoryList();
		}
	}, []);

	/**
	 * 카테고리 리스트 가져오기
	 * @returns {Promise<void>}
	 */
	const getCategoryList = async () => {
		setIsLoading(true);
		const res = await api.getProductCategoryAll();
		if (res.data.code === 200) {
			// 2레벨
			let list = [
				{
					category_id: "all",
					category_level: 2,
					category_name: "전체",
				},
			];
			let parent = {};
			res.data.result?.level2.map((item, idx) => {
				if (item.is_show && idx < 4) {
					list.push(item);
				}
				if (item.category_id === "vitamin") {
					parent = item;
				}
			});

			// 3레벨
			// let param = {
			// 	category_level: 3,
			// 	category_parent: parent?.category_id,
			// };
			// const rsp = await api.getProductCategoryList(param);
			// if (rsp.data.code === 200) {
			// 	setSubCategoryList([
			// 		{
			// 			category_id: "all",
			// 			category_name: "전체",
			// 			count: rsp.data.result.count,
			// 			category_parent: parent?.category_id,
			// 		},
			// 		...rsp.data.result.productCategoryDTOList,
			// 	]);
			// 	// setAllCount(rsp.data.result.count);
			// }
			// setCategory(parent);
			setCategoryList(list);
			setCurrentPageNumber(1);

			// 상품 리스트
			let param2 = {
				searchType: "all",
				searchText: "",
				user_id: user.id,
				offset: 0,
				pageSize: pageSize,
				search_age: 99, //filter.age,
				gender_id: 1, // filter.gender,
				orderType: sortType.orderType, // filter.sort_by[0],
				orderBy: sortType.orderBy, // filter.sort_by[1],
			};
			const result = await api.getSearchListNew(param2);
			if (result.data.code === 200) {
				setAllCount(result.data.result?.totalCnt);
				setProductList(result.data.result?.nutrientsKpiList);
				setCategory({
					category_id: "all",
					category_name: "전체",
				});
			}
		}
		setIsLoading(false);
	};

	/**
	 * 카테고리 클릭시
	 * @param item
	 * @returns {Promise<void>}
	 */
	const handleCategory = async (item) => {
		setCurrentPageNumber(1);
		if (item.category_id === "all") {
			handleSearch("");
		} else {
			setIsLoading(true);
			// let param = {
			// 	category_level: 3,
			// 	category_parent: item?.category_id,
			// };
			// const res = await api.getProductCategoryList(param);
			// if (res.data.code === 200) {
			// 	setSubCategoryList([
			// 		{
			// 			category_id: "all",
			// 			category_name: "전체",
			// 			count: res.data.result.count,
			// 			category_parent: item?.category_id,
			// 		},
			// 		...res.data.result.productCategoryDTOList,
			// 	]);
			// 	setSubCategory({
			// 		category_id: "all",
			// 		category_name: "전체",
			// 		count: res.data.result.count,
			// 		category_parent: item?.category_id,
			// 	});
			// 	setCurrentPageNumber(1);
			// }
			let param2 = {
				offset: 0,
				pageSize: pageSize,
				searchType: "category_2_all",
				categoryId: item.category_id,
				userId: user.id,
				orderType: sortType.orderType,
				orderBy: sortType.orderBy,
			};
			const rsp = await api.getProductItemCategory(param2);
			if (rsp.data.code === 200) {
				setProductList(rsp.data.result.productDTOList);
				setAllCount(rsp.data.result.totalCnt);
			}
			setIsLoading(false);
		}
	};

	/**
	 * 서브 카테고리 클릭 시
	 * @param item
	 * @returns {Promise<void>}
	 */
	const handleSubCategory = async (item) => {
		setIsLoading(true);
		let param = {
			offset: 0,
			pageSize: pageSize,
			searchType: item?.category_id === "all" ? "category_2_all" : "category",
			categoryId:
				item?.category_id === "all" ? item.category_parent : item?.category_id,
			userId: user.id,
			orderType: sortType.orderType,
			orderBy: sortType.orderBy,
		};
		const res = await api.getProductItemCategory(param);
		if (res.data.code === 200) {
			setProductList(res.data.result.productDTOList);
			setAllCount(res.data.result.totalCnt);
			setCurrentPageNumber(1);
		}

		setIsLoading(false);
	};

	/**
	 * page 넘김
	 */
	const handleChangePagination = async (pageNumber, type) => {
		setIsLoading(true);
		const currOffset = (pageNumber - 1) * pageSize;
		setCurrentPageNumber(pageNumber);
		if (category.category_id !== "all") {
			let param = {
				offset: currOffset,
				pageSize: pageSize,
				searchType:
					subCategory?.category_id === "all" ? "category_2_all" : "category",
				categoryId:
					subCategory?.category_id === "all"
						? subCategory.category_parent
						: subCategory?.category_id,
				userId: user.id,
				orderType: sortType.orderType,
				orderBy: sortType.orderBy,
			};
			const res = await api.getProductItemCategory(param);
			if (res.data.code === 200) {
				setProductList(res.data.result.productDTOList);
				setAllCount(res.data.result.totalCnt);
			}
		} else {
			let param = {
				searchType: "all",
				searchText: "",
				user_id: user?.id,
				pageSize: pageSize,
				offset: currOffset,
				search_age: 99, //filter.age,
				gender_id: 1, // filter.gender,
				orderType: sortType.orderType, // filter.sort_by[0],
				orderBy: sortType.orderBy, // filter.sort_by[1],
			};
			const res = await api.getSearchListNew(param);
			if (res.data.code === 200) {
				setAllCount(res.data.result?.totalCnt);
				setProductList(res.data.result?.nutrientsKpiList);
				setCategory({
					category_id: "all",
					category_name: "전체",
				});
			}
		}
		if (type !== "query") {
			window.scrollTo({
				top: 0,
				behavior: "smooth",
			});
		}
		setIsLoading(false);
	};

	/**
	 * 브랜드, 상품 검색
	 * @param searchValue
	 * @returns {Promise<void>}
	 */
	const handleSearch = async (searchValue) => {
		setIsLoading(true);
		let param = {
			searchType: "all",
			searchText: searchValue,
			user_id: user?.id,
			pageSize: pageSize,
			offset: 0,
			search_age: 99, //filter.age,
			gender_id: 1, // filter.gender,
			orderType: sortType.orderType, // filter.sort_by[0],
			orderBy: sortType.orderBy, // filter.sort_by[1],
		};
		const res = await api.getSearchListNew(param);
		if (res.data.code === 200) {
			setAllCount(res.data.result?.totalCnt);
			setProductList(res.data.result?.nutrientsKpiList);
			setCategory({
				category_id: "all",
				category_name: "전체",
			});
		}
		setIsLoading(false);
	};

	const handleDropdown = (e, idx) => {
		setSortType(items[idx]);
		setSortOpen(false);
	};

	useEffect(() => {
		if (category.category_id === "all") {
			getCategoryList();
		} else {
			handleSubCategory(subCategory);
		}
	}, [sortType]);

	return (
		<div className="main-wrap">
			<div className={`max-width-apply`}>
				{!mediaResult && <LinkComponent />}
				<h1 className={`main-title add-mobile-css`} id={`main-title`}>
					상품 링크
				</h1>
				{!mediaResult && (
					<img
						src={`/assets/images/product_link/product-link-banner_m.png`}
						className={`product-link-banner-image mobile`}
						alt={`프로젝트 링크 배너`}
						style={{
							width: "100%",
							height: "auto",
							marginBottom: "2em",
							// boxShadow: "10px 10px 10px 0 #a7bcd2",
						}}
					/>
				)}
				<img
					src={`/assets/images/product_link/product-link-banner.png`}
					className={`product-link-banner-image`}
					alt={`프로젝트 링크 배너`}
					style={{
						width: "100%",
						height: "auto",
						marginBottom: "2em",
						// boxShadow: "10px 10px 10px 0 #a7bcd2",
					}}
				/>

				{/* 상품 검색 form */}
				<ProductNav
					categoryList={categoryList}
					subCategoryList={subCategoryList}
					handleCategory={handleCategory}
					category={category}
					setCategory={setCategory}
					handleSubCategory={handleSubCategory}
					subCategory={subCategory}
					setSubCategory={setSubCategory}
					handleSearch={handleSearch}
					type={"products"}
					mediaResult={mediaResult}
				/>

				{/* 상품 리스트 표기 */}
				<div className={`main-body`} style={{ position: "relative" }}>
					{!!isLoading && (
						<div
							style={{
								width: "100%",
								position: "fixed",
								top: 0,
								left: 0,
								right: 0,
								bottom: 0,
								zIndex: 999,
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								backgroundColor: "rgba(0,0,0,0.2)",
							}}
						>
							<Spin size={"large"} />
						</div>
					)}
					{!mediaResult && (
						<h3 className={`body-title`}>{category.category_name}</h3>
					)}
					<Row justify={"space-between"}>
						<b style={{ whiteSpace: "nowrap" }}>
							총 <span style={{ color: "#117ffa" }}>{allCount}</span>개
						</b>
						<Popover
							placement="rightTop"
							content={sortItem}
							trigger="click"
							open={sortOpen}
							overlayClassName={"product-sort-popup"}
						>
							<button
								onClick={() => setSortOpen(!sortOpen)}
								style={{
									display: "flex",
									alignItems: "center",
									flexWrap: "nowrap",
								}}
							>
								<b style={{ whiteSpace: "nowrap" }}>{sortType.name}</b>
								<img
									src={`/assets/images/products/dropdown-${sortType.orderBy}.png`}
									alt={`드롭다운`}
									style={{
										height: "1.1em",
										width: "auto",
									}}
								/>
							</button>
						</Popover>
					</Row>

					<Row className={`product-list-wrap`} style={{ rowGap: "1.5em" }}>
						<>
							{productList.length > 0 ? (
								<>
									{/* 상품별 */}
									{/* {productList.map((item, idx) => (
										<Col
											className={`product-list-each product-link ${
												mediaResult ? "dashboard" : ""
											}`}
											key={`상품 리스트 확인 ${idx}`}
										>
											{item?.quantity < 1 && (
												<div className={`sold-out`}>품절</div>
											)}
											<ProductComponent
												item={item}
												handleChangePagination={handleChangePagination}
												currentPageNumber={currentPageNumber}
												selectedLinkItem={selectedLinkItem}
												setSelectedLinkItem={setSelectedLinkItem}
												type={"dashboard"}
												mediaResult={mediaResult}
											/>
										</Col>
									))} */}
									{productList.map((item, idx) => (
										<Col
											className={`product-list-each dashboard`}
											key={`상품 리스트 확인 ${idx}`}
										>
											{item?.quantity < 1 && (
												<div className={`sold-out`}>품절</div>
											)}
											<ProductComponent
												item={item}
												handleChangePagination={handleChangePagination}
												currentPageNumber={currentPageNumber}
												selectedLinkItem={selectedLinkItem}
												setSelectedLinkItem={setSelectedLinkItem}
												type={"dashboard"}
												mediaResult={mediaResult}
											/>
										</Col>
									))}
								</>
							) : (
								<Row
									align={"middle"}
									style={{
										width: "100%",
										flexDirection: "column",
										padding: "3em 0",
									}}
								>
									<img
										src={`/assets/images/warning.png`}
										alt={`warning-icon`}
										style={{
											width: "4em",
											height: "auto",
										}}
									/>
									<span
										style={{
											marginTop: "1em",
											color: "#adb5bd",
											fontSize: "1.2em",
											fontWeight: 500,
										}}
									>
										일치하는 상품이 없습니다.
									</span>
								</Row>
							)}
						</>
					</Row>

					<CustomPagination
						pageSize={pageSize}
						totalCount={allCount}
						currPageNum={currentPageNumber}
						pageClick={handleChangePagination}
					/>
				</div>
			</div>
		</div>
	);
};
export default Products;
