//-----------쿠키 가져오기----------------
// 주어진 이름의 쿠키를 반환하는데,
// 조건에 맞는 쿠키가 없다면 undefined를 반환합니다.
export function getCookie(name) {
	let matches = document.cookie.match(
		new RegExp(
			"(?:^|; )" + name.replace(`/([.$?*|{}()[]\\/+^])/g`, "\\$1") + "=([^;]*)"
		)
	);
	return matches ? decodeURIComponent(matches[1]) : undefined;
}

//-----------쿠키 설정하기----------------
export function setCookie(name, value, options = {}) {
	options = {
		path: "/",
		// 필요한 경우, 옵션 기본값을 설정할 수도 있습니다.
		...options,
	};

	if (options.expires instanceof Date) {
		options.expires = options.expires.toUTCString();
	}

	let updatedCookie =
		encodeURIComponent(name) + "=" + encodeURIComponent(value);

	for (let optionKey in options) {
		updatedCookie += "; " + optionKey;
		let optionValue = options[optionKey];
		if (optionValue !== true) {
			updatedCookie += "=" + optionValue;
		}
	}

	document.cookie = updatedCookie;
}

// Example of use:
// setCookie("user", "John", { secure: true, "max-age": 3600 });

//-----------쿠키 삭제하기----------------

export function deleteCookie(name) {
	setCookie(name, "", {
		"max-age": -1,
	});
}

// --- 하루 그만 보기 ---
export function setCookieOneDay(name, value, expiredays) {
	let todayDate = new Date();
	todayDate.setDate(todayDate.getDate() + expiredays);
	document.cookie =
		name +
		"=" +
		escape(value) +
		"; path=/; expires=" +
		todayDate.toGMTString() +
		";";
}
