import React from "react";
import { useHistory, useLocation } from "react-router-dom";

import { Button } from "antd";

const GoldSettlementRequestCompleteComponent = () => {
	const history = useHistory();
	const location = useLocation();
	const state = location.state;

	return (
		<div className="main-wrap gold-settlement-request-complete-wrap">
			<div className={`gold-settlement-request-complete-content`}>
				<p className="title">정산 신청이{"\n"}완료 되었습니다.</p>
				<div className="gold-settlement-div tooltip">
					<span>{state?.changeGold?.toLocaleString() ?? 0}</span>Gold
				</div>
				<div className="gold-settlement-div">
					<span>
						{state?.changeGold / 10 > 0
							? (state?.changeGold / 10).toLocaleString()
							: 0}
					</span>
					원
				</div>
				<p className="help-text">*100 Gold = 10원</p>
			</div>
			<Button
				className={`confirm`}
				onClick={() => history.replace("/dashboard")}
			>
				확인
			</Button>
		</div>
	);
};

export default GoldSettlementRequestCompleteComponent;
