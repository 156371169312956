import axios from "axios";

const baseUrl = `${process.env.REACT_APP_BACKEND_HOST}`;

/**
 * 상담게시판 목록 조회
 * @param param
 * offset
 * pageSize
 * influencerId
 * userId
 * @returns {Promise<unknown>}
 */
const readInquiryList = async (param) => {
	const { data } = await axios.get(`${baseUrl}/partners/inquiry/list`, {
		params: param,
	});
	return data;
};

/**
 * 상담게시글 단건 조회
 * @param param
 * inquiryId
 * influencerId
 * userId
 * @returns {Promise<unknown>}
 */
const readInquiryBoard = async (param) => {
	const { data } = await axios.get(`${baseUrl}/partners/inquiry/getOne`, {
		params: param,
	});
	return data;
};

/**
 * 상담게시판 내가 쓴 목록 조회
 * @param param
 * offset
 * pageSize
 * influencerId
 * userId
 * @returns {Promise<unknown>}
 */
const findInquiryList = async (param) => {
	const { data } = await axios.get(`${baseUrl}/partners/inquiry/find`, {
		params: param,
	});
	return data;
};

/**
 * 상담게시판 글 등록 및 수정
 * @param param
 * id: 있으면 등록/없으면 수정
 * influencer_id
 * title
 * content
 * is_secret
 * created_by
 * @returns {Promise<unknown>}
 */
const postInquiry = async (param) => {
	const { data } = await axios.post(
		`${baseUrl}/partners/register/inquiry`,
		param
	);
	return data;
};

/**
 * 상담게시판 관련 이미지 등록/수정/삭제
 * @param param
 * id
 * influencer_id
 * is_inquiry - true : 질문 / false : 답변
 * created_by
 * inquiryImageList : 질문 관련 이미지
 * answerImageList : 답변 관련 이미지
 * @returns {Promise<unknown>}
 */
const postInquiryImages = async (param) => {
	const { data } = await axios.post(
		`${baseUrl}/partners/register/inquiry/image`,
		param
	);
	return data;
};

/**
 * 상담게시판 글 삭제
 * @param param
 * inquiryId
 * influencerId
 * userId
 * @returns {Promise<unknown>}
 */
const deleteInquiry = async (param) => {
	const { data } = await axios.delete(`${baseUrl}/partners/delete/inquiry`, {
		params: param,
	});
	return data;
};

/**
 * admin: 상담게시판 목록 조회
 * @param param
 * offset
 * pageSize
 * userId
 * answerStatus: tatal, waiting, answer
 * @returns {Promise<unknown>}
 */
const adminReadInquiryList = async (param) => {
	const { data } = await axios.get(`${baseUrl}/partners/admin/inquiry/list`, {
		params: param,
	});
	return data;
};

/**
 * 상담게시판 답변 작성/수정
 * @param param
 * id
 * inquiry_id
 * title
 * content
 * created_by
 * @returns {Promise<unknown>}
 */
const adminPostInquiry = async (param) => {
	const { data } = await axios.post(
		`${baseUrl}/partners/admin/inquiry/answer/register`,
		param
	);
	return data;
};

/**
 * 상담게시판 답변만 삭제
 * @param param
 * inquiryId
 * answerId
 * influencerId
 * userId
 * @returns {Promise<unknown>}
 */
const adminDeleteInquiry = async (param) => {
	const { data } = await axios.delete(
		`${baseUrl}/partners/admin/inquiry/answer`,
		{
			params: param,
		}
	);
	return data;
};

/**
 * 상담게시판 질문/답변 모두 삭제
 * @param param
 * inquiryId
 * influencerId
 * userId
 * @returns {Promise<unknown>}
 */
const adminDeleteInquiryAll = async (param) => {
	const { data } = await axios.delete(`${baseUrl}/partners/admin/inquiry`, {
		params: param,
	});
	return data;
};

/**
 * 사용자별 답변이 완료되었으나 읽지 않은 문의 갯수 조회
 * @param param
 * userId
 * @returns {Promise<unknown>}
 */
const readUnreadInquiryCnt = async (param) => {
	const { data } = await axios.get(`${baseUrl}/csCenter/read/status/unread`, {
		params: param,
	});
	return data;
};

/**
 * 사용자별 문의 목록 조회
 * @param param
 * offset
 * pageSize
 * user_id
 * isApp
 * isTogether
 * @returns {Promise<unknown>}
 */
const readInquiryListByUser = async (param) => {
	const { data } = await axios.get(`${baseUrl}/csCenter/read`, {
		params: param,
	});
	return data;
};

/**
 * 사용자별 문의 단건 조회
 * @param param
 * csId
 * userId
 * @returns {Promise<unknown>}
 */
const readInquiryOne = async (param) => {
	const { data } = await axios.get(`${baseUrl}/csCenter/read/one`, {
		params: param,
	});
	return data;
};

/**
 * 사용자별 문의사항 등록하기
 * @param param
 * @returns {Promise<unknown>}
 */
const createInquiry = async (param) => {
	const { data } = await axios.post(`${baseUrl}/csCenter/add`, param);
	return data;
};

/**
 * 사용자별 읽지 않은 답변 완료된 문의 읽음 처리하기
 * @param param
 * @returns {Promise<unknown>}
 */
const readInquiry = async (param) => {
	const { data } = await axios.post(`${baseUrl}/csCenter/edit/read`, param);
	return data;
};

const inquiryApi = {
	readInquiryList,
	readInquiryBoard,
	findInquiryList,
	postInquiry,
	postInquiryImages,
	deleteInquiry,

	adminReadInquiryList,
	adminPostInquiry,
	adminDeleteInquiry,
	adminDeleteInquiryAll,

	readUnreadInquiryCnt,
	readInquiryListByUser,
	readInquiryOne,
	createInquiry,
	readInquiry,
};

export default inquiryApi;
