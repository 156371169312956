import { useSelector } from "react-redux";

import { Row, Spin, Tooltip } from "antd";

const ReportStatisticsItemComponent = ({
	title,
	value,
	unit,
	statistics,
	mediaResult,
	// targetMonth,
}) => {
	const isLoading = useSelector((state) => state.common.loading);

	const tax_text =
		"· 원천세(개인) : -3.3%\n· 부가가치세 포함\n· 카톡푸시 이용 금액 제외";

	return (
		<div className="report-statistics-item-wrap">
			<Row
				align={"middle"}
				// justify={"center"}
				style={{
					gap: mediaResult ? "0" : "3px",
					position: "relative",
					justifyContent: mediaResult ? "flex-start" : "center",
				}}
			>
				{title}
				{title === "정산 금액" && (
					<>
						<Tooltip
							trigger={`click`}
							title={tax_text}
							overlayStyle={{ whiteSpace: "pre-wrap" }}
						>
							<img
								src={`/assets/images/report/notice.png`}
								alt={`세금 안내`}
								style={{
									height: "1.1em",
									width: "auto",
									cursor: "pointer",
								}}
							/>
						</Tooltip>
					</>
				)}
			</Row>
			{isLoading ? (
				<Spin
					style={{
						margin: mediaResult ? 0 : "auto",
						width: mediaResult ? "auto" : "100%",
						paddingTop: mediaResult ? 0 : "1em",
					}}
				/>
			) : (
				<>
					{title === "카톡푸시 이용 금액" &&
					!value &&
					statistics?.purchases_complete_num ? (
						<div
							className="report-statistics-value-wrap"
							style={
								title === "정산 금액"
									? {
											color: "#117ffa",
									  }
									: {}
							}
						>
							{unit
								? unit === "%"
									? parseFloat(value).toFixed(2)
									: value.toLocaleString()
								: value.toLocaleString()}
							{unit ? unit : null}
						</div>
					) : (
						<>
							{value !== null && (
								<div
									className="report-statistics-value-wrap"
									style={
										title === "정산 금액"
											? {
													color: "#117ffa",
											  }
											: {}
									}
								>
									{unit
										? unit === "%"
											? parseFloat(value).toFixed(2)
											: value.toLocaleString()
										: value.toLocaleString()}
									{mediaResult ? (
										<span className={`report-statistics-value-unit`}>
											{unit ? unit : null}
										</span>
									) : (
										<>{unit ? unit : null}</>
									)}
								</div>
							)}
						</>
					)}
				</>
			)}
		</div>
	);
};

export default ReportStatisticsItemComponent;
