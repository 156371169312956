import { Helmet } from "react-helmet-async";

const MetaTag = (props) => {
	const title =
		props?.title ?? "당영투게더 | 누구나 손쉬운 부업, 높은 수익률까지";
	const description =
		props?.description ??
		"영양제 특화 몰의 믿을 수 있는 제품 제공! 판매 수익 투명하게 실시간 확인 가능";
	const keywords =
		props?.keywords ?? "당신의 영양제, 당신의영양제, 당영, 당영투게더, 투게더";
	const image = props?.image ?? "/together.png";
	const url = props?.url ?? "https://together.dangyoung.com/";

	return (
		<Helmet>
			<title>{title}</title>

			<meta name="image" content={image} />
			<meta name="description" content={description} />
			<meta name="keywords" content={keywords} />

			<meta property="og:type" content="website" />
			<meta property="og:title" content={title} />
			<meta property="og:site_name" content={title} />
			<meta property="og:description" content={description} />
			<meta property="og:image" content={image} />
			<meta property="og:url" content={url} />

			<meta name="twitter:title" content={title} />
			<meta name="twitter:description" content={description} />
			<meta name="twitter:image" content={image} />

			<link rel="canonical" href={url} />
		</Helmet>
	);
};

export default MetaTag;
