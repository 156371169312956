import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

const InquiryGuideFooterComponent = () => {
	const history = useHistory();

	const [mediaResult, setMediaResult] = useState(null);

	useEffect(() => {
		if (typeof window !== "undefined") {
			const matchQueryList = window.matchMedia("all and (max-width: 768px)");
			setMediaResult(matchQueryList.matches);

			const media = function handleChange(e) {
				setMediaResult(e.matches);
			};

			matchQueryList.addEventListener("change", media);
		}
	}, []);

	const routeToQnA = () => {
		window.scrollTo({
			top: 0,
			behavior: "smooth",
		});
		history.push(
			mediaResult ? "/user/inquiry?type=create" : "/user?inquiry=create"
		);
	};

	const routeToGuide = () => {
		window.scrollTo({
			top: 0,
			behavior: "smooth",
		});
		history.push("/benefit/information");
	};

	return (
		<div className={"inquiry-guide-footer"}>
			<div
				className={"item-wrap"}
				style={{
					justifyContent: `${mediaResult ? "center" : "flex-end"}`,
				}}
				onClick={routeToQnA}
			>
				<div className={"img-wrap"}>
					<img
						src={`/assets/images/layout/inquiry-icon.png`}
						alt={`qna-icon`}
						style={{
							width: `${mediaResult ? "10rem" : "inherit"}`,
							height: "inherit",
							objectFit: "cover",
							border: "none",
						}}
					/>
				</div>
				<div className={"text-wrap"}>
					<h4
						style={{
							marginBottom: 0,
							paddingBottom: `0.5rem`,
							fontSize: "1.75rem",
							fontWeight: "600",
						}}
					>{`1:1 문의하기`}</h4>
					<p style={{ color: "#868788", fontSize: "1.25rem" }}>
						궁금한 점이 있으시면 문의 주세요. 도와드리겠습니다.
					</p>
				</div>
			</div>
			<div className={"inquiry-guide-item-divider"} />
			<div
				className={"item-wrap"}
				style={{
					justifyContent: `${mediaResult ? "center" : "flex-start"}`,
				}}
				onClick={routeToGuide}
			>
				<div className={"img-wrap"}>
					<img
						src={`/assets/images/layout/guide-icon.png`}
						alt={`faq-icon`}
						style={{
							width: "10rem",
							height: `${mediaResult ? "inherit" : "auto"}`,
							objectFit: "cover",
							border: "none",
							padding: `${mediaResult ? "25px 0" : "0"}`,
						}}
					/>
				</div>
				<div className={"text-wrap"}>
					<h4
						style={{
							marginBottom: 0,
							paddingBottom: `0.5rem`,
							fontSize: "1.75rem",
							fontWeight: "600",
						}}
					>{`이용가이드`}</h4>
					<p style={{ color: "#868788", fontSize: "1.25rem" }}>
						서비스 이용 방법과 문의 처리에 대한 안내입니다.
					</p>
				</div>
			</div>
		</div>
	);
};

export default InquiryGuideFooterComponent;
