import { all } from "redux-saga/effects";
import moment from "moment";

import produce, { enableES5 } from "immer";

//타입
const commonTypes = {
	SET_LOADING: "SET_LOADING",
	SET_RECORD_DATE: "SET_RECORD_DATE",
	SET_RECORD_DATES: "SET_RECORD_DATES",
	SET_SEARCH_DATA: "SET_SEARCH_DATA",

	SET_SEARCH_TEXT: "SET_SEARCH_TEXT",
};

//리듀서
export const initialState = {
	loading: false,
	recordDate: moment().format("YYYY-MM-DD"),
	recordDates: {
		startDate: moment().format("YYYY-MM-DD"),
		endDate: moment().add(1, "d").format("YYYY-MM-DD"),
	},
	searchData: "",
	searchText: " ",
};

const produceFunc = (...args) => {
	enableES5();
	return produce(...args);
};

export const commonReducer = (state = initialState, action) => {
	return produceFunc(state, (draft) => {
		switch (action.type) {
			case commonTypes.SET_LOADING:
				draft.loading = action.loading;
				break;
			case commonTypes.SET_RECORD_DATE:
				draft.recordDate = action.recordDate;
				break;
			case commonTypes.SET_RECORD_DATES:
				draft.recordDates = action.recordDates;
				break;
			case commonTypes.SET_SEARCH_DATA:
				draft.searchData = action.searchData;
				break;
			case commonTypes.SET_SEARCH_TEXT:
				draft.searchText = action.searchText;
				break;
			default:
				break;
		}
	});
};

//액션
export const setLoading = (loading) => ({
	type: commonTypes.SET_LOADING,
	loading,
});

export const setSearchText = (searchText) => ({
	type: commonTypes.SET_SEARCH_TEXT,
	searchText,
});

export const setRecordDate = (recordDate) => ({
	type: commonTypes.SET_RECORD_DATE,
	recordDate,
});

export const setRecordDates = (recordDates) => ({
	type: commonTypes.SET_RECORD_DATES,
	recordDates,
});

export const setSearchData = (searchData) => ({
	type: commonTypes.SET_SEARCH_DATA,
	searchData,
});

//미들웨어
export default function* commonSaga() {
	yield all([
		// takeLatest(shopTypes.SHOP_ADD_CART.REQUEST, addCart$),
	]);
}
