import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { Col, Menu, Popover, Row, Skeleton, Tooltip, List } from "antd";

import ApiService from "../../utils/ApiService";
import ProductComponent from "../products/ProductComponent";
import ProductNav from "../products/ProductNav";

const api = new ApiService();

const ProductLink = ({ mediaResult }) => {
	const pageSize = 10;
	const infoText = (
		<div className={`dashboard-product-link-info-wrap`}>
			<h3>링크 공유 전 꼭 확인해주세요!</h3>
			<p>
				상품 정보 클릭 시 넘어가는 페이지의 링크는 적용되지 않으니 반드시 링크
				생성을 통해 사용해주세요
			</p>
		</div>
	);
	const user = useSelector((state) => state.auth.user);

	const [isLoading, setIsLoading] = useState(false);
	const [categoryList, setCategoryList] = useState([]);
	const [subCategoryList, setSubCategoryList] = useState([]);
	const [category, setCategory] = useState({
		category_id: "all",
		category_level: 2,
		category_name: "전체",
	});
	const [subCategory, setSubCategory] = useState({
		category_id: "all",
		category_name: "전체",
		category_parent: "vitamin",
	});
	const [sortOpen, setSortOpen] = useState(false);
	const [sortType, setSortType] = useState({
		name: "커미션순",
		orderType: "commission",
		orderBy: "desc",
	});
	const [allCount, setAllCount] = useState(0);
	const [productList, setProductList] = useState([]);
	const [selectedLinkItem, setSelectedLinkItem] = useState({});

	const items = [
		{
			name: "커미션순",
			orderType: "commission",
			orderBy: "desc",
		},
		{
			name: "이름순",
			orderType: "name",
			orderBy: "asc",
		},
		{
			name: "브랜드순",
			orderType: "brand",
			orderBy: "asc",
		},
		{
			name: "추천순",
			orderType: "kpi",
			orderBy: "desc",
		},
	];
	const sortItem = (
		<Menu className={`product-sort-menu`}>
			{items?.map((item, idx) => (
				<Menu.Item
					key={`dropdown ${idx}`}
					onClick={(e) => handleDropdown(e, idx)}
				>
					{item.name}
				</Menu.Item>
			))}
		</Menu>
	);
	const emptyList = Array.from({
		length: 10,
	}).map((_, i) => ({
		brand_name: "프로메드",
		product_name: "에너지솔루션",
		commission_rate: "20%",
	}));

	useEffect(() => {
		if (categoryList?.length === 0 || !subCategory?.category_id) {
			getCategoryList();
		}
	}, []);

	/**
	 * 카테고리 리스트 가져오기
	 * @returns {Promise<void>}
	 */
	const getCategoryList = async () => {
		setIsLoading(true);
		const res = await api.getProductCategoryAll();
		if (res.data.code === 200) {
			// 2레벨
			let list = [
				{
					category_id: "all",
					category_level: 2,
					category_name: "전체",
				},
			];
			let parent = {};
			res.data.result?.level2.map((item, idx) => {
				if (item.is_show && idx < 4) {
					list.push(item);
				}
				if (item.category_id === "vitamin") {
					parent = item;
				}
			});
			setCategoryList(list);

			// 상품 리스트
			let param2 = {
				searchType: "all",
				searchText: "",
				user_id: user.id,
				offset: 0,
				pageSize: pageSize,
				search_age: 99, //filter.age,
				gender_id: 1, // filter.gender,
				orderType: sortType.orderType, // filter.sort_by[0],
				orderBy: sortType.orderBy, // filter.sort_by[1],
			};
			const result = await api.getSearchListNew(param2);
			if (result.data.code === 200) {
				setAllCount(result.data.result?.totalCnt);
				setProductList(result.data.result?.nutrientsKpiList);
				setCategory({
					category_id: "all",
					category_name: "전체",
				});
			}
		}
		setIsLoading(false);
	};

	/**
	 * 카테고리 클릭시
	 * @param item
	 * @returns {Promise<void>}
	 */
	const handleCategory = async (item) => {
		if (item.category_id === "all") {
			handleSearch("");
		} else {
			setIsLoading(true);
			let param2 = {
				offset: 0,
				pageSize: pageSize,
				searchType: "category_2_all",
				categoryId: item.category_id,
				userId: user.id,
				orderType: sortType.orderType,
				orderBy: sortType.orderBy,
			};
			const rsp = await api.getProductItemCategory(param2);
			if (rsp.data.code === 200) {
				setProductList(rsp.data.result.productDTOList);
				setAllCount(rsp.data.result.totalCnt);
			}
			setIsLoading(false);
		}
	};

	/**
	 * 서브 카테고리 클릭 시
	 * @param item
	 * @returns {Promise<void>}
	 */
	const handleSubCategory = async (item) => {
		setIsLoading(true);
		let param = {
			offset: 0,
			pageSize: pageSize,
			searchType: item?.category_id === "all" ? "category_2_all" : "category",
			categoryId:
				item?.category_id === "all" ? item.category_parent : item?.category_id,
			userId: user.id,
			orderType: sortType.orderType,
			orderBy: sortType.orderBy,
		};
		const res = await api.getProductItemCategory(param);
		if (res.data.code === 200) {
			setProductList(res.data.result.productDTOList);
			setAllCount(res.data.result.totalCnt);
		}

		setIsLoading(false);
	};

	/**
	 * 브랜드, 상품 검색
	 * @param searchValue
	 * @returns {Promise<void>}
	 */
	const handleSearch = async (searchValue) => {
		setIsLoading(true);
		let param = {
			searchType: "all",
			searchText: searchValue,
			user_id: user?.id,
			pageSize: pageSize,
			offset: 0,
			search_age: 99, //filter.age,
			gender_id: 1, // filter.gender,
			orderType: sortType.orderType, // filter.sort_by[0],
			orderBy: sortType.orderBy, // filter.sort_by[1],
		};
		const res = await api.getSearchListNew(param);
		if (res.data.code === 200) {
			setAllCount(res.data.result?.totalCnt);
			setProductList(res.data.result?.nutrientsKpiList);
			setCategory({
				category_id: "all",
				category_name: "전체",
			});
		}
		setIsLoading(false);
	};

	const handleDropdown = (e, idx) => {
		setSortType(items[idx]);
		setSortOpen(false);
	};

	return (
		<div
			className={`dashboard-component`}
			style={{
				position: "relative",
				gap: "0.5em",
				marginTop: mediaResult ? "-0.8rem" : 0,
			}}
		>
			<div className={`dashboard-component-header-wrap`}>
				<h3>상품링크</h3>
				{/* <Tooltip placement="right" title={infoText}>
					<div className="header-notice-mark">!</div>
				</Tooltip> */}
			</div>
			<Link to={`/products`} className={`read-more product`}>
				{`자세히보기 >`}
			</Link>

			{/* 상품 검색 form */}
			<ProductNav
				categoryList={categoryList}
				subCategoryList={subCategoryList}
				handleCategory={handleCategory}
				category={category}
				setCategory={setCategory}
				handleSubCategory={handleSubCategory}
				subCategory={subCategory}
				setSubCategory={setSubCategory}
				handleSearch={handleSearch}
				// type={"dashboard"}
				type={"products"}
			/>

			<Row justify={"space-between"} className="productLink dropAreaRow">
				<b style={{ whiteSpace: "nowrap" }}>
					총 <span style={{ color: "#117ffa" }}>{allCount}</span>개
				</b>
				<Popover
					placement="rightTop"
					content={sortItem}
					trigger="click"
					open={sortOpen}
					overlayClassName={"product-sort-popup dashboardMobile"}
				>
					<button
						onClick={() => setSortOpen(!sortOpen)}
						style={{
							display: "flex",
							alignItems: "center",
							flexWrap: "nowrap",
						}}
					>
						<b style={{ whiteSpace: "nowrap" }}>{sortType.name}</b>
						<img
							src={`/assets/images/products/dropdown-${
								sortOpen ? "asc" : "desc"
							}.png`}
							alt={`드롭다운`}
							style={{
								height: "1.1em",
								width: "auto",
							}}
						/>
					</button>
				</Popover>
			</Row>
			<Row
				className={`product-list-wrap`}
				style={
					mediaResult
						? { rowGap: "1.5em", paddingBottom: 0, paddingTop: "1em" }
						: { rowGap: "1.5em" }
				}
			>
				<>
					{isLoading ? (
						<List
							className={`skeleton-empty-list`}
							grid={{
								gutter: 24,
								column: mediaResult ? 2 : 5,
							}}
							dataSource={emptyList}
							renderItem={(item, idx) => (
								<List.Item key={`dashboard-product-${idx}`}>
									{/*스켈레톤*/}
									<Skeleton.Image
										loading={isLoading}
										active={isLoading}
										className={`img-skeleton`}
									/>
									<div className={`dashboard-product-etc-info-wrap`}>
										<Skeleton.Input
											className={`dashboard-product-etc-info`}
											loading={isLoading}
											active={isLoading}
										/>
										<Skeleton.Input
											className={`dashboard-product-etc-info`}
											loading={isLoading}
											active={isLoading}
										/>
									</div>
									<Skeleton.Input
										className={`dashboard-product-name`}
										loading={isLoading}
										active={isLoading}
									/>
									<Skeleton.Button
										active={isLoading}
										block={true}
										style={{ marginBottom: "4px" }}
									/>
									<Skeleton.Button active={isLoading} block={true} />
								</List.Item>
							)}
						/>
					) : (
						<>
							{productList.length > 0 ? (
								<>
									{/* 상품별 */}
									{productList.map((item, idx) => (
										<Col
											className={`product-list-each dashboard`}
											key={`상품 리스트 확인 ${idx}`}
										>
											{item?.quantity < 1 && (
												<div className={`sold-out`}>품절</div>
											)}
											<ProductComponent
												item={item}
												currentPageNumber={1}
												selectedLinkItem={selectedLinkItem}
												setSelectedLinkItem={setSelectedLinkItem}
												type={"dashboard"}
												mediaResult={mediaResult}
											/>
										</Col>
									))}
								</>
							) : (
								<Row
									align={"middle"}
									style={{
										width: "100%",
										flexDirection: "column",
										padding: "3em 0",
									}}
								>
									<img
										src={`/assets/images/warning.png`}
										alt={`warning-icon`}
										style={{
											width: "4em",
											height: "auto",
										}}
									/>
									<span
										style={{
											marginTop: "1em",
											color: "#adb5bd",
											fontSize: "1.2em",
											fontWeight: 500,
										}}
									>
										일치하는 상품이 없습니다.
									</span>
								</Row>
							)}
						</>
					)}
				</>
			</Row>
			<div
				className="dashboard-component-footer no-line mobile"
				style={{
					paddingBottom: 0,
				}}
			>
				<Link to={`/products`} className={`read-more dashboard-gold-info`}>
					{`자세히보기 >`}
				</Link>
			</div>
		</div>
	);
};

export default ProductLink;
