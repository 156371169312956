import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { Button, Input } from "antd";
import moment from "moment";

import Swal from "sweetalert2";

import inquiryApi from "../../apis/Inquiry.api";
import { setLoading } from "../../redux/store/common/commonDuck";

const InquiryAdd = ({ formValue, setFormValue, mediaResult, handleMenu }) => {
	let regPhone = /010-?([0-9]{4})-?([0-9]{4})$/;
	const dispatch = useDispatch();
	const history = useHistory();
	const user = useSelector((state) => state.auth.user);
	const isLoading = useSelector((state) => state.common.loading);
	const setIsLoading = (value) => dispatch(setLoading(value));

	/**
	 * 폼 입력창 handler
	 * @param e
	 */
	const handleChange = (e) => {
		const { name, value } = e.target;
		if (name === "company_contact") {
			if (value?.split("-").join("").length <= 11) {
				let phone = value
					.replace(/[^0-9]/g, "")
					.replace(/^(\d{2,3})(\d{3,4})(\d{4})$/g, "$1-$2-$3")
					.replace(/(-{1,2})$/g, "");
				setFormValue({
					...formValue,
					[name]: phone,
				});
			}
		} else {
			setFormValue({
				...formValue,
				[name]: value,
			});
		}
	};

	/**
	 * 문의 등록하기
	 */
	const handleClick = async () => {
		// 문의 내용 저장하기 위한 값이 전부 유효한지 확인
		const valid = isValidForm();
		if (valid) {
			setIsLoading(true);
			try {
				let now = new Date();
				const dateStr = moment(now).format("YYYY/MM/DD/HH:mm");
				const title = `[일반] ${formValue.name}님 ${dateStr} 문의사항`;
				let param = {
					...formValue,
					type: 2,
					category: "general",
					title: title,
					question_content:
						`[${formValue.title}]\n` + formValue.question_content,
					company_contact: formValue.company_contact.replaceAll("-", ""),
					answer_status: 0,
					is_read: false,
					created_at: now,
					created_by: user?.id,
					updated_at: now,
					updated_by: user?.id,
				};
				const res = await inquiryApi.createInquiry(param);
				if (res.code === 200) {
					Swal.fire({
						customClass: "alert-custom-confirm",
						text: "문의 등록이 완료되었습니다.",
						confirmButtonText: "확인",
						confirmButtonColor: "#117FFA",
					}).then(() => {
						// Form 초기화
						setFormValue({
							title: "", // 문의제목
							name: "", // 성함
							company_contact: "", // 휴대폰 번호
							question_content: "", // 문의내용
						});
						if (mediaResult) {
							handleMenu("list", true);
						} else {
							// 페이지 맨 위로 이동
							window.scrollTo(0, 0);
							// 문의 목록 페이지로 이동
							history.push("/user?inquiry");
						}
					});
				} else {
					Swal.fire({
						customClass: "alert-custom-confirm",
						text: `일시적인 장애로 새로고침 후 다시 시도해주세요.`,
						confirmButtonText: "확인",
						confirmButtonColor: "#117FFA",
					});
				}
			} catch (e) {
				console.log("handleClick :: error = ", e);
			} finally {
				setIsLoading(false);
			}
		}
	};

	/**
	 * 문의 취소하기
	 */
	const handleCancel = () => {
		if (mediaResult) {
			handleMenu("list");
		} else {
			if (
				formValue.name ||
				formValue.company_contact ||
				formValue.title ||
				formValue.question_content
			) {
				// 입력한 값이 하나라도 있으면 취소하겠냐는 팝업 띄우기
				Swal.fire({
					customClass: "alert-custom-confirm",
					text: `작성중인 내용이 모두 사라집니다.\n정말 취소하시겠습니까?`,
					showCancelButton: true,
					confirmButtonText: "확인",
					confirmButtonColor: "#117FFA",
					cancelButtonText: "취소",
					cancelButtonColor: "#FFFFFF",
				}).then((res) => {
					if (res.isConfirmed) {
						// Form 초기화
						setFormValue({
							title: "", // 문의제목
							name: "", // 성함
							company_contact: "", // 휴대폰 번호
							question_content: "", // 문의내용
						});
						// 페이지 맨 위로 이동
						window.scrollTo(0, 0);
						// 문의 목록 페이지로 이동
						history.push(`/user?inquiry`);
					}
				});
			} else {
				// Form 초기화
				setFormValue({
					title: "", // 문의제목
					name: "", // 성함
					company_contact: "", // 휴대폰 번호
					question_content: "", // 문의내용
				});
				// 페이지 맨 위로 이동
				window.scrollTo(0, 0);
				// 문의 목록 페이지로 이동
				history.push(`/user?inquiry`);
			}
		}
	};

	const isDisabled = () => {
		if (
			formValue.name?.trim() ||
			formValue.company_contact?.trim() ||
			formValue.title?.trim() ||
			formValue.question_content?.trim()
		) {
			return true;
		} else {
			return false;
		}
	};

	/**
	 * 문의 항목 내용 유효성 검사
	 * @returns {boolean}
	 */
	const isValidForm = () => {
		// step 1. 모든 항목에 입력값이 있는지 확인하기
		if (
			formValue.title?.trim() &&
			formValue.name?.trim() &&
			formValue.company_contact?.trim() &&
			formValue.question_content?.trim()
		) {
			// step 2. 전화번호 형식에 맞는지 확인하기
			if (regPhone.test(formValue.company_contact)) {
				return true;
			} else {
				Swal.fire({
					customClass: "alert-custom-confirm",
					text: `휴대폰 형식에 맞춰서 입력해주세요.`,
					showCancelButton: false,
					confirmButtonText: "확인",
					confirmButtonColor: "#117FFA",
				});
				return false;
			}
		} else {
			Swal.fire({
				customClass: "alert-custom-confirm",
				text: `문의를 남기기 위한 필수 입력 사항들을 채워주세요.`,
				showCancelButton: false,
				confirmButtonText: "확인",
				confirmButtonColor: "#117FFA",
			});
			return false;
		}
	};

	return (
		<div className="max-width-apply">
			{!mediaResult && (
				<p
					style={{ fontWeight: 600, fontSize: "1.4em", paddingBottom: "0.8em" }}
				>
					문의하기
				</p>
			)}
			<div className="calculate-inquiry-table add-inquiry">
				<div className="calculate-inquiry-add-wrapper">
					<div className="calculate-inquiry-add-wrap add-inquiry">
						<div
							className="calculate-inquiry-add-wrap-title"
							style={{ paddingTop: mediaResult && "1.4em" }}
						>
							문의 제목
							<span>*</span>
						</div>
						<div className="wrap-input inquiry">
							<Input
								type="text"
								placeholder={"문의제목을 입력해주세요."}
								name={"title"}
								value={formValue.title}
								onChange={handleChange}
							/>
						</div>
						{/*/!*<div style={{ position: "relative" }}>*!/*/}
						{/*// 	<Dropdown*/}
						{/*// 		menu={{*/}
						{/*// 			items: [{ label: "골드 문의", key: "gold" }],*/}
						{/*// 			onClick: ({ item, key, keyPath }) => {*/}
						{/*/!*				if (key) {*!/*/}
						{/*/!*					if (key === "gold") {*!/*/}
						{/*/!*						setFormValue({*!/*/}
						{/*/!*							...formValue,*!/*/}
						{/*/!*							category: key,*!/*/}
						{/*/!*							category_kr: "골드 문의",*!/*/}
						{/*/!*						});*!/*/}
						{/*/!*					}*!/*/}
						{/*/!*				}*!/*/}
						{/*/!*			},*!/*/}
						{/*/!*		}}*!/*/}
						{/*/!*		trigger={["click"]}*!/*/}
						{/*/!*		overlayClassName={"calculate-inquiry-select-wrap"}*!/*/}
						{/*/!*		overlayStyle={{*!/*/}
						{/*/!*			maxHeight: 200,*!/*/}
						{/*/!*			overflow: "auto",*!/*/}
						{/*/!*		}}*!/*/}
						{/*/!*	>*!/*/}
						{/*// 		<Input*/}
						{/*// 			placeholder={"문의내용을 선택해주세요."}*/}
						{/*// 			value={formValue.category_kr}*/}
						{/*/!*			style={{*!/*/}
						{/*/!*				cursor: "pointer",*!/*/}
						{/*/!*			}}*!/*/}
						{/*/!*		/>*!/*/}
						{/*/!*	</Dropdown>*!/*/}
						{/*/!*	<img*!/*/}
						{/*/!*		src={`/assets/images/down-arrow.png`}*!/*/}
						{/*// 		alt={`화살표`}*/}
						{/*// 		style={{*/}
						{/*// 			height: "0.5em",*/}
						{/*/!*			width: "1em",*!/*/}
						{/*/!*			position: "absolute",*!/*/}
						{/*/!*			top: "46%",*!/*/}
						{/*/!*			right: "4%",*!/*/}
						{/*/!*		}}*!/*/}
						{/*/!*	/>*!/*/}
						{/*/!*</div>*!/*/}
					</div>
					<div className="calculate-inquiry-add-wrap">
						<div className="calculate-inquiry-add-wrap-title">
							이름
							<span>*</span>
						</div>
						<div className="wrap-input inquiry">
							<Input
								type="text"
								placeholder="성함을 입력해주세요"
								name={"name"}
								value={formValue.name}
								onChange={handleChange}
							/>
						</div>
					</div>
					<div className="calculate-inquiry-add-wrap">
						<div className="calculate-inquiry-add-wrap-title">
							휴대폰 번호
							<span>*</span>
						</div>
						<div className="wrap-input inquiry">
							<Input
								type="text"
								placeholder="-를 생략하고 입력해주세요."
								name={"company_contact"}
								value={formValue.company_contact}
								onChange={handleChange}
							/>
						</div>
					</div>
					<div
						className="calculate-inquiry-add-wrap"
						style={{ border: "none" }}
					>
						<div className="calculate-inquiry-add-wrap-title">
							내용
							<span>*</span>
						</div>
					</div>
					<div className="calculate-inquiry-add-wrap-textarea">
						<textarea
							name="question_content"
							placeholder="내용을 입력해주세요."
							value={formValue.question_content}
							onChange={handleChange}
							style={{ height: "300px", marginTop: "-0.5em" }}
						></textarea>
					</div>
				</div>
			</div>
			<div className="calculate-inquiry-button-wrap add-inquiry">
				<Button
					className="calculate-inquiry-write-button"
					type={"primary"}
					disabled={!isDisabled() || isLoading}
					onClick={() => {
						if (!isLoading) {
							handleClick();
						}
					}}
				>
					저장
				</Button>
				<Button
					className="calculate-inquiry-cancel-button"
					onClick={handleCancel}
				>
					취소
				</Button>
			</div>
		</div>
	);
};

export default InquiryAdd;
