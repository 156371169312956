import Swal from "sweetalert2";

import channelUserApi from "../../apis/ChannelUser.api";
import partnersApi from "../../apis/Partners.api";
import { setUser } from "../../redux/store/auth/authDuck";

/**
 * 당영몰 유저와 파트너스 유저 대조 결과
 * 1) 당영몰/파트너스 회원 일치
 * 2) 파트너스 회원이 아닐 때 (code 400)
 * 2-1) 파트너스 회원 가입 적용
 * 3) 파트너스 회원일 때
 * 3-1) 인증/계좌 둘 다 없음 (code 401)
 * 3-2) 인증 없음 (code 402)
 * 3-3) 계좌 없음 (code 403)
 * @param user 당영몰 회원가입
 * @param dispatch redux 저장
 * @param history page 이동
 * @returns {Promise<void>}
 * 1) 405: type이 지정된 값이 아님
 * 2) 400: 해당하는 사용자 정보를 찾을 수 없음
 * 3) 401: 인증 정보와 계좌 정보 둘 다 없음
 * 4) 402: 인증 정보가 없음
 * 5) 403: 계좌 정보가 없음
 */

/**
 * 사용자 이력 남기기
 */
const logUserHistory = async (id) => {
	const param = {
		target_type: "user",
		target_id: id,
		action: "login",
		created_by: id,
	};
	await channelUserApi.userHistory(param);
};
export const handleIsMallUserWithPartners = async (user, dispatch, history) => {
	try {
		// 당영몰 회원가입 성공
		const param = {
			type: "mallId",
			userId: user?.mall_id
				? user?.mall_id === user?.id
					? user.id
					: user.mall_id
				: user.id,
		};
		// 회원가입한 user 재정의
		// let data = {
		// 	// ...user,
		// 	// is_influencer: true,
		// 	// mall_id: user.id,
		// };
		// 당영몰/파트너스 회원 대조
		const res = await partnersApi.readMallPartnersId(param);
		// 1) 당영몰/파트너스 회원 일치
		// * 402는 인증이 없다는 에러이지만, 통합인증을 뒤로 수정하면서 회원 가입으로 표기
		if (res.code === 200 || res.code === 402) {
			dispatch(setUser(res.result));
			// Swal.fire({
			// 	customClass: "alert-custom",
			// 	text: `환영합니다.`,
			// 	confirmButtonText: "확인",
			// 	// confirmButtonColor: "#212529",
			// }).then(() => {
			logUserHistory(res.result.id);
			history.push("/dashboard");
			// });
		}

		// 2) 파트너스 회원이 아닐 때 (code 400)
		// 2-1) 파트너스 회원 가입 적용
		else if (res.code === 400) {
			await createPartnersUsers(user, dispatch, history);
		}

		// 3) 파트너스 회원일 때
		// 3-1) 인증/계좌 둘 다 없음 (code 401)
		// 3-2) 인증 없음 (code 402)
		// 3-3) 계좌 없음 (code 403)
		else if (
			res.code === 401 ||
			// || res.code === 402
			res.code === 403
		) {
			dispatch(
				setUser({
					...res.result,
					is_influencer: true,
				})
			);

			let errText =
				res.code === 401
					? // ? "본인인증과 계좌정보가 필요합니다."
					  "추가 정보 입력이 필요합니다."
					: res.code === 402
					? "인증 정보가 없습니다."
					: res.code === 403
					? "계좌 정보가 없습니다."
					: "";

			// await createPartnersUsers(user, dispatch, history);
			logUserHistory(res.result.id);
			history.replace("/dashboard");
		}

		// 4) error
		else {
			Swal.fire({
				customClass: "alert-custom",
				text: "오류가 발생했습니다.\n다시 시도해주시거나 관리자에게 문의해주시길 바랍니다.",
				confirmButtonText: "확인",
				// confirmButtonColor: "#212529",
			}).then(() => {});
		}
	} catch (e) {
		console.log("handleIsMallUserWithPartners", e);
	}
};

/**
 * 파트너스 계정 생성
 * @param data 몰 user data
 * @returns {Promise<void>}
 */
const createPartnersUsers = async (data, dispatch, history) => {
	let param = {
		mall_id: data.id,
		is_app: false,
	};
	const referralCode = sessionStorage.getItem("referral_code");
	const webUrl = sessionStorage.getItem("web_url_list");
	const snsUrl = sessionStorage.getItem("sns_url_list");
	if (referralCode) {
		param["referral_code"] = referralCode;
	}
	if (webUrl) {
		param["webSiteUrlList"] = JSON.parse(webUrl);
	}
	if (snsUrl) {
		param["snsUrlList"] = JSON.parse(snsUrl);
	}
	const res = await partnersApi.postCreatePartnersUsers(param);
	if (res.code === 200) {
		sessionStorage.removeItem("referral_code");
		sessionStorage.removeItem("web_url_list");
		sessionStorage.removeItem("sns_url_list");
		dispatch(
			setUser({
				...res.result,
				is_influencer: true,
			})
		);
		// Swal.fire({
		// 	customClass: "alert-custom",
		// 	text: `추가 정보 입력이 필요합니다.`,
		// 	confirmButtonText: "확인",
		// 	// confirmButtonColor: "#212529",
		// }).then(() => {
		// history.push("/user/together/signup");
		logUserHistory(res.result.id);
		history.push("/dashboard");
		// });
	} else {
		Swal.fire({
			customClass: "alert-custom",
			text: "오류가 발생했습니다.\n다시 시도해주시거나 관리자에게 문의해주시길 바랍니다.",
			confirmButtonText: "확인",
			// confirmButtonColor: "#212529",
		}).then(() => {});
	}
};

const adjRandomList = [
	"귀여운",
	"아름다운",
	"야무진",
	"세심한",
	"꼼꼼한",
	"희망찬",
	"반가운",
	"착한",
	"너그러운",
	"예쁜",
	"친절한",
	"다정한",
	"순수한",
	"솔직한",
	"정직한",
	"공손한",
	"용감한",
	"성실한",
	"똑똑한",
	"기분좋은",
	"매력적인",
	"위대한",
	"훌륭한",
	"완벽한",
	"멋진",
	"행복한",
	"침착한",
	"부지런한",
	"지적인",
	"활발한",
	"긍정적인",
	"재미있는",
	"창의적인",
	"활동적인",
	"단정한",
	"열정적인",
	"영리한",
	"섬세한",
	"발랄한",
	"안정적인",
	"상냥한",
	"새침한",
	"만족한",
	"성공한",
	"즐거운",
	"신난",
	"튼튼한",
	"편안한",
	"건강한",
	"씩씩한",
	"기쁜",
	"활기찬",
	"겸손한",
	"유쾌한",
	"명랑한",
];

const nameRandomList = [
	"판다",
	"다람쥐",
	"여우",
	"범고래",
	"돌고래",
	"독수리",
	"라쿤",
	"오소리",
	"기린",
	"고슴도치",
	"강아지",
	"고양이",
	"돼지",
	"두더지",
	"나무늘보",
	"양",
	"낙타",
	"노루",
	"개구리",
	"거위",
	"앵무새",
	"곰",
	"바다사자",
	"펭귄",
	"오리",
	"베짱이",
	"도롱뇽",
	"하마",
	"토끼",
	"사슴",
	"코끼리",
	"사자",
	"호랑이",
	"소",
	"원숭이",
	"늑대",
	"코알라",
	"미어캣",
	"말",
	"캥거루",
	"물개",
	"염소",
	"자라",
	"거북이",
	"악어",
	"카멜레온",
	"나비",
	"백조",
	"비둘기",
	"참새",
	"까치",
	"부엉이",
	"올빼미",
	"코뿔소",
	"병아리",
];

export { adjRandomList, nameRandomList };
