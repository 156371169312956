import React, { useEffect, useState } from "react";

import { Button, Input, Modal } from "antd";

import Swal from "sweetalert2";

const ProductLinkModal = ({
	user,
	linkShow,
	setLinkShow,
	selectedLinkItem,
	queryUrl = "",
	item,
}) => {
	const [gradeMention, setGradeMention] = useState({
		nowGrade: "HELLO",
		nextGrade: "FAMILY",
		maxReward: 20,
	});
	const featureList = {
		title: selectedLinkItem?.usp,
		contentList: [
			...Array.from({ length: 6 }).map((arr, idx) => {
				return selectedLinkItem[`information_${idx + 1}`];
			}),
		].filter((arr) => arr !== null),
	};

	const handleCopyFeatures = () => {
		let text = "";
		featureList.contentList?.map((feature, idx) => {
			if (feature) {
				console.log(feature);
				text += `0${idx + 1}. ${feature} \n`;
			}
		});
		const textValue = `${featureList.title}\n\n${text}`;
		window.navigator.clipboard.writeText(textValue).then(() => {
			alert("복사가 완료 되었습니다.");
			// setLinkShow(false);
		});
	};

	useEffect(() => {
		handleGradeMention();
	}, [user]);

	const handleGradeMention = () => {
		let grade = user?.grade;
		if (grade) {
			switch (grade) {
				case "grade_1":
					setGradeMention({
						nowGrade: "PREMIUM",
						nextGrade: null,
						maxReward: 30,
					});
					return;
				case "grade_2":
					setGradeMention({
						nowGrade: "FAMILY",
						nextGrade: "PREMIUM",
						maxReward: 30,
					});
					return;
				case "grade_3":
					setGradeMention({
						nowGrade: "HELLO",
						nextGrade: "FAMILY",
						maxReward: 20,
					});
					return;
				default:
					setGradeMention({
						nowGrade: "HELLO",
						nextGrade: "FAMILY",
						maxReward: 20,
					});
					return;
			}
		}
	};

	const handleCopyLink = (e) => {
		window.navigator.clipboard.writeText(e.currentTarget.value).then(() => {
			Swal.fire({
				customClass: "alert-custom-confirm",
				text: `링크 복사가 완료되었습니다.`,
				confirmButtonText: "확인",
				confirmButtonColor: "#117FFA",
			}).then((res) => {
				if (res.isConfirmed) {
					setLinkShow(false);
				}
			});
		});
	};

	return (
		<>
			{/* 새로운 모달 */}
			<Modal
				title=""
				wrapClassName="product-link-modal-wrap new-link-modal"
				open={linkShow}
				// onOk={handleOk}
				// confirmLoading={confirmLoading}
				onCancel={() => setLinkShow(false)}
				footer={<></>}
				width={1030}
			>
				<p className="link-modal-title">링크생성</p>
				<div className={`product-link-create-body`}>
					{featureList?.contentList?.length > 0 && (
						<div className={`product-link-create-description-wrap`}>
							<div className="product-link-flex-div">
								<span>URL</span>
								<Input
									type={`text`}
									value={`https://www.dangyoung.com/products/${selectedLinkItem.target_id}?query=${queryUrl}`}
								/>
							</div>
							<div className="product-link-flex-div">
								<span>제품 설명</span>
								<div className={`feature-list link-modal-content`}>
									{selectedLinkItem.target_id ? (
										<img
											className="thumbnail"
											src={`${process.env.REACT_APP_S3_HOST}/image/recommend/${selectedLinkItem.target_id}.jpg`}
											alt={`${featureList.title} 이미지`}
										/>
									) : (
										<img
											className="thumbnail"
											src={`/assets/images/no-product.png`}
											alt={`이미지 없음`}
										/>
									)}
									<div>
										<p className={`product-description`}>{featureList.title}</p>
										<p className={`product-name`}>
											{selectedLinkItem.brand_name} {selectedLinkItem.name}
										</p>
										{featureList.contentList?.map((item, idx) => (
											<p className={`content`} key={`featureList-${idx}`}>
												<span>0{idx + 1}</span>
												{item}
											</p>
										))}
									</div>
								</div>
							</div>
						</div>
					)}
					<div className="product-link-btn-wrap-home">
						<button onClick={() => setLinkShow(false)} className={`cancel-btn`}>
							취소
						</button>
						<button
							value={`https://www.dangyoung.com/products/${selectedLinkItem.target_id}?query=${queryUrl}`}
							onClick={handleCopyLink}
							className={`link-copy-button`}
						>
							링크복사
						</button>
					</div>
				</div>
			</Modal>
			{/* 오래된 모달 */}
			{/* <Modal
				title=""
				wrapClassName="product-link-modal-wrap"
				open={linkShow}
				// onOk={handleOk}
				// confirmLoading={confirmLoading}
				onCancel={() => setLinkShow(false)}
				footer={<></>}
			>
				<div className={`product-link-create-body`}>
					<div className={`product-link-create-copy-wrap`}>
						{gradeMention.nextGrade ? (
							<div className={`product-link-mention-div`}>
								<img
									src={`/assets/images/product_link/${gradeMention.nowGrade}.png`}
									alt={gradeMention.nowGrade}
								/>
								<div className={`product-link-mention-text-div`}>
									<p className={`first-row`}>
										<b>{gradeMention.nowGrade}</b> 등급에서 한발 더 나아가
									</p>
									<p className={`second-row`}>
										<span>{gradeMention.nextGrade}</span> 등급이 되어{" "}
										<span>리워드 최대 {gradeMention.maxReward}%</span>를
										챙겨가세요!
									</p>
								</div>
							</div>
						) : (
							<div className={`product-link-mention-div premium`}>
								<div className={`product-link-img-premium-div`}>
									<img
										src={`/assets/images/product_link/${gradeMention.nowGrade}.png`}
										alt={gradeMention.nowGrade}
									/>
								</div>
								<div className={`product-link-mention-text-div`}>
									<p className={`first-row`}>
										<span style={{ fontSize: "1.1em" }}>최대 리워드</span>를
										받고 계시네요!
									</p>
									<p className={`second-row`}>
										앞으로도 <span>PREMIUM 등급을 지켜주세요^^</span>
									</p>
								</div>
							</div>
						)}
						<div className={`product-link-copy-input-div`}>
							<Input
								type={`text`}
								value={`https://www.dangyoung.com/products/${selectedLinkItem.target_id}?query=${queryUrl}`}
							/>
							<Button
								value={`https://www.dangyoung.com/products/${selectedLinkItem.target_id}?query=${queryUrl}`}
								onClick={handleCopyLink}
								className={`link-copy-button`}
							>
								링크복사
							</Button>
						</div>
					</div>
					{featureList?.contentList?.length > 0 && (
						<div className={`product-link-create-description-wrap`}>
							<div className={`link-create-features`}>
								<p className={`title`}>{featureList.title}</p>
								<div className={`feature-list`}>
									{featureList.contentList?.map((item, idx) => (
										<p className={`content`} key={`featureList-${idx}`}>
											<span>0{idx + 1}</span>
											{item}
										</p>
									))}
								</div>
								<div className={`link-feature-copy`}>
									<p>장점 텍스트를 복사해서 사용해보세요!</p>
									<Button onClick={handleCopyFeatures}>전체복사</Button>
								</div>
							</div>
						</div>
					)}
				</div>
			</Modal> */}
		</>
	);
};

export default ProductLinkModal;
