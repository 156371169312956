import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { getCookie, setCookie, setCookieOneDay } from "../../utils/cookie";

// 첫 회원가입
export function WelcomeEventModal({ open }) {
	const history = useHistory();
	const [openModal, setOpenModal] = useState(open);
	const handleClick = () => {
		// 랜덤 열기 조건(이 모달을 읽어야만 실행)
		localStorage.removeItem("welcome-modal");
		localStorage.setItem("modal-step", 2);
		setOpenModal(false);
		history.push("/event/benefit");
	};

	const handleClosePopup = () => {
		setOpenModal(false);
	};
	return (
		openModal && (
			<article className="landing-event-modal-wrap">
				<div className="content-wrap">
					<button type="button" className="close-modal-btn">
						<img
							src="/assets/images/ico-blue-close.svg"
							alt="모달 닫기"
							onClick={handleClosePopup}
						/>
					</button>
					<img
						className="icon"
						src="/assets/images/landing/icon-smile.png"
						alt="가입환영 아이콘"
					/>
					<div className="text-wrap">
						<p className="title underline">가입을 환영합니다</p>
						<p className="desc">
							첫 만남의 반가움을 담아{"\n"}
							<span>70,000원을 적립</span>해드렸어요!
						</p>
						<p className="sub-desc">
							당영투게더 활동을 통해 많은 수익을 내길 바래요.{"\n"}
							원활한 수익 창출을 위해{"\n"}
							당영투게더에 대해 간단한 소개 먼저 해드릴게요{"\n"}
						</p>
					</div>
					<button
						type="button"
						className="go-event-link-btn"
						onClick={handleClick}
					>
						확인하러 가기
					</button>
				</div>
			</article>
		)
	);
}

// Math.floor(Math.random() * 4);
//  이용가이드 유도 팝업 1
export function InduceUsingGuide1({ modalIndex, isExpire }) {
	const history = useHistory();
	const [openModal, setOpenModal] = useState(
		modalIndex === 1 && localStorage.getItem("modal-step") === "2" && !isExpire
	);
	const handleClick = () => {
		// 이동경로 (이용가이드)
		setOpenModal(false);
		history.push("/benefit/information");
		//setCookie
		setCookieOneDay("read-random-modal", "yes", 1);
	};
	const handleClosePopup = () => {
		setOpenModal(false);
	};
	return (
		openModal && (
			<article className="landing-event-modal-wrap">
				<div className="content-wrap">
					<button
						type="button"
						className="close-modal-btn"
						style={{ gap: "2.5em" }}
					>
						<img
							src="/assets/images/ico-blue-close.svg"
							alt="모달 닫기"
							onClick={handleClosePopup}
						/>
					</button>

					<div className="text-wrap">
						<p className="title underline">7,000원 받고</p>
						<p className="title" style={{}}>
							시작하세요
						</p>
						<p className="desc">
							회원가입만 해도 <span>7,000원 적립!</span>
							{"\n"}
							든든하게 준비된 선물 받고 시작하세요!
						</p>
					</div>
					<img
						style={{ maxWidth: "200px", marginTop: "1.1em" }}
						className="icon"
						src="/assets/images/landing/icon-phone.png"
						alt="가입환영 아이콘"
					/>
					<button
						type="button"
						className="go-event-link-btn"
						onClick={handleClick}
					>
						확인하러 가기
					</button>
				</div>
			</article>
		)
	);
}
//  이용가이드 유도 팝업 2
export function InduceUsingGuide2({ modalIndex, isExpire }) {
	const history = useHistory();
	const [openModal, setOpenModal] = useState(
		modalIndex === 2 && localStorage.getItem("modal-step") === "2" && !isExpire
	);
	const handleClick = () => {
		// 이동경로 (이용가이드)
		setOpenModal(false);
		history.push("/benefit/information");
		//setCookie
		setCookieOneDay("read-random-modal", "yes", 1);
	};
	const handleClosePopup = () => {
		setOpenModal(false);
	};
	return (
		openModal && (
			<article className="landing-event-modal-wrap">
				<div className="content-wrap">
					<button
						type="button"
						className="close-modal-btn"
						style={{ gap: "2.5em" }}
					>
						<img
							src="/assets/images/ico-blue-close.svg"
							alt="모달 닫기"
							onClick={handleClosePopup}
						/>
					</button>

					<div className="text-wrap">
						<p className="title">링크 클릭만으로</p>
						<p className="title underline">현금이 쌓여요!</p>
						<p className="desc">
							링크를 클릭할 때마다{"\n"}
							최대 <span>100원씩 현금을 적립</span>해드립니다
						</p>
					</div>
					<img
						style={{ maxWidth: "200px", margin: "1.1em auto 1em" }}
						className="icon"
						src="/assets/images/landing/icon-wallet.png"
						alt="가입환영 아이콘"
					/>
					<button
						type="button"
						className="go-event-link-btn"
						onClick={handleClick}
					>
						확인하러 가기
					</button>
				</div>
			</article>
		)
	);
}
//  이용가이드 유도 팝업 3
export function InduceUsingGuide3({ modalIndex, isExpire }) {
	const history = useHistory();
	const [openModal, setOpenModal] = useState(
		modalIndex === 3 && localStorage.getItem("modal-step") === "2" && !isExpire
	);
	const handleClick = () => {
		// 이동경로 (이용가이드)
		setOpenModal(false);
		history.push("/benefit/information");
		//setCookie
		setCookieOneDay("read-random-modal", "yes", 1);
	};
	const handleClosePopup = () => {
		setOpenModal(false);
	};
	return (
		openModal && (
			<article className="landing-event-modal-wrap">
				<div className="content-wrap">
					<button
						type="button"
						className="close-modal-btn"
						style={{ gap: "4em" }}
					>
						<img
							src="/assets/images/ico-blue-close.svg"
							alt="모달 닫기"
							onClick={handleClosePopup}
						/>
					</button>

					<div className="text-wrap">
						<p className="title underline">최대 30%의 수수료로</p>
						<p className="title">간편한 수익 창출하세요</p>
						<p className="desc">
							타사보다 10배 더 높은 <span>최대 30%의 수수료</span>로,{"\n"}
							수익을 손쉽게 올릴 수 있어요.
						</p>
					</div>
					<img
						style={{ maxWidth: "200px", margin: "1.3em auto" }}
						className="icon"
						src="/assets/images/landing/icon-cash.png"
						alt="가입환영 아이콘"
					/>
					<button
						type="button"
						className="go-event-link-btn"
						onClick={handleClick}
					>
						확인하러 가기
					</button>
				</div>
			</article>
		)
	);
}

//  이용가이드 유도 팝업 4
export function InduceUsingGuide4({ isRandomRead, isExpire }) {
	const history = useHistory();
	const [openModal, setOpenModal] = useState(
		localStorage.getItem("modal-step") === "2" &&
			!isExpire &&
			isRandomRead === "yes"
	);
	console.log(openModal);
	const handleClick = () => {
		// 이동경로 (이용가이드)
		setOpenModal(false);
		history.push("/event/recommend");
		//setCookie
		setCookieOneDay("read-friend-modal", "yes", 1);
	};
	const handleClosePopup = () => {
		setOpenModal(false);
		setCookieOneDay("read-friend-modal", "yes", 1);
	};
	return (
		openModal && (
			<article className="landing-event-modal-wrap">
				<div className="content-wrap" style={{ gap: "0em", paddingTop: "6em" }}>
					<button type="button" className="close-modal-btn">
						<img
							src="/assets/images/ico-blue-close.svg"
							alt="모달 닫기"
							onClick={handleClosePopup}
						/>
					</button>

					<div className="text-wrap">
						<p className="title underline">친구추천 이벤트</p>
						<p className="desc">
							추천한 사람, 추천 받은 사람{"\n"}
							모두에게 <span>10,000골드 쏩니다!</span>
						</p>
						<p className="sub-desc" style={{ paddingTop: "12px" }}>
							추천인 코드로 친구가 가입하면{"\n"}
							나는 10,000골드! 친구는 80,000골드!
						</p>
					</div>
					<img
						style={{ maxWidth: "330px", margin: "0 auto" }}
						className="icon"
						src="/assets/images/landing/icon-together.png"
						alt="가입환영 아이콘"
					/>
					<button
						type="button"
						className="go-event-link-btn"
						onClick={handleClick}
					>
						확인하러 가기
					</button>
					<button
						type="button"
						className="cancel-event-link-btn "
						onClick={handleClosePopup}
					>
						다음에
					</button>
				</div>
			</article>
		)
	);
}
