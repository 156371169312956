import { useSelector } from "react-redux";

import { Row } from "antd";

import ReportStatisticsItemComponent from "./ReportStatisticsItemComponent";

const ReportStatisticsComponent = ({
	statistics,
	orderList,
	type,
	targetMonth,
	groupType,
	mediaResult,
}) => {
	const user = useSelector((state) => state.auth.user);

	return (
		<div
			className="report-statistics-wrap"
			style={{
				display: "flex",
				flexDirection: "column",
				gap: "1.5em",
				marginBottom:
					groupType && groupType === "click"
						? mediaResult
							? "4em"
							: "2em"
						: 0,
			}}
		>
			{type && type === "case" ? (
				<Row
					// justify="space-around"
					justify="space-evenly"
					align="middle"
					wrap={false}
				>
					<ReportStatisticsItemComponent
						title="구매 확정"
						value={
							statistics.purchases_complete_num
								? statistics.purchases_complete_num
								: 0
						}
						statistics={statistics}
						targetMonth={targetMonth}
					/>
					<ReportStatisticsItemComponent
						title="구매 확정 금액"
						value={statistics.combined_amount ? statistics.combined_amount : 0}
						unit={"원"}
						statistics={statistics}
						targetMonth={targetMonth}
					/>
					<ReportStatisticsItemComponent
						title="확정 수익"
						value={statistics.profit_amount ? statistics.profit_amount : 0}
						unit={"원"}
						statistics={statistics}
						targetMonth={targetMonth}
					/>
					{(user?.role === "super_admin" || user?.role === "level_1") && (
						<ReportStatisticsItemComponent
							title="카톡푸시 이용 금액"
							value={
								statistics.kakao_push_amount ? statistics.kakao_push_amount : 0
							}
							unit={"원"}
							statistics={statistics}
							targetMonth={targetMonth}
						/>
					)}
					<ReportStatisticsItemComponent
						title="정산 금액"
						value={
							(orderList?.length
								? orderList[0]?.vat
									? Math.round(statistics.profit_amount * 1.0)
									: Math.round(statistics.profit_amount * 0.967)
								: 0) - (statistics.kakao_push_amount ?? 0)
						}
						unit={"원"}
						statistics={statistics}
						targetMonth={targetMonth}
					/>
				</Row>
			) : (
				<>
					{groupType && groupType === "sale" && (
						<Row
							// justify="space-around"
							justify="space-evenly"
							align={mediaResult ? "stretch" : "middle"}
							wrap={false}
							style={{
								flexDirection: mediaResult ? "column" : "row",
								gap: mediaResult ? 14 : 0,
							}}
						>
							{/*<ReportStatisticsItemComponent*/}
							{/*    title="클릭" value={statistics.click} />*/}
							<ReportStatisticsItemComponent
								title="구매 건수"
								value={statistics.purchases_num ? statistics.purchases_num : 0}
								mediaResult={mediaResult}
								unit={"건"}
							/>
							{/*<ReportStatisticsItemComponent*/}
							{/*    title="전환율" value={statistics.cvr} unit={"%"} />*/}
							<ReportStatisticsItemComponent
								// title="구매 합산 금액" value={statistics.combined_amount} unit={"원"} />
								title={mediaResult ? "구매 합산 금액" : "구매 합산 금액(원)"}
								value={statistics.total_price ? statistics.total_price : 0}
								unit={"원"}
								mediaResult={mediaResult}
							/>
							<ReportStatisticsItemComponent
								title={mediaResult ? "획득 골드" : "획득 골드(G)"}
								value={
									statistics.expected_profit_amount
										? statistics.expected_profit_amount
										: 0
								}
								unit={"G"}
								mediaResult={mediaResult}
							/>
						</Row>
					)}
					{groupType && groupType === "click" && (
						<Row
							justify="space-evenly"
							align={mediaResult ? "stretch" : "middle"}
							wrap={false}
							style={{
								flexDirection: mediaResult ? "column" : "row",
								gap: mediaResult ? 14 : 0,
							}}
						>
							<ReportStatisticsItemComponent
								title="클릭 건수"
								value={statistics.click_count ? statistics.click_count : 0}
								mediaResult={mediaResult}
								unit={"건"}
							/>
							<ReportStatisticsItemComponent
								title={mediaResult ? "획득 골드" : "획득 골드(G)"}
								value={statistics.click_profit ? statistics.click_profit : 0}
								unit={"G"}
								mediaResult={mediaResult}
							/>
						</Row>
					)}
					{groupType && groupType === "suggest" && (
						<Row
							justify="space-evenly"
							align={mediaResult ? "stretch" : "middle"}
							wrap={false}
							style={{
								flexDirection: mediaResult ? "column" : "row",
								gap: mediaResult ? 14 : 0,
							}}
						>
							<ReportStatisticsItemComponent
								title="친구추천 수"
								value={
									statistics.recommend_count ? statistics.recommend_count : 0
								}
								unit={"건"}
								mediaResult={mediaResult}
							/>
							<ReportStatisticsItemComponent
								title={mediaResult ? "획득 골드" : "획득 골드(G)"}
								value={
									statistics.recommend_profit ? statistics.recommend_profit : 0
								}
								unit={"G"}
								mediaResult={mediaResult}
							/>
						</Row>
					)}
				</>
			)}
		</div>
	);
};

export default ReportStatisticsComponent;
