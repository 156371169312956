import { all } from "redux-saga/effects";
import produce, { enableES5 } from "immer";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

//타입
const channelTypes = {
	SET_REDUX_CHANNEL: "SET_REDUX_CHANNEL",
	FIRST_INFLOW: "FIRST_INFLOW",
};

//리듀서
export const initialState = {
	reduxChannel: {},
	firstInflow: false,
};

const produceFunc = (...args) => {
	enableES5();
	return produce(...args);
};

export const channelReducer = persistReducer(
	{ storage, key: "channel", whitelist: [] },
	(state = initialState, action) => {
		return produceFunc(state, (draft) => {
			switch (action.type) {
				case channelTypes.SET_REDUX_CHANNEL:
					draft.reduxChannel = action.reduxChannel;
					break;
				case channelTypes.FIRST_INFLOW:
					draft.firstInflow = action.firstInflow;
					break;

				default:
					break;
			}
		});
	}
);

//액션
export const setReduxChannel = (reduxChannel) => ({
	type: channelTypes.SET_REDUX_CHANNEL,
	reduxChannel,
});
export const setFirstInflow = (firstInflow) => ({
	type: channelTypes.FIRST_INFLOW,
	firstInflow,
});

//미들웨어
export default function* commonSaga() {
	yield all([
		// takeLatest(shopTypes.SHOP_ADD_CART.REQUEST, addCart$),
	]);
}
