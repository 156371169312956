import "../../components/user/User.scss";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import CustomModal from "../../components/common/CustomModal";
import SignUpHeader from "../../components/user/SignUpHeader";
import ApiService from "../../utils/ApiService";

const api = new ApiService();

const Recommend = () => {
	const user = useSelector((state) => state.auth.user);

	const [recommendCount, setRecommendCount] = useState(0);
	const [showCopyComplete, setShowCopyComplete] = useState(false);
	const [mediaResult, setMediaResult] = useState(null);

	useEffect(() => {
		window.scrollTo({
			top: 0,
		});
		if (typeof window !== "undefined") {
			const matchQueryList = window.matchMedia("all and (max-width: 768px)");
			setMediaResult(matchQueryList.matches);

			const media = function handleChange(e) {
				setMediaResult(e.matches);
			};

			matchQueryList.addEventListener("change", media);
		}
	}, []);

	useEffect(() => {
		console.log("user = ", user);
		if (user && user?.id) {
			readRecommendCount();
		}
	}, [user]);

	/**
	 * 사용자별 누적 친구초대 수 조회
	 * @returns {Promise<void>}
	 */
	const readRecommendCount = async () => {
		try {
			const param = {
				type: "cum",
				userId: user?.id,
			};
			const res = await api.getRecommendSummaryStatistics(param);
			if (res.data.code === 200) {
				setRecommendCount(res.data.result?.count ?? 0);
			}
		} catch (e) {
			console.log("readRecommendCount :: error = ", e);
		}
	};

	/**
	 * 추천인 코드 복사하기 클릭 이벤트 handler
	 */
	const handleCopyClick = () => {
		if (user?.referral_code) {
			window.navigator.clipboard
				.writeText(
					`${process.env.REACT_APP_HOST}?referral_code=${user.referral_code}`
				)
				.then(() => {
					setShowCopyComplete(true);
				});
		}
	};

	return (
		<>
			<SignUpHeader title={"친구 추천"} back={true} close={false} />
			<div
				className="main-wrap padding-0"
				style={{ padding: !mediaResult && "0 3em" }}
			>
				{!mediaResult && <div className="mypage-head-title">친구추천</div>}
				{/*배너*/}
				<div className="recommend-banner-wrap">
					<div className="banner-text">
						<p className="small">
							친구 초대하면 <span className="benefit">10,000 골드씩</span>
						</p>
						<p className="large">친구와 나 모두에게 드려요!</p>
					</div>
					<img
						src={
							mediaResult
								? `/assets/images/user/recommend_banner.png`
								: `/assets/images/user/recommend_banner-pc.png`
						}
						alt={`recommend_banner`}
						style={{
							width: "100%",
							height: "auto",
						}}
					/>
				</div>
				<div className={`recommend-content-wrap`}>
					{/*내 추천코드*/}
					<div className={`recommend-code-wrap`}>
						<p className={`title`}>내 추천코드</p>
						<p className={`referral-code`}>{user?.referral_code}</p>
						<div
							className={`recommend-code-copy-div`}
							onClick={handleCopyClick}
						>
							<img
								src={`/assets/images/user/copy-icon.png`}
								alt={`copy-icon`}
								style={{
									width: 15,
									height: 15,
								}}
							/>
							<p>추천인 코드 복사하기</p>
						</div>
					</div>
					{/*친구초대 현황*/}
					<div className={`recommend-list-wrap`}>
						<p className={`title`}>친구초대 현황</p>
						<div className={`recommend-list-content-wrap`}>
							<p>초대한 누적 친구 수</p>
							<p>
								{!recommendCount?.toLocaleString()
									? 0
									: recommendCount?.toLocaleString()}
								명
							</p>
						</div>
					</div>
				</div>
			</div>
			<CustomModal
				show={showCopyComplete}
				setShow={setShowCopyComplete}
				title={"추천인 코드가 복사되었습니다."}
				titleCss={{ marginBottom: 29 }}
				mediaResult={true}
			/>
		</>
	);
};

export default Recommend;
