import React, { useEffect, useState } from "react";
import "../../pages/channel/Channel.scss";

import Slider from "react-slick"; // react-slick 사용을 위해 import

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import MallLoginComponent from "../common/MallLoginComponent";

import { useHistory } from "react-router-dom";

const SignInLoginComponent = () => {
	const history = useHistory();
	const [mediaResult, setMediaResult] = useState(null);

	useEffect(() => {
		const timer = setTimeout(() => {
			window.scrollTo({
				top: 0,
				behavior: "smooth",
			});
			return () => clearTimeout(timer);
		}, 100);

		if (typeof window !== "undefined") {
			const matchQueryList = window.matchMedia("all and (max-width: 1120px)");
			setMediaResult(matchQueryList.matches);

			const media = function handleChange(e) {
				setMediaResult(e.matches);
			};

			matchQueryList.addEventListener("change", media);
		}

		// 친구 추천 코드
		if (location.search.includes("referral_code")) {
			sessionStorage.setItem("referral_code", location.search.split("=")[1]);
		}
	}, []);

	const bannerList = [
		{
			name: mediaResult ? "landing-banner-1" : "landing-banner-1-pc",
			alt: "클릭만 했을 뿐인데 현금이 쌓여요.",
		},
		{
			name: mediaResult ? "landing-banner-2" : "landing-banner-2-pc",
			alt: "ZERO에서 시작? NO! 당신의 시작을 응원합니다.",
		},
		{
			name: mediaResult ? "landing-banner-3" : "landing-banner-3-pc",
			alt: "높은 수수료율로 수익 창출까지 손쉽게",
		},
		{
			name: mediaResult ? "landing-banner-4" : "landing-banner-4-pc",
			alt: "하나의 링크로 다른 제품의 수수료까지 모두 내 것으로!",
		},
	];
	let settings = {
		arrows: false,
		dots: true,
		infinite: true,
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: true,
		speed: 1000,
		autoplaySpeed: 5000,
	};
	return (
		<div className="noselect landing-home landing-new-home">
			{/* pc */}
			{/* <div className={`together-landing-banner-wrap sign-in-login-banner-wrap`}>
				<img
					src={`/assets/images/landing/landing-bg-2.png`}
					alt={`당영 투게더 1`}
				/>
				<div className={`together-landing-banner-title-wrap`}>
					<h1>로그인</h1>
					<p>당영투게더에 오신 것을 환영합니다.</p>
				</div>
			</div> */}
			{/* mobile */}
			<Slider {...settings}>
				{bannerList?.map(({ name, alt }, idx) => {
					return (
						<div
							key={idx}
							className={`together-landing-banner-wrap sign-in-login-banner-wrap`}
						>
							<img src={`/assets/images/landing/${name}.png`} alt={alt} />
						</div>
					);
				})}
			</Slider>
			<div className={`together-landing-banner-img-div signin`}>
				<div className={`mobile-title-div`}>
					<h1>로그인</h1>
					<p>당영투게더에 오신 것을 환영합니다.</p>
				</div>
				<div style={{ display: "flex", justifyContent: "center" }}>
					<MallLoginComponent />
				</div>
				<div className={`login-password-sub-div mobile`}>
					{!mediaResult && (
						<button
							style={{
								color: "#117ffa",
								marginTop: "2em",
							}}
							onClick={() =>
								window.open(
									"https://midas.dangyoung.com/login/before",
									"_blank"
								)
							}
						>
							기존 로그인으로 돌아가기
						</button>
					)}
					<button onClick={() => history.push(`/user/find`)}>
						<p id={`forget-password`}>비밀번호를 잊으셨나요?</p>
					</button>
				</div>
			</div>
		</div>
	);
};
export default SignInLoginComponent;
