import React, { useCallback, useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { Row, Skeleton } from "antd";
import moment from "moment";

import orderApi from "../../apis/Order.api";
import ApiService from "../../utils/ApiService";

import { negativeColor, positiveColor } from "./BannerAlgorithm";

const api = new ApiService();

const MonthSales = () => {
	const user = useSelector((state) => state.auth.user);
	const [loading, setLoading] = useState(false);

	const [mediaResult, setMediaResult] = useState(null);
	useEffect(() => {
		if (typeof window !== "undefined") {
			const matchQueryList = window.matchMedia("all and (max-width: 768px)");
			setMediaResult(matchQueryList.matches);

			const media = function handleChange(e) {
				setMediaResult(e.matches);
			};

			matchQueryList.addEventListener("change", media);
		}
	}, []);

	const [statistics, setStatistics] = useState({
		total: 0,
		compare_total: 0,
		compare_click: 0,
		compare_count: 0,
		compare_expect: 0,
	});

	const [compareList, setCompareList] = useState([
		{
			name: "구매 건수",
			count: 0,
			condition: 0,
			unit: "건",
		},
		{
			name: "클릭 건수",
			count: 0,
			condition: 0,
			unit: "회",
		},
		// {
		// 	name: "거래액",
		// 	count: 0,
		// 	condition: 0,
		// 	unit: "원",
		// },
		{
			name: "획득 골드",
			count: 0,
			condition: 0,
			unit: "G",
		},
	]);

	const [dataChart, setDataChart] = useState([]);

	const options = {
		responsive: true,
		maintainAspectRatio: false,
		legend: {
			position: "top",
			align: "center",
			display: false,
		},
		tooltips: {
			intersect: false,
			callbacks: {
				label: function (tooltipItem, data) {
					let tooltipsList = [];
					const item = data.datasets[0].item[tooltipItem.index];
					const purchasesNum = item?.purchases_num ?? 0;
					const clickCount = item?.click_count ?? 0;
					const purchaseAmount = item?.total_purchase_amount ?? 0;
					const expected_profit_amount =
						item?.expected_profit_amount + clickCount * 10 ?? 0;
					let value = data.datasets[0].data[tooltipItem.index];
					tooltipsList.push(`구매 건수: ${purchasesNum.toLocaleString()}건`);
					tooltipsList.push(`클릭 건수: ${clickCount.toLocaleString()}회`);
					// tooltipsList.push(`거래액: ${purchaseAmount.toLocaleString()}원`);
					tooltipsList.push(
						`획득 골드: ${(expected_profit_amount * 10).toLocaleString()}G`
					);
					return tooltipsList;
				},
			},
		},
		scales: {
			yAxes: [
				{
					scaleLabel: {
						display: false,
					},
					ticks: {
						display: false, // it should work
						beginAtZero: true,
						// padding: 3,
					},
					gridLines: {
						display: false,
					},
				},
			],
			xAxes: [
				{
					gridLines: {
						display: false,
					},
				},
			],
		},
	};

	// 클릭 수익 추가 (2024.08.08) - ksb
	const data = {
		labels: dataChart?.length
			? dataChart?.map((item) => moment(item.created_at).format("M월"))
			: [],
		datasets: [
			{
				minBarLength: 5,
				data: dataChart?.length
					? dataChart?.map(
							(item) => item.total_purchase_amount + item?.click_count * 100
					  )
					: [],
				backgroundColor: [
					"#adb5bd",
					"#adb5bd",
					"#adb5bd",
					"#adb5bd",
					statistics?.compare_total > 0 ? positiveColor : negativeColor,
					// "#df1d1d" : "#117ffb",
				],
				borderColor: [
					"#adb5bd",
					"#adb5bd",
					"#adb5bd",
					"#adb5bd",
					statistics?.compare_total > 0 ? positiveColor : negativeColor,
					// "#df1d1d" : "#117ffb",
				],
				borderWidth: 2,
				item: dataChart?.length ? dataChart : [],
			},
		],
	};

	useEffect(() => {
		if (user?.id) {
			const timer = setTimeout(() => {
				getStatistics();
			}, 100);
			return () => clearTimeout(timer);
		}
	}, [user]);

	const getStatistics = useCallback(async () => {
		setLoading(true);
		const param = {
			userId: user?.id,
		};
		const res = await api.getProductLinkList(param);
		if (res.data.code === 200 && res.data.result?.length) {
			const linkList = res.data.result.map((link) => link.query);
			const query = linkList.join(",");

			const paramBefore = {
				startDate: moment()
					.subtract(1, "months")
					.startOf("months")
					.format("YYYYMMDDHHmm"),
				endDate: moment()
					.subtract(1, "months")
					.endOf("months")
					.format("YYYYMMDDHHmm"),
				queryList: query,
			};
			const paramNow = {
				startDate: moment().startOf("months").format("YYYYMMDDHHmm"),
				endDate: moment().endOf("months").format("YYYYMMDDHHmm"),
				queryList: query,
			};

			let list = [];
			const paramMonthly = {
				startDate: moment()
					.subtract(4, "months")
					.startOf("months")
					.format("YYYYMMDDHHmm"),
				endDate: moment().endOf("months").format("YYYYMMDDHHmm"),
				queryList: query,
				dateType: "monthly",
			};
			const resM = await orderApi.orderStatistics(paramMonthly);
			if (resM.code === 200) {
				list = resM.result?.statisticsList ?? [];
			}
			setDataChart(list);

			const resB = await orderApi.orderStatistics(paramBefore);
			const resN = await orderApi.orderStatistics(paramNow);

			let beforeValue = {
				total: 0,
				click: 0,
				count: 0,
				expect: 0,
				combine: 0,
			};
			let nowValue = {
				total: 0,
				click: 0,
				count: 0,
				expect: 0,
				combine: 0,
			};

			if (resB.code === 200) {
				const before = resB.result;
				beforeValue = {
					total: before?.total_purchase_amount ?? 0,
					click: before?.click_count ?? 0,
					count: before?.purchases_num ?? 0,
					expect: before?.expected_profit_amount ?? 0,
					combine: before?.combined_amount ?? 0,
				};
			}
			if (resN.code === 200) {
				const now = resN.result;
				nowValue = {
					total: now?.total_purchase_amount ?? 0,
					click: now?.click_count ?? 0,
					count: now?.purchases_num ?? 0,
					expect: now?.expected_profit_amount ?? 0,
					combine: now?.combined_amount ?? 0,
				};
			}
			// 현금 -> 골드 계산 추가 (2024.08.08) - ksb
			const nowTotal = nowValue?.total + nowValue?.click * 100;
			const beforeTotal = beforeValue?.total + beforeValue?.click * 100;
			setStatistics({
				total: nowValue?.total + nowValue?.click * 100,
				compare_total: nowTotal - beforeTotal,
				compare_click: nowValue?.click - beforeValue?.click, // 사용 X (2024.08.08) - ksb
				compare_count: nowValue?.count - beforeValue?.count, // 사용 X (2024.08.08) - ksb
				compare_expect: nowValue?.expect - beforeValue?.expect, // 사용 X (2024.08.08) - ksb
			});

			const nowExpect = nowValue?.expect + Number(nowValue?.click) * 10;
			const beforeExpect =
				beforeValue?.expect + Number(beforeValue?.click) * 10;
			setCompareList([
				{
					name: "구매 건수",
					count: nowValue?.count,
					condition: nowValue?.count - beforeValue?.count,
					unit: "건",
				},
				{
					name: "클릭 건수",
					count: nowValue?.click,
					condition: nowValue?.click - beforeValue?.click,
					unit: "회",
				},
				// {
				// 	name: "거래액",
				// 	count: nowValue?.total,
				// 	condition: nowValue?.total - beforeValue?.total,
				// 	unit: "원",
				// },
				{
					name: "획득 골드",
					count: (nowValue?.expect + Number(nowValue?.click) * 10) * 10,
					condition: nowExpect - beforeExpect,
					unit: "G",
				},
			]);
		}
		setLoading(false);
	}, [user]);

	return (
		<>
			{mediaResult ? (
				// 모바일
				<div className={`dashboard-component`}>
					<h3>
						이번 달 실적
						<span className={`light-gray`}> {moment().format("M")}월</span>
					</h3>
					<div className={`dashboard-component-chart-bottom`}>
						<div className={`dashboard-big-price-div`}>
							{/* 이번 달 실적액 표기 */}
							<div className={`dashboard-point-with-img-div align-start`}>
								<Skeleton
									loading={loading}
									active={loading}
									size={"large"}
									block={true}
									className={`skeleton-point-price`}
									style={{
										height: "6em",
									}}
									title={null}
									paragraph={{
										rows: 2,
										width: "100%",
									}}
								>
									<div
										style={{
											display: "flex",
											alignItems: "baseline",
											gap: 5,
										}}
									>
										<h1>{statistics?.total?.toLocaleString()}</h1>
										<span className={"title-unit"}>G</span>
										<img
											src={`/assets/images/dashboard/${
												statistics?.compare_total > 0
													? "blue-up"
													: statistics?.compare_total < 0
													? "red-down"
													: "middle"
											}.png`}
											alt={`대시보드 경고 아이콘`}
											style={{
												width: "1.8em",
												height: "1.8em",
												marginLeft: 5,
											}}
										/>
									</div>
									<p className={`dark-gray`}>
										지난 달 대비
										<span
											style={{
												color:
													statistics?.compare_total > 0
														? positiveColor
														: statistics?.compare_total < 0
														? negativeColor
														: "inherit",
											}}
										>
											{" "}
											{Math.abs(statistics?.compare_total)?.toLocaleString()}
											<span className={"pre-date-unit"}>G</span>{" "}
											{statistics?.compare_total >= 0 ? "증가" : "감소"}
										</span>
									</p>
								</Skeleton>
							</div>
						</div>
					</div>

					{/* 구매 건수, 거래액, 예상 수익 - mobile-show */}
					<div
						className={`dashboard-point-with-img-div point-list point-list-mobile`}
						style={{
							paddingBottom: 0,
						}}
					>
						<Skeleton
							loading={loading}
							active={loading}
							size={"large"}
							block={true}
							style={{
								height: "6em",
							}}
							title={null}
							paragraph={{
								rows: 3,
								width: "100%",
							}}
						>
							{compareList?.map((item, idx) => (
								<div
									style={{
										display: "flex",
										justifyContent: "space-between",
										alignItems: "center",
										width: "100%",
										padding: "0.5em 0",
									}}
									key={`dashboard-today-${idx}`}
								>
									<p className={`dark-gray`}>{item.name}</p>
									<div style={{ display: "flex", alignItems: "center" }}>
										<p>
											{item.count?.toLocaleString()} <small>{item.unit}</small>
										</p>
										<img
											src={`/assets/images/dashboard/${
												item?.condition > 0
													? "up"
													: item?.condition < 0
													? "down"
													: "middle"
											}.png`}
											alt={`대시보드 경고 아이콘`}
											style={{
												width: "1.1em",
												height: "1.1em",
												marginLeft: 6,
											}}
										/>
									</div>
								</div>
							))}
						</Skeleton>
					</div>

					<div
						className={`border-top`}
						style={{
							position: "relative",
							width: "100%",
							height: "25vh",
							padding: "1.5em 0 0",
						}}
					>
						{loading ? (
							<Skeleton.Button
								loading={loading}
								active={loading}
								size={"large"}
								block={true}
								style={{
									height: "20vh",
								}}
								title={null}
							/>
						) : (
							<Bar options={options} data={data} />
						)}
						{!dataChart?.length && !loading && (
							<Row
								align={"middle"}
								style={{
									width: "100%",
									flexDirection: "column",
									padding: "3em 0",
									position: "absolute",
									top: "50%",
									left: "50%",
									transform: "translate(-50%, -50%)",
									height: "100%",
									justifyContent: "center",
								}}
							>
								<img
									src={`/assets/images/warning.png`}
									alt={`warning-icon`}
									style={{
										width: "3em",
										height: "auto",
									}}
								/>
								<p
									style={{
										marginTop: "1em",
										color: "#adb5bd",
										fontSize: "1.1em",
										fontWeight: 500,
									}}
								>
									최근 실적이 없습니다.
								</p>
							</Row>
						)}
					</div>

					<div className={`dashboard-component-footer`}>
						<Link to={`/reports`} className={`dashboard-footer-font`}>
							수익 리포트로 이동 {">"}
						</Link>
					</div>
				</div>
			) : (
				// PC
				<div className={`dashboard-component`}>
					<div style={{ display: "flex", width: "100%", gap: "2%" }}>
						<div
							className={`dashboard-component-chart-bottom`}
							style={{ width: "35%", gap: "1em" }}
						>
							<h3>
								이번 달 실적
								<span className={`light-gray`}> {moment().format("M")}월</span>
							</h3>

							{/* 이번 달 실적액 표기 */}
							<div
								className={`dashboard-point-with-img-div`}
								style={{
									alignItems: "flex-start",
								}}
							>
								<Skeleton
									loading={loading}
									active={loading}
									size={"large"}
									block={true}
									className={`skeleton-point-price`}
									style={{
										height: "6em",
										paddingRight: 0,
									}}
									title={null}
									paragraph={{
										rows: 2,
										width: "100%",
									}}
								>
									<div
										style={{
											display: "flex",
											alignItems: "baseline",
											gap: 5,
										}}
									>
										<h1>{statistics?.total?.toLocaleString()}</h1>
										<span className={"title-unit"}>G</span>
										<img
											src={`/assets/images/dashboard/${
												statistics?.compare_total > 0
													? "blue-up"
													: statistics?.compare_total < 0
													? "red-down"
													: "middle"
											}.png`}
											alt={`대시보드 경고 아이콘`}
											style={{
												width: "1.8em",
												height: "1.8em",
												marginLeft: 5,
											}}
										/>
									</div>
									<p className={`dark-gray`}>
										지난 달 대비
										<span
											style={{
												color:
													statistics?.compare_total > 0
														? // ? "#df1d1d"
														  positiveColor
														: statistics?.compare_total < 0
														? // ? "#117ffb"
														  negativeColor
														: "inherit",
											}}
										>
											{" "}
											{Math.abs(statistics?.compare_total)?.toLocaleString()}
											<span className={"pre-date-unit"}>G</span>{" "}
											{statistics?.compare_total >= 0 ? "증가" : "감소"}
										</span>
									</p>
								</Skeleton>
							</div>

							{/* 구매 건수, 거래액, 예상 수익 - mobile-hidden */}
							<div
								className={`dashboard-point-with-img-div border-top`}
								style={{
									padding: "0.5em 0",
								}}
							>
								<Skeleton
									loading={loading}
									active={loading}
									size={"large"}
									block={true}
									style={{
										height: "6em",
									}}
									title={null}
									paragraph={{
										rows: 3,
										width: "100%",
									}}
								>
									{compareList?.map((item, idx) => (
										<div
											style={{
												display: "flex",
												justifyContent: "space-between",
												alignItems: "center",
												width: "100%",
												padding: "0.4em 0",
											}}
											key={`dashboard-today-${idx}`}
										>
											<p className={`dark-gray`}>{item.name}</p>
											<div style={{ display: "flex", alignItems: "center" }}>
												<p>
													{item.count?.toLocaleString()}{" "}
													<small>{item.unit}</small>
												</p>
												<img
													src={`/assets/images/dashboard/${
														item?.condition > 0
															? "up"
															: item?.condition < 0
															? "down"
															: "middle"
													}.png`}
													alt={`대시보드 경고 아이콘`}
													style={{
														width: "1.1em",
														height: "1.1em",
														marginLeft: 6,
													}}
												/>
											</div>
										</div>
									))}
								</Skeleton>
							</div>
						</div>

						<div
							className={`dashboard-component-chart-bottom border-left`}
							style={{
								width: "63%",
								padding: "1.5em",
								// height: "calc(100% + 3em)",
								margin: "-1.5em 0 -1em",
							}}
						>
							<h3>월 실적 분석</h3>
							<div
								style={{
									position: "relative",
									width: "100%",
									height: "25vh",
									padding: "1.5em 0 0",
									flex: "auto",
								}}
							>
								{loading ? (
									<Skeleton.Button
										loading={loading}
										active={loading}
										size={"large"}
										block={true}
										style={{
											height: "20vh",
										}}
										title={null}
									/>
								) : (
									<Bar options={options} data={data} />
								)}
								{!dataChart?.length && !loading && (
									<Row
										align={"middle"}
										style={{
											width: "100%",
											flexDirection: "column",
											padding: "3em 0",
											position: "absolute",
											top: "50%",
											left: "50%",
											transform: "translate(-50%, -50%)",
											height: "100%",
											justifyContent: "center",
										}}
									>
										<img
											src={`/assets/images/warning.png`}
											alt={`warning-icon`}
											style={{
												width: "3em",
												height: "auto",
											}}
										/>
										<p
											style={{
												marginTop: "1em",
												color: "#adb5bd",
												fontSize: "1.1em",
												fontWeight: 500,
											}}
										>
											최근 실적이 없습니다.
										</p>
									</Row>
								)}
							</div>
						</div>
					</div>
					<div className={`dashboard-component-footer`}>
						<Link to={`/reports`} className={`dashboard-footer-font`}>
							수익 리포트로 이동 {">"}
						</Link>
					</div>
				</div>
			)}
		</>
	);
};

export default MonthSales;
