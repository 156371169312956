import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { Button, Input } from "antd";

import Swal from "sweetalert2";

import ApiService from "../../apis/ApiService_mall";
import channelUserApi from "../../apis/ChannelUser.api";
import { handleIsMallUserWithPartners } from "../../pages/user/LoginUitls";
import { setLoading } from "../../redux/store/common/commonDuck";
const mallApi = new ApiService();
const MallLoginComponent = () => {
	const [mediaResult, setMediaResult] = useState(null);
	const [togetherId, setTogetherId] = useState(0);

	const dispatch = useDispatch();
	const history = useHistory();
	const isLoading = useSelector((state) => state.common.loading);
	const setIsLoading = (value) => dispatch(setLoading(value));

	useEffect(() => {
		window.scrollTo({
			top: 0,
		});
		if (typeof window !== "undefined") {
			const matchQueryList = window.matchMedia("all and (max-width: 768px)");
			setMediaResult(matchQueryList.matches);

			const media = function handleChange(e) {
				setMediaResult(e.matches);
			};

			matchQueryList.addEventListener("change", media);
		}
	}, []);
	/**
	 * 일반 로그인 데이터
	 */
	const [userLogin, setUserLogin] = useState({
		phone: "",
		password: "",
	});

	/**
	 * 일반 로그인 정보 받기
	 * @param e
	 */
	const handleUserLogin = (e) => {
		let { name, value } = e.target;
		if (name === "phone") {
			if (value?.split("-").join("").length <= 11) {
				let phone = value
					?.replace(/[^0-9]/g, "")
					.replace(/^(\d{2,3})(\d{3,4})(\d{4})$/g, "$1-$2-$3")
					.replace(/(-{1,2})$/g, "");
				setUserLogin({
					...userLogin,
					[name]: phone,
				});
			}
		} else {
			setUserLogin({
				...userLogin,
				[name]: value,
			});
		}
	};

	/**
	 * 사용자 이력 남기기
	 */

	/**
	 * 일반 로그인 버튼 클릭
	 */
	const submitUserLogin = async () => {
		setIsLoading(true);
		const param = {
			...userLogin,
			phone: userLogin.phone.replaceAll("-", ""),
		};

		let res = await mallApi.phoneLogin(param);
		if (res.data.code === 200) {
			const user = res.data.result.user;
			localStorage.removeItem("admin:accessToken");
			localStorage.removeItem("admin:refreshToken");
			localStorage.setItem(
				"admin:accessToken",
				res.data.result.tokenDto.accessToken
			);
			localStorage.setItem(
				"admin:refreshToken",
				res.data.result.tokenDto.refreshToken
			);
			setIsLoading(false);
			await handleIsMallUserWithPartners(user, dispatch, history);
		} else {
			Swal.fire({
				customClass: "alert-custom-confirm",
				text: `${res.data.msg}`,
				confirmButtonText: "확인",
				confirmButtonColor: "#117FFA",
			});
			setIsLoading(false);
		}
		setIsLoading(false);
	};

	return (
		<div className={`login-page-div mall-login-page-div`}>
			<div className={`login-div`}>
				<div className={`login-input-div`}>
					{/* <p className={`login-title`}>휴대폰 번호</p> */}
					<Input
						type={"text"}
						placeholder={`휴대폰번호`}
						pattern="\d*"
						name={"phone"}
						value={userLogin.phone}
						onChange={handleUserLogin}
						disabled={isLoading}
					/>
				</div>
				<div className={`login-input-div`}>
					{/* <p className={`login-title`}>비밀번호</p> */}
					<Input
						type={"password"}
						placeholder={`비밀번호`}
						name={"password"}
						value={userLogin.password}
						onChange={handleUserLogin}
						onPressEnter={submitUserLogin}
						disabled={isLoading}
					/>
				</div>
				<div style={{ display: "flex", flexDirection: "column" }}>
					{/* <button
						className={`signup-user-phone-button`}
						onClick={() => {
							sessionStorage.removeItem("snsUserInfo");
							history.push(`/user/signup`);
						}}
					>
						휴대폰 번호로 회원가입
					</button> */}
					<Button
						className={`blue-login-button`}
						style={{
							fontSize: "1em",
							marginTop: "1.5em",
						}}
						onClick={submitUserLogin}
						loading={isLoading}
					>
						로그인
					</Button>
					<Button
						className={`white-signup-button`}
						style={{
							fontSize: "1em",
							marginTop: "0.3em",
							marginBottom: "0",
						}}
						onClick={() => history.push("/user/signup")}
						loading={isLoading}
					>
						회원가입
					</Button>
				</div>
				{/* <p className={`signup-login-footer-text`}>
					당영투게더 회원가입 시 당영몰과 연동되며{"\n"}
					당영몰 아이디로도 당영투게더에 로그인이 가능합니다.
				</p> */}
				{mediaResult && (
					<button
						style={{
							color: "#117ffa",
							marginTop: "2em",
						}}
						onClick={() =>
							window.open("https://midas.dangyoung.com/login/before", "_blank")
						}
					>
						기존 로그인으로 돌아가기
					</button>
				)}
			</div>
		</div>
		// <div className={`login-password-sub-div`}>
		// 	<button onClick={() => history.push(`/user/find`)}>
		// 		<p id={`forget-password`}>비밀번호를 잊으셨나요?</p>
		// 	</button>
		// 	{/*<p>아이디 저장</p>*/}
		// </div>
	);
};

export default MallLoginComponent;
