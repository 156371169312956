import "./User.scss";
import * as React from "react";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { Button, Input } from "antd";

import Swal from "sweetalert2";

import ApiService from "../../apis/ApiService_mall";
import { setLoading } from "../../redux/store/common/commonDuck";

import SignUpHeader from "./SignUpHeader";

const api = new ApiService();
const FindPasswordComponent = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const isLoading = useSelector((state) => state.common.loading);
	const setIsLoading = (value) => dispatch(setLoading(value));

	let regPhone = /010-?([0-9]{4})-?([0-9]{4})$/;
	const regCode = /([0-9]{6})$/;
	const [mediaResult, setMediaResult] = useState(null);
	const [userValue, setUserValue] = useState({
		phone: "",
		phone_valid: "",
		password: "",
		password_valid: "",
	});

	const [phoneValid, setPhoneValid] = useState(false); // 인증번호 전송
	const [isPhoneValid, setIsPhoneValid] = useState(false); // 인증번호 확인

	const [passwordRegValid, setPasswordRegValid] = useState(false); // 비밀번호 대소문자,숫자,특수문자 true/false
	const [passwordCheckValid, setPasswordCheckValid] = useState(false); // 비밀번호 확인

	const [inputValid, setInputValid] = useState(false); // 전체 의동의

	useEffect(() => {
		window.scrollTo({ top: 0 });
		if (typeof window !== "undefined") {
			const matchQueryList = window.matchMedia("all and (max-width: 768px)");
			setMediaResult(matchQueryList.matches);

			const media = function handleChange(e) {
				setMediaResult(e.matches);
			};

			matchQueryList.addEventListener("change", media);
		}
	}, []);

	/**
	 * 회원 정보 받기
	 * @type {(function(*): void)|*}
	 */
	const handleInputUser = useCallback(
		(e) => {
			const { value, name } = e.target;
			if (name === "phone") {
				setPhoneValid(false);
				setIsPhoneValid(false);
				if (value.split("-").join("").length <= 11) {
					let phone = value
						.replace(/[^0-9]/g, "")
						.replace(/^(\d{2,3})(\d{3,4})(\d{4})$/g, "$1-$2-$3")
						.replace(/(-{1,2})$/g, "");
					setUserValue({
						...userValue,
						phone: phone,
					});
				}
				// if (value.split("-").join("").length <= 11) {
				// 	let phone = value.replace(/[^0-9]/g, "");
				// 	setUserValue({
				// 		...userValue,
				// 		phone: phone,
				// 	});
				// }
			} else if (name === "phone_valid") {
				if (value.split("-").join("").length <= 6) {
					let phone_valid = value.replace(/[^0-9]/g, "");
					setUserValue({
						...userValue,
						phone_valid: phone_valid,
					});
				}
			} else if (name === "password") {
				let password = value;
				// 특수문자 / 문자 / 숫자 포함 형태의 8~16 자리 이내의 암호 정규식
				let regPass =
					/^.*(?=^.{8,16}$)(?=.*\d)(?=.*[a-zA-Z])(?=.*[!@#$%^&+=]).*$/;
				if (password.match(regPass)) {
					setPasswordRegValid(true);
				} else {
					setPasswordRegValid(false);
				}

				// 숫자, 문자, 특수문자만 입력 가능
				const regExp = /[^0-9a-zA-Z!@#$%^&+=]/g;
				if (regExp.test(value)) {
					password = value.replace(regExp, "");
				}
				setUserValue({
					...userValue,
					[name]: password,
				});

				const regPassword =
					/^(?=.*[A-Za-z])(?=.*\d)[a-zA-Z\\d`~!@#$%^&*()-_=+(?=.*\d)]{8,}$/;
				if (regPassword.test(e.target.value)) {
					if (!passwordRegValid) setPasswordRegValid(true);
				} else {
					if (passwordRegValid) setPasswordRegValid(false);
				}
			} else if (name === "password_valid") {
				setUserValue({
					...userValue,
					[name]: value,
				});
				if (userValue.password === value) {
					setPasswordCheckValid(true);
				} else {
					setPasswordCheckValid(false);
				}
			} else {
				setUserValue({
					...userValue,
					[name]: value,
				});
			}
		},
		[userValue]
	);

	/**
	 * step 2-1 : 휴대폰 인증
	 */
	const sendPhoneValid = async () => {
		if (regPhone.test(userValue.phone)) {
			setIsLoading(true);

			let param = {
				phone: userValue.phone.replaceAll("-", ""),
				is_user_valid: true,
			};
			let res = await api.smsPhoneCertifySend(param);

			if (res.data.code === 200) {
				setPhoneValid(true);
				setIsLoading(false);
			} else {
				setIsLoading(false);

				Swal.fire({
					customClass: "alert-custom-confirm",
					text: `해당 번호로 가입된 유저가 없습니다. 회원가입 하시겠습니까?`,
					confirmButtonText: "확인",
					confirmButtonColor: "#117FFA",
				}).then((res) => {
					if (res.isConfirmed) {
						history.push(`/user/signup`);
					}
				});
			}
		} else {
			Swal.fire({
				customClass: "alert-custom-confirm",
				text: "휴대폰 번호를 정확하게 입력해주세요.",
				confirmButtonText: "확인",
				confirmButtonColor: "#117FFA",
			});
		}
	};

	/**
	 * step 2-2: 휴대폰 인증번호 일치 확인
	 */
	const checkPhoneValid = async () => {
		if (regCode.test(userValue.phone_valid)) {
			setIsLoading(true);

			let param = {
				phone: userValue.phone.replaceAll("-", ""),
				sms_verify_code: userValue.phone_valid,
			};
			let res = await api.smsCertifyVerify(param);

			if (res.data.code === 200) {
				Swal.fire({
					customClass: "alert-custom-confirm",
					// text: "인증번호가 일치합니다.",
					text: "본인인증이 완료되었습니다.",
					confirmButtonText: "다음",
					confirmButtonColor: "#117FFA",
				}).then((res) => {
					if (res.isConfirmed) {
						// history.push(`/user/signup`);
					}
				});
				setIsPhoneValid(true);
				setIsLoading(false);
			} else {
				Swal.fire({
					customClass: "alert-custom-confirm",
					text: "인증번호가 일치하지 않습니다.",
					confirmButtonText: "확인",
					confirmButtonColor: "#117FFA",
				});
				setIsLoading(false);
			}
		} else {
			Swal.fire({
				customClass: "alert-custom-confirm",
				text: "인증번호 6자리를 정확하게 입력해주세요.",
				confirmButtonText: "확인",
				confirmButtonColor: "#117FFA",
			});
		}
	};

	/**
	 * 입력 값 밸리데이션 -> signupValue 변할 때 마다 적용
	 */
	useEffect(() => {
		const { phone } = userValue;
		if (
			phone?.replaceAll("-", "").length !== 11 ||
			!phoneValid ||
			!isPhoneValid ||
			!passwordRegValid ||
			!passwordCheckValid
		) {
			if (inputValid) {
				setInputValid(false);
			}
		} else {
			setInputValid(true);
		}
	}, [passwordRegValid, userValue]);

	/**
	 * 변경하기 버튼 클릭
	 */
	const handleSignupButton = () => {
		if (isPhoneValid && passwordCheckValid) {
			setIsLoading(true);
			const timer = setTimeout(() => {
				updatePassword();
			}, 100);
			return () => clearTimeout(timer);
		} else {
			Swal.fire({
				customClass: "alert-custom-confirm",
				text: "입력 항목을 모두 적어주세요.",
				confirmButtonText: "확인",
				confirmButtonColor: "#117FFA",
			});
		}
	};

	/**
	 * 비밀번호 변경
	 */
	const updatePassword = async () => {
		setIsLoading(true);
		const regPassword =
			/^(?=.*[A-Za-z])(?=.*\d)[a-zA-Z\\d`~!@#$%^&*()-_=+(?=.*\d)]{8,}$/;
		if (regPassword.test(userValue.password) && !!passwordCheckValid) {
			let param = {
				phone: userValue.phone.replaceAll("-", ""),
				password: userValue.password,
			};
			let res = await api.updatePhonePassword(param);

			if (res.data.code === 200) {
				Swal.fire({
					customClass: "alert-custom-confirm",
					text: "비밀번호가 성공적으로 변경되었습니다.",
					confirmButtonText: "확인",
					confirmButtonColor: "#117FFA",
				}).then(() => {
					setIsLoading(false);
					history.replace(`/`);
				});
			} else {
				Swal.fire({
					customClass: "alert-custom-confirm",
					text: "일시적인 오류로 실패하였습니다. 다시 시도해주세요.",
					confirmButtonText: "확인",
					confirmButtonColor: "#117FFA",
				});
				setIsLoading(false);
			}
		} else {
			Swal.fire({
				customClass: "alert-custom-confirm",
				text: "8자리 이상 비밀번호를 입력해주세요. (최소 영문, 숫자 1개 이상 포함)",
				confirmButtonText: "확인",
				confirmButtonColor: "#117FFA",
			});
			setIsLoading(false);
		}
	};

	return (
		<div className="main-wrap">
			{/* 모바일 헤더 추가 */}
			<SignUpHeader
				title={isPhoneValid ? "비밀번호 재설정" : "비밀번호 찾기"}
				back={true}
				close={false}
			/>
			<div className="max-width-apply">
				{/* 기본 정보 */}
				<div className={`signup-form-parents`}>
					<h1 className={`main-title find-pwd`} id={`main-title`}>
						{isPhoneValid ? "비밀번호 재설정" : "비밀번호 찾기"}
					</h1>
					{!mediaResult && (
						<h2
							style={{
								textAlign: "center",
								fontSize: "1.05em",
								marginBottom: "1.3em",
								lineHeight: 1,
							}}
						>
							{isPhoneValid
								? "새로운 비밀번호를 입력해주세요."
								: "비밀번호를 재설정할 휴대폰 번호를 입력해 주세요."}
						</h2>
					)}

					<div className={`signup-form-wrap find-pwd`}>
						{mediaResult ? (
							// 모바일 시작
							<>
								{isPhoneValid ? (
									// 모바일 비밀번호 변경
									<>
										<div className={`signup-form-item padding`}>
											<label>새로운 비밀번호</label>
											<div className={`input-div`}>
												<Input
													type={`password`}
													placeholder={`비밀번호를 입력해주세요.`}
													value={userValue.password}
													name={"password"}
													onChange={handleInputUser}
													pattern={`/d*`}
													style={{
														borderColor:
															userValue.password?.length > 0 &&
															!passwordRegValid &&
															"#FF0000",
														boxShadow:
															userValue.password?.length > 0 &&
															!passwordRegValid &&
															"0 0 0 2px rgba(255, 77, 79, .2)",
													}}
												/>
											</div>
											<div className={`help-text-wrap`}>
												<p className={`help-text`}>
													8자리 이상의 비밀번호를 입력해주세요.
												</p>
												<p className={`help-text`}>
													(최소 영문, 숫자 1개 이상 포함)
												</p>
											</div>
										</div>
										<div className={`signup-form-item padding`}>
											<label>새로운 비밀번호 확인</label>
											<div className={`input-div`}>
												<Input
													type={`password`}
													placeholder={`비밀번호를 입력해주세요.`}
													value={userValue.password_valid}
													name={"password_valid"}
													onChange={handleInputUser}
													pattern={`/d*`}
													style={{
														borderColor:
															userValue.password_valid?.length > 0 &&
															!passwordCheckValid &&
															"#FF0000",
														boxShadow:
															userValue.password_valid?.length > 0 &&
															!passwordCheckValid &&
															"0 0 0 2px rgba(255, 77, 79, .2)",
													}}
												/>
											</div>
										</div>
									</>
								) : (
									// 모바일 휴대폰 번호 인증
									<>
										<div
											className={`signup-form-item ${
												phoneValid ? "padding" : "divider"
											}`}
										>
											<label>휴대폰 번호</label>
											<div className={`input-div phone`}>
												<Input
													type={`text`}
													placeholder={`-를 생략하고 입력해주세요.`}
													value={userValue.phone}
													name={"phone"}
													onChange={handleInputUser}
													pattern={`/d*`}
													disabled={isPhoneValid}
												/>
											</div>
										</div>
										{phoneValid && (
											<div
												className={`signup-form-item divider`}
												// style={{ paddingTop: 0 }}
											>
												<label>인증번호</label>
												<div
													className={`input-div phone`}
													style={{ width: mediaResult ? "100%" : "30%" }}
												>
													<Input
														type={`text`}
														placeholder={`인증번호 6자리를 입력해주세요.`}
														value={userValue.phone_valid}
														name={"phone_valid"}
														onChange={handleInputUser}
														pattern={`/d*`}
													/>
												</div>
											</div>
										)}
									</>
								)}
							</>
						) : (
							// pc 시작
							<>
								{/*  new  pc  비밀번호 찾기 */}
								<div style={{ display: "flex", justifyContent: "center" }}>
									{!isPhoneValid && (
										<div
											className={`login-page-div mall-login-page-div find-pwd-div`}
										>
											<div className={`login-div`}>
												<div className={`login-input-div`}>
													<p className={`login-title`}>휴대폰 번호</p>
													<Input
														type={`text`}
														placeholder={`-를 생략하고 입력해주세요.`}
														value={userValue.phone}
														name={"phone"}
														onChange={handleInputUser}
														pattern={`/d*`}
														disabled={isPhoneValid}
													/>
												</div>
												{phoneValid && (
													<div className={`login-input-div`}>
														<p className={`login-title`}>인증번호</p>
														<Input
															type={`text`}
															placeholder={`인증번호 6자리를 입력해주세요.`}
															value={userValue.phone_valid}
															name={"phone_valid"}
															onChange={handleInputUser}
															pattern={`/d*`}
															disabled={isPhoneValid}
														/>
													</div>
												)}
												<div
													style={{ display: "flex", flexDirection: "column" }}
												>
													{!phoneValid && (
														<Button
															className={`blue-login-button`}
															loading={isLoading}
															disabled={isPhoneValid}
															onClick={sendPhoneValid}
															style={{ marginTop: "2.2em" }}
														>
															다음
														</Button>
													)}
													{phoneValid && (
														<Button
															className={`blue-login-button`}
															loading={isLoading}
															disabled={!userValue.phone_valid}
															onClick={checkPhoneValid}
															style={{ marginTop: "2.2em" }}
														>
															다음
														</Button>
													)}
												</div>
											</div>
										</div>
									)}
									{/* new  pc 비밀번호 재설정 */}
									{isPhoneValid && (
										<div
											className={`login-page-div mall-login-page-div find-pwd-div`}
										>
											<div className={`login-div`}>
												<>
													<div className={`login-input-div`}>
														<p className={`login-title`}>새로운 비밀번호</p>
														<Input
															type={`password`}
															placeholder={`비밀번호를 입력해주세요.`}
															value={userValue.password}
															name={"password"}
															onChange={handleInputUser}
															pattern={`/d*`}
															style={{
																borderColor:
																	userValue.password?.length > 0 &&
																	!passwordRegValid &&
																	"red",
															}}
														/>
														<p
															className={`help-text`}
															style={{
																lineHeight: 1.15,
																color: "#adb5bd",
																fontSize: "0.85em",
																padding: "0.6em 0 0.6em 1.2em",
															}}
														>
															8자리 이상의 비밀번호를 입력해주세요. <br />
															(최소 영문, 숫자 1개 이상 포함)
														</p>
													</div>

													<div className={`login-input-div`}>
														<p className={`login-title`}>
															새로운 비밀번호 확인
														</p>
														<Input
															type={`password`}
															placeholder={`비밀번호를 입력해주세요.`}
															value={userValue.password_valid}
															name={"password_valid"}
															onChange={handleInputUser}
															pattern={`/d*`}
															style={{
																borderColor:
																	userValue.password_valid?.length > 0 &&
																	!passwordCheckValid &&
																	"red",
															}}
														/>
													</div>
												</>
												<div
													style={{ display: "flex", flexDirection: "column" }}
												>
													{isPhoneValid && !mediaResult && (
														<Button
															loading={isLoading}
															className={`blue-login-button`}
															onClick={handleSignupButton}
															style={{ marginTop: "1.8em" }}
														>
															변경하기
														</Button>
													)}
												</div>
											</div>
										</div>
									)}
								</div>
							</>
						)}
					</div>
				</div>
			</div>
			<div className={`signup-fixed-bottom`}>
				{/*비밀번호 변경하기 & 인증번호 요청 및 확인하기*/}
				{isPhoneValid ? (
					<Button
						loading={isLoading}
						className={`signup-blue-button`}
						onClick={handleSignupButton}
					>
						변경하기
					</Button>
				) : (
					<Button
						loading={isLoading}
						disabled={
							phoneValid
								? !regCode.test(userValue.phone_valid)
								: !regPhone.test(userValue.phone)
						}
						onClick={() => {
							if (phoneValid) {
								checkPhoneValid();
							} else {
								sendPhoneValid();
							}
						}}
					>
						{!isLoading && (phoneValid ? "확인" : "인증요청")}
					</Button>
				)}
			</div>
		</div>
	);
};

export default FindPasswordComponent;
