import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Button, Dropdown, Input, Modal } from "antd";

import Swal from "sweetalert2";

import partnersApi from "../../apis/Partners.api";
import { setUser } from "../../redux/store/auth/authDuck";
import { setLoading } from "../../redux/store/common/commonDuck";
import { isGeneralBank } from "../../utils/status";
import PersonalAuthentication from "../common/PersonalAuthentication";

import SendAccount1Modal from "./SendAccount1Modal";
import UserVerificationCompleteModal from "./UserVerificationCompleteModal";
import VerifyAccountModal from "./VerifyAccountModal";

const UserVerificationModal = ({ show, setShow, mediaResult, bankList }) => {
	const dispatch = useDispatch();

	const user = useSelector((state) => state.auth.user);
	const isLoading = useSelector((state) => state.common.loading);
	const setIsLoading = (value) => dispatch(setLoading(value));

	const [certifiedData, setCertifiedData] = useState({});
	const [isClickBankDropdown, setIsClickBankDropdown] = useState(false);
	const [selectedBank, setSelectedBank] = useState({
		code: null,
		name: null,
	}); // 선택한 은행
	const [submitBank, setSubmitBank] = useState({
		bank_code: "",
		account_number: "",
		is_submit: false,
	}); // 계좌번호 인증
	const [checkBankAccountNumber, setCheckBankAccountNumber] =
		useState(undefined);
	const [submitBankLoading, setSubmitBankLoading] = useState(false);
	const [isShowModalCheckBankAccount, setIsShowModalCheckBankAccount] =
		useState(false);
	const [isShowModalSuccessBankAccount, setIsShowModalSuccessBankAccount] =
		useState(false);
	const [showCompleteModal, setShowCompleteModal] = useState(false);
	const [userValue, setUserValue] = useState({
		name: "",
		first_personal_number: "",
		second_personal_number: "",
		accountName: "",
	});
	const [showCancelModal, setShowCancelModal] = useState(false);

	const items =
		bankList?.length > 0
			? bankList?.filter(isGeneralBank)?.map((item) => ({
					label: item.name,
					key: item.code,
					icon: (
						<img
							src={`/assets/images/bank/${item.code}.png`}
							style={{
								width: 16,
								height: 16,
							}}
							onError={(e) => {
								e.currentTarget.style.visibility = "hidden";
							}}
						/>
					),
			  }))
			: [];

	/**
	 * 계좌인증
	 * @param type account-입력한 계좌번호로 1원 전송하기 / code-1원 전송한 계좌 코드 입력하기
	 * @returns {Promise<void>}
	 */
	const verifyAccount = async (type = "account") => {
		try {
			setSubmitBankLoading(true);
			let param = {
				...submitBank,
				type: type,
				user_id: user?.id,
			};
			if (type === "account") {
				param["name"] = userValue.name;
				param["birthday"] = userValue.first_personal_number;
			} else {
				param["code"] = checkBankAccountNumber;
			}
			const res = await partnersApi.verifyAccount(param);
			if (res.data.code === 200) {
				if (type === "account") {
					setIsShowModalCheckBankAccount(true);
					setCheckBankAccountNumber(null);
				} else {
					setIsShowModalSuccessBankAccount(true);
					setCheckBankAccountNumber(undefined);
					// 계좌 소유자 본명으로 처리
					setUserValue({
						...userValue,
						accountName: userValue.name,
					});
					setSubmitBank({
						...submitBank,
						is_submit: true,
					});
				}
			} else {
				Swal.fire({
					customClass: "alert-custom-confirm",
					text: `${res.data.msg}`,
					confirmButtonText: "확인",
					confirmButtonColor: "#117FFA",
				});
			}
		} catch (e) {
			console.log("verifyAccount :: e = ", e);
		} finally {
			setSubmitBankLoading(false);
		}
	};

	/**
	 * 가입하기 버튼 클릭
	 */
	const handleSubmit = () => {
		// 유저 - 이름, 주민번호(앞/뒤)
		// 계좌 - 은행코드, 계좌번호
		let valid =
			userValue.name?.length > 0 &&
			userValue.first_personal_number?.length > 0 &&
			userValue.second_personal_number?.length > 0 &&
			submitBank.bank_code?.length > 0 &&
			submitBank.account_number?.length > 0 &&
			submitBank.is_submit &&
			certifiedData.is_certified;
		if (valid) {
			setIsLoading(true);
			const timer = setTimeout(() => {
				updateUserInformation();
			}, 100);
			return () => clearTimeout(timer);
		} else {
			Swal.fire({
				customClass: "alert-custom-confirm",
				text: "필수 입력 항목을 모두 적어주세요.",
				confirmButtonText: "확인",
				confirmButtonColor: "#117FFA",
			});
			const timer = setTimeout(() => {
				window.scrollTo({
					top: 0,
					behavior: "smooth",
				});
			}, 100);
			return () => clearTimeout(timer);
		}
	};

	const updateUserInformation = async () => {
		let param = {
			id: user.id,
			key: "additional_info",
			name: userValue.name,
			personal_number:
				userValue.first_personal_number +
				"-" +
				userValue.second_personal_number,
			bank_code: submitBank.bank_code,
			account_number: submitBank.account_number,
			is_app: false,
			account_holder_name: userValue.accountName,
		};
		const res = await partnersApi.postPersonalUserData(param);
		if (res.code === 200) {
			dispatch(
				setUser({
					...user,
					// bank_code: res.result?.bank_code,
					// account_number: res.result?.account_number,
					nickname: res.result?.nickname,
					// phone: res.result?.phone,
					is_influencer: true,
					is_certified: true,
					// account_holder_name: res.result?.account_holder_name,
				})
			);

			setShowCompleteModal(true);
		} else if (res.code === 998) {
			Swal.fire({
				customClass: "alert-custom-confirm",
				text: "입력하신 이름이나 연락처가\n인증된 정보와 일치하지 않습니다.",
				confirmButtonText: "확인",
				confirmButtonColor: "#117FFA",
			}).then(() => {
				console.log("updateUserInformation");
			});
		}
		setIsLoading(false);
	};

	/**
	 * 은행 항목 선택하기
	 * @param key
	 */
	const onClick = ({ key }) => {
		let clickBank = bankList.filter((item) => item.code === key);
		if (clickBank?.[0]?.code) {
			setSelectedBank(clickBank?.[0] ?? { code: null, name: null });
			setSubmitBank({
				...submitBank,
				bank_code: clickBank[0].code,
				is_submit: false,
			});
			setUserValue({
				...userValue,
				accountName: "",
			});
			setCheckBankAccountNumber(undefined);
			setIsClickBankDropdown(false);
		}
	};

	/**
	 * 회원 정보 받기
	 * @type {(function(*): void)|*}
	 */
	const handleInputUser = useCallback(
		(e) => {
			const { value, name } = e.target;

			// 주민번호 앞자리
			if (name === "first_personal_number") {
				setCertifiedData({
					...certifiedData,
					is_certified: false,
				});
				if (value.split("-").join("").length <= 6) {
					let number = value.replace(/[^0-9]/g, "");
					setUserValue({
						...userValue,
						first_personal_number: number,
					});
				}
			}
			// 주민번호 뒷자리
			else if (name === "second_personal_number") {
				setCertifiedData({
					...certifiedData,
					is_certified: false,
				});
				if (value.split("-").join("").length <= 7) {
					let number = value.replace(/[^0-9]/g, "");
					setUserValue({
						...userValue,
						second_personal_number: number,
					});
				}
			}
			// 휴대폰 번호
			else if (name === "phone") {
				if (value.split("-").join("").length <= 11) {
					let phone = value.replace(/[^0-9]/g, "");
					setUserValue({
						...userValue,
						phone: phone,
					});
				}
			} else {
				setUserValue({
					...userValue,
					[name]: value,
				});
			}
		},
		[userValue]
	);

	/**
	 * 취소하기
	 */
	const handleCancel = () => {
		setUserValue({
			name: "",
			first_personal_number: "",
			second_personal_number: "",
			accountName: "",
		});
		setSubmitBank({
			bank_code: "",
			account_number: "",
			is_submit: false,
		});
		setSelectedBank({
			code: null,
			name: null,
		});
		setCertifiedData({});
		setCheckBankAccountNumber(undefined);
	};

	return (
		<>
			<Modal
				title=""
				wrapClassName="account-verification-modal-wrap"
				open={show}
				closable={false}
				maskClosable={false}
				onCancel={() => setShow(false)}
				footer={null}
			>
				<h1 className={`main-title`} id={`main-title`}>
					본인인증
				</h1>
				<div className={`account-verification-form-wrap`}>
					<div className="wrap-verification-for-pc">
						{/* 주민번호 */}
						<div
							className={`account-verification-form-item-wrap identification-item`}
						>
							<label>
								주민등록번호
								{/* <span className={`item-required`}>*</span> */}
							</label>
							<div className={`input-wrap personal-number`}>
								<Input
									type={`text`}
									value={userValue.first_personal_number}
									name={"first_personal_number"}
									onChange={handleInputUser}
									pattern={`/d*`}
									disabled={isLoading}
								/>
								<div className={`divider`} />
								<Input
									type={`password`}
									value={userValue.second_personal_number}
									name={"second_personal_number"}
									onChange={handleInputUser}
									pattern={`/d*`}
									disabled={isLoading}
								/>
							</div>
							{/* 본인 인증 */}
							<PersonalAuthentication
								certifiedData={certifiedData}
								setCertifiedData={setCertifiedData}
								disabled={
									!userValue.first_personal_number.length ||
									!userValue.second_personal_number.length
								}
							/>
							<p className={`help-text`}>
								<span>대금 정산</span>을 위하여 주민등록번호를 소득세법 제
								145조에 따라 수집이용하며, 이 법에 따라 5년 동안 보관합니다.
								{"\n"}
								가입시 확인한 본인 인증 정보와 동일한 주민등록 정보가 제공되어야
								합니다.
							</p>
						</div>
					</div>

					{/* 계좌 소유자 */}
					<div
						className={`account-verification-form-item-wrap account-name-item`}
					>
						<label>
							계좌 소유자
							{/* <span className={`item-required`}>*</span> */}
						</label>
						<div className={`input-wrap`}>
							<Input
								type={`text`}
								placeholder={`계좌 소유자명을 입력해주세요.`}
								value={userValue.name}
								name={"name"}
								onChange={handleInputUser}
							/>
						</div>
					</div>
					{/* 은행 */}
					<div className={`account-verification-form-item-wrap`}>
						<label>
							은행
							{/* <span className={`item-required`}>*</span> */}
						</label>
						<div className={`input-wrap`}>
							<Dropdown
								overlayClassName={`bank-dropdown-wrap`}
								menu={{
									items,
									onClick,
								}}
								trigger={["click"]}
								open={isClickBankDropdown}
								onOpenChange={(open) => setIsClickBankDropdown(open)}
								overlayStyle={{
									maxHeight: 200,
									overflow: "auto",
								}}
							>
								<Input
									loading={isLoading}
									placeholder={"은행을 선택해 주세요."}
									value={selectedBank.name}
									suffix={
										<img
											src={`/assets/images/dropdown-${
												isClickBankDropdown ? "up" : "down"
											}-gray.png`}
											alt={`dropdown-icon`}
											onClick={() =>
												setIsClickBankDropdown(!isClickBankDropdown)
											}
										/>
									}
									style={{
										cursor: "pointer",
									}}
								/>
							</Dropdown>
						</div>
					</div>
					{/* 계좌번호 */}
					<div className={`account-verification-form-item-wrap`}>
						<label>
							계좌 번호
							{/* <span className={`item-required`}>*</span> */}
						</label>
						<div className={`input-wrap account-input`}>
							<Input
								placeholder={`계좌번호를 입력해주세요.`}
								disabled={isLoading}
								value={submitBank.account_number}
								onChange={(e) => {
									setSubmitBank({
										...submitBank,
										account_number: e.target.value?.replace(/[^0-9]/g, ""),
										is_submit: false,
									});
									setUserValue({
										...userValue,
										accountName: "",
									});
									setCheckBankAccountNumber(undefined);
								}}
								pattern={`/d*`}
							/>
							{submitBank.is_submit ? (
								<div className="input-div-button-success">
									<img
										src={`/assets/images/success_button_icon.png`}
										alt={`success_button_icon`}
										style={{
											width: "1em",
											height: "auto",
										}}
									/>
									<span>계좌인증</span>
								</div>
							) : (
								<Button
									className="certification-request-btn"
									onClick={() => {
										if (!submitBankLoading) {
											verifyAccount("account");
										}
									}}
									loading={submitBankLoading}
									disabled={
										!userValue.name.length ||
										!userValue.first_personal_number.length ||
										!submitBank.bank_code ||
										!submitBank.account_number ||
										submitBankLoading
									}
								>
									{!submitBankLoading && "인증요청"}
								</Button>
							)}
						</div>
						{mediaResult && (
							<div className={`help-text-wrap`}>
								<span>*</span>
								<p className={`help-text`}>
									<span>계좌번호 오기입</span>
									으로 인해 다른계좌로 송금 시 당영투게더에서는 책임지지
									않습니다.
									{"\n"}
									다시 한번 더 확인 부탁드립니다.
								</p>
							</div>
						)}
					</div>
					{checkBankAccountNumber !== undefined && !submitBank.is_submit && (
						<>
							<div className={`account-verification-form-item-wrap`}>
								<p className={`account-verification-code-label`}>
									입금자명 표시된 인증번호{" "}
									<span className={`text-highlight`}>4자리</span>를 입력해
									주세요.
								</p>
								<div className={`account-verification-example-img-wrap`}>
									<div className={`account-verification-example-img-item-wrap`}>
										<p className={`title`}>입금자명</p>
										<p className={`description`}>
											DY_<span className={`code-wrap`}>****</span>
										</p>
									</div>
									<div
										className={`account-verification-example-img-item-wrap end-item`}
									>
										<p className={`title`}>입금</p>
										<p className={`description`}>1원</p>
									</div>
								</div>
							</div>
							<div className={`account-verification-form-item-wrap`}>
								<label>
									{/* 인증번호 <span className={`item-required`}>*</span> */}
								</label>
								<div className={`input-wrap account-input`}>
									<Input
										placeholder={`인증번호를 입력해주세요.`}
										disabled={isLoading}
										value={checkBankAccountNumber}
										onChange={(e) => {
											if (e.target.value?.length <= 4) {
												setCheckBankAccountNumber(
													e.target.value?.replace(/[^0-9]/g, "")
												);
											}
										}}
										pattern={`/d*`}
									/>
									<Button
										className="certification-request-btn"
										onClick={() => {
											if (!submitBankLoading) {
												verifyAccount("code");
											}
										}}
										loading={submitBankLoading}
										disabled={
											!submitBank.bank_code ||
											!submitBank.account_number ||
											!checkBankAccountNumber
										}
									>
										{!submitBankLoading && "확인"}
									</Button>
								</div>
							</div>
						</>
					)}
					{!mediaResult && (
						<div className={`help-text-wrap`}>
							<p
								className={`help-text`}
								style={{
									fontSize: "1.15em",
									textAlign: "center",
									marginTop: "1em",
								}}
							>
								<span style={{ color: "#117ffa", fontWeight: 600 }}>
									* 계좌번호 오기입
								</span>
								으로 인해 다른계좌로 송금 시 당영투게더에서는 책임지지 않습니다.
								{"\n"}
								다시 한번 더 확인 부탁드립니다.
							</p>
						</div>
					)}
					<div className={`account-verification-form-btn-wrap`}>
						<Button
							loading={isLoading}
							className={`confirm`}
							disabled={
								!(
									userValue.name?.length > 0 &&
									userValue.first_personal_number?.length > 0 &&
									userValue.second_personal_number?.length > 0 &&
									submitBank.bank_code?.length > 0 &&
									submitBank.account_number?.length > 0 &&
									submitBank.is_submit &&
									certifiedData.is_certified
								)
							}
							onClick={handleSubmit}
						>
							확인
						</Button>
						<Button
							className={`cancel`}
							onClick={() => setShowCancelModal(true)}
						>
							취소
						</Button>
					</div>
				</div>
			</Modal>

			{/*계좌 1원 입금완료 모달*/}
			<SendAccount1Modal
				show={isShowModalCheckBankAccount}
				setShow={setIsShowModalCheckBankAccount}
				mediaResult={mediaResult}
			/>
			{/*계좌인증완료 모달*/}
			<VerifyAccountModal
				show={isShowModalSuccessBankAccount}
				setShow={setIsShowModalSuccessBankAccount}
				mediaResult={mediaResult}
			/>
			{/*취소 모달*/}
			<Modal
				title=""
				open={showCancelModal}
				footer={null}
				closable={false}
				wrapClassName="modal-wrap seller-grade-modal-wrap custom-modal-wrap"
				width={400}
				centered={mediaResult}
			>
				<div className="modal-title" style={{ wordBreak: "keep-all" }}>
					진행중인 내용이 전부 취소됩니다.{"\n"}그래도 취소하시겠습니까?
				</div>
				<div className="modal-button-wrap">
					<div
						className="modal-button-success"
						onClick={() => {
							setShowCancelModal(false);
							setShow(false);
							handleCancel();
						}}
					>
						확인
					</div>
					<div
						className="modal-button-cancel"
						onClick={() => setShowCancelModal(false)}
					>
						취소
					</div>
				</div>
			</Modal>
			{/*본인인증 완료 모달*/}
			<UserVerificationCompleteModal
				show={showCompleteModal}
				setShow={setShowCompleteModal}
				mediaResult={mediaResult}
				handleClickConfirm={() => {
					setShow(false);
					window.location.replace("/dashboard");
				}}
			/>
		</>
	);
};

export default UserVerificationModal;
