import * as React from "react";

import { Modal } from "antd";

const CustomModal = ({
	show,
	setShow,
	isCancelShow,
	mediaResult,
	title,
	titleCss,
	handleClick,
}) => {
	return (
		<Modal
			title=""
			open={show}
			footer={null}
			closable={false}
			wrapClassName="modal-wrap seller-grade-modal-wrap custom-modal-wrap"
			width={400}
			centered={mediaResult}
		>
			<div className="modal-title" style={titleCss ? titleCss : {}}>
				{title}
			</div>
			<div className="modal-button-wrap">
				{isCancelShow && (
					<div onClick={() => setShow(false)} className={`modal-button-cancel`}>
						취소
					</div>
				)}
				<div
					onClick={() => {
						setShow(false);
						if (handleClick) {
							handleClick();
						}
					}}
					className="modal-button-success"
				>
					확인
				</div>
			</div>
		</Modal>
	);
};

export default CustomModal;
