import { all, put, takeLatest } from "redux-saga/effects";

const authTypes = {
	CHECK_USER_REQUEST: "CHECK_USER_REQUEST",
	CHECK_USER_SUCCESS: "CHECK_USER_SUCCESS",
	CHECK_USER_FAILURE: "CHECK_USER_FAILURE",

	LOGIN_REQUEST: "LOGIN_REQUEST",
	LOGIN_SUCCESS: "LOGIN_SUCCESS",
	LOGIN_FAILURE: "LOGIN_FAILURE",

	SET_USER: "SET_USER",
	SET_SNS_USER_INFO: "SET_SNS_USER_INFO",
	SET_REG_ACCOUNT: "SET_REG_ACCOUNT",
};

function* checkLoginUser$(action) {
	// try {
	//     const { data: { result: userData } } = yield apiClient.get(`/auth-user?expands=image,addresses,membership`);
	//     if (window.location.pathname === '/membership' && !userData?._relations?.membership) {
	//         yield call(forwardTo('/membership/invite'));
	//     } else {
	//         yield put({
	//             type: authTypes.CHECK_USER_SUCCESS,
	//             isAuthenticated: Boolean(userData),
	//             user: userData,
	//         });
	//     }
	// } catch (error) {
	//     yield put({
	//         type: authTypes.CHECK_USER_FAILURE,
	//         error
	//     });
	// }
}

function* userIdLogin$(action) {
	try {
		yield put({
			type: authTypes.LOGIN_SUCCESS,
			// token: data.api_token,
			// user: data.user,
		});
	} catch (error) {
		yield put({
			type: authTypes.LOGIN_FAILURE,
			error,
		});
	}
}

export default function* authSaga() {
	yield all([
		takeLatest(authTypes.CHECK_USER_REQUEST, checkLoginUser$),
		takeLatest(authTypes.LOGIN_REQUEST, userIdLogin$),
	]);
}
