import React from "react";
import { useHistory } from "react-router-dom";

const SignUpHeader = ({ title, back = false, close = true, handleGoBack }) => {
	const history = useHistory();
	const onClickClose = () => {
		history.goBack();
	};
	return (
		<div className={`header together-header-height signup-header`}>
			{back && (
				<img
					onClick={() => {
						if (handleGoBack) {
							handleGoBack();
						} else {
							onClickClose();
						}
					}}
					src={`/assets/images/go-back.png`}
					alt={`닫기`}
					style={{
						left: 0,
					}}
				/>
			)}
			<p>{title}</p>
			{close && (
				<img
					onClick={onClickClose}
					src={`/assets/images/channel/close-icon.png`}
					alt={`닫기`}
					style={{
						right: 0,
					}}
				/>
			)}
		</div>
	);
};
export default SignUpHeader;
