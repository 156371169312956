import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import produce, { enableES5 } from "immer";

const produceFunc = (...args) => {
	enableES5();
	return produce(...args);
};

export const getAuth = (state) => state.auth;

const authTypes = {
	CHECK_USER_REQUEST: "CHECK_USER_REQUEST",
	CHECK_USER_SUCCESS: "CHECK_USER_SUCCESS",
	CHECK_USER_FAILURE: "CHECK_USER_FAILURE",

	LOGIN_REQUEST: "LOGIN_REQUEST",
	LOGIN_SUCCESS: "LOGIN_SUCCESS",
	LOGIN_FAILURE: "LOGIN_FAILURE",

	SET_USER: "SET_USER",
	SET_SNS_USER_INFO: "SET_SNS_USER_INFO",
	SET_REG_ACCOUNT: "SET_REG_ACCOUNT",
	SET_REG_SUCCESS: "SET_REG_SUCCESS",

	SET_REG_SUCCESS_DATA: "SET_REG_SUCCESS_DATA",

	SET_PROIFLE_IMG: "SET_PROIFLE_IMG",
	SET_CHANNEL_USER: "SET_CHANNEL_USER",
	SET_SNS_CHANNEL_USER: "SET_SNS_CHANNEL_USER",

	SET_MENU_LIST: "SET_MENU_LIST",
};

export const initialState = {
	loading: false,
	user: null,
	isAuthenticated: false,
	snsUserInfo: null,
	regAccount: null,
	regSuccess: false,
	regSuccessData: null,
	profileImg: null,
	channelUser: {},
	snsChannelUser: {},
	menuList: [],
};

// reducer
export const authReducer = persistReducer(
	{ storage, key: "auth", whitelist: [] },
	(state = initialState, action) => {
		return produceFunc(state, (draft) => {
			switch (action.type) {
				case authTypes.SET_USER:
					draft.user = action.user;
					break;

				case authTypes.SET_SNS_USER_INFO:
					draft.snsUserInfo = action.snsUserInfo;
					break;

				case authTypes.SET_REG_ACCOUNT:
					draft.regAccount = action.regAccount;
					break;

				case authTypes.SET_REG_SUCCESS:
					draft.regSuccess = action.regSuccess;
					break;

				case authTypes.SET_REG_SUCCESS_DATA:
					draft.regSuccessData = action.regSuccessData;
					break;

				case authTypes.SET_PROIFLE_IMG:
					draft.profileImg = action.profileImg;
					break;

				case authTypes.SET_CHANNEL_USER:
					draft.channelUser = action.channelUser;
					break;

				case authTypes.SET_SNS_CHANNEL_USER:
					draft.snsChannelUser = action.snsChannelUser;
					break;

				case authTypes.SET_MENU_LIST:
					draft.menuList = action.menuList;
					break;

				default:
					break;
			}
		});
	}
);

export const checkLoginUser = () => ({
	type: authTypes.CHECK_USER_REQUEST,
});

export const userIdLogin = (user_id, password, remember = false) => ({
	type: authTypes.LOGIN_REQUEST,
	user_id,
	password,
	remember,
});

export const setUser = (user) => ({
	type: authTypes.SET_USER,
	user,
});

export const setRegSuccess = (regSuccess) => ({
	type: authTypes.SET_REG_SUCCESS,
	regSuccess,
});

export const setRegSuccessData = (regSuccessData) => ({
	type: authTypes.SET_REG_SUCCESS_DATA,
	regSuccessData,
});

export const setChannelUser = (channelUser) => ({
	type: authTypes.SET_CHANNEL_USER,
	channelUser,
});

export const setSnsChannelUser = (snsChannelUser) => ({
	type: authTypes.SET_SNS_CHANNEL_USER,
	snsChannelUser,
});

export const setMenuList = (menuList) => ({
	type: authTypes.SET_MENU_LIST,
	menuList,
});

export default authReducer;
