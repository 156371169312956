import React, { useEffect, useState } from "react";

const CustomPagination = ({
	pageSize,
	totalCount,
	currPageNum,
	pageClick,
	customStyle,
}) => {
	const [pageArray, setPageArray] = useState([]);
	const [pageCount, setPageCount] = useState(0);
	const [lastPage, setLastPage] = useState(0);
	const [lastPageArray, setLastPageArray] = useState([]);

	useEffect(() => {
		let last = 1;
		if (totalCount !== 0) {
			last = Math.ceil(totalCount / pageSize);
		}
		const lastNum = last % 10;
		let tensDigit = 0;
		if (last >= 10) {
			tensDigit = Math.floor(last / 10);
		}
		let firstNum = 0;
		if (lastNum <= 5) {
			if (tensDigit !== 0) {
				firstNum = Number(tensDigit + "1");
			} else {
				firstNum = 1;
			}
		} else {
			if (tensDigit !== 0) {
				firstNum = Number(tensDigit + "6");
			} else {
				firstNum = 6;
			}
		}
		if (firstNum > 0) {
			let newPageArray = [];
			for (let i = firstNum; i <= last; i++) {
				newPageArray.push(i);
			}
			setLastPageArray(newPageArray);
		}
		setLastPage(last);
		setPageCount(last > 5 ? 5 : last);
	}, [totalCount]);

	useEffect(() => {
		setPageArray(Array.from({ length: pageCount }, (_, index) => index + 1));
	}, [pageCount]);

	/**
	 * pagination 리스트 수정
	 */
	const pageArrayChange = (e) => {
		if (e.target.id === "page-down") {
			if (pageArray[0] !== 1) {
				let first = pageArray[0] - 5;
				setPageArray(Array.from({ length: 5 }, (_, index) => index + first));
				pageClick(first);
			}
		} else if (e.target.id === "page-up") {
			let length = lastPage - pageArray[pageArray.length - 1];
			const nextFirst = pageArray[pageArray.length - 1] + 1;
			const nextEnd =
				pageArray[pageArray.length - 1] + 5 > lastPage
					? lastPage
					: pageArray[pageArray.length - 1] + 5;
			if (length > 0) {
				let newPageArray = [];
				for (let i = nextFirst; i < nextEnd + 1; i++) {
					newPageArray.push(i);
				}
				setPageArray(newPageArray);
				pageClick(nextFirst);
			}
		} else if (e.target.id === "page-first") {
			setPageArray(
				Array.from(
					{ length: lastPage > 5 ? 5 : lastPage },
					(_, index) => index + 1
				)
			);
			pageClick(1);
		} else if (e.target.id === "page-last") {
			const lastNum = lastPage % 10;
			let tensDigit = 0;
			if (lastPage >= 10) {
				tensDigit = Math.floor(lastPage / 10);
			}
			let firstNum = 0;
			if (lastNum <= 5) {
				if (tensDigit !== 0) {
					firstNum = Number(tensDigit + "1");
				} else {
					firstNum = 1;
				}
			} else {
				if (tensDigit !== 0) {
					firstNum = Number(tensDigit + "6");
				} else {
					firstNum = 6;
				}
			}
			if (firstNum > 0) {
				let newPageArray = [];
				for (let i = firstNum; i <= lastPage; i++) {
					newPageArray.push(i);
				}
				setPageArray(newPageArray);
				pageClick(lastPage);
			}
		}
	};

	const prePageDisabled = () => {
		if (
			currPageNum === 1 ||
			currPageNum === 2 ||
			currPageNum === 3 ||
			currPageNum === 4 ||
			currPageNum === 5
		) {
			return true;
		} else {
			return false;
		}
	};

	const nextPageDisabled = () => {
		const findIdx = lastPageArray.findIndex((page) => page === currPageNum);
		if (findIdx > -1) {
			return true;
		} else {
			return false;
		}
	};

	return (
		<div className={`custom-pagination-div`} style={customStyle ?? {}}>
			<div className={`pagination-arrow-div`}>
				<img
					id="page-first"
					src={`/assets/images/double-left-arrow${
						currPageNum === 1 ? "-inactive" : ""
					}.png`}
					alt={`pagination-icons`}
					onClick={(e) => {
						if (currPageNum !== 1) {
							pageArrayChange(e);
						}
					}}
					style={{
						width: 18,
						height: 18,
					}}
				/>
				<img
					id="page-down"
					src={`/assets/images/left-arrow${
						prePageDisabled() ? "-inactive" : ""
					}.png`}
					alt={`pagination-icons`}
					onClick={(e) => {
						if (currPageNum !== 1) {
							pageArrayChange(e);
						}
					}}
					style={{
						width: 18,
						height: 18,
					}}
				/>
			</div>
			{pageArray.map((number, idx) => (
				<button
					type={`button`}
					key={`페이지네이션 ${idx}`}
					className={`pagination-button ${
						currPageNum === number ? "active" : ""
					}`}
					onClick={() => pageClick(number)}
				>
					<span>{number}</span>
				</button>
			))}
			<div className={`pagination-arrow-div`}>
				<img
					id="page-up"
					src={`/assets/images/right-arrow${
						currPageNum === lastPage || nextPageDisabled() ? "-inactive" : ""
					}.png`}
					alt={`pagination-icons`}
					onClick={(e) => {
						if (currPageNum !== lastPage) {
							pageArrayChange(e);
						}
					}}
					style={{
						width: 18,
						height: 18,
					}}
				/>
				<img
					id="page-last"
					src={`/assets/images/double-right-arrow${
						currPageNum === lastPage ? "-inactive" : ""
					}.png`}
					alt={`pagination-icons`}
					onClick={(e) => {
						if (currPageNum !== lastPage) {
							pageArrayChange(e);
						}
					}}
					style={{
						width: 18,
						height: 18,
					}}
				/>
			</div>
		</div>
	);
};

export default CustomPagination;
