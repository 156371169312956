import axios from "axios";
const baseUrl = `${process.env.REACT_APP_BACKEND_HOST}`;

const orderStatistics = async (param) => {
	const { data } = await axios.get(`${baseUrl}/userOrder/together/readStatus`, {
		params: param,
	});
	return data;
};

const orderByInfluencer = async (param) => {
	const { data } = await axios.get(
		`${baseUrl}/userOrder/detailList/searchByInfluencer`,
		{ params: param }
	);
	return data;
};

const orderApi = {
	orderStatistics,
	orderByInfluencer,
};

export default orderApi;
