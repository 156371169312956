import { useHistory, useLocation } from "react-router-dom";

import { Row } from "antd";

const LinkComponent = () => {
	const history = useHistory();
	const location = useLocation();
	const path = location.pathname;

	const pathname = () => {
		if (path) {
			if (path.includes("/notice")) {
				return "공지사항";
			} else {
				switch (path) {
					case "/reports_analysis/category":
						return "채널 분석";
					case "/reports_analysis/contents":
						return "채널 분석";
					case "/reports_analysis/products":
						return "채널 분석";
					case "/reports":
						return "리포트";
					case "/products":
						return "상품 링크";
					case "/edit/channel":
						return "사이트 관리";
					case "/user_comment_manage":
						return "댓글 관리";
					case "/user_mission_comment_manage":
						return "미션 댓글 관리";
					case "/group_buying":
						return "공동구매";
					case "/user_message":
						return "메세지 발송";
					case "/user_app_push":
						return "앱 푸시 발송";
					case "/user_manage":
						return "회원 관리";
					case "/inquiry_manage":
						return "상담 답변";
					case "/edit/board":
						return "게시판 관리";
					case "/faq":
						return "자주묻는 질문";
					default:
						return "";
				}
			}
		}
	};

	return (
		<div className={`link-wrap`}>
			<Row
				align={"middle"}
				style={{ gap: "3px", cursor: "pointer" }}
				onClick={() => history.push("/")}
				wrap={false}
			>
				{/*<img*/}
				{/*	src={`/assets/images/products/home_icon.png`}*/}
				{/*	alt={`화살표`}*/}
				{/*	style={{*/}
				{/*		height: "0.8em",*/}
				{/*		width: "auto",*/}
				{/*	}}*/}
				{/*/>*/}
				<span>홈</span>
			</Row>
			<img
				src={`/assets/images/products/arrow.png`}
				alt={`화살표`}
				style={{
					height: "auto",
					width: "1em",
				}}
			/>
			<p>{pathname()}</p>
		</div>
	);
};
export default LinkComponent;
