import "../../Calculate.scss";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import queryString from "querystring";

import { Tabs } from "antd";

import Swal from "sweetalert2";

import inquiryApi from "../../apis/Inquiry.api";
import InquiryAdd from "../../components/calculate/InquiryAdd";
import InquiryList from "../../components/calculate/InquiryList";
import SignUpHeader from "../../components/user/SignUpHeader";
import { setLoading } from "../../redux/store/common/commonDuck";

const UserInquiry = () => {
	const pageSize = 10;

	const dispatch = useDispatch();
	const history = useHistory();
	const queryJson = queryString.parse(
		history.location?.search?.replace("?", "")
	);
	const user = useSelector((state) => state.auth.user);
	const setIsLoading = (value) => dispatch(setLoading(value));

	const [mediaResult, setMediaResult] = useState(null);
	const [currentMenu, setCurrentMenu] = useState("list");
	const [inquiryList, setInquiryList] = useState([]);
	const [totalCnt, setTotalCnt] = useState(0);
	const [formValue, setFormValue] = useState({
		title: "", // 문의제목
		name: "", // 성함
		company_contact: "", // 휴대폰 번호
		question_content: "", // 문의내용
	});

	/**
	 * tab 클릭 이벤트 handler
	 * @param e
	 */
	const handleMenu = (e, completeStatus = false) => {
		if (e !== currentMenu) {
			if (e === "list") {
				if (completeStatus) {
					setCurrentMenu(e);
					if (queryJson && queryJson?.type) {
						history.replace("/user/inquiry");
					}
					// 페이지 맨 위로 이동
					window.scrollTo(0, 0);
					readInquiryList();
				} else {
					handleCancel();
				}
			} else {
				setCurrentMenu(e);
				if (queryJson && queryJson?.type) {
					history.replace("/user/inquiry");
				}
				// 페이지 맨 위로 이동
				window.scrollTo(0, 0);
			}
		}
	};

	/**
	 * 문의 목록 조회
	 * @returns {Promise<void>}
	 */
	const readInquiryList = async () => {
		try {
			setIsLoading(true);
			const param = {
				user_id: user?.id ?? 0,
				isApp: false,
				isTogether: true,
				offset: 0,
				pageSize: pageSize,
			};
			const res = await inquiryApi.readInquiryListByUser(param);
			if (res.code === 200) {
				setInquiryList(res.result?.csInquiryList);
				setTotalCnt(res.result?.total);
			} else {
				setInquiryList([]);
				setTotalCnt(0);
			}
		} catch (e) {
			console.log("readInquiryList :: error = ", e);
			Swal.fire({
				customClass: "alert-custom-confirm",
				text: "예기치 못한 오류가 발생했습니다. 새로고침 후 다시 시도해주세요.",
				confirmButtonText: "확인",
				confirmButtonColor: "#117FFA",
			});
		} finally {
			setIsLoading(false);
		}
	};

	/**
	 * 문의 취소하기
	 */
	const handleCancel = () => {
		if (
			formValue.name ||
			formValue.company_contact ||
			formValue.title ||
			formValue.question_content
		) {
			// 입력한 값이 하나라도 있으면 취소하겠냐는 팝업 띄우기
			Swal.fire({
				customClass: "alert-custom-confirm",
				text: `작성중인 내용이 모두 사라집니다.\n정말 취소하시겠습니까?`,
				showCancelButton: true,
				confirmButtonText: "확인",
				confirmButtonColor: "#117FFA",
				cancelButtonText: "취소",
				cancelButtonColor: "#FFFFFF",
			}).then((res) => {
				if (res.isConfirmed) {
					// Form 초기화
					setFormValue({
						title: "", // 문의제목
						name: "", // 성함
						company_contact: "", // 휴대폰 번호
						question_content: "", // 문의내용
					});
					if (queryJson && queryJson?.type) {
						history.replace("/user/inquiry");
					}
					setCurrentMenu("list");
				}
			});
		} else {
			// Form 초기화
			setFormValue({
				title: "", // 문의제목
				name: "", // 성함
				company_contact: "", // 휴대폰 번호
				question_content: "", // 문의내용
			});
			if (queryJson && queryJson?.type) {
				history.replace("/user/inquiry");
			}
			setCurrentMenu("list");
		}
	};

	const menuItems = [
		{
			label: "문의내역",
			key: "list",
			children: (
				<InquiryList
					inquiryList={inquiryList}
					setInquiryList={setInquiryList}
					totalCnt={totalCnt}
					setTotalCnt={setTotalCnt}
					readInquiryList={readInquiryList}
					pageSize={pageSize}
				/>
			),
		},
		{
			label: "문의하기",
			key: "create",
			children: (
				<InquiryAdd
					formValue={formValue}
					setFormValue={setFormValue}
					mediaResult={mediaResult}
					handleMenu={handleMenu}
				/>
			),
		},
	];

	useEffect(() => {
		if (typeof window !== "undefined") {
			const matchQueryList = window.matchMedia("all and (max-width: 768px)");
			setMediaResult(matchQueryList.matches);

			const media = function handleChange(e) {
				setMediaResult(e.matches);
			};

			matchQueryList.addEventListener("change", media);
		}
	}, []);

	useEffect(() => {
		if (
			queryJson &&
			queryJson?.type &&
			queryJson?.type === "create" &&
			currentMenu !== "create"
		) {
			setCurrentMenu(queryJson?.type);
		}
	}, [queryJson]);

	return (
		<>
			<SignUpHeader title={"문의"} back={true} close={false} />
			<div className="main-wrap padding-0">
				{!mediaResult && (
					<p
						style={{
							padding: "1em 0",
							fontSize: "1.5em",
							fontWeight: 700,
						}}
					>
						문의
					</p>
				)}
				<Tabs
					className={`calculate-inquiry-tab-bar-wrap`}
					activeKey={currentMenu}
					items={menuItems}
					onChange={handleMenu}
				/>
			</div>
		</>
	);
};

export default UserInquiry;
