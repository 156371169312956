import "../../Product.scss";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { Button, Input } from "antd";

import CustomModal from "../common/CustomModal";
import SignUpHeader from "../user/SignUpHeader";

const ProductLinkComponent = () => {
	const history = useHistory();
	const location = useLocation();
	const state = location.state;

	const [showCopyComplete, setCopyComplete] = useState(false);

	const featureList = {
		title: state.item?.usp,
		contentList: [
			...Array.from({ length: 6 }).map((arr, idx) => {
				return state.item[`information_${idx + 1}`];
			}),
		].filter((arr) => arr !== null),
	};

	useEffect(() => {
		window.scrollTo({
			top: 0,
		});
	}, []);

	const handleCopyLink = (e) => {
		window.navigator.clipboard
			.writeText(
				`https://www.dangyoung.com/products/${state.item?.target_id}?query=${state?.url}`
			)
			.then(() => {
				setCopyComplete(true);
				// alert("링크 복사가 완료 되었습니다.");
			});
	};

	return (
		<div className="main-wrap">
			{/* 모바일 헤더 추가 */}
			<SignUpHeader title={"링크생성"} back={true} close={false} />
			<div className={`product-link-content-wrap`}>
				<div className={`product-link-list-wrap`}>
					<label>
						{state.item?.brand_name} {state.item?.name} URL
					</label>
					<Input
						type={`text`}
						value={`https://www.dangyoung.com/products/${state.item?.target_id}?query=${state?.url}`}
					/>
				</div>
				<div className={`product-link-list-wrap`}>
					<label>제품 설명</label>
					<div className={`product-link-product-content-wrap`}>
						<div className={`product-link-product-img-wrap`}>
							<img
								src={`${process.env.REACT_APP_S3_HOST}/image/recommend/${state.item.target_id}.jpg`}
								onError={({ currentTarget }) => {
									currentTarget.onerror = null; // prevents looping
									currentTarget.src = `/assets/images/no-product.png`;
								}}
								alt={`${state.item.brand_name} ${state.item.name}`}
								style={{
									objectFit: "cover",
								}}
							/>
						</div>
						{featureList?.contentList?.length > 0 && (
							<div className={`product-link-create-description-wrap`}>
								<p className={`title`}>{featureList.title}</p>
								<p className={`product-name`}>
									{state.item?.brand_name} {state.item?.name}
								</p>
								<div className={`feature-list-wrap`}>
									{featureList.contentList?.map((item, idx) => (
										<div className={`feature-list`} key={`featureList-${idx}`}>
											<p>0{idx + 1}</p>
											<p>{item}</p>
										</div>
									))}
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
			<div className={`product-link-btn-wrap`}>
				<Button className={`confirm`} onClick={handleCopyLink}>
					링크 복사하기
				</Button>
				<Button
					className={`cancel`}
					onClick={() => {
						history.goBack();
					}}
				>
					취소
				</Button>
			</div>

			{/*모달 - 링크복사 완료 모달*/}
			<CustomModal
				show={showCopyComplete}
				setShow={setCopyComplete}
				mediaResult={true}
				title={"링크복사가 완료되었습니다."}
				titleCss={{
					marginBottom: 29,
				}}
			/>
		</div>
	);
};

export default ProductLinkComponent;
